import React, { PureComponent } from 'react';
import './TasksPosition.css';

import ListAssignableTasks from '../../components/listAssignableTasks/ListAssignableTasks'
import AddAssignableTask from '../../components/addAssignableTask/AddAssignableTask'

import * as subscriptions from '../../graphql/subscriptions';
import * as assignableTaskApi from '../../utils/api/assignableTask';
import * as positionApi from '../../utils/api/position';
import * as participantApi from '../../utils/api/participant';
import * as taskValidationApi from '../../utils/api/taskValidation';
import * as protocolTaskApi from '../../utils/api/protocolTask';
import * as userApi from '../../utils/api/user';

import { API, graphqlOperation } from 'aws-amplify';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Utils from '../../utils/utils';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Link } from "react-router-dom";
import { Button, Box } from '@material-ui/core';
import { GroupPositionsRoute } from '../../Routing';
import moment from 'moment';


class TasksPosition extends PureComponent {
    state = {
        positionInfo: null,
        upgradedList: [],
        listAssignableTasks: [],
        listValidation: [],
        protocolTasksList: [],
        staffList: [],
        participantInfo: null,
        loading: false,
        lastUpdate: new Date().toISOString(),
        lastEvent: new Date(null).toISOString(),
        isStudyManager: false,
    };

    componentDidMount() {
        this.queryCompleteList();
        let refreshId = setInterval(this.updateFromTimer.bind(this), 1000);
        this.setState({ refreshId: refreshId });
        this.checkGroups();
        // Subscribe to new task
        this.subscriptionNew = this.subscribeNewTask();
        // Subscribe to delete task
        this.subscriptionDelete = this.subscribeDeleteTask();
        // Subscribe to edit task
        this.subscriptionUpdate = this.subscribeUpdateTask();
    }

    async checkGroups() {
        const isStudyManager = await userApi.isStudyManager();
        this.setState({
            isStudyManager: isStudyManager,
        })
    }

    async updateFromEvent(lastEventArg) {
        if (lastEventArg !== this.state.lastUpdate && moment.duration(new Date(lastEventArg) - new Date(this.state.lastEvent)).asSeconds() > 1) {
            console.log('Update display from event')
            this.setState({
                lastUpdate: lastEventArg,
                lastEvent: lastEventArg,
            })
        } else {
            this.setState({
                lastEvent: lastEventArg,
            })
        }
    }

    async updateFromTimer() {
        const currentDate = new Date().toISOString();

        if (this.state.lastEvent !== this.state.lastUpdate && moment.duration(new Date(currentDate) - new Date(this.state.lastEvent)).asSeconds() > 1) {
            console.log('update display from Timer')
            this.setState({
                lastUpdate: this.state.lastEvent,
            })
        }
    }


    async queryCompleteList() {
        if (!this.props.match.params.id) {
            return
        }
        this.setState({ loading: true })
        // Get validation list
        const listValidationRow = await taskValidationApi.listTaskValidationByPosition(this.props.match.params.id)
        const listValidation = listValidationRow.items ? listValidationRow.items : [];
        // Get Position
        const queryPositionResult = await positionApi.getPosition(this.props.match.params.id);
        const positionInfo = Utils.checkNested(queryPositionResult, 'data', 'getPosition');
        // Get Protocol task list
        const protocolTasksListRow = await protocolTaskApi.listProtocolTaskByGroup(positionInfo.groupId);
        const protocolTasksList = protocolTasksListRow.items ? protocolTasksListRow.items : [];
        // Get Staff list
        const staffList = await userApi.listAllUsers();
        // Get the assignable list
        const queryTasksResult = await assignableTaskApi.listAssignableTaskByPosition(this.props.match.params.id);
        const listAssignableTasks = Utils.checkNested(queryTasksResult, 'items');
        // Get participant info
        const participantInfoRow = await participantApi.getParticipant(positionInfo.participantId);
        const participantInfo = Utils.checkNested(participantInfoRow, 'data', 'getParticipant');

        let upgradedList = [];
        // Upgrade element in the list       
        for (let i = 0; i < listAssignableTasks.length; i++) {
            const newElement = this.improveAssignableTask(listAssignableTasks[i], protocolTasksList, listValidation, staffList);
            upgradedList.push(newElement);
        }

        this.setState({
            upgradedList: upgradedList,
            listAssignableTasks: listAssignableTasks,
            listValidation: listValidation,
            protocolTasksList: protocolTasksList,
            staffList: staffList,
            positionInfo: positionInfo,
            participantInfo: participantInfo,
            lastUpdate: new Date().toISOString(),
            loading: false,
        });
    }

    improveAssignableTask(assignableTask, protocolTasksList, listValidation, listStaff) {
        let returnTask = assignableTask;
        returnTask.protocolName = '';
        returnTask.protocolDescription = '';

        // If the task is link to a protocol task get info from there
        if (Utils.checkNested(assignableTask, 'protocolTaskId') !== null && Utils.checkNested(assignableTask, 'notLinkToProtocol') === false) {
            let found = false;
            let protocolTask = null;
            for (let i = 0; i < protocolTasksList.length; i++) {
                if (protocolTasksList[i].id === assignableTask.protocolTaskId) {
                    found = true;
                    protocolTask = protocolTasksList[i];
                    break
                }
            }
            if (!found) {
                // The protocol task was not found on the list (should not happened )
                // Try to get it by id
                console.log("WARNING! cannot find this protocol task whan getting by group")
            }
            if (protocolTask) {
                returnTask.protocolName = protocolTask.name;
                returnTask.protocolDescription = protocolTask.description;
            }
        }

        if (Utils.checkNested(assignableTask, 'userId') !== null) {
            let found = false;
            let staffResult = null;
            for (let i = 0; i < listStaff.length; i++) {
                if (listStaff[i].userName === assignableTask.userId) {
                    found = true;
                    staffResult = listStaff[i];
                }
            }
            if (!found) {
                // The protocol task was not found on the list (should not happened )
                // Try to get it by id
                console.log("WARNING! cannot find this user")
            }
            const name = Utils.checkNested(staffResult, 'userName');
            const pseudo = Utils.checkNested(staffResult, 'pseudo');
            const color = Utils.checkNested(staffResult, 'color');

            if (name !== null) {
                returnTask.staffName = name;
                returnTask.staffPseudo = pseudo;
                returnTask.staffColor = color;
            } else {
                returnTask.staffName = '';
                returnTask.staffColor = '';
            }

        }
        for (let j = 0; j < listValidation.length; j++) {
            if (listValidation[j].assignableTaskId === assignableTask.id) {
                returnTask.isDone = true;
                returnTask.doneUser = listValidation[j].userId;
                returnTask.doneDate = listValidation[j].date;
                break
            }
        }
        return returnTask
    }

    subscribeNewTask() {
        return API.graphql(
            graphqlOperation(subscriptions.onCreateAssignableTask)
        ).subscribe({
            next: (taskData) => {
                let newListInfo = [...this.state.upgradedList];
                let newTaskPositionId = Utils.checkNested(taskData, 'value', 'data', 'onCreateAssignableTask', 'positionId');
                if (newTaskPositionId === this.props.match.params.id) {
                    const newElement = this.improveAssignableTask(taskData.value.data.onCreateAssignableTask,
                        this.state.protocolTasksList,
                        this.state.listValidation,
                        this.state.staffList);
                    newListInfo.push(newElement);
                    this.setState({ upgradedList: newListInfo });
                    this.updateFromEvent(new Date().toISOString())
                }
            }
        });
    }

    subscribeDeleteTask() {
        return API.graphql(
            graphqlOperation(subscriptions.onDeleteAssignableTask)
        ).subscribe({
            next: (taskData) => {
                let newListInfo = [...this.state.upgradedList];
                for (let i = 0; i < newListInfo.length; i++) {
                    if (newListInfo[i].id === taskData.value.data.onDeleteAssignableTask.id) {
                        newListInfo.splice(i, 1);
                    }
                }
                this.setState({ upgradedList: newListInfo });
                this.updateFromEvent(new Date().toISOString())
            }
        });
    }

    subscribeUpdateTask() {
        return API.graphql(
            graphqlOperation(subscriptions.onUpdateAssignableTask)
        ).subscribe({
            next: (taskData) => {
                let newListInfo = [...this.state.upgradedList];
                for (let i = 0; i < newListInfo.length; i++) {
                    if (newListInfo[i].id === taskData.value.data.onUpdateAssignableTask.id) {
                        const newElement = this.improveAssignableTask(taskData.value.data.onUpdateAssignableTask,
                            this.state.protocolTasksList,
                            this.state.listValidation,
                            this.state.staffList);
                        newListInfo[i] = newElement;
                        this.setState({ upgradedList: newListInfo })
                        this.updateFromEvent(new Date().toISOString())
                        return
                    }
                }
            }
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.refreshId);
        this.subscriptionNew.unsubscribe();
        this.subscriptionDelete.unsubscribe();
        this.subscriptionUpdate.unsubscribe();
    }

    render() {
        const { positionInfo, upgradedList, participantInfo, lastUpdate, loading, isStudyManager } = this.state;
        const positionName = positionInfo ? positionInfo.name : "Unknown";
        const positionGroupId = positionInfo ? positionInfo.groupId : null;
        const positionT0 = positionInfo ? positionInfo.t0 : null;
        const participantCode = participantInfo ? participantInfo.code : "Unassigned";
        const participantSelection = participantInfo ? participantInfo.selection : "Unassigned";

        return (
            <div>
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"></link>
                <Typography variant="h4" color="inherit">
                    Tasks for position: {positionName}
                </Typography>
                <Divider variant="middle" />
                {
                    positionGroupId ?
                        <Button color="primary" component={Link} to={GroupPositionsRoute + positionGroupId} >
                            <ArrowBack />
                            Back to positions
                        </Button>
                        :
                        <Button color="primary" >
                            <ArrowBack />
                            Loading...
                        </Button>
                }
                <br /><br />
                <Box>
                    Name: {positionName}<br />
                    T0: {moment(positionT0).format("HH:mm DD/MM/YYYY")}<br />
                    Participant code: {participantCode}<br />
                    Participant selection number: {participantSelection}<br />
                </Box>
                {
                    isStudyManager ?
                        <div className='add-button'>
                            <AddAssignableTask loading={loading} positionid={this.props.match.params.id}></AddAssignableTask>
                        </div>
                        : null
                }

                <ListAssignableTasks listTasks={upgradedList} lastUpdate={lastUpdate} groupId={positionGroupId} />
            </div>
        );
    }
}

export default TasksPosition;