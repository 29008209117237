import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Divider } from "@aws-amplify/ui-react";
import {
  CreateEventInput,
  Label,
  Location,
} from "../../../utils/BarcodeClient/API";
import { createEvent } from "../../../utils/BarcodeClient/graphql/mutations";
import { EventType } from "./event";
import ConfirmationDialog from "./ConfirmationDialog";
import { theoreticalTimeFormatter } from "./helpers";
import ScanBarcode from "./ScanBarcode";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Delete, DeleteForever, PinDrop } from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";
import ReturnIcon from "./ReturnIcon";
import { getCurrentPseudo, getCurrentUsername } from "../../../utils/api/user";
import {
  BarcodeClientConfig,
  initClient,
} from "../../../utils/BarcodeClient/initClient";
import gql from "graphql-tag";
import AskPseudoDialog from "./AskPseudoDialog";
import useEventSideEffect, { SideEffectEvent } from "./useEventSideEffect";
import AddSpecificComment from "./AddSpecificComment";
import CommentDropDown from "./CommentDropDown";

const DEFAULT_TYPE = EventType.FILL;

const AddEventDialog: React.FC<{
  isOpen: boolean;
  cancel: () => void;
  initListLabels?: Label[];
  initEvent?: Partial<CreateEventInput>;
  lockEvent?: boolean;
}> = ({ isOpen, cancel, initListLabels, initEvent }) => {
  const [username, setUsername] = useState("Unkown");
  const [pseudo, setPseudo] = useState("Unkown");
  const [isPseudoDialogOpen, setIsPseudoDialogOpen] = useState(false);

  const updateUsernameAndPseudo = async () => {
    const fetchUsername = await getCurrentUsername();
    const fetchPseudo = await getCurrentPseudo();
    setUsername(fetchUsername);
    if (fetchPseudo) {
      setPseudo(fetchPseudo);
    } else {
      setIsPseudoDialogOpen(true);
    }
  };

  const handleNewPseudo = (newPseudo: string) => {
    setIsPseudoDialogOpen(false);
    setPseudo(newPseudo);
  };

  useEffect(() => {
    updateUsernameAndPseudo();
  }, []);

  const [listLabels, setListLabels] = useState<Label[]>([]);
  const [listSideEffectEvents, setSideEffectEvents] = useState<
    SideEffectEvent[]
  >([]);

  const [event, setEvent] = useState<CreateEventInput>({
    labelId: "",
    owner: username,
    pseudo,
    usedDate: new Date().toISOString(),
    type: DEFAULT_TYPE,
    description : ""
  });

  useEffect(() => {
    if (isOpen) {
      setListLabels([]);
      setSideEffectEvents([]);
      setEvent({
        labelId: "",
        owner: username,
        pseudo,
        usedDate: new Date().toISOString(),
        type: DEFAULT_TYPE,
        description: ""
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [addLoading, setAddLoading] = useState(false);

  const [selectedLocation, setSelectedLocation] = useState<Location>();
  const [selectedLabel, setSelectedLabel] = useState("");
  const [specificComment, setSpecificComment] = useState<
    Record<string, string>
  >({});

  const onCloseSpecificCommentDialog = (comment: string | null) => {
    if (comment !== null)
      setSpecificComment((prev) => ({
        ...prev,
        [selectedLabel]: comment
      }));
    setSelectedLabel("");
  };

  const { enqueueSnackbar } = useSnackbar();

  const { eventSideEffect } = useEventSideEffect();

  useEffect(() => {
    if (initListLabels) setListLabels(initListLabels);
  }, [initListLabels]);

  useEffect(() => {
    setEvent({
      ...initEvent,
      labelId: "",
      usedDate: initEvent?.usedDate || new Date().toISOString(),
      owner: username,
      pseudo,
      type: initEvent?.type || DEFAULT_TYPE,
      description: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initEvent]);

  useEffect(() => {
    setEvent((prev) => ({ ...prev, owner: username, pseudo }));
  }, [username, pseudo]);

  const formatComment = (genericComment: string | undefined | null, speComment: string | undefined) => {
    if (!genericComment && !speComment)
      return "";
    return `${genericComment ? `${genericComment} ` : ""}${
      speComment
        ? `(spé) ${speComment}`
        : ""
    }`;
  }

  const handleAddEvent = async () => {
    if (!event.usedDate || !event.type) {
      enqueueSnackbar("The form is incorrectly filled", { variant: "error" });
      return;
    }
    setAddLoading(true);
    let failCount = 0;
    for (let i = 0; i < listLabels.length; i += 1) {
      const eventToCreate: CreateEventInput = {
        ...event,
        labelId: listLabels[i].id,
        labelData: `${listLabels[i].serial}/${listLabels[i].studyName}/${
          listLabels[i].inclusion
        }/${listLabels[i].period}${
          listLabels[i].index ? `/${listLabels[i].index}` : ""
        }`,
        description: formatComment(event.description, specificComment[listLabels[i].id])
      };
      try {
        // eslint-disable-next-line no-await-in-loop
        const clientOverride = initClient(BarcodeClientConfig);
        if (!clientOverride) throw new Error("No client override");
        await clientOverride.mutate({
          mutation: gql(createEvent),
          variables: { input: eventToCreate },
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        failCount += 1;
        enqueueSnackbar("An error occur adding events", { variant: "error" });
      }
    }
    if (failCount === 0) {
      enqueueSnackbar("Events have been added", { variant: "success" });
    } else {
      enqueueSnackbar(`${failCount} events have not been added`, {
        variant: "error",
      });
    }

    // All side effect event
    for (let i = 0; i < listSideEffectEvents.length; i += 1) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const clientOverride = initClient(BarcodeClientConfig);
        if (!clientOverride) throw new Error("No client override");
        await clientOverride.mutate({
          mutation: gql(createEvent),
          variables: {
            input: {
              ...listSideEffectEvents[i].event,
              owner: event.owner,
              pseudo: event.pseudo,
              usedDate: event.usedDate,
              labelData: `${listSideEffectEvents[i].label.serial}/${
                listSideEffectEvents[i].label.studyName
              }/${listSideEffectEvents[i].label.inclusion}/${
                listSideEffectEvents[i].label.period
              }${
                listSideEffectEvents[i].label.index
                  ? `/${listSideEffectEvents[i].label.index}`
                  : ""
              }`,
              description: formatComment(event.description, specificComment[listSideEffectEvents[i].label.id])
            },
          },
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        enqueueSnackbar("An error occur adding events", { variant: "error" });
      }
    }
    if (listSideEffectEvents.length > 0) {
      enqueueSnackbar("Events have been added as side effect", {
        variant: "success",
      });
    }

    setAddLoading(false);
    setConfirmOpen(false);
    setListLabels([]);
    cancel();
  };

  const handleUpdateLocation = async (
    location: Location,
    eventType: EventType
  ) => {
    setSelectedLocation(location);
    setEvent((prev) => ({ ...prev, type: eventType, locationId: location.id }));
  };

  const checkUnicitySideEffect = (
    currentSideEvents: SideEffectEvent[],
    newSideEvents: SideEffectEvent[],
    newLabel?: Label
  ): SideEffectEvent[] => {
    const newList = [...currentSideEvents, ...newSideEvents];
    const uniqueIds = Array.from(new Set(newList.map((c) => c.label.id)));
    const uniqueList = uniqueIds
      .map((id) => {
        if ([...listLabels, newLabel].find((c) => c?.id === id))
          return undefined;
        return newList.find((d) => d.label.id === id);
      })
      .filter((c) => c !== undefined) as SideEffectEvent[];
    return uniqueList;
  };

  const handleAddNewLabel = async (newLabel: Label) => {
    // Check duplicate
    if (listLabels.findIndex((c) => c.id === newLabel.id) >= 0) {
      enqueueSnackbar(`Label already in the list ${newLabel.serial}`, {
        variant: "error",
      });
      return;
    }

    if (
      listSideEffectEvents.findIndex((c) => c.label.id === newLabel.id) >= 0
    ) {
      enqueueSnackbar(
        `Label already in the side effect list ${newLabel.serial}`,
        {
          variant: "error",
        }
      );
      return;
    }

    setListLabels((prev) => {
      const newList = [...prev];
      newList.push(newLabel);
      return newList;
    });

    // Business logic
    const sideEffectEvents = await eventSideEffect(newLabel, event);
    setSideEffectEvents((prev) =>
      checkUnicitySideEffect(prev, sideEffectEvents, newLabel)
    );
  };

  const sideEffectFullRefetch = async () => {
    const allSideEffectEvents: SideEffectEvent[] = [];
    for (let i = 0; i < listLabels.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const sideEffectEvents = await eventSideEffect(listLabels[i], event);
      allSideEffectEvents.push(...sideEffectEvents);
    }
    setSideEffectEvents(checkUnicitySideEffect([], allSideEffectEvents));
  };

  const deleteSideEffect = (sideEffectEvent: SideEffectEvent) => {
    setSideEffectEvents((prev) =>
      prev.filter((c) => c.label.id !== sideEffectEvent.label.id)
    );
  };

  const handleRemoveLabel = (LabelToRemove: Label) => {
    if (!LabelToRemove.id) return;
    setListLabels((prev) => {
      const newList = [...prev];
      const indexToDel = newList.findIndex((c) => c.id === LabelToRemove.id);
      if (indexToDel >= 0) {
        newList.splice(indexToDel, 1);
      }
      return newList;
    });

    setSideEffectEvents((prev) =>
      prev.filter((c) => c.labelIdEventTrigger !== LabelToRemove.id)
    );
  };

  const handleRemoveLocation = () => {
    setEvent((prev) => ({ ...prev, locationId: null }));
    setSelectedLocation(undefined);
  };
  const handleChangeComment = (comment: string) => {
    setEvent((prev) => ({ ...prev, description: comment }));
  };
  useEffect(() => {
    if (isOpen) {
      setSideEffectEvents([]);
      setSpecificComment({});
    }
  }, [isOpen]);

  useEffect(() => {
    sideEffectFullRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event.type]);

  return (
    <Dialog
      open={isOpen}
      onClose={(_, raison) => {
        if (raison === "backdropClick") return;
        cancel();
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogContent>
        <div style={{ marginBottom: "2em" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ScanBarcode
              onFindLabel={handleAddNewLabel}
              onFindLocation={handleUpdateLocation}
            />
            <div style={{ flexGrow: "1" }} />
            <FormControl style={{ marginRight: "0.8em" }}>
              <InputLabel id="select-event-type">Type</InputLabel>
              <Select
                labelId="select-event-type-label"
                id="select-event-type"
                value={event?.type}
                label="Type"
                onChange={(e) =>
                  setEvent({
                    ...event,
                    type: e.target.value as string,
                  })
                }
              >
                {Object.keys(EventType).map((type) => (
                  <MenuItem key={type} value={type}>
                    {type.toLowerCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <ReturnIcon event={event.type} style={{ fontSize: "2.5em" }} />
          </div>
          <div style={{ height: "15px" }}>

              {event.locationId && (
                <div
                  style={{
                    flexGrow: "1",
                    display: "flex",
                    flexDirection: "row-reverse",
                    alignItems: "center",
                  }}
                        >
                  <IconButton
                    style={{ marginLeft: "0.8em", color: "red" }}
                    onClick={handleRemoveLocation}
                  >
                    <DeleteForever fontSize="small"/>
                  </IconButton>
                  <Typography variant="subtitle2">
                    {selectedLocation?.friendlyName} ({selectedLocation?.name})
                  </Typography>
                  <PinDrop style={{ marginRight: "0.5em" }} fontSize="small"/>
                </div>
              )}
              </div>
          <div
            style={{
              display: "flex",
              marginTop: "1.5em",
              alignItems: "center",
            }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                label="Day"
                format="dd/MM/yyyy"
                value={event.usedDate}
                onChange={(newValue) => {
                  setEvent((prev) => ({
                    ...prev,
                    usedDate: new Date(newValue || 0).toISOString(),
                  }));
                }}
                animateYearScrolling
              />
            </MuiPickersUtilsProvider>
            <div style={{ width: "2em" }} />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <TimePicker
                margin="dense"
                ampm={false}
                label="Time"
                format="HH:mm"
                value={event.usedDate}
                onChange={(newValue) => {
                  setEvent((prev) => ({
                    ...prev,
                    usedDate: new Date(newValue || 0).toISOString(),
                  }));
                }}
              />
            </MuiPickersUtilsProvider>
            <Button
            style={{marginLeft : "2em"}}
            variant="contained"
            color="primary"
            onClick={() => {
              setEvent((prev) => ({
                ...prev,
                usedDate : new Date().toISOString()
              }))
            }}
            >RESET DATE</Button>
            <div style={{ flexGrow: "1" }} />
            <Typography>
              {event.owner}/{event.pseudo}
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              marginTop: "1em",
              alignItems: "center",
            }}
          >
            <TextField
              style={{ marginRight: "1em", flexGrow: "1" }}
              id="comment"
              label="comment"
              variant="outlined"
              // fullWidth
              multiline
              value={event.description}
              onChange={(e) => {
                setEvent((prev) => ({
                  ...prev,
                  description: e.target.value as string,
                }));
              }}
            />
            <CommentDropDown callBack={handleChangeComment} />
            <div
              style={{
                flexGrow: "1",
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
              }}
            >
            </div>
          </div>
        </div>
        <div>
          <Typography variant="h6">Labels ({listLabels.length})</Typography>
          <List>
            {listLabels.map((label) => (
              <div key={label.id}>
                <ListItem
                button
                onClick={() => setSelectedLabel(label.id)}
                >
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: grey[200] }}>
                      <ReturnIcon event={label.lastEventType} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <div style={{ display: "flex" }}>
                        <Typography>{label.inclusion}</Typography>

                        <Typography style={{ marginLeft: "1em" }}>
                          {theoreticalTimeFormatter(label)}
                        </Typography>
                      </div>
                    }
                    secondary={`${label.index} ${label.matrixName}`}
                  />
                  <Typography
                    variant="subtitle2"
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: "40ch"
                    }}
                  >
                    {specificComment[label.id]
                      ? `Specific comment : ${specificComment[label.id]}`
                      : ""}
                    </Typography>
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleRemoveLabel(label)}
                    >
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </div>
            ))}
          </List>
        </div>
        {listSideEffectEvents.length > 0 && (
          <div style={{ marginTop: "1.5em" }}>
            <Typography variant="h6">
              Side effects ({listSideEffectEvents.length})
            </Typography>
            <List>
              {listSideEffectEvents.map((sideEvent) => (
                <div key={sideEvent.label.id}>
                  <ListItem
                  button
                  onClick={() => setSelectedLabel(sideEvent.label.id)}
                  >
                    <ListItemAvatar>
                      <Avatar
                        style={{
                          backgroundColor: grey[100],
                        }}
                      >
                        <ReturnIcon event={sideEvent.label.lastEventType} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <div style={{ display: "flex" }}>
                          <Typography>{sideEvent.label.inclusion}</Typography>

                          <Typography style={{ marginLeft: "1em" }}>
                            {theoreticalTimeFormatter(sideEvent.label)}
                          </Typography>
                        </div>
                      }
                      secondary={`${sideEvent.label.index} ${sideEvent.label.matrixName} - ${sideEvent.label.serial}`}
                    />
                    <Typography
                      variant="subtitle2"
                      style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: "40ch"
                      }}
                    >
                      {specificComment[sideEvent.label.id]
                        ? `Specific comment : ${
                            specificComment[sideEvent.label.id]
                          }`
                        : ""}
                    </Typography>
                    <Avatar
                      style={{
                        backgroundColor: grey[100],
                      }}
                    >
                      <ReturnIcon event={sideEvent.event?.type} />
                    </Avatar>
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => deleteSideEffect(sideEvent)}
                      >
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </div>
              ))}
            </List>
          </div>
        )}
      </DialogContent>
      <DialogActions style={{ display: "flex" }}>
        <Button onClick={cancel}>Cancel</Button>
        <div style={{ flexGrow: "1" }} />
        <Button
          onClick={handleAddEvent}
          disabled={addLoading}
          variant="contained"
          color="primary"
        >
          Add event
        </Button>
      </DialogActions>
      <ConfirmationDialog
        isOpen={confirmOpen}
        cancel={() => setConfirmOpen(false)}
        loading={addLoading}
        confirm={handleAddEvent}
        message={
          <>
            <Typography variant="h6">Please confirm</Typography>
            <Typography>
              This action will create add a {event.type} event to{" "}
              {listLabels.length} labels
            </Typography>
            {listSideEffectEvents.length > 0 && (
              <Typography>
                As well as {listSideEffectEvents.length} side effect events
              </Typography>
            )}
          </>
        }
      />
      <AskPseudoDialog
        isOpen={isPseudoDialogOpen}
        loading={false}
        onValidate={(newPseudo) => handleNewPseudo(newPseudo)}
      />
      <AddSpecificComment
        open={!!selectedLabel}
        cancel={onCloseSpecificCommentDialog}
        comment={specificComment[selectedLabel]}
      />
    </Dialog>
  );
};

export default AddEventDialog;
