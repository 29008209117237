import React, { Component } from 'react';
import './UsersManagment.css';

import * as subscriptions from '../../graphql/subscriptions';
import { API, graphqlOperation } from 'aws-amplify';

import ListUsers from '../../components/listUsers/ListUsers'

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import Utils from '../../utils/utils';
import * as userApi from '../../utils/api/user';
import AddUser from '../../components/addUser/AddUser';

class UsersManagment extends Component {
  state = {
    listUser: [],
    selectedUserId: "",
    isStaffManager: false,
  };

  componentDidMount() {
    this.checkGroups();
    // Get the list of users
    this.queryCompleteUserList();
    // Subscribe to user update
    this.subscriptionUpdate = this.subscribeUpdateUser();
    // Subscribe to user create
    this.subscriptionNew = this.subscribeNewUser();
    // Subscribe to delete user
    this.subscriptionDelete = this.subscribeDeleteUser();
  }

  async checkGroups() {
    const isStaffManager = await userApi.isStaffManager()
    this.setState({
      isStaffManager: isStaffManager,
    })
  }

  async queryCompleteUserList() {
    const userList = await userApi.listAllUsers();

    let formatUserList = [];
    userList.forEach(user => {
      let formatUser = {};
      formatUser.name = user.userName || "";
      formatUser.color = user.color || "";
      formatUser.pseudo = user.pseudo || "";
      formatUserList.push(formatUser);
    });
    this.setState({
      listUser: formatUserList,
    });
  }

  subscribeUpdateUser() {
    return API.graphql(
      graphqlOperation(subscriptions.onUpdateUser)
    ).subscribe({
      next: (userData) => {
        let newListUser = this.state.listUser;
        for (let i = 0; i < newListUser.length; i++) {
          if (newListUser[i].name === userData.value.data.onUpdateUser.userName) {
            newListUser[i].name = Utils.checkNested(userData, 'value', 'data', 'onUpdateUser', 'userName') || "";
            newListUser[i].color = Utils.checkNested(userData, 'value', 'data', 'onUpdateUser', 'color') || "";
            newListUser[i].pseudo = Utils.checkNested(userData, 'value', 'data', 'onUpdateUser', 'pseudo') || "";
            this.setState({ listUser: newListUser });
            return
          }
        }
      }
    });
  }

  subscribeNewUser() {
    return API.graphql(
      graphqlOperation(subscriptions.onCreateUser)
    ).subscribe({
      next: (userData) => {
        console.log(userData)
        let newListUser = this.state.listUser;
        let newUser;

        newUser.name = Utils.checkNested(userData, 'value', 'data', 'onCreateUser', 'userName') || "";
        newUser.color = Utils.checkNested(userData, 'value', 'data', 'onCreateUser', 'color') || "";
        newUser.pseudo = Utils.checkNested(userData, 'value', 'data', 'onCreateUser', 'pseudo') || "";

        newListUser.push(newUser);
        this.setState({ listUser: newListUser });
      }
    });
  }

  subscribeDeleteUser() {
    return API.graphql(
      graphqlOperation(subscriptions.onDeleteUser)
    ).subscribe({
      next: (userData) => {
        let newListUser = this.state.listUser;
        for (let i = 0; i < newListUser.length; i++) {
          if (newListUser[i].name === userData.value.data.onDeleteUser.userName) {
            newListUser.splice(i, 1);
            console.log("deleted!")
          }
        }
        this.setState({ listUser: newListUser });
      }
    });
  }

  componentWillUnmount() {
    this.subscriptionNew.unsubscribe();
    this.subscriptionDelete.unsubscribe();
    this.subscriptionUpdate.unsubscribe();
  }

  render() {
    const { isStaffManager, listUser } = this.state;
    return (
      <div>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"></link>
        <Typography variant="h4" color="inherit">
          User Management
      </Typography>
        <Divider variant="middle" />
        <br /><br />
        {
          isStaffManager ?
            <div className="add-button"><AddUser></AddUser></div>
            :
            null
        }

        <br />
        <ListUsers listUsers={listUser}></ListUsers>
      </div>
    );
  }
}

export default UsersManagment;