import React, { Component } from 'react';
import './GroupParticipants.css';

import * as groupApi from '../../utils/api/group';
import * as participantApi from '../../utils/api/participant';
import * as positionApi from '../../utils/api/position';
import { API, graphqlOperation } from 'aws-amplify';

import Utils from '../../utils/utils';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import AddParticipant from '../../components/addParticipant/AddParticipant'
import ListGroupParticipants from '../../components/listGroupParticipants/ListGroupParticipants';
import { GroupRoute } from '../../Routing';

import ArrowBack from '@material-ui/icons/ArrowBack';
import { Link } from "react-router-dom";
import { Button, LinearProgress } from '@material-ui/core';
import { isParticipantManager } from '../../utils/api/user';
import * as subscriptions from '../../graphql/subscriptions';

import uuid from 'uuid/v4';

class GroupParticipants extends Component {
    state = {
        groupID: "",
        groupInfo: null,
        listParticipants: [],
        listPositions: [],
        ParticipantManager: false,
        loading: false,
    };

    componentDidMount() {
        this.queryGroupInfo(this.props.match.params.id);
        this.checkGroups();

        // Subscribe to new participant
        this.subscriptionNew = this.subscribeNewParticipant();
        // Subscribe to delete participant
        this.subscriptionDelete = this.subscribeDeleteParticipant();
        // Subscribe to edit participant
        this.subscriptionUpdate = this.subscribeUpdateParticipant();
    }

    async checkGroups() {
        const ParticipantManager = await isParticipantManager()
        this.setState({
            ParticipantManager: ParticipantManager,
        })
    }

    async queryGroupInfo(groupID) {
        this.setState({ loading: true });
        const queryGroup = await groupApi.getGroupById(groupID)
        const queryParticipant = await participantApi.listParticipantByGroup(groupID)
        const queryPositions = await positionApi.listPositionByGroup(groupID)

        let listParticipants = [];
        const listPositions = Utils.checkNested(queryPositions, "items");
        const listParticipantsRaw = Utils.checkNested(queryParticipant, "items");
        listParticipants = this.upgradeParticipantsList(listParticipantsRaw, listPositions)

        this.setState({
            groupInfo: queryGroup,
            groupID: groupID,
            listParticipants: listParticipants,
            listPositions: listPositions,
            loading: false,
        });
    }

    upgradeParticipantsList(listParticipantsRaw, listPositionsArg = null) {
        let listPositions = listPositionsArg;
        if (!listPositions) {
            listPositions = this.state.listPositions;
        }

        console.log(listPositions)
        let listParticipants = [];
        listParticipantsRaw.forEach(participant => {
            let entry = participant;
            for (let i = 0; i < listPositions.length; i++) {
                if (listPositions[i].participantId === participant.id) {
                    entry.positionName = listPositions[i].name;
                    entry.validate = listPositions[i].validate;
                    break
                }
            }
            listParticipants.push(entry);
        });
        return listParticipants
    }

    subscribeNewParticipant() {
        return API.graphql(
            graphqlOperation(subscriptions.onCreateParticipant)
        ).subscribe({
            next: (participantData) => {
                let newListParticipants = [...this.state.listParticipants];
                if (participantData.value.data.onCreateParticipant.groupId === this.state.groupID) {
                    newListParticipants.unshift(participantData.value.data.onCreateParticipant);
                    newListParticipants = this.upgradeParticipantsList(newListParticipants);
                    this.setState({ listParticipants: newListParticipants });
                }
            },
            error: (err) => {
                window.location.reload()
            }
        }
       );
    }

    subscribeDeleteParticipant() {
        return API.graphql(
            graphqlOperation(subscriptions.onDeleteParticipant)
        ).subscribe({
            next: (participantData) => {
                let newListParticipants = [...this.state.listParticipants];
                for (let i = 0; i < newListParticipants.length; i++) {
                    if (newListParticipants[i].id === participantData.value.data.onDeleteParticipant.id) {
                        newListParticipants.splice(i, 1);
                    }
                }
                this.setState({ listParticipants: newListParticipants });
            }
        });
    }

    subscribeUpdateParticipant() {
        return API.graphql(
            graphqlOperation(subscriptions.onUpdateParticipant)
        ).subscribe({
            next: (participantData) => {
                let newListParticipants = [...this.state.listParticipants];
                for (let i = 0; i < newListParticipants.length; i++) {
                    if (newListParticipants[i].id === participantData.value.data.onUpdateParticipant.id) {
                        newListParticipants[i] = participantData.value.data.onUpdateParticipant;
                    }
                }
                newListParticipants = this.upgradeParticipantsList(newListParticipants);
                this.setState({ listParticipants: newListParticipants });
            }
        });
    }

    componentWillUnmount() {
        this.subscriptionNew.unsubscribe();
        this.subscriptionDelete.unsubscribe();
        this.subscriptionUpdate.unsubscribe();
    }

    render() {
        const { groupInfo, groupID, listParticipants, ParticipantManager, loading } = this.state;
        const name = groupInfo ? groupInfo.name : null;
        return (
            <div>
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"></link>
                <Typography variant="h4" color="inherit" >
                    Participant list: {name}
                </Typography>
                <Divider variant="middle" />

                {
                    groupInfo ?
                        <Button color="primary" component={Link} to={GroupRoute + groupID} >
                            <ArrowBack />
                            Back to group
                        </Button>
                        :
                        <Button color="primary" >
                            <ArrowBack />
                            Loading...
                        </Button>
                }
                {
                    ParticipantManager ?
                        <div className="add-button">
                            <AddParticipant loading={loading} groupID={groupID}></AddParticipant>
                        </div>
                        :
                        null
                }
                {
                    loading ?
                        <LinearProgress />
                        : null
                }
                <ListGroupParticipants listParticipants={listParticipants} />
            </div>
        );
    }
}

export default GroupParticipants;