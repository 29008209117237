import React, { PureComponent } from 'react';
import './GroupProtocol.css';

import * as subscriptions from '../../graphql/subscriptions';

import { API, graphqlOperation } from 'aws-amplify';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ArrowBack from '@material-ui/icons/ArrowBack';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Link } from "react-router-dom";
import { Button, Box, LinearProgress } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker
} from '@material-ui/pickers';

import AddProtocolTask from '../../components/addProtocolTask/AddProtocolTask'
import ImportProtocolTasks from '../../components/importProtocolTasks/ImportProtocolTasks'

import ListGroupProtocol from '../../components/listGroupProtocol/ListGroupProtocol';
import { GroupRoute } from '../../Routing';

import * as protocolTaskApi from '../../utils/api/protocolTask';
import * as groupApi from '../../utils/api/group';
import * as userApi from '../../utils/api/user';
import * as taskTypeApi from '../../utils/api/taskType';

import Utils from '../../utils/utils';
import moment from 'moment';
import ValidateProtocol from '../../components/validateProtocol/ValidateProtocol';

class GroupProtocol extends PureComponent {
    state = {
        groupID: "",
        groupInfo: [],
        upgradedList: [],
        listTasks: [],
        taskTypeList: [],
        isStudyManager: false,
        loading: false,
        tokenIndex: 0,
        tokenList: [null],
        indicativeT0: new Date().toISOString(),
        lastUpdate: new Date().toISOString(),
        lastEvent: new Date(null).toISOString(),
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.indicativeT0 !== this.state.indicativeT0) {
            console.log()
            const upgradedList = this.state.listTasks.map((el) => this.improveProtocolTask(el));
            this.setState({upgradedList: upgradedList})
        }
    }

    async updateFromEvent(lastEventArg) {
        if (lastEventArg !== this.state.lastUpdate && moment.duration(new Date(lastEventArg) - new Date(this.state.lastEvent)).asSeconds() > 1) {
            console.log('Update display from event')
            this.setState({
                lastUpdate: lastEventArg,
                lastEvent: lastEventArg,
            })
        } else {
            this.setState({
                lastEvent: lastEventArg,
            })
        }
    }

    async updateFromTimer() {
        const currentDate = new Date().toISOString();

        if (this.state.lastEvent !== this.state.lastUpdate && moment.duration(new Date(currentDate) - new Date(this.state.lastEvent)).asSeconds() > 1) {
            console.log('update display from Timer')
            this.setState({
                lastUpdate: this.state.lastEvent,
            })
        }
    }

    async componentDidMount() {
        this.checkGroups();
        let refreshId = setInterval(this.updateFromTimer.bind(this), 1000);
        this.setState({ refreshId: refreshId });

        // Subscribe to new protocol task
        this.subscriptionNew = this.subscribeNewProtocolTask();
        // Subscribe to delete protocol task
        this.subscriptionDelete = this.subscribeDeleteProtocolTask();
        // Subscribe to edit protocol task
        this.subscriptionUpdate = this.subscribeUpdateProtocolTask();

        await this.getTaskType();
        const nextToken = await this.queryCompleteList(this.props.match.params.id);
        if (nextToken !== null) {
            let tokenList = [...this.state.tokenList];
            tokenList.push(nextToken);
            this.setState({ tokenList: tokenList })
        }
    }

    async checkGroups() {
        const isStudyManager = await userApi.isStudyManager()
        this.setState({
            isStudyManager: isStudyManager,
        })
    }

    async getTaskType() {
        let taskTypeList = await taskTypeApi.listTaskTypes();
        this.setState({
            taskTypeList: taskTypeList,
        })
    }

    async queryCompleteList(groupID, nextToken = null) {
        this.setState({ loading: true })
        const queryGroup = await groupApi.getGroupById(groupID);
        const queryProtocolTasks = await protocolTaskApi.listProtocolTaskByGroup(groupID, nextToken);
        const listTasks = Utils.checkNested(queryProtocolTasks, "items") ? Utils.checkNested(queryProtocolTasks, "items") : [];

        const upgradedList = listTasks.map((el) => this.improveProtocolTask(el));

        // If an indicative T0 has been defined then use it
        const indicativeT0 = Utils.checkNested(queryGroup, "indicativeT0") ? Utils.checkNested(queryGroup, "indicativeT0") : this.state.indicativeT0;

        this.setState({
            groupID: groupID,
            groupInfo: queryGroup,
            upgradedList: upgradedList,
            listTasks: listTasks,
            loading: false,
            lastUpdate: new Date().toISOString(),
            indicativeT0: indicativeT0,
        });

        return Utils.checkNested(queryProtocolTasks, "nextToken")
    }

    improveProtocolTask(taskArg) {
        let task = taskArg;
        task.date = Utils.dateFromDelta(this.state.indicativeT0, task.timeToT0).toISOString();
        task.taskType = 'Undefined';
        if (!task.taskTypeId || task.taskTypeId === '') return task
        for (let i = 0; i < this.state.taskTypeList.length; i++) {
            if (this.state.taskTypeList[i].id === task.taskTypeId) {
                task.taskType = this.state.taskTypeList[i].name;
                break
            }
        }
        return task
    }

    async nextPage() {
        if (this.state.tokenList.length > this.state.tokenIndex + 1) {
            const newTokenIndex = this.state.tokenIndex + 1;
            const nextToken = await this.queryCompleteList(this.state.groupID, this.state.tokenList[newTokenIndex])
            let tokenList = [...this.state.tokenList];
            if (nextToken !== null) {
                tokenList.push(nextToken);
            }
            this.setState({ tokenIndex: newTokenIndex, tokenList: tokenList })
        }
    }

    async prevPage() {
        if (this.state.tokenIndex > 0) {
            const newTokenIndex = this.state.tokenIndex - 1;
            await this.queryCompleteList(this.state.groupID, this.state.tokenList[newTokenIndex])
            this.setState({ tokenIndex: newTokenIndex })
        }
    }

    subscribeNewProtocolTask() {
        return API.graphql(
            graphqlOperation(subscriptions.onCreateProtocolTask)
        ).subscribe({
            next: (taskData) => {
                let newListTasks = this.state.upgradedList;
                if (taskData.value.data.onCreateProtocolTask.groupId === this.state.groupID) {
                    const newElement = this.improveProtocolTask(taskData.value.data.onCreateProtocolTask);
                    newListTasks.unshift(newElement);
                    this.setState({ upgradedList: newListTasks });
                    this.updateFromEvent(new Date().toISOString())
                }
            }
        });
    }

    subscribeDeleteProtocolTask() {
        return API.graphql(
            graphqlOperation(subscriptions.onDeleteProtocolTask)
        ).subscribe({
            next: (taskData) => {
                let newListTasks = this.state.upgradedList;
                if (taskData.value.data.onDeleteProtocolTask.groupId === this.state.groupID) {
                    for (let i = 0; i < newListTasks.length; i++) {
                        if (newListTasks[i].id === taskData.value.data.onDeleteProtocolTask.id) {
                            newListTasks.splice(i, 1);
                        }
                    }
                    this.setState({ upgradedList: newListTasks });
                    this.updateFromEvent(new Date().toISOString())
                }
            }
        });
    }

    subscribeUpdateProtocolTask() {
        return API.graphql(
            graphqlOperation(subscriptions.onUpdateProtocolTask)
        ).subscribe({
            next: (taskData) => {
                let newListTasks = this.state.upgradedList;
                if (taskData.value.data.onUpdateProtocolTask.groupId === this.state.groupID) {
                    for (let i = 0; i < newListTasks.length; i++) {
                        if (newListTasks[i].id === taskData.value.data.onUpdateProtocolTask.id) {
                            const newElement = this.improveProtocolTask(taskData.value.data.onUpdateProtocolTask);
                            newListTasks[i] = newElement;
                            this.setState({ upgradedList: newListTasks });
                            this.updateFromEvent(new Date().toISOString());
                            return
                        }
                    }
                }
            }
        });
    }

    handleIndicativeT0Change = date => {
        // If the change is made by a study manager then the info is store
        if (this.state.isStudyManager) {
            // update the db
            groupApi.updateGroupIndicativeT0(this.state.groupID, date.toISOString())
        }
        this.setState({
            indicativeT0: date.toISOString(),
        });
    };

    componentWillUnmount() {
        clearInterval(this.state.refreshId);
        this.subscriptionNew.unsubscribe();
        this.subscriptionDelete.unsubscribe();
        this.subscriptionUpdate.unsubscribe();
    }
    
    render() {
        const { groupInfo, groupID, upgradedList, isStudyManager, loading, tokenIndex, tokenList, indicativeT0, lastUpdate } = this.state;
        const name = groupInfo ? groupInfo.name : null;
        return (
            <div>
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"></link>
                <Typography variant="h4" color="inherit" >
                    Protocol: {name}
                </Typography>
                <Divider variant="middle" />
                {
                    groupInfo ?
                        <Button color="primary" component={Link} to={GroupRoute + groupID} >
                            <ArrowBack />
                            Back to group
                        </Button>
                        :
                        <Button color="primary" >
                            <ArrowBack />
                            Loading...
                        </Button>
                }

                <Box marginBottom={2}>
                    <ValidateProtocol group={groupInfo} />
                    {
                        isStudyManager ?
                            <Box display="flex" flexWrap="wrap" alignItems="center">
                                <Box>
                                    <ImportProtocolTasks loading={loading} groupID={groupID} />
                                </Box>
                                <Box flexGrow={1} />
                                <Box>
                                    <AddProtocolTask loading={loading} groupID={groupID} indicativet0={indicativeT0} />
                                </Box>
                            </Box>
                            :
                            null
                    }
                </Box>

                {
                    tokenList.length > tokenIndex + 1 || tokenIndex > 0 ?
                        <Box>
                            <Typography style={{ color: "red" }} variant="h6">
                                WARNING! The protocol size limit has been reached (2000 tasks)!
                                The data consistancy cannot be insure after this limit. Please split your protocol onto two separate protocols.
                            </Typography>

                            <Box display='flex' flexWrap='wrap' alignItems='center'>
                                <Button color="secondary" disabled={!(tokenIndex > 0)} onClick={() => this.prevPage()}>
                                    <KeyboardArrowLeft />
                                    Previous page
                                </Button>
                                <Box flexGrow={1} />
                                <Button color="secondary" disabled={!(tokenList.length > tokenIndex + 1)} onClick={() => this.nextPage()} >
                                    Next page
                                <KeyboardArrowRight />
                                </Button>
                            </Box>
                        </Box>
                        : null
                }
                <form noValidate>
                    <Box marginBottom={1} marginTop={1}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDateTimePicker
                                required
                                margin="dense"
                                ampm={false}
                                label="Indicative T0"
                                format="dd/MM/yyyy HH:mm"
                                value={indicativeT0}
                                onChange={this.handleIndicativeT0Change}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Box>
                </form>
                {
                    loading ?
                        <LinearProgress />
                        : null
                }
                <ListGroupProtocol listTasks={upgradedList || []} lastUpdate={lastUpdate} indicativeT0={indicativeT0} />
            </div>
        );
    }
}

export default GroupProtocol;