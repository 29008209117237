import React, { PureComponent } from 'react';

import './ListGroupProtocol.css';

import MaterialTable from 'material-table'
import * as userApi from '../../utils/api/user';

import EditProtocolTaskDialog from './editProtocolTaskDialog/EditProtocolTaskDialog';
import DeleteProtocolTaskDialog from './deleteProtocolTaskDialog/DeleteProtocolTaskDialog';
import TranslateProtocolTasksDialog from './translateProtocolTasksDialog/TranslateProtocolTasksDialog';

import CopyProtocolTasksDialog from './copyProtocolTasksDialog/CopyProtocolTasksDialog';
import Utils from '../../utils/utils'

class ListGroupProtocol extends PureComponent {
  state = {
    dialogEditOpen: false,
    dialogDeleteOpen: false,
    dialogCopyOpen: false,
    dialogEditDeltaT0Open: false,
    selectedProtocolTask: "",
    selectedProtocolTasks: [],
    protocolTasksList: [],
    isStudyManager: false,
    nbrPerPage: this.getInitNbrPerPage(),
  };

  componentDidMount() {
    this.checkGroups();
  }

  getInitNbrPerPage() {
    return localStorage.getItem('nbrPerPageProtocol')
      ? Number(localStorage.getItem('nbrPerPageProtocol'))
      : 100;
  }

  async checkGroups() {
    const isStudyManager = await userApi.isStudyManager();
    this.setState({
      isStudyManager: isStudyManager,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lastUpdate !== this.props.lastUpdate ||
      prevProps.indicativeT0 !== this.props.indicativeT0) {
      this.setState({
        protocolTasksList: this.props.listTasks,
        indicativeT0: this.props.indicativeT0,
      })
    }
  }
  handleEditClose = () => {
    this.setState({ dialogEditOpen: false });
  };

  handleEditOpen(rowData) {
    if (rowData.length > 1) {
      alert("Editing option is available only for editing task one by one, you selected " + rowData.length + " tasks.");
      return
    }

    this.setState({
      selectedProtocolTask: rowData[0],
      dialogEditOpen: true
    });
  }

  handleEditDeltaT0Close = () => {
    this.setState({ dialogEditDeltaT0Open: false });
  };

  handleEditDeltaT0Open(rowData) {
    this.setState({
      selectedProtocolTasks: rowData,
      dialogEditDeltaT0Open: true
    });
  }

  handleDeleteClose = () => {
    this.setState({ dialogDeleteOpen: false });
  };

  handleDeleteOpen(rowData) {
    this.setState({
      selectedProtocolTasks: rowData,
      dialogDeleteOpen: true
    });
  }

  handleCopyClose = () => {
    this.setState({ dialogCopyOpen: false });
  };

  handleCopyOpen(rowData) {
    this.setState({
      selectedProtocolTasks: rowData,
      dialogCopyOpen: true
    });
  }

  render() {
    const { indicativeT0 } = this.props;
    const { selectedProtocolTask,
      selectedProtocolTasks,
      protocolTasksList,
      isStudyManager } = this.state;
    return (
      <div>
        <MaterialTable
          onChangeRowsPerPage={(newNbrPerPage) => {
            this.setState({ nbrPerPage: newNbrPerPage });
            localStorage.setItem('nbrPerPageProtocol', newNbrPerPage);
          }}
          columns={[
            { title: 'Theoretical time', field: 'name', width: null, },
            {
              title: 'Delta T0',
              field: 'timeToT0',

              render: rowData => {
                let toDisplay = 'Error';
                let color = "#8b0000";

                if (rowData.timeToT0 !== null) {
                  toDisplay = Utils.minutesToDaysHoursMinutesString(rowData.timeToT0)
                  color = "black";
                }
                return (
                  <div style={{ color: color }}>
                    {toDisplay}
                  </div>
                )
              },
            },
            {
              title: 'Indicative date', field: 'date', defaultSort: 'asc', render: rowData => {

                const date = Utils.dateFromDelta(indicativeT0, rowData.timeToT0).format("HH:mm DD/MM/YYYY");
                return (
                  <div>
                    {date}
                  </div>
                )
              }
            },
            { title: 'Act', field: 'description' },
            { title: 'Type', field: 'taskType' },
          ]}
          actions={[
            {
              icon: 'edit',
              tooltip: 'Edit',
              hidden: !isStudyManager ? true : false,
              onClick: (event, rowData) => this.handleEditOpen(rowData),
            },
            {
              icon: 'file_copy',
              tooltip: 'Copy tasks',
              hidden: !isStudyManager ? true : false,
              onClick: (event, rowData) => this.handleCopyOpen(rowData),
            },
            {
              icon: 'swap_vert',
              tooltip: 'Translate tasks',
              hidden: !isStudyManager ? true : false,
              onClick: (event, rowData) => this.handleEditDeltaT0Open(rowData),
            },
            {
              icon: 'delete',
              tooltip: 'Delete tasks',
              hidden: !isStudyManager ? true : false,
              onClick: (event, rowData) => this.handleDeleteOpen(rowData),
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            paging: true,
            pageSizeOptions: [100, 200, 500, 1000],
            pageSize: this.state.nbrPerPage,
            selection: true,
            sorting: true,
            draggable: false,
          }}
          data={protocolTasksList}
          title=""
        />
        <EditProtocolTaskDialog
          open={this.state.dialogEditOpen}
          onClose={this.handleEditClose}
          protocolTask={selectedProtocolTask}
          indicativet0={this.state.indicativeT0}
        />
        <DeleteProtocolTaskDialog
          open={this.state.dialogDeleteOpen}
          onClose={this.handleDeleteClose}
          protocolTasks={selectedProtocolTasks}
        />
        <CopyProtocolTasksDialog
          open={this.state.dialogCopyOpen}
          onClose={this.handleCopyClose}
          protocolTasks={selectedProtocolTasks}
        />
        <TranslateProtocolTasksDialog
          open={this.state.dialogEditDeltaT0Open}
          onClose={this.handleEditDeltaT0Close}
          protocolTasks={selectedProtocolTasks}
        />
      </div>
    );
  }
}

export default ListGroupProtocol;