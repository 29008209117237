import React, { PureComponent } from 'react';
import './Assignation.css';

import ListAssignation from '../../components/listAssignation/ListAssignation'

import * as userApi from '../../utils/api/user';
import * as assignableTaskApi from '../../utils/api/assignableTask';
import * as protocolTaskApi from '../../utils/api/protocolTask';
import * as taskTypeApi from '../../utils/api/taskType';
import * as positionApi from '../../utils/api/position';
import * as participantApi from '../../utils/api/participant';
import * as groupApi from '../../utils/api/group';
import * as partApi from '../../utils/api/part';
import * as studyApi from '../../utils/api/study';
import * as protocolValidationApi from '../../utils/api/protocolValidation';

import { API, graphqlOperation } from 'aws-amplify';
import * as subscriptions from '../../graphql/subscriptions';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import Utils from '../../utils/utils';
import moment from 'moment';
import { Box, Select, MenuItem, FormControl, LinearProgress } from '@material-ui/core';

class Assignation extends PureComponent {
  state = {
    listAssignableTasks: [],
    upgradedList: [],
    dateFilterBeginning: this.getBeginningDate(),
    dateFilterEnd: this.getEndDate(),
    isNextToken: false,
    listUser: [],
    selectedUserId: "",
    taskToUpdate: null,
    lastUpdate: new Date().toISOString(),
    lastEvent: new Date(null).toISOString(),

    loadedStaff: [],
    loadedProtocolTask: [],
    loadedTaskType: [],
    loadedPosition: [],
    loadedParticipant: [],
    loadedGroup: [],
    loadedPart: [],
    loadedStudy: [],
    loadedValidationStatus: [],
  };

  getBeginningDate() {
    return localStorage.getItem('dateAssignationBeginning')
      ? new Date(localStorage.getItem('dateAssignationBeginning')).toISOString()
      : new Date().toISOString();
  }

  getEndDate() {
    return localStorage.getItem('dateAssignationEnd')
      ? new Date(localStorage.getItem('dateAssignationEnd')).toISOString()
      : moment(new Date()).add(12, 'hours').toISOString();
  }

  async updateFromEvent(lastEventArg) {
    if (lastEventArg !== this.state.lastUpdate && moment.duration(new Date(lastEventArg) - new Date(this.state.lastEvent)).asSeconds() > 2) {
      console.log('Update display from event')
      this.setState({
        lastUpdate: lastEventArg,
        lastEvent: lastEventArg,
      })
    } else {
      this.setState({
        lastEvent: lastEventArg,
      })
    }
  }

  async updateFromTimer() {
    const currentDate = new Date().toISOString();

    if (this.state.lastEvent !== this.state.lastUpdate && moment.duration(new Date(currentDate) - new Date(this.state.lastEvent)).asSeconds() > 1) {
      console.log('update display from Timer')
      this.setState({
        lastUpdate: this.state.lastEvent,
      })
    }
  }

  componentDidMount() {
    let refreshId = setInterval(this.updateFromTimer.bind(this), 1000);
    this.setState({ refreshId: refreshId });

    // Subscribe to new task
    this.subscriptionNew = this.subscribeNewTask();
    // Subscribe to delete task
    this.subscriptionDelete = this.subscribeDeleteTask();
    // Subscribe to edit task
    this.subscriptionUpdate = this.subscribeUpdateTask();

    this.queryCompleteList(this.state.dateFilterBeginning, this.state.dateFilterEnd);
  }

  async queryCompleteList(dateBeginning, dateEnd) {
    this.setState({ loading: true, loaded: 0 })
    // Get all users
    const userList = await userApi.listAllUsers();

    const filterBeginning = new Date(dateBeginning).toISOString();
    const filterEnd = new Date(dateEnd).toISOString();
    const assignableTaskQuery = await assignableTaskApi.listAssignableTaskByTimeFrame(filterBeginning, filterEnd)
    const isNextToken = Utils.checkNested(assignableTaskQuery, 'nextToken') ? true : false;
    const listAssignableTasks = Utils.checkNested(assignableTaskQuery, 'items') ? assignableTaskQuery.items : [];

    this.state.loadedStaff = userList;
    this.state.loadedValidationStatus = [];
    // Upgrade all tasks
    let upgradedList = [];
    let remainingList = [...listAssignableTasks];
    let totalElements = 0;
    for (let i = 0; i < listAssignableTasks.length; i++) {
      if (remainingList.length === 0) break
      // Batch 100 tasks together
      const batchList = remainingList.slice(0, 100);
      remainingList.splice(0, 100)
      totalElements += batchList.length;
      this.setState({ loaded: totalElements * 100 / listAssignableTasks.length })
      await Promise.all(batchList.map(async (task) => {
        const newElement = await this.formatATask(task);
        upgradedList.push(newElement);
      }));
    }

    this.setState({
      listAssignableTasks: listAssignableTasks,
      upgradedList: upgradedList,
      lastUpdate: new Date().toISOString(),
      isNextToken: isNextToken,
      listUser: userList,
      loading: false,
      loaded: 0,
    });
  }


  async formatATask(task) {
    let formatTask = {};
    // Directly with applicable task
    formatTask.id = Utils.checkNested(task, 'id');
    formatTask.applicableDate = Utils.checkNested(task, 'applicableDate') || "";
    formatTask.specificDescription = Utils.checkNested(task, 'description') || false;

    // Need staff
    const staffId = task.userId;
    formatTask.staffUserName = "Unassigned";
    formatTask.staffColor = "";
    formatTask.staffColumnName = "Unassigned";
    if (staffId) {
      let found = false;
      this.state.loadedStaff.forEach(staff => {
        if (staff.userName === staffId) {
          found = true;
          formatTask.staffUserName = staff.userName;
          formatTask.staffPseudo = staff.pseudo;
          formatTask.staffColumnName = formatTask.staffPseudo || formatTask.staffUserName;
          formatTask.staffColor = staff.color;
        }
      });
      if (!found) {
        const newUser = await this.loadUser(staffId);
        this.state.loadedStaff.push(newUser)
        formatTask.staffUserName = newUser.userName;
        formatTask.staffPseudo = newUser.pseudo;
        formatTask.staffColumnName = formatTask.staffPseudo || formatTask.staffUserName;
        formatTask.staffColor = newUser.color;
      }
    }

    // Need protocol task
    // get theorical time (name), description
    const protocolTaskId = task.protocolTaskId;
    formatTask.protocolName = "";
    formatTask.protocolDescription = "";
    if (protocolTaskId) {
      let found = false;
      let protocolTask = null;
      for (let j = 0; j < this.state.loadedProtocolTask.length; j++) {
        const protocolTaskLoaded = this.state.loadedProtocolTask[j];
        if (protocolTaskLoaded.id === protocolTaskId) {
          found = true;
          formatTask.protocolName = protocolTaskLoaded.name;
          formatTask.protocolDescription = protocolTaskLoaded.description;
          protocolTask = protocolTaskLoaded;
        }
      }
      if (!found) {
        const newProtocolTask = await this.loadProtocolTask(protocolTaskId);
        this.state.loadedProtocolTask.push(newProtocolTask);
        formatTask.protocolName = newProtocolTask.name;
        formatTask.protocolDescription = newProtocolTask.description;
        protocolTask = newProtocolTask;
      }

      // Need protocol task / task type
      // get task type
      const taskTypeId = Utils.checkNested(protocolTask, "taskTypeId");
      if (taskTypeId) {
        let found = false;
        this.state.loadedTaskType.forEach(taskType => {
          if (taskType.id === taskTypeId) {
            found = true;
            formatTask.taskTypeName = taskType.name;
          }
        });
        if (!found) {
          const newTaskType = await this.loadTaskType(taskTypeId);
          if (newTaskType) {
            this.state.loadedTaskType.push(newTaskType);
            formatTask.taskTypeName = newTaskType.name;
          } else {
            formatTask.taskTypeName = "Unknonw";
          }

        }
      }
    }

    // Need position
    // get position name (name), inclusion
    const positionId = task.positionId;
    formatTask.positionName = "";
    formatTask.positionInclusion = "";
    let position = null;
    if (positionId) {
      let found = false;
      this.state.loadedPosition.forEach(positionLoaded => {
        if (positionLoaded.id === positionId) {
          found = true;
          formatTask.positionName = positionLoaded.name;
          formatTask.positionInclusion = positionLoaded.inclusion;
          formatTask.positionValidate = positionLoaded.validate;
          position = positionLoaded;
        }
      });
      if (!found) {
        const newPosition = await this.loadPosition(positionId);
        this.state.loadedPosition.push(newPosition);
        formatTask.positionName = newPosition.name;
        formatTask.positionInclusion = newPosition.inclusion;
        formatTask.positionValidate = newPosition.validate;
        position = newPosition;
      }

      // Need position / participant
      // get sex 
      if (position.participantId) {
        found = false;
        this.state.loadedParticipant.forEach(participant => {
          if (participant.id === position.participantId) {
            found = true;
            formatTask.participantSex = participant.sex;
          }
        });
        if (!found) {
          const newParticipant = await this.loadParticipant(position.participantId);
          this.state.loadedParticipant.push(newParticipant);
          formatTask.participantSex = newParticipant.sex;
        }
      }

      // Need position / group
      const groupId = Utils.checkNested(position, "groupId");
      formatTask.groupName = "";
      if (groupId) {
        let found = false;
        let group = null;
        this.state.loadedGroup.forEach(groupLoaded => {
          if (groupLoaded.id === groupId) {
            found = true;
            formatTask.groupName = groupLoaded.name;
            formatTask.groupDescription = groupLoaded.description;
            group = groupLoaded;
          }
        });
        if (!found) {
          const newGroup = await this.loadGroup(groupId);
          this.state.loadedGroup.push(newGroup);
          formatTask.groupName = newGroup.name;
          formatTask.groupDescription = newGroup.description;
          group = newGroup;
        }

        // Need validatation status
        let foundValidation = false;
        this.state.loadedValidationStatus.forEach(validationStatusLoaded => {
          if (validationStatusLoaded.groupId === groupId) {
            foundValidation = true;
            formatTask.groupValidate = validationStatusLoaded.isValidate;
          }
        });
        if (!foundValidation) {
          let newValidationStatus = await protocolValidationApi.getValidationStatus(groupId);
          newValidationStatus.groupId = groupId;
          this.state.loadedValidationStatus.push(newValidationStatus);
          formatTask.groupValidate = newValidationStatus.isValidate;
        }

        // Need part
        const partId = Utils.checkNested(group, "partId")
        if (partId) {
          let found = false;
          let part = null;
          this.state.loadedPart.forEach(partLoaded => {
            if (partLoaded.id === partId) {
              found = true;
              formatTask.partName = partLoaded.name;
              part = partLoaded;
            }
          });
          if (!found) {
            const newPart = await this.loadPart(partId);
            this.state.loadedPart.push(newPart);
            formatTask.partName = newPart.name;
            part = newPart;
          }

          // Need study
          const studyId = Utils.checkNested(part, "studyId")
          if (studyId) {
            let found = false;
            this.state.loadedStudy.forEach(study => {
              if (study.id === studyId) {
                found = true;
                formatTask.studyName = study.name;
              }
            });
            if (!found) {
              const newStudy = await this.loadStudy(studyId);
              this.state.loadedStudy.push(newStudy);
              formatTask.studyName = newStudy.name;
            }
          }
        }
      }
    }
    return formatTask
  }

  formatATaskFast(task) {
    let formatTask = {};
    // Directly with applicable task
    formatTask.id = Utils.checkNested(task, 'id');
    formatTask.applicableDate = Utils.checkNested(task, 'applicableDate') || "";
    formatTask.specificDescription = Utils.checkNested(task, 'description') || false;

    // Need staff
    const staffId = task.userId;
    formatTask.staffUserName = "Unassigned";
    formatTask.staffColor = "";
    formatTask.staffColumnName = "Unassigned";
    if (staffId) {
      this.state.loadedStaff.forEach(staff => {
        if (staff.userName === staffId) {
          formatTask.staffUserName = staff.userName;
          formatTask.staffPseudo = staff.pseudo;
          formatTask.staffColumnName = formatTask.staffPseudo || formatTask.staffUserName;
          formatTask.staffColor = staff.color;
        }
      });
    }

    // Need protocol task
    // get theorical time (name), description
    const protocolTaskId = task.protocolTaskId;
    formatTask.protocolName = "";
    formatTask.protocolDescription = "";
    if (protocolTaskId) {
      let protocolTask = null;
      for (let j = 0; j < this.state.loadedProtocolTask.length; j++) {
        const protocolTaskLoaded = this.state.loadedProtocolTask[j];
        if (protocolTaskLoaded.id === protocolTaskId) {
          formatTask.protocolName = protocolTaskLoaded.name;
          formatTask.protocolDescription = protocolTaskLoaded.description;
          protocolTask = protocolTaskLoaded;
        }
      }

      // Need protocol task / task type
      // get task type
      const taskTypeId = Utils.checkNested(protocolTask, "taskTypeId");
      if (taskTypeId) {
        this.state.loadedTaskType.forEach(taskType => {
          if (taskType.id === taskTypeId) {
            formatTask.taskTypeName = taskType.name;
          }
        });
      }
    }

    // Need position
    // get position name (name), inclusion
    const positionId = task.positionId;
    formatTask.positionName = "";
    formatTask.positionInclusion = "";
    let position = null;
    if (positionId) {
      this.state.loadedPosition.forEach(positionLoaded => {
        if (positionLoaded.id === positionId) {
          formatTask.positionName = positionLoaded.name;
          formatTask.positionInclusion = positionLoaded.inclusion;
          formatTask.positionValidate = positionLoaded.validate;
          position = positionLoaded;
        }
      });

      // Need position / participant
      // get sex 
      if (position.participantId) {
        this.state.loadedParticipant.forEach(participant => {
          if (participant.id === position.participantId) {
            formatTask.participantSex = participant.sex;
          }
        });
      }

      // Need position / group
      const groupId = Utils.checkNested(position, "groupId");
      formatTask.groupName = "";
      if (groupId) {
        let group = null;
        this.state.loadedGroup.forEach(groupLoaded => {
          if (groupLoaded.id === groupId) {
            formatTask.groupName = groupLoaded.name;
            formatTask.groupDescription = groupLoaded.description;
            group = groupLoaded;
          }
        });

        // Need validatation status
        this.state.loadedValidationStatus.forEach(validationStatusLoaded => {
          if (validationStatusLoaded.groupId === groupId) {
            formatTask.groupValidate = validationStatusLoaded.isValidate;
          }
        });

        // Need part
        const partId = Utils.checkNested(group, "partId")
        if (partId) {
          let part = null;
          this.state.loadedPart.forEach(partLoaded => {
            if (partLoaded.id === partId) {
              formatTask.partName = partLoaded.name;
              part = partLoaded;
            }
          });

          // Need study
          const studyId = Utils.checkNested(part, "studyId")
          if (studyId) {
            this.state.loadedStudy.forEach(study => {
              if (study.id === studyId) {
                formatTask.studyName = study.name;
              }
            });
          }
        }
      }
    }
    return formatTask
  }

  async loadUser(userId) {
    const userQuery = await userApi.getUser(userId);
    const user = Utils.checkNested(userQuery, 'data', 'getUser');
    return user
  }

  async loadProtocolTask(id) {
    const protocolTaskQuery = await protocolTaskApi.getProtocolTask(id);
    const protocolTask = Utils.checkNested(protocolTaskQuery, 'data', 'getProtocolTask');
    return protocolTask
  }

  async loadTaskType(id) {
    const taskTypeQuery = await taskTypeApi.getTaskType(id);
    const taskType = Utils.checkNested(taskTypeQuery, 'data', 'getTaskType');
    return taskType
  }

  async loadPosition(id) {
    const positionQuery = await positionApi.getPosition(id);
    const position = Utils.checkNested(positionQuery, 'data', 'getPosition');
    return position
  }

  async loadParticipant(id) {
    let rawParticipant = await participantApi.getParticipant(id);
    let participant = Utils.checkNested(rawParticipant, 'data', 'getParticipant');
    if (!participant) {
      participant = { id: id }
    }
    return participant
  }

  async loadGroup(id) {
    const group = await groupApi.getGroupById(id);
    return group
  }

  async loadPart(id) {
    const partQuery = await partApi.getPart(id);
    const part = Utils.checkNested(partQuery, 'data', 'getPart');
    return part
  }

  async loadStudy(id) {
    const studyQuery = await studyApi.getStudy(id);
    const study = Utils.checkNested(studyQuery, 'data', 'getStudy');
    return study
  }

  updateDateBeginning(date) {
    localStorage.setItem('dateAssignationBeginning', date);
    this.setState({
      dateFilterBeginning: new Date(date).toISOString(),
    });
    this.queryCompleteList(date, this.state.dateFilterEnd);
  };

  updateDateEnd(date) {
    localStorage.setItem('dateAssignationEnd', date);
    this.setState({
      dateFilterEnd: new Date(date).toISOString(),
    });
    this.queryCompleteList(this.state.dateFilterBeginning, date);
  };

  handleChange = stateName => event => {
    this.setState({
      [stateName]: event.target.value,
    });
  };

  subscribeNewTask() {
    return API.graphql(
      graphqlOperation(subscriptions.onCreateAssignableTask)
    ).subscribe({
      next: (taskData) => {
        let newListInfo = this.state.upgradedList;
        if (taskData.value.data.onCreateAssignableTask.applicableDate) {
          if (this.state.dateFilterBeginning < taskData.value.data.onUpdateAssignableTask.applicableDate &&
            taskData.value.data.onUpdateAssignableTask.applicableDate < this.state.dateFilterEnd) {
            const newElement = this.formatATaskFast(taskData.value.data.onCreateAssignableTask);
            newListInfo.unshift(newElement);
            this.setState({ upgradedList: newListInfo });
            this.updateFromEvent(new Date().toISOString());
          }
        }
      }
    });
  }

  subscribeDeleteTask() {
    return API.graphql(
      graphqlOperation(subscriptions.onDeleteAssignableTask)
    ).subscribe({
      next: (taskData) => {
        let newListInfo = this.state.upgradedList;
        for (let i = 0; i < newListInfo.length; i++) {
          if (newListInfo[i].id === taskData.value.data.onDeleteAssignableTask.id) {
            newListInfo.splice(i, 1);
            break
          }
        }
        this.setState({ upgradedList: newListInfo });
        this.updateFromEvent(new Date().toISOString());
      }
    });
  }

  subscribeUpdateTask() {
    return API.graphql(
      graphqlOperation(subscriptions.onUpdateAssignableTask)
    ).subscribe({
      next: (taskData) => {
        let newListInfo = this.state.upgradedList;
        let found = false;
        for (let i = 0; i < newListInfo.length; i++) {
          if (newListInfo[i].id === taskData.value.data.onUpdateAssignableTask.id) {
            found = true;
            if (this.state.dateFilterBeginning < taskData.value.data.onUpdateAssignableTask.applicableDate &&
              taskData.value.data.onUpdateAssignableTask.applicableDate < this.state.dateFilterEnd &&
              taskData.value.data.onUpdateAssignableTask.activationStatus === 'active') {
              const newElement = this.formatATaskFast(taskData.value.data.onUpdateAssignableTask);
              newListInfo[i] = newElement;
              this.setState({ upgradedList: newListInfo });
              this.updateFromEvent(new Date().toISOString());
            } else {
              newListInfo.splice(i, 1);
              this.setState({ upgradedList: newListInfo });
              this.updateFromEvent(new Date().toISOString());
            }
            break
          }
        }
        if (found === false &&
          this.state.dateFilterBeginning < taskData.value.data.onUpdateAssignableTask.applicableDate &&
          taskData.value.data.onUpdateAssignableTask.applicableDate < this.state.dateFilterEnd &&
          taskData.value.data.onUpdateAssignableTask.activationStatus === 'active') {
          const newElement = this.formatATaskFast(taskData.value.data.onUpdateAssignableTask);
          newListInfo.unshift(newElement);
          this.setState({ upgradedList: newListInfo });
          this.updateFromEvent(new Date().toISOString());
        }

      }
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.refreshId);
    this.subscriptionNew.unsubscribe();
    this.subscriptionDelete.unsubscribe();
    this.subscriptionUpdate.unsubscribe();
  }

  render() {
    const { upgradedList, isNextToken, lastUpdate, listUser, loaded, loading, selectedUserId } = this.state;
    return (
      <div>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"></link>
        <Typography variant="h4" color="inherit" >
          Assignation
        </Typography>
        <Divider variant="middle" />
        <Box marginTop={1} marginBottom={1}>
          <form noValidate>
            <Box display='flex' flexWrap="wrap" alignItems="center">
              <Box marginRight={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDateTimePicker
                    required
                    margin="dense"
                    ampm={false}
                    label="Time start"
                    format="dd/MM/yyyy HH:mm"
                    value={this.state.dateFilterBeginning}
                    onChange={(date) => this.updateDateBeginning(date)}

                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              <Box>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDateTimePicker
                    required
                    margin="dense"
                    ampm={false}
                    label="Time end"
                    format="dd/MM/yyyy HH:mm"
                    value={this.state.dateFilterEnd}
                    onChange={(date) => this.updateDateEnd(date)}

                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              <Box flexGrow={1} />
              <Box>
                <FormControl>
                  <Select
                    displayEmpty
                    value={this.state.selectedUserId}
                    onChange={this.handleChange('selectedUserId')}
                    autoWidth
                  >
                    <MenuItem value="" disabled>
                      Select Staff
                  </MenuItem>
                    {listUser.sort(function (a, b) {
                      let nameOrPseudoA = a.pseudo || a.userName;
                      let nameOrPseudoB = b.pseudo || b.userName;
                      var nameA = nameOrPseudoA.toUpperCase();
                      var nameB = nameOrPseudoB.toUpperCase();
                      if (nameA < nameB) {
                        return -1;
                      }
                      if (nameA > nameB) {
                        return 1;
                      }
                      return 0;
                    }).map(user =>
                      <MenuItem value={user.userName} key={user.userName}>{user.pseudo || user.userName}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            {
              isNextToken ?
                <Box style={{ color: "red" }}><Typography variant="h6">WARNING: Selected time range too wide. Too many elements to display (more than 2000).
           Please reduce the time range.</Typography></Box>
                :
                null
            }
          </form>
        </Box>
        {
          loading ?
            <LinearProgress variant="determinate" value={loaded} />
            : null
        }
        <ListAssignation listTasks={upgradedList} lastUpdate={lastUpdate} userSelected={selectedUserId} />
      </div>
    );
  }
}

export default Assignation;