import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './AddParticipant.css';

import * as participantApi from '../../utils/api/participant'

// UI
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardDateTimePicker
} from '@material-ui/pickers';

import * as constants from '../../utils/constants';

import { InputLabel, Select, MenuItem, Divider, Box } from '@material-ui/core';

class AddParticipantDialog extends React.PureComponent {
    state = {
        errors: [],
        loading: false,
        selection: "",
        code: "",
        sex: constants.SEX_OTHER,
        birthday: null,
        room: "",
        inDate: null,
        outDate: null,
    };

    handleEntering() {
    }

    handleCancel = () => {
        this.props.onClose();
    };

    async handleConfirmation() {
        let errors = [];

        if (!this.state.selection || !this.state.code) {
            errors.push('Please fill all mandatory fields (*)');
            this.setState({
                errors: errors,
            });
            return
        }
        this.setState({ loading: true });

        if (!this.props.groupID) {
            return
        }
        try {
            await participantApi.createParticipant(this.props.groupID,
                this.state.selection,
                this.state.code,
                this.state.sex,
                this.state.birthday,
                this.state.room,
                this.state.inDate,
                this.state.outDate);
        } catch (e) {
            console.log(e)
            e.errors.forEach(error => {
                errors.push(error.message)
            });
        }

        this.setState({ loading: false });
        if (errors.length > 0) {
            this.setState({
                errors: errors,
            });
        } else {
            this.props.onClose();
        }
    }

    handleOk = () => {
        this.props.onClose();
    };

    handleChange = stateName => event => {
        this.setState({
            [stateName]: event.target.value,
        });
    };
    handleDateChange = stateName => date => {
        this.setState({
            [stateName]: date,
        });
    };

    render() {
        const { groupID, ...other } = this.props;
        const { loading,
            selection,
            code,
            sex,
            birthday,
            room,
            inDate,
            outDate,
            errors,
        } = this.state;

        let loadingDisplay;
        if (loading) {
            loadingDisplay = <DialogContent><CircularProgress /></DialogContent>;
        }

        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="md"
                onEntering={() => this.handleEntering()}
                aria-labelledby="confirmation-dialog-title"
                {...other}
            >
                <DialogTitle id="confirmation-dialog-title">You are about to add a new participant to this group</DialogTitle>
                <DialogContent>
                    Please fill the information below:
                    <form noValidate autoComplete="off">
                        <Box marginBottom={2}>
                            <TextField
                                required
                                id="selection"
                                label="Selection number"
                                value={selection}
                                onChange={this.handleChange('selection')}
                                margin="normal"
                            />
                        </Box>
                        <Divider />
                        <Box marginBottom={2}>
                            <TextField
                                required
                                id="code"
                                label="Code"
                                value={code}
                                onChange={this.handleChange('code')}
                                margin="normal"
                            />
                        </Box>
                        <Divider />
                        <Box marginBottom={2}>
                            <TextField
                                id="room"
                                label="Room"
                                value={room}
                                onChange={this.handleChange('room')}
                                margin="normal"
                            />
                        </Box>
                        <Divider />
                        <Box marginBottom={2} marginTop={2}>
                            <InputLabel id="select-sex-label">Sex</InputLabel>
                            <Select
                                labelId="select-sex-label"
                                id="select-sex"
                                value={sex}
                                onChange={this.handleChange('sex')}
                            >
                                <MenuItem value={constants.SEX_OTHER}>Unkown</MenuItem>
                                <MenuItem value={constants.SEX_WOMAN}>Female</MenuItem>
                                <MenuItem value={constants.SEX_MAN}>Male</MenuItem>
                            </Select>
                        </Box>
                        <Divider />
                        <Box marginBottom={2}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-birthday"
                                    label="Date of birth"
                                    format="dd/MM/yyyy"
                                    value={birthday}
                                    onChange={this.handleDateChange('birthday')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Box>
                        <Divider />
                        <Box marginBottom={2}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDateTimePicker
                                    margin="dense"
                                    ampm={false}
                                    label="In date"
                                    format="dd/MM/yyyy HH:mm"
                                    value={inDate}
                                    onChange={this.handleDateChange('inDate')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Box>
                        <Divider />
                        <Box marginBottom={2}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDateTimePicker
                                    margin="dense"
                                    ampm={false}
                                    label="Out date"
                                    format="dd/MM/yyyy HH:mm"
                                    value={outDate}
                                    onChange={this.handleDateChange('outDate')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Box>
                    </form>
                </DialogContent>
                {loadingDisplay}
                <DialogContent>
                    <ul>
                        {errors.map((value, index) => {
                            return <li style={{ color: "red" }} key={index}>{value}</li>
                        })}
                    </ul>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={this.handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button variant="contained" disabled={loading} onClick={() => this.handleConfirmation()} color="primary">
                        Add!
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

AddParticipantDialog.propTypes = {
    onClose: PropTypes.func
};

class AddParticipant extends PureComponent {
    state = {
        dialogOpen: false,
    };

    handleClose = () => {
        this.setState({ dialogOpen: false });
    };

    handleOpen() {
        this.setState({ dialogOpen: true });
    }

    render() {
        return (
            <div>
                <Button variant="contained" color="primary" onClick={() => this.handleOpen()} disabled={this.props.loading}>
                    <AddIcon />
                    Add a participant to this group
                </Button>

                <AddParticipantDialog
                    open={this.state.dialogOpen}
                    onClose={this.handleClose}
                    groupID={this.props.groupID}
                />
            </div>
        );
    }
}

export default AddParticipant;