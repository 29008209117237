import React, { PureComponent } from 'react';
import './App.scss';

// Import amplify
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

// Import the main layout (that also take care of routing)
import MainLayout from './layouts/mainLayout/MainLayout.js'
import { SnackbarProvider } from 'notistack';

Amplify.configure(awsconfig);


const components = {
  Header() {
    return (
      <div style={{ margin: "5em" }} />
    );
  },
};
class App extends PureComponent {
  render() {
    return (
      <SnackbarProvider>
        <Authenticator hideSignUp={true} components={components}>
          {() => (
            <MainLayout />
          )}
        </Authenticator>
      </SnackbarProvider>
    );
  }
}

export default App
