import React, { Component } from 'react';

import './ListGroupPositions.css';

import MaterialTable from 'material-table'

import { TasksPositionRoute } from '../../Routing.js'
import moment from 'moment';
import * as users from '../../utils/api/user';
import EditPositionDialog from './editPositionDialog/EditPositionDialog';
import DeletePositionDialog from './deletePositionDialog/DeletePositionDialog';


class ListGroupPositions extends Component {
  state = {
    dialogEditOpen: false,
    dialogDeleteOpen: false,
    dialogViewOpen: false,
    selectedPosition: "",
    isPositionUpdater: false,
    isStudyManager: false,
    isParticipantValidator: false,
  };

  componentDidMount() {
    this.checkGroups();
  }

  async checkGroups() {
    const isPositionUpdater = await users.isPositionUpdater();
    const isStudyManager = await users.isStudyManager();
    const isParticipantValidator = await users.isParticipantValidator();
    this.setState({
      isParticipantValidator: isParticipantValidator,
      isStudyManager: isStudyManager,
      isPositionUpdater: isPositionUpdater,
    })
  }

  handleEditClose = () => {
    this.setState({ dialogEditOpen: false });
  };

  handleEditOpen(rowData) {
    this.setState({
      selectedPosition: rowData,
      dialogEditOpen: true
    });
  }

  handleDeleteClose = () => {
    this.setState({ dialogDeleteOpen: false });
  };

  handleDeleteOpen(rowData) {
    this.setState({
      selectedPosition: rowData,
      dialogDeleteOpen: true
    });
  }

  handleOpenOpen(rowData) {
    window.location.href = (TasksPositionRoute + rowData.id);
  }

  render() {
    const { listPositions, listParticipantsUnassigned } = this.props;
    const { selectedPosition, isStudyManager, isPositionUpdater, isParticipantValidator } = this.state;
    console.log('refresh')
    return (
      <div>
        <MaterialTable
          columns={[
            { title: 'Position', field: 'name', width: null },
            {
              title: 'T0',
              field: 't0',
              defaultSort: 'asc',
              render: rowData => {
                let toDisplay = 'Unknown';
                let color = "#8b0000";
                const date = new Date(rowData.t0).toISOString();
                if (date) {
                  toDisplay = moment(date).format('DD/MM/YYYY HH:mm');
                  color = "black";
                }
                return (
                  <div style={{ color: color }}>
                    {toDisplay}
                  </div>
                )
              },
            },
            { title: 'N°Inclusion', field: 'inclusion' },
            { title: 'N°Treatment', field: 'treatment' },
            { title: 'Dose', field: 'dose' },
            {
              title: 'N°Selection',
              render: rowData => {
                let toDisplay = 'Unknown';
                let color = "#8b0000";
                if (rowData.participantSelection) {
                  toDisplay = rowData.participantSelection;
                  color = "black";
                }
                return (
                  <div style={{ color: color }}>
                    {toDisplay}
                  </div>
                )
              },
            },
            {
              title: 'Code',
              field: 'code',
              render: rowData => {
                let toDisplay = 'Unknown';
                let color = "#8b0000";
                if (rowData.participantCode) {
                  toDisplay = rowData.participantCode;
                  color = "black";
                }
                return (
                  <div style={{ color: color }}>
                    {toDisplay}
                  </div>
                )
              },
            },
            {
              title: 'Sex',
              field: 'sex',
              render: rowData => {
                let toDisplay = 'Unknown';
                let color = "#8b0000";
                if (rowData.participantSex) {
                  toDisplay = rowData.participantSex;
                  color = "black";
                }
                return (
                  <div style={{ color: color }}>
                    {toDisplay}
                  </div>
                )
              },
            },
            {
              title: 'Status',
              field: 'validate',
              render: rowData => {
                let toDisplay = 'Not validated';
                let color = "#8b0000";
                if (rowData.validate) {
                  toDisplay = "Validate";
                  color = "green";
                }
                return (
                  <div style={{ color: color }}>
                    {toDisplay}
                  </div>
                )
              },
            },
          ]}
          actions={[
            {
              icon: 'visibility',
              onClick: (event, rowData) => this.handleEditOpen(rowData),
              hidden: !isParticipantValidator && !isPositionUpdater ? true : false,
              tooltip: "View and Edit position"
            },
            {
              icon: 'delete',
              onClick: (event, rowData) => this.handleDeleteOpen(rowData),
              hidden: !isStudyManager ? true : false,
              tooltip: "Delete position"
            },
            rowData => ({
              icon: 'storage',
              onClick: (event, rowData) => this.handleOpenOpen(rowData),
              tooltip: "See position timetable",
              hidden: rowData.id ? false : true,
            })
          ]}
          options={{
            actionsColumnIndex: -1,
            paging: false,
            draggable: false,
          }}
          data={listPositions}
          title=""
        />
        <EditPositionDialog
          open={this.state.dialogEditOpen}
          onClose={this.handleEditClose}
          position={selectedPosition}
          listparticipants={listParticipantsUnassigned}
        />
        <DeletePositionDialog
          open={this.state.dialogDeleteOpen}
          onClose={this.handleDeleteClose}
          position={selectedPosition}
        />
      </div>
    );
  }
}

export default ListGroupPositions;