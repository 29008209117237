import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import AppBar from '@material-ui/core/AppBar';
import HistoryIcon from "@material-ui/icons/History";
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import ClearIcon from '@material-ui/icons/Clear'
import PeopleIcon from '@material-ui/icons/People'
import AssignmentIcon from '@material-ui/icons/Assignment'
import HoteltIcon from '@material-ui/icons/Hotel'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import SettingsIcon from '@material-ui/icons/Settings'
import ListAltIcon from '@material-ui/icons/ListAlt'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

// Routing
import Routing, { StudiesRoute, PlanningRoute, SupervisorRoute, UsersManagmentRoute, AssignationRoute, ReportsRoute } from '../../Routing.js'

// Css
import './MainLayout.css';

// Import react router
import { BrowserRouter as Router } from "react-router-dom";

// Need to log out in the drawer
import { Auth } from 'aws-amplify';
import { getCurrentUsername, isStudyManager, isStudyValidator, isParameterManager, isTaskAssign, isStaffManager, isReportViewer, isParticipantValidator, isPositionUpdater, isParticipantManager, getPseudoFromUserName, isSupervisor } from '../../utils/api/user.js';
import { Box } from '@material-ui/core';
import ClockComponent from '../../components/clockComponent/ClockComponent.js';
import EventButton from '../../components/labelEvent/EventButton.tsx';

const drawerWidth = 300;

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: blue,
    },
});

const styles = theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        marginLeft: drawerWidth,
    },
    drawer: {
    },
    menuButton: {
        marginRight: 20,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
});

class MainLayout extends React.PureComponent {
    state = {
        mobileOpen: false,
        username: "Unkown",
        StudyManager: false,
        StudyValidator: false,
        Supervisor: false,
        ParticipantValidator: false,
        ParticipantManager: false,
        PositionUpdater: false,
        TaskAssign: false,
        StaffManager: false,
        ParameterManager: false,
        ReportViewer: false,
    };

    componentDidMount() {
        this.loadUsername();
        this.checkGroups();
    }

    async loadUsername() {
        const username = await getCurrentUsername();
        const pseudo = await getPseudoFromUserName(username);
        this.setState({
            username: username,
            pseudo: pseudo,
        })
    }

    async checkGroups() {
        let StudyManager = await isStudyManager();
        let StudyValidator = await isStudyValidator();
        let Supervisor = await isSupervisor();
        let ParticipantValidator = await isParticipantValidator();
        let ParticipantManager = await isParticipantManager();
        let PositionUpdater = await isPositionUpdater();
        let TaskAssign = await isTaskAssign();
        let StaffManager = await isStaffManager();
        let ParameterManager = await isParameterManager();
        let ReportViewer = await isReportViewer();

        this.setState({
            StudyManager: StudyManager,
            StudyValidator: StudyValidator,
            Supervisor: Supervisor,
            ParticipantValidator: ParticipantValidator,
            ParticipantManager: ParticipantManager,
            PositionUpdater: PositionUpdater,
            TaskAssign: TaskAssign,
            ParameterManager: ParameterManager,
            ReportViewer: ReportViewer,
            StaffManager: StaffManager,
        })
    }

    handleDrawerToggle = () => {
        this.setState(state => ({ mobileOpen: !state.mobileOpen }));
    };

    onSignOutClick() {
        console.log("click log out");
        Auth.signOut()
            .then(data => console.log(data))
            .catch(err => console.log(err));
        window.location.reload();
    }

    render() {
        const { classes } = this.props;
        const { username,
            pseudo,
            StudyManager,
            StudyValidator,
            Supervisor,
            ParticipantValidator,
            ParticipantManager,
            PositionUpdater,
            TaskAssign,
            StaffManager,
            ParameterManager,
            ReportViewer,
        } = this.state;

        const drawer = (
            <div>
                <div className="drawer-container">
                    <div className="drawer-background"></div>
                    <Typography variant="h6" color="inherit" className="drawer-text">
                        <span className="drawer-title">Eurofins Optimed Times</span>
                    </Typography>
                </div>
                <Divider />
                <List>
                    <ListItem button onClick={() => window.location.replace(PlanningRoute)}>
                        <ListItemIcon><AssignmentIcon /></ListItemIcon>
                        <ListItemText primary="Planning" />
                    </ListItem>
                    {
                        History ? (
                            <ListItem button onClick={() => window.location.replace("/scan-history")}>
                                <ListItemIcon><HistoryIcon /></ListItemIcon>
                                <ListItemText primary="History" />
                            </ListItem>
                        ) : null}
                    {
                        Supervisor ?
                            <ListItem button onClick={() => window.location.replace(SupervisorRoute)}>
                                <ListItemIcon><FullscreenIcon /></ListItemIcon>
                                <ListItemText primary="Supervisor" />
                            </ListItem>
                            :
                            null
                    }
                    {
                        StudyManager || StudyValidator || ParticipantValidator || ParticipantManager || PositionUpdater ?
                            <ListItem button onClick={() => window.location.replace(StudiesRoute)}>
                                <ListItemIcon><HoteltIcon /></ListItemIcon>
                                <ListItemText primary="Studies" />
                            </ListItem>
                            :
                            null
                    }

                    {
                        TaskAssign ?
                            <ListItem button onClick={() => window.location.replace(AssignationRoute)}>
                                <ListItemIcon><PlaylistAddIcon /></ListItemIcon>
                                <ListItemText primary="Assignation" />
                            </ListItem>
                            :
                            null
                    }

                    {
                        StaffManager ?
                            <ListItem button onClick={() => window.location.replace(UsersManagmentRoute)}>
                                <ListItemIcon><PeopleIcon /></ListItemIcon>
                                <ListItemText primary="Staff management" />
                            </ListItem>
                            :
                            null
                    }

                    {
                        ParameterManager ?
                            <ListItem button onClick={() => this.handleDrawerToggle()}>
                                <ListItemIcon><SettingsIcon /></ListItemIcon>
                                <ListItemText primary="Parameters" />
                            </ListItem>
                            :
                            null
                    }

                    {
                        ReportViewer ?
                            <ListItem button onClick={() => window.location.replace(ReportsRoute)}>
                                <ListItemIcon><ListAltIcon /></ListItemIcon>
                                <ListItemText primary="Reports" />
                            </ListItem>
                            :
                            null
                    }

                    <Divider />
                    <ListItem />
                    <Divider />

                    <ListItem button onClick={() => this.onSignOutClick()}>
                        <ListItemIcon><ClearIcon /></ListItemIcon>
                        <ListItemText primary="Log out" />
                    </ListItem>
                </List>
                <Divider />
            </div>
        );
        return (
            <Router>
                <MuiThemeProvider theme={theme}>
                    <div className={classes.root}>
                        <CssBaseline />
                        <AppBar position="fixed" color="primary" className={classes.appBar}>
                            <Toolbar>
                                <IconButton
                                    color="inherit"
                                    aria-label="Open drawer"
                                    onClick={this.handleDrawerToggle}
                                    className={classes.menuButton}
                                >
                                    <MenuIcon />
                                </IconButton>

                                <Typography variant="h6" color="inherit" >
                                    {username}{pseudo ? '/' + pseudo : null}
                                </Typography>
                                <Box flexGrow={1} />
                                <EventButton />
                                <ClockComponent />
                            </Toolbar>
                        </AppBar>
                        <nav className={classes.drawer}>
                            <Drawer
                                className={classes.drawer}
                                variant="temporary"
                                open={this.state.mobileOpen}
                                onClose={this.handleDrawerToggle}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                anchor="left"
                            >
                                {drawer}
                                <div className="footer">Developped for the exclusive use of © Eurofins Optimed <br />
                                    all right reserved<br />
                                    Version: 3.2.2 </div>
                            </Drawer>
                        </nav>
                        <main className={classes.content}>
                            <div className={classes.toolbar} />
                            <Routing></Routing>
                        </main>
                    </div>
                </MuiThemeProvider>
            </Router>
        );
    }
}

MainLayout.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MainLayout);