/* eslint-disable @typescript-eslint/ban-types */
import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import React from "react";
import { initClient, InitClientInterface } from "../initClient";

export type SubscriptionConfigType<
  VariableType extends {},
  ItemType extends {}
  > = {
    subscription: string;
    key: string;
    variables?: VariableType;
    condition?: (item: ItemType) => boolean;
    client?: InitClientInterface;
  };

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useSubscription = <ItemType extends {}, VariablesType extends {} = {}>(
  config: SubscriptionConfigType<VariablesType, ItemType>
) => {
  const [item, update] = React.useState<ItemType | undefined>();

  React.useEffect(() => {
    const clientOverride = initClient(config.client);
    let unsubscribe;
    if (config) {
      const { subscription, key, variables } = config;
      let subscriptionRes;
      if (clientOverride) {
        subscriptionRes = clientOverride.subscribe({
          query: gql(subscription),
          variables
        });
      } else {
        subscriptionRes = API.graphql(
          graphqlOperation(subscription, variables)
        );
      }

      if (!(subscriptionRes instanceof Promise)) {
        const sub = subscriptionRes.subscribe({
          next: (payload: { value: { data: { [x: string]: any; }; }; data: { [x: string]: any; }; }) => {
            try {
              let subItem = payload?.value?.data[key];
              if (!subItem) subItem = payload?.data[key];
              if (
                !config?.condition ||
                (config?.condition && config?.condition(subItem))
              )
                update(subItem);
            } catch (error) {
              // eslint-disable-next-line no-console
              console.error(
                `Check the key property: the current value is ${key}`
              );
            }
          }
        });
        unsubscribe = () => {
          sub.unsubscribe();
        };
      }
    }
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(config)]);

  return {
    item
  };
};

export default useSubscription;
