export const LIMIT = 2000;
export const STATUS_ONLINE = "online";
export const STATUS_OFFLINE = "offline";
export const SEX_MAN = "M";
export const SEX_WOMAN = "F";
export const SEX_OTHER = "O";
export const ORDER_DESC = "DESC";
export const ORDER_ASC = "ASC";

export const PROTOCOL_ACTION_VALIDATION = "Validation";
export const PROTOCOL_ACTION_IMPORT = "Import";
export const PROTOCOL_ACTION_CREATE = "Create";
export const PROTOCOL_ACTION_UPDATE = "Update";
export const PROTOCOL_ACTION_DELETE = "Delete";


const awsExports = require('../aws-exports')
const REGEX = /.*-(\w+)/
export const ENV = awsExports.default.aws_content_delivery_bucket.match(REGEX)[1];

let UserPoolId = "eu-central-1_6Wd0Ja1Hp";
if(ENV === "master") {
    UserPoolId = "eu-central-1_1fRoudrSM";
}
export const USER_POOL_ID = UserPoolId;

export const ASSIGNABLE_TASK_ACTIVE = "active";
export const ASSIGNABLE_TASK_INACTIVE = "inactive";

export const VALIDATION_TASK_ACTIVE = "active";
export const VALIDATION_TASK_INACTIVE = "inactive";

export const POSITION_UNASSIGNED = "unassigned";

export const ACTIVE_STATUS_ONLINE = "online";
export const ACTIVE_STATUS_OFFLINE = "offline";

export const CROSS_KEYWORD = "#CROSSED#";
