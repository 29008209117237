/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreatePrintTemplate = /* GraphQL */ `
  subscription OnCreatePrintTemplate(
    $filter: ModelSubscriptionPrintTemplateFilterInput
  ) {
    onCreatePrintTemplate(filter: $filter) {
      id
      templatePath
      name
      printerId
      printer {
        id
        printerNetworkId
        friendlyName
        active
        createdAt
        updatedAt
      }
      active
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePrintTemplate = /* GraphQL */ `
  subscription OnUpdatePrintTemplate(
    $filter: ModelSubscriptionPrintTemplateFilterInput
  ) {
    onUpdatePrintTemplate(filter: $filter) {
      id
      templatePath
      name
      printerId
      printer {
        id
        printerNetworkId
        friendlyName
        active
        createdAt
        updatedAt
      }
      active
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePrintTemplate = /* GraphQL */ `
  subscription OnDeletePrintTemplate(
    $filter: ModelSubscriptionPrintTemplateFilterInput
  ) {
    onDeletePrintTemplate(filter: $filter) {
      id
      templatePath
      name
      printerId
      printer {
        id
        printerNetworkId
        friendlyName
        active
        createdAt
        updatedAt
      }
      active
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePrinter = /* GraphQL */ `
  subscription OnCreatePrinter($filter: ModelSubscriptionPrinterFilterInput) {
    onCreatePrinter(filter: $filter) {
      id
      printerNetworkId
      friendlyName
      active
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePrinter = /* GraphQL */ `
  subscription OnUpdatePrinter($filter: ModelSubscriptionPrinterFilterInput) {
    onUpdatePrinter(filter: $filter) {
      id
      printerNetworkId
      friendlyName
      active
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePrinter = /* GraphQL */ `
  subscription OnDeletePrinter($filter: ModelSubscriptionPrinterFilterInput) {
    onDeletePrinter(filter: $filter) {
      id
      printerNetworkId
      friendlyName
      active
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLocationCat = /* GraphQL */ `
  subscription OnCreateLocationCat(
    $filter: ModelSubscriptionLocationCatFilterInput
  ) {
    onCreateLocationCat(filter: $filter) {
      id
      name
      prefix
      active
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLocationCat = /* GraphQL */ `
  subscription OnUpdateLocationCat(
    $filter: ModelSubscriptionLocationCatFilterInput
  ) {
    onUpdateLocationCat(filter: $filter) {
      id
      name
      prefix
      active
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLocationCat = /* GraphQL */ `
  subscription OnDeleteLocationCat(
    $filter: ModelSubscriptionLocationCatFilterInput
  ) {
    onDeleteLocationCat(filter: $filter) {
      id
      name
      prefix
      active
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLocation = /* GraphQL */ `
  subscription OnCreateLocation($filter: ModelSubscriptionLocationFilterInput) {
    onCreateLocation(filter: $filter) {
      id
      locationCatId
      name
      friendlyName
      active
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLocation = /* GraphQL */ `
  subscription OnUpdateLocation($filter: ModelSubscriptionLocationFilterInput) {
    onUpdateLocation(filter: $filter) {
      id
      locationCatId
      name
      friendlyName
      active
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLocation = /* GraphQL */ `
  subscription OnDeleteLocation($filter: ModelSubscriptionLocationFilterInput) {
    onDeleteLocation(filter: $filter) {
      id
      locationCatId
      name
      friendlyName
      active
      createdAt
      updatedAt
    }
  }
`;
export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment($filter: ModelSubscriptionCommentFilterInput) {
    onCreateComment(filter: $filter) {
      id
      selector
      active
      comment
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment($filter: ModelSubscriptionCommentFilterInput) {
    onUpdateComment(filter: $filter) {
      id
      selector
      active
      comment
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment($filter: ModelSubscriptionCommentFilterInput) {
    onDeleteComment(filter: $filter) {
      id
      selector
      active
      comment
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSampleProtocolTemplate = /* GraphQL */ `
  subscription OnCreateSampleProtocolTemplate(
    $filter: ModelSubscriptionSampleProtocolTemplateFilterInput
  ) {
    onCreateSampleProtocolTemplate(filter: $filter) {
      id
      partId
      active
      name
      sponsorName
      studyName
      validationDate
      validateUserId
      validationDateSecond
      validateUserIdSecond
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSampleProtocolTemplate = /* GraphQL */ `
  subscription OnUpdateSampleProtocolTemplate(
    $filter: ModelSubscriptionSampleProtocolTemplateFilterInput
  ) {
    onUpdateSampleProtocolTemplate(filter: $filter) {
      id
      partId
      active
      name
      sponsorName
      studyName
      validationDate
      validateUserId
      validationDateSecond
      validateUserIdSecond
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSampleProtocolTemplate = /* GraphQL */ `
  subscription OnDeleteSampleProtocolTemplate(
    $filter: ModelSubscriptionSampleProtocolTemplateFilterInput
  ) {
    onDeleteSampleProtocolTemplate(filter: $filter) {
      id
      partId
      active
      name
      sponsorName
      studyName
      validationDate
      validateUserId
      validationDateSecond
      validateUserIdSecond
      createdAt
      updatedAt
    }
  }
`;
export const onCreateListing = /* GraphQL */ `
  subscription OnCreateListing($filter: ModelSubscriptionListingFilterInput) {
    onCreateListing(filter: $filter) {
      id
      sampleProtocolTemplateId
      name
      printTemplateId
      printTemplate {
        id
        templatePath
        name
        printerId
        printer {
          id
          printerNetworkId
          friendlyName
          active
          createdAt
          updatedAt
        }
        active
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateListing = /* GraphQL */ `
  subscription OnUpdateListing($filter: ModelSubscriptionListingFilterInput) {
    onUpdateListing(filter: $filter) {
      id
      sampleProtocolTemplateId
      name
      printTemplateId
      printTemplate {
        id
        templatePath
        name
        printerId
        printer {
          id
          printerNetworkId
          friendlyName
          active
          createdAt
          updatedAt
        }
        active
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteListing = /* GraphQL */ `
  subscription OnDeleteListing($filter: ModelSubscriptionListingFilterInput) {
    onDeleteListing(filter: $filter) {
      id
      sampleProtocolTemplateId
      name
      printTemplateId
      printTemplate {
        id
        templatePath
        name
        printerId
        printer {
          id
          printerNetworkId
          friendlyName
          active
          createdAt
          updatedAt
        }
        active
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSampleTemplate = /* GraphQL */ `
  subscription OnCreateSampleTemplate(
    $filter: ModelSubscriptionSampleTemplateFilterInput
  ) {
    onCreateSampleTemplate(filter: $filter) {
      id
      listingId
      theoreticalTime
      theoreticalTimeEnd
      theoreticalTimeLabel
      period
      matrixName
      index
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSampleTemplate = /* GraphQL */ `
  subscription OnUpdateSampleTemplate(
    $filter: ModelSubscriptionSampleTemplateFilterInput
  ) {
    onUpdateSampleTemplate(filter: $filter) {
      id
      listingId
      theoreticalTime
      theoreticalTimeEnd
      theoreticalTimeLabel
      period
      matrixName
      index
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSampleTemplate = /* GraphQL */ `
  subscription OnDeleteSampleTemplate(
    $filter: ModelSubscriptionSampleTemplateFilterInput
  ) {
    onDeleteSampleTemplate(filter: $filter) {
      id
      listingId
      theoreticalTime
      theoreticalTimeEnd
      theoreticalTimeLabel
      period
      matrixName
      index
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAlicotTemplate = /* GraphQL */ `
  subscription OnCreateAlicotTemplate(
    $filter: ModelSubscriptionAlicotTemplateFilterInput
  ) {
    onCreateAlicotTemplate(filter: $filter) {
      id
      listingId
      indexPrefix
      indexFirst
      number
      matrixName
      printTemplateId
      printTemplate {
        id
        templatePath
        name
        printerId
        printer {
          id
          printerNetworkId
          friendlyName
          active
          createdAt
          updatedAt
        }
        active
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAlicotTemplate = /* GraphQL */ `
  subscription OnUpdateAlicotTemplate(
    $filter: ModelSubscriptionAlicotTemplateFilterInput
  ) {
    onUpdateAlicotTemplate(filter: $filter) {
      id
      listingId
      indexPrefix
      indexFirst
      number
      matrixName
      printTemplateId
      printTemplate {
        id
        templatePath
        name
        printerId
        printer {
          id
          printerNetworkId
          friendlyName
          active
          createdAt
          updatedAt
        }
        active
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAlicotTemplate = /* GraphQL */ `
  subscription OnDeleteAlicotTemplate(
    $filter: ModelSubscriptionAlicotTemplateFilterInput
  ) {
    onDeleteAlicotTemplate(filter: $filter) {
      id
      listingId
      indexPrefix
      indexFirst
      number
      matrixName
      printTemplateId
      printTemplate {
        id
        templatePath
        name
        printerId
        printer {
          id
          printerNetworkId
          friendlyName
          active
          createdAt
          updatedAt
        }
        active
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCohort = /* GraphQL */ `
  subscription OnCreateCohort($filter: ModelSubscriptionCohortFilterInput) {
    onCreateCohort(filter: $filter) {
      id
      partId
      name
      positionList
      active
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCohort = /* GraphQL */ `
  subscription OnUpdateCohort($filter: ModelSubscriptionCohortFilterInput) {
    onUpdateCohort(filter: $filter) {
      id
      partId
      name
      positionList
      active
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCohort = /* GraphQL */ `
  subscription OnDeleteCohort($filter: ModelSubscriptionCohortFilterInput) {
    onDeleteCohort(filter: $filter) {
      id
      partId
      name
      positionList
      active
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLabel = /* GraphQL */ `
  subscription OnCreateLabel($filter: ModelSubscriptionLabelFilterInput) {
    onCreateLabel(filter: $filter) {
      id
      cohortId
      serial
      theoreticalTime
      theoreticalTimeLabel
      theoreticalTimeEnd
      period
      matrixName
      index
      printTemplateId
      printTemplate {
        id
        templatePath
        name
        printerId
        printer {
          id
          printerNetworkId
          friendlyName
          active
          createdAt
          updatedAt
        }
        active
        createdAt
        updatedAt
      }
      printed
      active
      studyName
      sponsorName
      inclusion
      parentId
      firstFillEventTime
      firstFillEventUserAndPseudo
      firstCentriEventTime
      firstCentriEventUserAndPseudo
      firstFreezeEventTime
      firstFreezeEventUserAndPseudo
      firstOutEventTime
      firstOutEventUserAndPseudo
      lastEventLocationID
      lastEventType
      lastEventTime
      lastEventAuthor
      comments
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLabel = /* GraphQL */ `
  subscription OnUpdateLabel($filter: ModelSubscriptionLabelFilterInput) {
    onUpdateLabel(filter: $filter) {
      id
      cohortId
      serial
      theoreticalTime
      theoreticalTimeLabel
      theoreticalTimeEnd
      period
      matrixName
      index
      printTemplateId
      printTemplate {
        id
        templatePath
        name
        printerId
        printer {
          id
          printerNetworkId
          friendlyName
          active
          createdAt
          updatedAt
        }
        active
        createdAt
        updatedAt
      }
      printed
      active
      studyName
      sponsorName
      inclusion
      parentId
      firstFillEventTime
      firstFillEventUserAndPseudo
      firstCentriEventTime
      firstCentriEventUserAndPseudo
      firstFreezeEventTime
      firstFreezeEventUserAndPseudo
      firstOutEventTime
      firstOutEventUserAndPseudo
      lastEventLocationID
      lastEventType
      lastEventTime
      lastEventAuthor
      comments
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLabel = /* GraphQL */ `
  subscription OnDeleteLabel($filter: ModelSubscriptionLabelFilterInput) {
    onDeleteLabel(filter: $filter) {
      id
      cohortId
      serial
      theoreticalTime
      theoreticalTimeLabel
      theoreticalTimeEnd
      period
      matrixName
      index
      printTemplateId
      printTemplate {
        id
        templatePath
        name
        printerId
        printer {
          id
          printerNetworkId
          friendlyName
          active
          createdAt
          updatedAt
        }
        active
        createdAt
        updatedAt
      }
      printed
      active
      studyName
      sponsorName
      inclusion
      parentId
      firstFillEventTime
      firstFillEventUserAndPseudo
      firstCentriEventTime
      firstCentriEventUserAndPseudo
      firstFreezeEventTime
      firstFreezeEventUserAndPseudo
      firstOutEventTime
      firstOutEventUserAndPseudo
      lastEventLocationID
      lastEventType
      lastEventTime
      lastEventAuthor
      comments
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEvent = /* GraphQL */ `
  subscription OnCreateEvent($filter: ModelSubscriptionEventFilterInput) {
    onCreateEvent(filter: $filter) {
      id
      labelId
      owner
      pseudo
      usedDate
      locationId
      type
      description
      labelData
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEvent = /* GraphQL */ `
  subscription OnUpdateEvent($filter: ModelSubscriptionEventFilterInput) {
    onUpdateEvent(filter: $filter) {
      id
      labelId
      owner
      pseudo
      usedDate
      locationId
      type
      description
      labelData
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEvent = /* GraphQL */ `
  subscription OnDeleteEvent($filter: ModelSubscriptionEventFilterInput) {
    onDeleteEvent(filter: $filter) {
      id
      labelId
      owner
      pseudo
      usedDate
      locationId
      type
      description
      labelData
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePrintServer = /* GraphQL */ `
  subscription OnCreatePrintServer(
    $filter: ModelSubscriptionPrintServerFilterInput
  ) {
    onCreatePrintServer(filter: $filter) {
      id
      endpoint
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePrintServer = /* GraphQL */ `
  subscription OnUpdatePrintServer(
    $filter: ModelSubscriptionPrintServerFilterInput
  ) {
    onUpdatePrintServer(filter: $filter) {
      id
      endpoint
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePrintServer = /* GraphQL */ `
  subscription OnDeletePrintServer(
    $filter: ModelSubscriptionPrintServerFilterInput
  ) {
    onDeletePrintServer(filter: $filter) {
      id
      endpoint
      createdAt
      updatedAt
    }
  }
`;
