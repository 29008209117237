/* eslint-disable @typescript-eslint/ban-types */
import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import { QueryConfigType } from "../hooks/useQuery";
import { initClient } from "../initClient";

const getQuery = async <ItemType extends {}, VariablesType extends {} = {}>(
  config: QueryConfigType<VariablesType>
) => {
  try {
    let rowResult;
    const clientOverride = initClient(config.client);
    if (clientOverride) {
      rowResult = await clientOverride.query({
        query: gql(config.query),
        variables: config.variables,
      });
    } else {
      rowResult = await API.graphql(
        graphqlOperation(config.query, config.variables)
      );
    }
    const {
      data: { [config.key]: returnedItem },
    } = rowResult as {
      data: {
        [key: string]: ItemType;
      };
    };
    return returnedItem;
  } catch (errorFetch) {
    // eslint-disable-next-line no-console
    console.log(errorFetch);
    return new Error(`Fail to fetch ${config.key}`);
  }
};

export default getQuery;
