import {
  Label,
  SampleProtocolTemplate,
  SampleTemplate,
} from "../../../utils/BarcodeClient/API";

export const isTemplateValidated = (
  template: SampleProtocolTemplate | undefined
) => {
  return !!(
    template &&
    template.validateUserId &&
    template.validationDate &&
    template.validateUserIdSecond &&
    template.validationDateSecond
  );
};

const MINUTES_IN_DAYS = 1440;
const MINUTES_IN_HOURS = 60;
export const theoreticalTimeFormatter = (
  object: Label | SampleTemplate
): string => {
  if (object.theoreticalTimeLabel) {
    return `${object.theoreticalTimeLabel}${
      object.theoreticalTimeEnd ? `➤ ${object.theoreticalTimeEnd}` : ""
    }`;
  }
  const thMinute = Number(object.theoreticalTime);
  const days = Math.floor(thMinute / MINUTES_IN_DAYS);
  const hours = Math.floor(
    (thMinute - days * MINUTES_IN_DAYS) / MINUTES_IN_HOURS
  );
  const minutes = Math.floor(
    thMinute - MINUTES_IN_DAYS * days - MINUTES_IN_HOURS * hours
  );
  return `D${days}T${hours}:${minutes}`;
};

export const ThTimeToString = (thTime: number) => {
  const isNegative = thTime < 0;
  return `${isNegative ? "-" : ""}${Math.abs(thTime)
    .toString()
    .padStart(isNegative ? 9 : 10, "0")}`;
};

export const sortByThTime = (
  a?: { period?: string; theoreticalTime?: string },
  b?: { period?: string; theoreticalTime?: string }
) => {
  if (
    !a ||
    !b ||
    !a.period ||
    !a.theoreticalTime ||
    !b.period ||
    !b.theoreticalTime
  )
    return 0;
  if (a.period > b.period) return 1;
  if (a.period < b.period) return -1;
  if (a.period === b.period) {
    if (Number(a.theoreticalTime) > Number(b.theoreticalTime)) return 1;
    return -1;
  }
  return 0;
};
