import React, { PureComponent, Component } from 'react';
import './Study.css';

import * as subscriptions from './../../graphql/subscriptions';

import { API, graphqlOperation } from 'aws-amplify';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListParts from '../../components/listParts/ListParts';

import AddPart from './../../components/addPart/AddPart'
import * as studyApi from '../../utils/api/study';
import * as partApi from '../../utils/api/part';
import Utils from '../../utils/utils';
import { Button, Box } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import SyncIcon from '@material-ui/icons/Sync';

import { Link } from "react-router-dom";
import { StudiesRoute, StudyCheckRoute } from '../../Routing';
import { isStudyManager } from '../../utils/api/user';

class Study extends Component {
    componentDidMount() {
        this.queryCompleteList(this.props.match.params.id);
        // Check groups
        this.checkGroups()
        // Subscribe to new part
        this.subscriptionNew = this.subscribeNewPart();
        // Subscribe to delete part
        this.subscriptionDelete = this.subscribeDeletePart();
        // Subscribe to update part
        this.subscriptionUpdate = this.subscribeUpdatePart();
    }
    state = {
        studyID: "",
        studyInfo: null,
        listParts: [],
        StudyManager: false,
    };

    async queryCompleteList(studyID) {
        const queryStudy = await studyApi.getStudy(studyID)
        const queryParts = await partApi.listPartsByStudy(studyID)

        let studyInfo = Utils.checkNested(queryStudy, 'data', 'getStudy');
        this.setState({
            studyInfo: studyInfo,
            studyID: studyID,
            listParts: Utils.checkNested(queryParts, "items")
        });
    }

    subscribeNewPart() {
        return API.graphql(
            graphqlOperation(subscriptions.onCreatePart)
        ).subscribe({
            next: (partData) => {
                let newListParts = this.state.listParts;
                if (partData.value.data.onCreatePart.studyId === this.state.studyID) {
                    newListParts.unshift(partData.value.data.onCreatePart);
                    this.setState({ listParts: newListParts });
                }
            }
        });
    }

    subscribeDeletePart() {
        return API.graphql(
            graphqlOperation(subscriptions.onDeletePart)
        ).subscribe({
            next: (partData) => {
                let newListParts = this.state.listParts;
                for (let i = 0; i < newListParts.length; i++) {
                    if (newListParts[i].id === partData.value.data.onDeletePart.id) {
                        newListParts.splice(i, 1);
                    }
                }
                this.setState({ listParts: newListParts });
            }
        });
    }

    subscribeUpdatePart() {
        return API.graphql(
            graphqlOperation(subscriptions.onUpdatePart)
        ).subscribe({
            next: (partData) => {
                let newListParts = this.state.listParts;
                for (let i = 0; i < newListParts.length; i++) {
                    if (newListParts[i].id === partData.value.data.onUpdatePart.id) {
                        newListParts[i] = partData.value.data.onUpdatePart;
                    }
                }
                this.setState({ listParts: newListParts });
            }
        });
    }

    componentWillUnmount() {
        this.subscriptionNew.unsubscribe();
        this.subscriptionDelete.unsubscribe();
        this.subscriptionUpdate.unsubscribe();
    }

    async checkGroups() {
        const StudyManager = await isStudyManager()
        this.setState({
            StudyManager: StudyManager,
        })
    }

    render() {
        const { studyInfo, studyID, listParts, StudyManager } = this.state;
        const name = studyInfo ? studyInfo.name : null;
        const description = studyInfo ? studyInfo.description : null;
        return (
            <div>
                <Typography variant="h4" color="inherit">
                    Study {name}
                </Typography>
                <Divider variant="middle" />
                <Button color="primary" component={Link} to={StudiesRoute} >
                    <ArrowBack />
                    Back to studies list
                </Button>

                {
                    description && description !== " " ?
                        <div className="description">
                            <Typography color="inherit">
                                Description: {description}
                            </Typography>
                        </div>
                        :
                        null
                }
                <Box display="flex" flexWrap="wrap" alignItems="center" marginTop={2} marginBottom={2}>
                    {
                        StudyManager ?
                            <Box>
                                <Button variant="contained" color="primary" component={Link} to={StudyCheckRoute + studyID} >
                                    <SyncIcon />
                                    Check study consistency
                                </Button>
                            </Box>
                            :
                            null
                    }
                    <Box flexGrow={1} />
                    {
                        StudyManager ?
                            <Box><AddPart studyID={studyID}></AddPart></Box>
                            :
                            null
                    }
                </Box>
                <ListParts parts={listParts}></ListParts>
            </div>
        );
    }
}

export default Study;