export enum EventType {
  CREATION = "CREATION",
  PRINT = "PRINT",
  FILL = "FILL",
  CENTRI = "CENTRI",
  TRANSFER = "TRANSFER",
  FREEZE = "FREEZE",
  LOCATION = "LOCATION",
  OUT = "OUT",
  COMMENT = "COMMENT"
}

interface EventParams {
  overrideLastIcon: boolean;
  withLocation: boolean;
}

export const EVENTS: Record<EventType, EventParams> = {
  [EventType.CREATION]: {
    overrideLastIcon: true,
    withLocation: false
  },
  [EventType.PRINT]: {
    overrideLastIcon: true,
    withLocation: false
  },
  [EventType.FILL]: {
    overrideLastIcon: true,
    withLocation: false
  },
  [EventType.CENTRI]: {
    overrideLastIcon: true,
    withLocation: true
  },
  [EventType.TRANSFER]: {
    overrideLastIcon: true,
    withLocation: true
  },
  [EventType.FREEZE]: {
    overrideLastIcon: true,
    withLocation: true
  },
  [EventType.LOCATION]: {
    overrideLastIcon: true,
    withLocation: true
  },
  [EventType.OUT]: {
    overrideLastIcon: true,
    withLocation: false
  },
  [EventType.COMMENT]: {
    overrideLastIcon: false,
    withLocation: false
  }
};
