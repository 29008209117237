import React, { PureComponent } from 'react';

import './ListGroupParticipants.css';

import EditParticipantDialog from './editParticipantDialog/EditParticipantDialog';
import DeleteParticipantDialog from './deleteParticipantDialog/DeleteParticipantDialog';

import MaterialTable from 'material-table'

import moment from 'moment';
import * as users from '../../utils/api/user';


class ListGroupParticipants extends PureComponent {
  state = {
    dialogEditOpen: false,
    dialogDeleteOpen: false,
    dialogViewOpen: false,
    selectedParticipant: "",
    isParticipantManager: false,
  };

  componentDidMount() {
    this.checkGroups();
  }

  async checkGroups() {
    const isParticipantManager = await users.isParticipantManager();
    this.setState({
      isParticipantManager: isParticipantManager,
    })
  }

  handleEditClose = () => {
    this.setState({ dialogEditOpen: false });
  };

  handleEditOpen(rowData) {
    this.setState({
      selectedParticipant: rowData,
      dialogEditOpen: true
    });
  }

  handleDeleteClose = () => {
    this.setState({ dialogDeleteOpen: false });
  };

  handleDeleteOpen(rowData) {
    this.setState({
      selectedParticipant: rowData,
      dialogDeleteOpen: true
    });
  }

  render() {
    const { listParticipants } = this.props;
    const { selectedParticipant, isParticipantManager } = this.state;
    const nullDate = new Date(null).toISOString();
    return (
      <div>
        <MaterialTable
          columns={[
            { title: 'n° Selection', field: 'selection', width: null },
            { title: 'Code', field: 'code', defaultSort: 'asc' },
            { title: 'Room', field: 'room' },
            { title: 'Sex', field: 'sex' },
            {
              title: 'Date of birth',
              field: 'birthday',
              render: rowData => {
                let toDisplay = 'Unknown';
                let color = "#8b0000";
                const date = new Date(rowData.birthday).toISOString();
                if (date !== nullDate) {
                  toDisplay = moment(date).format('DD/MM/YYYY');
                  color = "black";
                }
                return (
                  <div style={{ color: color }}>
                    {toDisplay}
                  </div>
                )
              },
            },
            {
              title: 'In date',
              field: 'inDate',
              render: rowData => {
                let toDisplay = 'Unknown';
                let color = "#8b0000";
                const date = new Date(rowData.inDate).toISOString();
                if (date !== nullDate) {
                  toDisplay = moment(date).format('DD/MM/YYYY HH:mm');
                  color = "black";
                }
                return (
                  <div style={{ color: color }}>
                    {toDisplay}
                  </div>
                )
              },
            },
            {
              title: 'Out date',
              field: 'outDate',
              render: rowData => {
                let toDisplay = 'Unknown';
                let color = "#8b0000";
                const date = new Date(rowData.outDate).toISOString();
                if (date !== nullDate) {
                  toDisplay = moment(date).format('DD/MM/YYYY HH:mm');
                  color = "black";
                }
                return (
                  <div style={{ color: color }}>
                    {toDisplay}
                  </div>
                )
              },
            },
            {
              title: 'Position',
              render: rowData => {
                let toDisplay = 'not set';
                let color = "#8b0000";
                if (rowData.positionName) {
                  toDisplay = rowData.positionName;
                  color = "black";
                }
                return (
                  <div style={{ color: color }}>
                    {toDisplay}
                  </div>
                )
              },
            },
            {
              title: 'Status',
              field: 'validate',
              render: rowData => {
                let toDisplay = 'Not validated';
                let color = "#8b0000";
                if (rowData.validate) {
                  toDisplay = "Validate";
                  color = "green";
                }
                return (
                  <div style={{ color: color }}>
                    {toDisplay}
                  </div>
                )
              },
            },
          ]}
          actions={[
            {
              icon: 'visibility',
              onClick: (event, rowData) => this.handleEditOpen(rowData),
              hidden: isParticipantManager? false : true ,
              tooltip: "View and Edit participant"
            },
            {
              icon: 'delete',
              onClick: (event, rowData) => this.handleDeleteOpen(rowData),
              hidden: isParticipantManager? false : true ,
              tooltip: "Delete participant"
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            paging: false,
            draggable: false,
          }}
          data={listParticipants}
          title=""
        />
        <EditParticipantDialog
          open={this.state.dialogEditOpen}
          onClose={this.handleEditClose}
          participant={selectedParticipant}
        />
        <DeleteParticipantDialog
          open={this.state.dialogDeleteOpen}
          onClose={this.handleDeleteClose}
          participant={selectedParticipant}
        />
      </div>
    );
  }
}

export default ListGroupParticipants;