import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';

import * as groupApi from './group'
import { API, graphqlOperation } from 'aws-amplify';
import Utils from '../../utils/utils';
import * as constants from '../constants';
import uuid from 'uuid/v4';

export async function deletePart(partId) {
    // When we delete a part we also want to delete all related groups
    let returnMessage = {
        code: "201",
        messages: [],
    }
    // First get all groups for this part
    const listGroupsByPart = await groupApi.listGroupsByPart(partId);
    const listGroupsByPartItems = Utils.checkNested(listGroupsByPart, 'items');

    // If listGroupsByPart is null, then an error occurs return without deleting anything
    if (listGroupsByPart === null) {
        returnMessage.code = "400";
        returnMessage.messages = "Fail to retreive groups, cancel deletion";
        return returnMessage
    }

    // Delete all groups link to this part
    let nmbDeleted = 0;
    let remainingList = [...listGroupsByPartItems];
    for (let i = 0; i < listGroupsByPartItems.length; i++) {
        if (remainingList.length === 0) break
        // Batch 100 tasks together
        const batchList = remainingList.slice(0, 100);
        remainingList.splice(0, 100)
        await Promise.all(batchList.map(async (group) => {
            // Use the custom delete group as we also want protocol tasks and assignable tasks to be deleted as well
            let resultDeleteGroup = await groupApi.deleteGroup(group.id);
            if (resultDeleteGroup.code !== '201') {
                returnMessage.code = "400";
                let newMessages = returnMessage.messages;
                resultDeleteGroup.messages.forEach(message => {
                    newMessages.push(message)
                });
                returnMessage.messages = newMessages;
            } else {
                nmbDeleted++
            }
        }));
    }

    // If all groups have been deleted, then we proceed to the part deletion
    if (returnMessage.code === "201") {
        try {
            await API.graphql(graphqlOperation(mutations.deletePart, { input: { id: partId } }));
        } catch (e) {
            returnMessage.code = "400";
            returnMessage.messages = e.errors;
            return returnMessage
        }
    }
    let newMessages = returnMessage.messages;
    newMessages.push("Deleted the parts as well as " + nmbDeleted + " groups.")
    returnMessage.messages = newMessages;
    return returnMessage
}

export async function listPartsByStudy(studyId) {
    try {
        const listPartsByStudy = await API.graphql(graphqlOperation(queries.partByStudy,
            {
                studyId: studyId,
                sortDirection: constants.ORDER_DESC,
                limit: constants.LIMIT
            }));
        let partByStudy = Utils.checkNested(listPartsByStudy, 'data', 'partByStudy')
        return partByStudy
    } catch (e) {
        console.log(e)
        return { items: [], nextToken: null }
    }
}

export async function createPart(studyId, name, description = null) {
    if (!studyId || !name) {
        return null
    }
    const date = new Date().toISOString();

    let input = {
        id: uuid(),
        studyId: studyId,
        name: name,
        date: date,
    }
    if (description && description !== "") {
        input.description = description;
    }
    let result = await API.graphql(graphqlOperation(mutations.createPart, { input: input }));
    return result
}

export async function updatePart(id, name, description = null) {
    let input = {
        id: id,
        name: name,
        description: description,
    }
    if (description === null || description === "") {
        input.description = " ";
    }
    try {
        let result = await API.graphql(graphqlOperation(mutations.updatePart, { input: input }));
        console.log(result)
        return result
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function getPart(id) {
    try {
        const result = await API.graphql(graphqlOperation(queries.getPart, { id: id }));
        return result
    } catch (e) {
        console.log(e)
        return false
    }
}