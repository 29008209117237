import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';

import { Auth } from 'aws-amplify';
import Utils from '../utils';
import * as constants from '../constants';

export async function createNewUser(userName, email, tempPassword, adminPassword) {
    let isAdmin = await isStaffManager();

    let returnMessage = {
        code: "201",
        messages: [],
    }

    if (isAdmin) {
        // First check if the username exist in the db (otherwise the lambda trigger is launch even if the creation fail)
        try {
            // Check no user has the same name
            let userList = await listAllUsers();
            for (let i = 0; i < userList.length; i++) {
                let user = userList[i];
                if (user.userName === userName) {
                    alert("The user name must be unique!")
                    returnMessage.code = "400";
                    returnMessage.messages.push("The user name must be unique!");
                    return returnMessage;
                }
            }
        } catch (e) {
            alert("Creation fail for an unkown reason, please retry later.")
            returnMessage.code = "400";
            returnMessage.messages.push("Fail to get the user list in db.");
            return returnMessage;
        }
        // Create the user in cognito
        const aws = require('aws-sdk');
        const adminCredentials = await getAdminCredentials(adminPassword);
        aws.config.update(
            {
                accessKeyId: adminCredentials.accessKeyId,
                secretAccessKey: adminCredentials.secretAccessKey,
                region: adminCredentials.region
            });

        const CognitoIdentityServiceProvider = new
            aws.CognitoIdentityServiceProvider();
        let params = {
            "DesiredDeliveryMediums": ["EMAIL"],
            "TemporaryPassword": tempPassword,
            "UserAttributes": [
                {
                    "Name": "email",
                    "Value": email
                },
                {
                    "Name": "email_verified",
                    "Value": "True"
                }
            ],
            "Username": userName,
            "UserPoolId": constants.USER_POOL_ID,
        }

        await CognitoIdentityServiceProvider.adminCreateUser(params, async (err, data) => {
            if (err) {
                returnMessage.code = "400";
                returnMessage.messages.push(err.toString());
                console.log(err)
                alert(err.toString())
                return returnMessage;
            }
            else {
                returnMessage.messages = returnMessage.messages.push("User sucessfully created");
                window.location.reload();
            }
        });
    } else {
        alert("You are not allowed to performed this action.")
        returnMessage.code = "400";
        returnMessage.messages.push("You are not allowed to performed this action.");
    }
    return returnMessage
}

export async function deleteUser(userName, password) {
    let isAdmin = await isStaffManager();

    let returnMessage = {
        code: "201",
        messages: [],
    }

    if (isAdmin) {
        // First, we delete the user from cognito
        const aws = require('aws-sdk');
        const adminCredentials = await getAdminCredentials(password);
        aws.config.update(
            {
                accessKeyId: adminCredentials.accessKeyId,
                secretAccessKey: adminCredentials.secretAccessKey,
                region: adminCredentials.region
            });

        const CognitoIdentityServiceProvider = new
            aws.CognitoIdentityServiceProvider();
        let params = {
            "Username": userName,
            "UserPoolId": constants.USER_POOL_ID
        }

        await CognitoIdentityServiceProvider.adminDeleteUser(params, async (err, data) => {
            if (err) {
                try {
                    if (err.toString().indexOf("User does not exist") === -1) {
                        returnMessage.code = "400";
                        returnMessage.messages.push(err.toString());
                        return returnMessage;
                    }
                    console.log("Fail to delete because does not exists on the cognito pool")
                } catch (e) {
                    returnMessage.code = "400";
                    returnMessage.messages.push(err.toString());
                    return returnMessage;
                }
            }
            else {
                returnMessage.messages.push("User deleted from the cognito pool");
            }
            // If we succeed or if the user does not exist on the cognito user pool we can delete it from the user db
            try {
                await API.graphql(graphqlOperation(mutations.deleteUser, { input: { userName: userName } }));
                console.log("User deleted from the db")
            } catch (e) {
                console.log(e)
                returnMessage.code = "400";
                returnMessage.messages.push("Fail to delete user from dynamodb.");
                let newMessages = returnMessage.messages;
                e.errors.forEach(message => {
                    newMessages.push(message)
                });
                returnMessage.messages = newMessages;
            }
        });
    } else {
        alert("You are not allowed to performed this action.")
        returnMessage.code = "400";
        returnMessage.messages = "You are not allowed to performed this action.";
    }
    return returnMessage
}

export async function listAllUsers() {
    try {
        const rawListUser = await API.graphql(graphqlOperation(queries.listUsers,
            {
                limit: constants.LIMIT
            }));
        let listUser = Utils.checkNested(rawListUser, 'data', 'listUsers', 'items')
        return listUser
    } catch (e) {
        console.log(e)
        return []
    }
}

export async function listCognitoUsers(password) {
    let isAdmin = await isStaffManager();
    console.log("is admin: " + isAdmin)
    if (isAdmin) {
        const aws = require('aws-sdk');
        const adminCredentials = await getAdminCredentials(password);
        aws.config.update(
            {
                accessKeyId: adminCredentials.accessKeyId,
                secretAccessKey: adminCredentials.secretAccessKey,
                region: adminCredentials.region
            });

        const CognitoIdentityServiceProvider = new
            aws.CognitoIdentityServiceProvider();
        let params = {
            "UserPoolId": constants.USER_POOL_ID
        }

        CognitoIdentityServiceProvider.listUsers(params, (err, data) => {
            if (err) {
                console.log(err)
                return err;
            }
            else {
                console.log(data)
                return data
            }
        });
    } else {
        alert("You are not allowed to performed this action.")
    }
}

async function getAdminCredentials(password) {
    let CryptoJS = require("crypto-js");

    let adminGet;
    try {
        adminGet = await API.graphql(graphqlOperation(queries.getAdminCredential, { id: "a2767310-4512-49b5-83ab-1c6b8291f9df" }));
    } catch (e) {
        if (e.errors[0].errorType === "Unauthorized") {
            alert("You are not allowed to performed this action.")
        }
        console.log(e.errors)
        return e
    }

    let regionEnc = Utils.checkNested(adminGet, 'data', 'getAdminCredential', 'region');
    let accessKeyIdEnc = Utils.checkNested(adminGet, 'data', 'getAdminCredential', 'accessKeyId');
    let secretAccessKeyEnc = Utils.checkNested(adminGet, 'data', 'getAdminCredential', 'secretAccessKey');

    let region = CryptoJS.AES.decrypt(regionEnc.toString(), password).toString(CryptoJS.enc.Utf8);
    let accessKeyId = CryptoJS.AES.decrypt(accessKeyIdEnc.toString(), password).toString(CryptoJS.enc.Utf8);
    let secretAccessKey = CryptoJS.AES.decrypt(secretAccessKeyEnc.toString(), password).toString(CryptoJS.enc.Utf8);

    let adminParams = {
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey,
        region: region
    }

    return adminParams
}

export async function isParameterManager() {
    let res = false;
    await Auth.currentSession()
        .then(data => {
            let groups = Utils.checkNested(data, 'idToken', 'payload', 'cognito:groups')
            if (groups) {
                for (let i = 0; i < groups.length; i++) {
                    if (groups[i] === "ParameterManager") {
                        res = true;
                        break
                    }
                }
            }
        })
        .catch(err => {
            console.log(err)
        }
        );
    return res
}

export async function isStaffManager() {
    let res = false;
    await Auth.currentSession()
        .then(data => {
            let groups = Utils.checkNested(data, 'idToken', 'payload', 'cognito:groups')
            if (groups) {
                for (let i = 0; i < groups.length; i++) {
                    if (groups[i] === "StaffManager") {
                        res = true;
                        break
                    }
                }
            }
        })
        .catch(err => {
            console.log(err)
        }
        );
    return res
}

export async function isStudyManager() {
    let res = false;
    await Auth.currentSession()
        .then(data => {
            let groups = Utils.checkNested(data, 'idToken', 'payload', 'cognito:groups')
            if (groups) {
                for (let i = 0; i < groups.length; i++) {
                    if (groups[i] === "StudyManager") {
                        res = true;
                        break
                    }
                }
            }
        })
        .catch(err => {
            console.log(err)
        }
        );
    return res
}

export async function isStudyValidator() {
    let res = false;
    await Auth.currentSession()
        .then(data => {
            let groups = Utils.checkNested(data, 'idToken', 'payload', 'cognito:groups')
            if (groups) {
                for (let i = 0; i < groups.length; i++) {
                    if (groups[i] === "StudyValidator") {
                        res = true;
                        break
                    }
                }
            }
        })
        .catch(err => {
            console.log(err)
        }
        );
    return res
}

export async function isSupervisor() {
    let res = false;
    await Auth.currentSession()
        .then(data => {
            let groups = Utils.checkNested(data, 'idToken', 'payload', 'cognito:groups')
            if (groups) {
                for (let i = 0; i < groups.length; i++) {
                    if (groups[i] === "Supervisor") {
                        res = true;
                        break
                    }
                }
            }
        })
        .catch(err => {
            console.log(err)
        }
        );
    return res
}

export async function isParticipantValidator() {
    let res = false;
    await Auth.currentSession()
        .then(data => {
            let groups = Utils.checkNested(data, 'idToken', 'payload', 'cognito:groups')
            if (groups) {
                for (let i = 0; i < groups.length; i++) {
                    if (groups[i] === "ParticipantValidator") {
                        res = true;
                        break
                    }
                }
            }
        })
        .catch(err => {
            console.log(err)
        }
        );
    return res
}

export async function isParticipantManager() {
    let res = false;
    await Auth.currentSession()
        .then(data => {
            let groups = Utils.checkNested(data, 'idToken', 'payload', 'cognito:groups')
            if (groups) {
                for (let i = 0; i < groups.length; i++) {
                    if (groups[i] === "ParticipantManager") {
                        res = true;
                        break
                    }
                }
            }
        })
        .catch(err => {
            console.log(err)
        }
        );
    return res
}

export async function isPositionUpdater() {
    let res = false;
    await Auth.currentSession()
        .then(data => {
            let groups = Utils.checkNested(data, 'idToken', 'payload', 'cognito:groups')
            if (groups) {
                for (let i = 0; i < groups.length; i++) {
                    if (groups[i] === "PositionUpdater") {
                        res = true;
                        break
                    }
                }
            }
        })
        .catch(err => {
            console.log(err)
        }
        );
    return res
}

export async function isTaskAssign() {
    let res = false;
    await Auth.currentSession()
        .then(data => {
            let groups = Utils.checkNested(data, 'idToken', 'payload', 'cognito:groups')
            if (groups) {
                for (let i = 0; i < groups.length; i++) {
                    if (groups[i] === "TaskAssign") {
                        res = true;
                        break
                    }
                }
            }
        })
        .catch(err => {
            console.log(err)
        }
        );
    return res
}

export async function isReportViewer() {
    let res = false;
    await Auth.currentSession()
        .then(data => {
            let groups = Utils.checkNested(data, 'idToken', 'payload', 'cognito:groups')
            if (groups) {
                for (let i = 0; i < groups.length; i++) {
                    if (groups[i] === "ReportViewer") {
                        res = true;
                        break
                    }
                }
            }
        })
        .catch(err => {
            console.log(err)
        }
        );
    return res
}

let currentUserName = null;
export async function getCurrentUsername() {
    if (currentUserName) return currentUserName
    let username = "Unknown";
    await Auth.currentUserInfo()
        .then(data => {
            username = data.username
        })
        .catch(err => {
            console.log(err)
        }
        );
    currentUserName = username
    return username
}

let currentPseudo = null;
export async function getCurrentPseudo() {
    if (currentPseudo) return currentPseudo
    let pseudo = "Unknown";
    const userName = await getCurrentUsername();
    pseudo = await getPseudoFromUserName(userName);
    return pseudo
}

export async function getPseudoFromUserName(userName) {
    const request = await getUser(userName);
    const user = Utils.checkNested(request, 'data', 'getUser');
    const pseudo = Utils.checkNested(user, 'pseudo');
    return pseudo
}

export async function getUser(userName) {
    if (userName === null) return false
    try {
        const result = await API.graphql(graphqlOperation(queries.getUser, { userName: userName }));
        return result
    } catch (e) {
        console.log(e)
        return false
    }
}