import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import * as protocolCommentApi from './protocolComment';
import * as protocolValidationApi from './protocolValidation';
import * as positionApi from './position';
import * as participantApi from './participant';
import * as protocolTaskApi from './protocolTask';
import { API, graphqlOperation } from 'aws-amplify';
import Utils from '../utils';
import * as constants from '../constants';
import uuid from 'uuid/v4';

export async function deleteGroup(groupId) {
    let returnMessage = {
        code: "201",
        messages: [],
    }
    // When we delete a group we also want to delete:
    // -> Protocol Comment
    // -> Protocol Validation
    // -> Position
    // -> Participant
    // -> Protocol Task

    // ------------------- Protocol Comment ---------------------
    let nmbProtocolCommentDeleted = 0;
    // First get all Protocol Comment for this group
    const listProtocolCommentByGroup = await protocolCommentApi.listProtocolCommentByGroup(groupId);
    const listProtocolCommentByGroupItems = Utils.checkNested(listProtocolCommentByGroup, 'items');
    // If listProtocolCommentByGroup is null, then an error occurs so we don't delete the group
    if (listProtocolCommentByGroupItems === null) {
        returnMessage.code = "400";
        returnMessage.messages = "Fail to retreive protocol commment, cancel group deletion";
    } else {
        // Delete all protocol comment link to this group
        let remainingList = [...listProtocolCommentByGroupItems];
        for (let i = 0; i < listProtocolCommentByGroupItems.length; i++) {
            if (remainingList.length === 0) break
            // Batch 100 tasks together
            const batchList = remainingList.slice(0, 100);
            remainingList.splice(0, 100)
            await Promise.all(batchList.map(async (protocolComment) => {
                let resultDeleteProtocolComment = await protocolCommentApi.deleteProtocolComment(protocolComment.id);
                if (resultDeleteProtocolComment.code !== '201') {
                    returnMessage.code = "400";
                    let newMessages = returnMessage.messages;
                    resultDeleteProtocolComment.messages.forEach(message => {
                        newMessages.push(message)
                    });
                    returnMessage.messages = newMessages;
                } else {
                    nmbProtocolCommentDeleted++
                }
            }));
        }
    }


    // ------------------- Protocol Validation ---------------------
    let nmbProtocolValidationDeleted = 0;
    // First get all Protocol Validation for this group
    const listProtocolValidationByGroup = await protocolValidationApi.listProtocolValidationByGroup(groupId);
    const listProtocolValidationByGroupItems = Utils.checkNested(listProtocolValidationByGroup, 'items');
    // If listProtocolValidationByGroup is null, then an error occurs so we don't delete the group
    if (listProtocolValidationByGroup === null) {
        returnMessage.code = "400";
        returnMessage.messages = "Fail to retreive protocol validation, cancel group deletion";
    } else {
        // Delete all protocol Validation link to this group
        let remainingList = [...listProtocolValidationByGroupItems];
        for (let i = 0; i < listProtocolValidationByGroupItems.length; i++) {
            if (remainingList.length === 0) break
            // Batch 100 tasks together
            const batchList = remainingList.slice(0, 100);
            remainingList.splice(0, 100)
            await Promise.all(batchList.map(async (protocolValidation) => {
                let resultDeleteProtocolValidation = await protocolValidationApi.deleteProtocolValidation(protocolValidation.id);
                if (resultDeleteProtocolValidation.code !== '201') {
                    returnMessage.code = "400";
                    let newMessages = returnMessage.messages;
                    resultDeleteProtocolValidation.messages.forEach(message => {
                        newMessages.push(message)
                    });
                    returnMessage.messages = newMessages;
                } else {
                    nmbProtocolValidationDeleted++
                }
            }));
        }
    }

    // ------------------- Position ---------------------
    let nmbPositionDeleted = 0;
    // First get all Position for this group
    const listPositionByGroup = await positionApi.listPositionByGroup(groupId);
    const listPositionByGroupItems = Utils.checkNested(listPositionByGroup, 'items');
    // If listPositionByGroup is null, then an error occurs so we don't delete the group
    if (listPositionByGroup === null) {
        returnMessage.code = "400";
        returnMessage.messages = "Fail to retreive position, cancel group deletion";
    } else {
        // Delete all position  link to this group
        let remainingList = [...listPositionByGroupItems];
        for (let i = 0; i < listPositionByGroupItems.length; i++) {
            if (remainingList.length === 0) break
            // Batch 100 tasks together
            const batchList = remainingList.slice(0, 100);
            remainingList.splice(0, 100)
            await Promise.all(batchList.map(async (position) => {
                let resultDeletePosition = await positionApi.deletePosition(position.id);
                if (resultDeletePosition.code !== '201') {
                    returnMessage.code = "400";
                    let newMessages = returnMessage.messages;
                    resultDeletePosition.messages.forEach(message => {
                        newMessages.push(message)
                    });
                    returnMessage.messages = newMessages;
                } else {
                    nmbPositionDeleted++
                }
            }));
        }
    }

    // ------------------- Protocol Task ---------------------
    let nmbProtocolTaskDeleted = 0;
    // First get all protocol task for this group
    const listProtocolTaskByGroup = await protocolTaskApi.listProtocolTaskByGroup(groupId);
    const listProtocolTaskByGroupItems = Utils.checkNested(listProtocolTaskByGroup, 'items');
    // If listProtocolTaskByGroup is null, then an error occurs so we don't delete the group
    if (listProtocolTaskByGroup === null) {
        returnMessage.code = "400";
        returnMessage.messages = "Fail to retreive protocol task, cancel group deletion";
    } else {
        // Delete all protocol task  link to this group
        let remainingList = [...listProtocolTaskByGroupItems];
        for (let i = 0; i < listProtocolTaskByGroupItems.length; i++) {
            if (remainingList.length === 0) break
            // Batch 100 tasks together
            const batchList = remainingList.slice(0, 100);
            remainingList.splice(0, 100)
            await Promise.all(batchList.map(async (protocolTask) => {
                let resultDeleteProtocolTask = await protocolTaskApi.deleteProtocolTask(protocolTask.id,
                    protocolTask.groupId,
                    protocolTask.name,
                    protocolTask.description,
                    protocolTask.timeToT0,
                );
                if (resultDeleteProtocolTask.code !== '201') {
                    returnMessage.code = "400";
                    let newMessages = returnMessage.messages;
                    resultDeleteProtocolTask.messages.forEach(message => {
                        newMessages.push(message)
                    });
                    returnMessage.messages = newMessages;
                } else {
                    nmbProtocolTaskDeleted++
                }
            }));
        }
    }

    // ------------------- Participant ---------------------
    let nmbParticipantDeleted = 0;
    // First get all participant for this group
    const listParticipantByGroup = await participantApi.listParticipantByGroup(groupId);
    const listParticipantByGroupItems = Utils.checkNested(listParticipantByGroup, 'items');
    // If listParticipantByGroup is null, then an error occurs so we don't delete the group
    if (listParticipantByGroup === null) {
        returnMessage.code = "400";
        returnMessage.messages = "Fail to retreive participant, cancel group deletion";
    } else {
        // Delete all participant  link to this group
        let remainingList = [...listParticipantByGroupItems];
        for (let i = 0; i < listParticipantByGroupItems.length; i++) {
            if (remainingList.length === 0) break
            // Batch 100 tasks together
            const batchList = remainingList.slice(0, 100);
            remainingList.splice(0, 100)
            await Promise.all(batchList.map(async (participant) => {
                let resultDeleteParticipant = await participantApi.deleteParticipant(participant.id);
                if (resultDeleteParticipant.code !== '201') {
                    returnMessage.code = "400";
                    let newMessages = returnMessage.messages;
                    resultDeleteParticipant.messages.forEach(message => {
                        newMessages.push(message)
                    });
                    returnMessage.messages = newMessages;
                } else {
                    nmbParticipantDeleted++
                }
            }));
        }
    }

    // If all protocol tasks and participants have been deleted, then we proceed to the group deletion
    if (returnMessage.code === "201") {
        try {
            await API.graphql(graphqlOperation(mutations.deleteGroup, { input: { id: groupId } }));
        } catch (e) {
            returnMessage.code = "400";
            returnMessage.messages = e.errors;
            return returnMessage
        }
    }
    let newMessages = returnMessage.messages;
    newMessages.push("Deleted the group as well as all data inside.")
    console.log("Deleted the group and : ")
    console.log(nmbProtocolCommentDeleted + " protocol comments")
    console.log(nmbProtocolValidationDeleted + " protocol validations")
    console.log(nmbProtocolTaskDeleted + " protocol tasks")
    console.log(nmbPositionDeleted + " positions")
    console.log(nmbParticipantDeleted + " participants")
    returnMessage.messages = newMessages;
    return returnMessage
}

export async function listGroupsByPart(partId) {
    try {
        const rawGroupByPart = await API.graphql(graphqlOperation(queries.groupByPart,
            {
                partId: partId,
                sortDirection: constants.ORDER_DESC,
                limit: constants.LIMIT
            }));
        let groupByPart = Utils.checkNested(rawGroupByPart, 'data', 'groupByPart')
        return groupByPart
    } catch (e) {
        console.log(e)
        return { items: [] }
    }
}

export async function getGroupById(id) {
    try {
        const rawGroup = await API.graphql(graphqlOperation(queries.getGroup,
            {
                id: id,
            }));
        let getGroup = Utils.checkNested(rawGroup, 'data', 'getGroup')
        return getGroup
    } catch (e) {
        console.log(e)
        return null
    }
}

export async function createGroup(partId, name, description = null, dateInput = null) {
    if (!partId || !name) {
        return null
    }
    const date = new Date(dateInput).toISOString();

    let input = {
        id: uuid(),
        partId: partId,
        name: name,
        date: date,
    }
    if (description && description !== "") {
        input.description = description;
    }

    let result = await API.graphql(graphqlOperation(mutations.createGroup, { input: input }));
    return result
}

export async function updateGroup(id, name, active, description = null, dateInput = null) {
    const date = new Date(dateInput).toISOString();
    let input = {
        id: id,
        name: name,
        description: description,
        date: date,
        active: active
    }
    console.log(input)
    if (description === null || description === "") {
        input.description = " ";
    }
    try {
        let result = await API.graphql(graphqlOperation(mutations.updateGroup, { input: input }));
        console.log(result)
        return result
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function updateGroupIndicativeT0(id, indicativeT0Input) {
    const indicativeT0 = new Date(indicativeT0Input).toISOString();
    let input = {
        id: id,
        indicativeT0: indicativeT0,
    }
    try {
        let result = await API.graphql(graphqlOperation(mutations.updateGroup, { input: input }));
        console.log(result)
        return result
    } catch (e) {
        console.log(e)
        return false
    }
}