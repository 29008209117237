import React, { useEffect, useState } from 'react';
import './ListStudies.css';

import * as subscriptions from './../../graphql/subscriptions';

import { API, graphqlOperation } from 'aws-amplify';
import { Link } from "react-router-dom";

// UI
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import FolderIcon from '@material-ui/icons/Folder';

// Own components
import { StudyRoute } from './../../Routing.js'
import { listInactiveStudiesByName, listStudiesByName } from '../../utils/api/study';
import { isStudyManager } from '../../utils/api/user';
import EditStudyButton from '../editStudyButton/EditStudyButton';
import { Box, Button, CircularProgress } from '@material-ui/core';

import * as constants from '../../utils/constants';

const ListStudies = () => {
  const [listStudies, setListStudies] = useState([]);
  const [studyManager, setStudyManager] = useState(false);
  const [activeStatus, setActiveStatus] = useState(constants.STATUS_ONLINE);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Check groups
    checkGroups();
    // Subscribe to new study
    const subscriptionNew = subscribeNewStudy();
    // Subscribe to delete study
    const subscriptionDelete = subscribeDeleteStudy();
    // Subscribe to update study
    const subscriptionUpdate = subscribeUpdateStudy();
    return () => {
      subscriptionNew.unsubscribe();
      subscriptionDelete.unsubscribe();
      subscriptionUpdate.unsubscribe();
    }
  }, [])

  useEffect(() => {
    // Do the first query
    queryCompleteList();
  }, [activeStatus])

  const checkGroups = async () => {
    const _studyManager = await isStudyManager();
    setStudyManager(_studyManager);
  }

  const queryCompleteList = async () => {
    setLoading(true);
    const studyByName = activeStatus === constants.STATUS_ONLINE ?
      await listStudiesByName()
      : await listInactiveStudiesByName();
    if (!studyByName.items) { setLoading(false); return }
    setListStudies(studyByName.items);
    setLoading(false);
  }

  const subscribeNewStudy = () => {
    return API.graphql(
      graphqlOperation(subscriptions.onCreateStudy)
    ).subscribe({
      next: (studyData) => {
        setListStudies((prev) => {
          if (studyData.value.data.onCreateStudy.active !== activeStatus) return
          let newList = [...prev];
          newList.unshift(studyData.value.data.onCreateStudy);
          return newList
        });
      }
    });
  }

  const subscribeDeleteStudy = () => {
    return API.graphql(
      graphqlOperation(subscriptions.onDeleteStudy)
    ).subscribe({
      next: (studyData) => {
        setListStudies((prev) => {
          let newList = [...prev];
          for (let i = 0; i < newList.length; i++) {
            if (newList[i].id === studyData.value.data.onDeleteStudy.id) {
              newList.splice(i, 1);
            }
          }
          return newList
        });
      }
    });
  }

  const subscribeUpdateStudy = () => {
    return API.graphql(
      graphqlOperation(subscriptions.onUpdateStudy)
    ).subscribe({
      next: (studyData) => {
        setListStudies((prev) => {
          let newList = [...prev];
          for (let i = 0; i < newList.length; i++) {
            if (newList[i].id === studyData.value.data.onUpdateStudy.id) {
              newList[i] = studyData.value.data.onUpdateStudy;
            }
          }
          return newList
        });
      }
    });
  }

  const ListView = ({ studies }) => {
    return (
      <div>
        <List>
          {studies.map(study =>
            <ListItem button divider component={Link} to={StudyRoute + study.id} key={study.id}>
              <ListItemAvatar>
                <Avatar>
                  <FolderIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={study.name}
                secondary={study.description}
              />
              {
                studyManager ?
                  <ListItemSecondaryAction>
                    <EditStudyButton study={study} />
                  </ListItemSecondaryAction>
                  :
                  null
              }
            </ListItem>
          )}
        </List>
      </div>
    );
  }

  return (
    <div>
      <Box paddingBottom='1.5em'>
        <Button
          variant={activeStatus === constants.STATUS_ONLINE ? 'contained' : 'outlined'}
          color='primary'
          disabled={loading}
          onClick={() => setActiveStatus(constants.STATUS_ONLINE)}>
          Active
          </Button>
        <Button
          variant={activeStatus === constants.STATUS_OFFLINE ? 'contained' : 'outlined'}
          color='primary'
          disabled={loading}
          onClick={() => setActiveStatus(constants.STATUS_OFFLINE)}>
          Inactive
          </Button>
      </Box>
      {
        loading ?
          <CircularProgress />
          :
          <ListView studies={listStudies} />
      }
    </div>
  )
}

export default ListStudies;