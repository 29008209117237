/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://dlogxpejwzf67blo2y23ovck6e.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-central-1:db8905b0-63fc-4d16-99a9-b93654999abc",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_1fRoudrSM",
    "aws_user_pools_web_client_id": "4o0vqm61676mogqdapfgd1ksof",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "optimed-times-20191205110446-hostingbucket-master",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "https://dlrh8zc45rcbe.cloudfront.net"
};


export default awsmobile;
