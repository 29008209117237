import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './AddStudy.css';

// UI
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { addStudy } from '../../utils/api/study';

class AddStudyDialog extends React.PureComponent {
  state = {
    name: 'OPXXXX',
    description: '',
    errors: "",
    loading: false,
  };

  handleCancel = () => {
    this.props.onClose();
  };

  async handleConfirmation() {
    let errors;

    this.setState({loading: true});

    try {
      await addStudy(this.state.name, this.state.description)
    } catch (e) {
      console.log(e)
      errors = e.errors;
    }
    this.setState({loading: false});
    if (errors) {
      this.setState({
        errors: errors,
      });
      console.log(errors)
    } else {
      this.props.onClose();
    }
  }

  handleOk = () => {
    this.props.onClose();
  };

  handleChange = stateName => event => {
    this.setState({
      [stateName]: event.target.value,
    });
  };

  render() {
    const { ...other } = this.props;
    const { loading } = this.state;

    let loadingDisplay
    if (loading) {
      loadingDisplay = <DialogContent><CircularProgress /></DialogContent>;
    }

    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="md"
        onEntering={this.handleEntering}
        aria-labelledby="confirmation-dialog-title"
        {...other}
      >
        <DialogTitle id="confirmation-dialog-title">You are about to add a new study to the list</DialogTitle>
        <DialogContent>
          Please fill the information below:
          <form noValidate autoComplete="off">
            <TextField
              required
              id="standard-name"
              label="Name"
              value={this.state.name}
              onChange={this.handleChange('name')}
              margin="normal"
            />
            <br/>
            <TextField
              id="description"
              label="Description"
              value={this.state.description}
              onChange={this.handleChange('description')}
              margin="normal"
            />
          </form>
        </DialogContent>
        { loadingDisplay }
        <DialogActions>
          <Button disabled={loading} onClick={this.handleCancel} color="primary">
            Cancel
        </Button>
          <Button disabled={loading} onClick={() => this.handleConfirmation()} color="primary">
            Add!
        </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AddStudyDialog.propTypes = {
  onClose: PropTypes.func
};

class AddStudy extends PureComponent {
  state = {
    dialogOpen: false,
  };

  handleClose = () => {
    this.setState({ dialogOpen: false });
  };

  handleOpen() {
    this.setState({ dialogOpen: true });
  }

  handleChange(name, ev) {
    this.setState({ [name]: ev.target.value });
  }

  render() {
    return (
      <div>
        <Button variant="contained" color="primary" onClick={() => this.handleOpen()}>
          <AddIcon />
          Create a new study
        </Button>

        <AddStudyDialog
          open={this.state.dialogOpen}
          onClose={this.handleClose}
        />
      </div>
    );
  }
}

export default AddStudy;