import moment from 'moment';

class Utils {
    static checkNested(obj /*, level1, level2, ... levelN*/) {
        var args = Array.prototype.slice.call(arguments, 1);

        for (var i = 0; i < args.length; i++) {
            if (!obj || !obj.hasOwnProperty(args[i])) {
                return null;
            }
            obj = obj[args[i]];
        }
        return obj;
    }

    static minutesToDaysHoursMinutes(delta) {
        const sign = delta < 0 ? -1 : 1;
        let tt0 = Math.abs(delta);
        const days = sign * Math.floor(tt0 / 1440);
        tt0 = tt0 % 1440;
        const hours = sign * Math.floor(tt0 / 60);
        tt0 = tt0 % 60;
        const minutes = sign * tt0;
        return [days, hours, minutes]
    }

    static minutesToDaysHoursMinutesString(delta) {
        const [days, hours, minutes] = this.minutesToDaysHoursMinutes(delta);
        return days.toString() + "d " + hours.toString() + "h " + minutes.toString() + "min"
    }

    static dateFromDelta(T0, delta) {
        const [days, hours, minutes] = this.minutesToDaysHoursMinutes(delta);
        let date = moment(new Date(T0)).add(days, "days").add(hours, "hours").add(minutes, "minutes");
        return date
    }

    static calculateDaysHoursMinutesBetween(dateRef, date2) {
        let ref = moment(dateRef);
        let diffDays = moment(date2).diff(moment(ref), "days")
        ref.add(diffDays, "days")
        let diffHours = moment(date2).diff(moment(ref), "hours")
        ref.add(diffHours, "hours")
        let diffMinutes = moment(date2).diff(moment(ref), "minutes")
        ref.add(diffMinutes, "minutes")
        return [diffDays, diffHours, diffMinutes]
      }
}

export default Utils;