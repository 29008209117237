import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import * as studyApi from '../../utils/api/study';
import * as partApi from '../../utils/api/part';
import * as groupApi from '../../utils/api/group';
import * as protocolTaskApi from '../../utils/api/protocolTask';
import * as positionApi from '../../utils/api/position';
import * as protocolValidationApi from '../../utils/api/protocolValidation';

// UI
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';

import TextField from '@material-ui/core/TextField';
import Utils from '../../utils/utils';
import { LinearProgress } from '@material-ui/core';
import moment from 'moment';

function getSteps() {
    return ['Select the group to copy', 'Confirm tasks you are going to import'];
}

class ImportProtocolTaskDialog extends React.PureComponent {
    state = {
        activeStep: 0,
        loading: false,
        listStudies: [],
        studySelected: '',
        listParts: [],
        partSelected: '',
        listGroups: [],
        groupSelected: '',
        listTasks: [],
        loaded: 0,
    };

    componentDidUpdate(prevProps) {
        if (prevProps.groupID !== this.props.groupID) {
            this.getStudyList();
        }
    }

    async getStudyList() {
        try {
            const queryResult = await studyApi.listStudiesByName();
            this.setState({ listStudies: queryResult.items });
            if (queryResult.items.length > 0) {
                this.setState({ studySelected: queryResult.items[0] });
                await this.getPartList(queryResult.items[0].id)
            }
        } catch (e) {
            console(e)
            alert("Fail to retreive the list of studies. Please retry.")
        }
    }

    async getPartList(studyId) {
        try {
            const queryResult = await partApi.listPartsByStudy(studyId);
            this.setState({ listParts: queryResult.items });
            if (queryResult.items.length > 0) {
                this.setState({ partSelected: queryResult.items[0] });
                await this.getGroupList(queryResult.items[0].id)
            }
        } catch (e) {
            console.log(e)
            alert("Fail to retreive the parts of this study. Please retry.")
        }
    }

    async getGroupList(partId) {
        try {
            const queryResult = await groupApi.listGroupsByPart(partId);
            this.setState({ listGroups: queryResult.items });
            if (queryResult.items.length > 0) {
                this.setState({ groupSelected: queryResult.items[0].id })
            }
        } catch (e) {
            console.log(e)
            alert("Fail to retreive the groups of this part. Please retry.")
        }
    }

    async getGroupProtocolList() {
        console.log("get: " + this.state.groupSelected)
        try {
            const queryResult = await protocolTaskApi.listProtocolTaskByGroup(this.state.groupSelected, null, 2000)
            this.setState({ listTasks: queryResult.items });
        } catch (e) {
            console.log(e)
            alert("Fail to retreive the tasks of this group. Please retry.")
        }

    }

    handleCancel = () => {
        this.props.onClose();
    };

    handleConfirmation = async () => {
        this.setState({ loading: true, loaded: 0 });
        // For each protocol task in the list task, create a copy for the current group
        if (!this.props.groupID) {
            console.log("Group id not found")
            alert("Fail to create new tasks, please retry.");
            return
        }
        let currentGroupId = this.props.groupID;
        const taskList = this.state.listTasks ? this.state.listTasks : [];
        let getPositionsResult;
        try {
            getPositionsResult = await positionApi.listPositionByGroup(currentGroupId);
        } catch (e) {
            console.log("Fail to get positions")
            alert("Fail to get positions in this group, please retry.");
            return
        }
        const listGroupPositions = Utils.checkNested(getPositionsResult, 'items');
        const startTime = moment(new Date());

        let shouldImportVersion = true;
        let remainingList = [...taskList];
        let totalElements = 0;
        for (let i = 0; i < taskList.length; i++) {
            if (remainingList.length === 0) break
            // Batch 100 tasks together
            const batchList = remainingList.slice(0, 100);
            remainingList.splice(0, 100)
            totalElements += batchList.length;
            this.setState({ loaded: totalElements * 100 / taskList.length })
            console.log(totalElements)
            await Promise.all(batchList.map(async (taskToCopy) => {
                const createProtocolTaskResult = await protocolTaskApi.createProtocolTask(currentGroupId,
                    taskToCopy.name,
                    taskToCopy.description,
                    taskToCopy.timeToT0,
                    taskToCopy.taskTypeId,
                    listGroupPositions);
                if (createProtocolTaskResult.code !== "201") {
                    console.log("Fail to import one task")
                    shouldImportVersion = false;
                }
            }));
        }

        if (shouldImportVersion === true) {
            // Get validation status from the imported group
            const validationStatus = await protocolValidationApi.getValidationStatus(this.state.groupSelected)
            console.log(validationStatus)
            // If it is a validated group then import the validation status
            if (validationStatus.isValidate === true) {
                await protocolValidationApi.importProtocolValidationStatus(currentGroupId, validationStatus);
            }
        }

        const end = moment(new Date());
        var duration = moment.duration(end.diff(startTime));
        console.log(duration.asMinutes())
        setTimeout(() => {
            if (taskList.length > 200) window.location.reload();
            this.setState({ loading: false, activeStep: 0, });
            this.props.onClose();
        }, 2000);
    }

    handleChange = stateName => event => {
        this.setState({
            [stateName]: event.target.value,
        });
        if (stateName === 'studySelected') {
            this.getPartList(event.target.value)
        }
        if (stateName === 'partSelected') {
            this.getGroupList(event.target.value)
        }
    };

    handleNext = () => {
        this.setState(state => ({
            activeStep: state.activeStep + 1,
        }));
        // Load the list of tasks to import
        if (this.state.activeStep === 0) {
            this.getGroupProtocolList();
        }
    };

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
        }));
    };


    render() {
        const { groupID, ...other } = this.props;
        const { loading, activeStep, listStudies, listParts, listGroups, listTasks, loaded } = this.state;
        const steps = getSteps();

        let loadingDisplay;
        if (loading) {
            loadingDisplay = <LinearProgress variant="determinate" value={loaded} />
        }

        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xl"
                onEntering={this.handleEntering}
                aria-labelledby="confirmation-dialog-title"
                {...other}
            >
                <DialogTitle id="confirmation-dialog-title">Follow this helper to import tasks from another group
                {loadingDisplay}
                </DialogTitle>
                <DialogContent>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((label, index) => {
                            return (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                    <StepContent disabled={loading}>
                                        {index === 0 && (
                                            <div>
                                                <TextField
                                                    id="standard-select-currency-native"
                                                    select
                                                    fullWidth
                                                    label="Select the study"
                                                    value={this.state.studySelected}
                                                    onChange={this.handleChange('studySelected')}
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    margin="normal"
                                                >
                                                    {listStudies.map(study => (
                                                        <option key={study.id} value={study.id}>
                                                            {study.name}
                                                        </option>
                                                    ))}
                                                </TextField><br />
                                                <TextField
                                                    id="standard-select-currency-native"
                                                    select
                                                    fullWidth
                                                    label="Select the part"
                                                    value={this.state.partSelected}
                                                    onChange={this.handleChange('partSelected')}
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    margin="normal"
                                                >
                                                    {listParts.map(part => (
                                                        <option key={part.id} value={part.id}>
                                                            {part.name}
                                                        </option>
                                                    ))}
                                                </TextField><br />
                                                <TextField
                                                    id="standard-select-currency-native"
                                                    select
                                                    fullWidth
                                                    label="Select the group"
                                                    value={this.state.groupSelected}
                                                    onChange={this.handleChange('groupSelected')}
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    margin="normal"
                                                >
                                                    {listGroups.map(group => (
                                                        <option key={group.id} value={group.id}>
                                                            {group.name}
                                                        </option>
                                                    ))}
                                                </TextField>
                                                <div>
                                                    <Button variant="contained" color="primary"
                                                        onClick={this.handleNext}
                                                        disabled={loading}
                                                    >
                                                        Next
                                                </Button>
                                                </div>
                                            </div>
                                        )}
                                        {index === 1 && (
                                            <div>
                                                <div>
                                                    You are going to import {listTasks.length} task to this protocol.
                                                </div>
                                                <br /><br />
                                                <div>
                                                    <div>
                                                        <Button
                                                            disabled={loading}
                                                            onClick={this.handleBack}
                                                        >
                                                            Back
                                                        </Button>
                                                        <Button variant="contained" color="primary"
                                                            onClick={this.handleConfirmation}
                                                            disabled={loading}
                                                        >
                                                            Import!
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </StepContent>
                                </Step>
                            );
                        })}
                    </Stepper>
                </DialogContent>

                <DialogActions>
                    <Button disabled={loading} onClick={this.handleCancel} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

ImportProtocolTaskDialog.propTypes = {
    onClose: PropTypes.func
};

class ImportProtocolTask extends PureComponent {
    state = {
        dialogOpen: false,
    };

    handleClose = () => {
        this.setState({ dialogOpen: false });
    };

    handleOpen() {
        this.setState({ dialogOpen: true });
    }

    render() {
        return (
            <div>
                <Button variant="contained" color="primary" onClick={() => this.handleOpen()} disabled={this.props.loading}>
                    <AddIcon />
                    Import protocol tasks from another group
                </Button>

                <ImportProtocolTaskDialog
                    open={this.state.dialogOpen}
                    onClose={this.handleClose}
                    groupID={this.props.groupID}
                />
            </div>
        );
    }
}

export default ImportProtocolTask;