import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './EditPartButton.css';

import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';

import * as partApi from '../../utils/api/part'
import { TextField } from '@material-ui/core';
import DeletePartButton from '../deletePartButton/DeletePartButton';

class ConfirmationDialogRaw extends React.PureComponent {
    state = {
        loading: false,
        name: "",
        description: "",
    };

    componentDidMount() {
        this.setState({
            name: this.props.part.name,
        })
        if (this.props.part.description) {
            this.setState({
                description: this.props.part.description,
            })
        }
    }

    handleCancel = () => {
        this.props.onClose();
    };

    handleChange = stateName => event => {
        this.setState({
            [stateName]: event.target.value,
        });
    };

    async handleConfirmation(part) {
        this.setState({ loading: true });
        if (this.state.name) {
            let resultUpdatePart = await partApi.updatePart(part.id, this.state.name, this.state.description);
            if (!resultUpdatePart) {
                alert("Fail to edit the part.")
            } else {
                window.location.reload();
            }
        }
        this.setState({ loading: false });
        this.props.onClose();
    }

    render() {
        const { part, ...other } = this.props;
        const { loading } = this.state;

        let loadingDisplay;
        if (loading) {
            loadingDisplay = <DialogContent><CircularProgress /></DialogContent>;
        }
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="md"
                onEntering={this.handleEntering}
                aria-labelledby="confirmation-dialog-title"
                {...other}
            >
                <DeletePartButton part={this.props.part} />
                
                <DialogTitle id="confirmation-dialog-title">You are about to edit {part.name}</DialogTitle>
                <DialogContent>
                    Please update the information below:
                    <form noValidate autoComplete="off">
                        <TextField
                            required
                            id="standard-name"
                            label="Name"
                            value={this.state.name}
                            onChange={this.handleChange('name')}
                            margin="normal"
                        />
                        <br />
                        <TextField
                            id="description"
                            label="Description"
                            value={this.state.description}
                            onChange={this.handleChange('description')}
                            margin="normal"
                            multiline
                        />
                    </form>
                </DialogContent>
                {loadingDisplay}
                <DialogActions>
                    <Button disabled={loading} onClick={this.handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={loading} onClick={() => this.handleConfirmation(part)} color="primary">
                        Done!
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

ConfirmationDialogRaw.propTypes = {
    onClose: PropTypes.func
};


class EditPartButton extends PureComponent {
    state = {
        open: false,
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    editPart() {
        this.setState({ open: true });
    }
    render() {
        const { part } = this.props;
        return (
            <div>
                <IconButton aria-label="Delete" onClick={() => this.editPart()}>
                    <EditIcon />
                </IconButton>

                <ConfirmationDialogRaw
                    open={this.state.open}
                    onClose={this.handleClose}
                    part={part}
                />
            </div>
        );
    }
}

export default EditPartButton;