import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import * as mutations from '../../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';

// UI
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

class EditUserDialog extends PureComponent {
  state = {
    userName: "",
    pseudo: "",
    errors: "",
    loading: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.setState({
        userName: this.props.user.name,
        pseudo: this.props.user.pseudo || "",
      });
    }
  }

  handleEntering = () => {
    this.setState({
      userName: this.props.user.name,
      pseudo: this.props.user.pseudo || "",
    });
  }

  handleCancel = () => {
    this.props.onClose();
  };

  async handleConfirmation() {
    let errors;

    this.setState({ loading: true });

    let input = {
      userName: this.state.userName,
      pseudo: this.state.pseudo,
    }
    try {
      await API.graphql(graphqlOperation(mutations.updateUser, { input: input }));
    } catch (e) {
      errors = e.errors;
      console.log(errors)
      alert("Fail to update the user pseudo, please retry later or contact your administrator.")
    }

    this.setState({ loading: false });
    this.props.onClose();
  }

  handleChange = stateName => event => {
    this.setState({
      [stateName]: event.target.value,
    });
  };

  render() {
    const { user, ...other } = this.props;
    const { loading, userName, pseudo } = this.state;

    let loadingDisplay;
    if (loading) {
      loadingDisplay = <DialogContent><CircularProgress /></DialogContent>;
    }

    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        onEntering={this.handleEntering}
        aria-labelledby="confirmation-dialog-title"
        maxWidth="sm"
        fullWidth
        {...other}
      >
        <DialogTitle id="confirmation-dialog-title">Edit {userName} pseudo</DialogTitle>
        <DialogContent>
          <form noValidate autoComplete="off"
          >
            <TextField
              required
              id="standard-pseudo"
              label="Pseudo"
              value={pseudo}
              onChange={this.handleChange('pseudo')}
              margin="normal"
            />
          </form>
        </DialogContent>
        <DialogContent>
          {loadingDisplay}
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={this.handleCancel} color="primary">
            Cancel
      </Button>
          <Button disabled={loading} onClick={() => this.handleConfirmation()} color="primary">
            Update!
      </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

EditUserDialog.propTypes = {
  onClose: PropTypes.func
};

export default EditUserDialog;