import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import Utils from '../utils';
import * as constants from '../constants';
import { getCurrentUsername } from './user';
import uuid from 'uuid/v4'

export async function deleteProtocolValidation(protocolValidationId) {
    let returnMessage = {
        code: "201",
        messages: [],
    }

    try {
        await API.graphql(graphqlOperation(mutations.deleteProtocolValidation, { input: { id: protocolValidationId } }));
    } catch (e) {
        returnMessage.code = "400";
        returnMessage.messages = e.errors;
        return returnMessage
    }
    let newMessages = returnMessage.messages;
    newMessages.push("Deleted the protocol validation")
    returnMessage.messages = newMessages;
    return returnMessage
}

export async function listProtocolValidationByGroup(groupId, token = null) {
    try {
        const rawProtocolValidationByGroup = await API.graphql(graphqlOperation(queries.validationByGroup,
            {
                groupId: groupId,
                sortDirection: constants.ORDER_DESC,
                limit: constants.LIMIT / 20,
                nextToken: token
            }));
        let protocolValidationByGroup = Utils.checkNested(rawProtocolValidationByGroup, 'data', 'validationByGroup')
        return protocolValidationByGroup
    } catch (e) {
        console.log(e)
        return null
    }
}

export async function getValidationStatus(groupId) {
    try {
        const rawProtocolValidationByGroup = await API.graphql(graphqlOperation(queries.validationByGroup,
            {
                groupId: groupId,
                sortDirection: constants.ORDER_DESC,
                limit: 2
            }));
        let protocolValidationByGroupItems = Utils.checkNested(rawProtocolValidationByGroup, 'data', 'validationByGroup', "items");
        let isValidate = false;
        let version = 0;
        let items = [];
        let validationDate = null;
        if (protocolValidationByGroupItems) items = protocolValidationByGroupItems;
        if (protocolValidationByGroupItems && protocolValidationByGroupItems.length === 2) {
            if (protocolValidationByGroupItems[0].validate === true && protocolValidationByGroupItems[1].validate === true) {
                isValidate = true;
                validationDate = protocolValidationByGroupItems[0].date;
            }
            version = protocolValidationByGroupItems[0].version;
            items = protocolValidationByGroupItems;
        }
        const res = { isValidate: isValidate, version: version, items: items, validatationDate: validationDate }
        return res
    } catch (e) {
        console.log(e)
        const res = { isValidate: false, version: 0, items: [] }
        return res
    }
}

export async function validateProtocol(groupId, validationStatus) {
    console.log(validationStatus)
    if (!validationStatus.items) {
        console.log('ValidationStatus invalid')
        return false
    }
    const userId = await getCurrentUsername();
    let validationItems = validationStatus.items;
    validationItems.sort((a, b) => new Date(b.date) - new Date(a.date));

    if (validationItems.length > 0) {
        if (validationItems[0].user === userId && validationItems[0].validate === true) {
            // That means is the last one validating, not allowed to do it again
            console.log('already validated')
            return false
        }
    }

    let version = 0;
    if (validationItems.length === 2) {
        // We have 2 element to compare, otherwise the version stay 0
        // Order by date both validation
        if (validationItems[0].validate === true && validationItems[1].validate === false) {
            // That means that this validation is the second one so we update the version
            version = Number(validationItems[0].version) + 1;
        } else {
            version = Number(validationItems[0].version);
        }
    }

    const input = {
        id: uuid(),
        groupId: groupId,
        date: new Date().toISOString(),
        user: userId,
        validate: true,
        action: constants.PROTOCOL_ACTION_VALIDATION,
        version: version,
    }

    let result = await API.graphql(graphqlOperation(mutations.createProtocolValidation, { input: input }));
    return result
}

export async function invalidateProtocol(groupId, action, comment) {
    const userId = await getCurrentUsername();
    const validationStatus = await getValidationStatus(groupId);
    const currentVersion = Utils.checkNested(validationStatus, "version") ? validationStatus.version : 0;
    const input = {
        id: uuid(),
        groupId: groupId,
        date: new Date().toISOString(),
        user: userId,
        validate: false,
        action: action,
        version: currentVersion,
        comment: comment,
    }
    let result = await API.graphql(graphqlOperation(mutations.createProtocolValidation, { input: input }));
    return result
}

export async function importProtocolValidationStatus(groupIdTo, validationStatusFrom) {
    console.log("Create validation status")
    if (!validationStatusFrom.items) {
        console.log('ValidationStatus invalid')
        return false
    }
    const userId = await getCurrentUsername();
    if (validationStatusFrom.isValidate === true) {
        const input1 = {
            id: uuid(),
            groupId: groupIdTo,
            date: new Date().toISOString(),
            user: userId,
            validate: true,
            action: constants.PROTOCOL_ACTION_IMPORT,
            version: validationStatusFrom.version,
        }
        const input2 = {
            id: uuid(),
            groupId: groupIdTo,
            date: new Date().toISOString(),
            user: userId,
            validate: true,
            action: constants.PROTOCOL_ACTION_IMPORT,
            version: validationStatusFrom.version,
        }

        let result = await API.graphql(graphqlOperation(mutations.createProtocolValidation, { input: input1 }));
        result = await API.graphql(graphqlOperation(mutations.createProtocolValidation, { input: input2 }));
        return result
    } else {
        console.log("Cannot import validation from not validated protocol")
        return null
    }
}