import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import Utils from '../../utils/utils';
import * as assignableTaskApi from './assignableTask'
import * as positionApi from './position';
import * as protocolValidationApi from './protocolValidation';
import * as constants from '../constants';
import uuid from 'uuid/v4';

export async function deleteProtocolTask(protocolTaskId, groupId, name, description, timeToT0) {
    let returnMessage = {
        code: "201",
        messages: [],
    }

    // When we delete a protocol task we also want to delete:
    // -> Assignable Tasks

    // ------------------- Assignable Tasks ---------------------
    let nmbDeleted = 0;
    // First get all Assignable Tasks for this protocol task
    const listAssignableTaskByProtocolTask = await assignableTaskApi.listAssignableTaskByProtocolTask(protocolTaskId);
    const listAssignableTaskByProtocolTaskItems = Utils.checkNested(listAssignableTaskByProtocolTask, 'items') || [];
    // If listAssignableTaskByProtocolTask is null, then an error occurs so we don't delete the protocol task
    if (listAssignableTaskByProtocolTask === null) {
        returnMessage.code = "400";
        returnMessage.messages = "Fail to retreive assignable task, cancel protocol task deletion";
    } else {
        // Delete all assignable task link to this protocol task
        let remainingList = [...listAssignableTaskByProtocolTaskItems];
        for (let i = 0; i < listAssignableTaskByProtocolTaskItems.length; i++) {
            if (remainingList.length === 0) break
            // Batch 100 tasks together
            const batchList = remainingList.slice(0, 100);
            remainingList.splice(0, 100)
            await Promise.all(batchList.map(async (assignableTask) => {
                let resultDeleteAssignableTask = await assignableTaskApi.deleteAssignableTask(assignableTask.id);
                if (resultDeleteAssignableTask.code !== '201') {
                    returnMessage.code = "400";
                    let newMessages = returnMessage.messages;
                    resultDeleteAssignableTask.messages.forEach(message => {
                        newMessages.push(message)
                    });
                    returnMessage.messages = newMessages;
                } else {
                    nmbDeleted++
                }
            }));
        }
    }

    try {
        await API.graphql(graphqlOperation(mutations.deleteProtocolTask, { input: { id: protocolTaskId } }));
    } catch (e) {
        returnMessage.code = "400";
        returnMessage.messages = e.errors;
        return returnMessage
    }
    let newMessages = returnMessage.messages;
    newMessages.push("Deleted the protocol task and " + nmbDeleted + " assignable task")
    returnMessage.messages = newMessages;

    // Remove validation
    const deltaString = Utils.minutesToDaysHoursMinutesString(timeToT0);
    const comment = "Theoretical time: " + name + " / Description: " + description + " / Delta: " + deltaString;
    await protocolValidationApi.invalidateProtocol(groupId, constants.PROTOCOL_ACTION_DELETE, comment)

    return returnMessage
}

export async function listProtocolTaskByGroup(groupId, nextToken = null, limit = constants.LIMIT) {
    try {
        const rawProtocolTaskByGroup = await API.graphql(graphqlOperation(queries.protocolTaskByGroup,
            {
                groupId: groupId,
                sortDirection: constants.ORDER_ASC,
                limit: limit,
                nextToken: nextToken,
            }));
        let protocolTaskByGroup = Utils.checkNested(rawProtocolTaskByGroup, 'data', 'protocolTaskByGroup')
        return protocolTaskByGroup
    } catch (e) {
        console.log(e)
        return { items: [], nextToken: null }
    }
}

export async function createProtocolTask(groupId, name, description, timeToT0, taskTypeId = null, listGroupPositions = null) {
    // When creating a new protocol task, we want to create all assignable tasks at the same time
    // First create the protocol task
    let input = {
        id: uuid(),
        groupId: groupId,
        name: name,
        description: description,
        timeToT0: timeToT0,
        taskTypeId: taskTypeId,
    }

    let returnMessage = {
        code: "201",
        messages: [],
    }

    let createProtocolResult;
    try {
        createProtocolResult = await API.graphql(graphqlOperation(mutations.createProtocolTask, { input: input }));
    } catch (e) {
        returnMessage.code = "400";
        returnMessage.messages = e.errors;
        returnMessage.messages.push('Fail to create the protocol task.')
        console.log(e.errors)
        return returnMessage
    }

    const protocolTaskId = Utils.checkNested(createProtocolResult, 'data', 'createProtocolTask', 'id');
    if (!protocolTaskId) {
        returnMessage.code = "400";
        returnMessage.messages.push('Fail to find the protocol task id.')
        return returnMessage
    }

    // Find all positions of the group
    let positions = [];
    if (listGroupPositions !== null) {
        positions = listGroupPositions;
    } else {
        let getPositionsResult;
        try {
            getPositionsResult = await positionApi.listPositionByGroup(groupId);
        } catch (e) {
            returnMessage.code = "400";
            returnMessage.messages = e.errors;
            console.log(e.errors)
            return returnMessage
        }
        positions = Utils.checkNested(getPositionsResult, 'items');
    }

    // Create an assignable task for each position
    let nbrCreated = 0;

    let remainingList = [...positions];
    for (let i = 0; i < positions.length; i++) {
        if (remainingList.length === 0) break
        // Batch 100 tasks together
        const batchList = remainingList.slice(0, 100);
        remainingList.splice(0, 100)
        await Promise.all(batchList.map(async (position) => {
            if (position) {
                let createAssignableTaskMessage = await assignableTaskApi.createAssignableTask(position.id, protocolTaskId, position.t0, timeToT0);
                if (createAssignableTaskMessage.code === "201") {
                    nbrCreated++;
                } else {
                    returnMessage.code = "400";
                    let newMessages = returnMessage.messages;
                    console.log(createAssignableTaskMessage)
                    createAssignableTaskMessage.messages.forEach(message => {
                        newMessages.push(message)
                    });
                    newMessages.push('Fail to find the protocol task id. Do not recreate the task. Instead, run a concistancy check on the study.')
                    returnMessage.messages = newMessages;
                    console.log(createAssignableTaskMessage)
                }
            } else {
                returnMessage.code = "400";
                returnMessage.messages.push("Error, can not find position id");
                console.log(createProtocolResult)
            }
        }));
    }

    let newMessages = returnMessage.messages;
    newMessages.push("Created the protocol task as well as " + nbrCreated + " assignable tasks.")
    returnMessage.messages = newMessages;

    // Remove validation
    const deltaString = Utils.minutesToDaysHoursMinutesString(timeToT0);
    const comment = "Theoretical time: " + name + " / Description: " + description + " / Delta: " + deltaString;
    await protocolValidationApi.invalidateProtocol(groupId, constants.PROTOCOL_ACTION_CREATE, comment)

    return returnMessage
}

export async function updateProtocolTask(protocolTaskId, groupId, name, description, timeToT0, taskTypeId) {
    // When editing a protocol task, we want to insure that all assignable tasks are correctly updated in case of delta t0 update
    let returnMessage = {
        code: "201",
        messages: [],
    }
    // First process to the update
    const input = {
        id: protocolTaskId,
        name: name,
        description: description,
        timeToT0: timeToT0,
        taskTypeId: taskTypeId,
    }

    try {
        await API.graphql(graphqlOperation(mutations.updateProtocolTask, { input: input }));
    } catch (e) {
        returnMessage.code = "400";
        returnMessage.messages = e.errors;
        returnMessage.messages.push("Fail to update the protocol task. Check you inputs and retry.");
        console.log(e.errors)
        return returnMessage
    }

    // Then get all assignable task link to this protocol task
    let getAssignableTasksResult;
    try {
        getAssignableTasksResult = await assignableTaskApi.listAssignableTaskByProtocolTask(protocolTaskId);
    } catch (e) {
        returnMessage.code = "400";
        returnMessage.messages = e.errors;
        returnMessage.messages.push("Fail to update the assignable tasks link with this task. Do not reupdate, instead run a consistency chack on the study.");
        console.log(e.errors)
        return returnMessage
    }
    let assignableTasks = Utils.checkNested(getAssignableTasksResult, 'items');

    // Update them   
    let nbrUpdated = 0;
    let remainingList = [...assignableTasks];
    for (let i = 0; i < assignableTasks.length; i++) {
        if (remainingList.length === 0) break
        // Batch 100 tasks together
        const batchList = remainingList.slice(0, 100);
        remainingList.splice(0, 100)
        await Promise.all(batchList.map(async (assignableTask) => {
            if (assignableTask.id) {
                const resultUpdate = await assignableTaskApi.updateAssignableTaskTime(assignableTask.id,
                    assignableTask.positionId,
                    protocolTaskId,
                    assignableTask.delta,
                    assignableTask.applicableDate,
                    null,
                    timeToT0);
                if (resultUpdate.code !== "201") {
                    let newMessages = returnMessage.messages;
                    resultUpdate.messages.forEach(message => {
                        newMessages.push(message)
                    });
                    returnMessage.messages = newMessages;
                } else {
                    nbrUpdated++;
                }
            }
        }));
    }

    let newMessages = returnMessage.messages;
    newMessages.push("Updated the protocol task as well as " + nbrUpdated + " assignable tasks.")
    returnMessage.messages = newMessages;

    // Remove validation
    const deltaString = Utils.minutesToDaysHoursMinutesString(timeToT0);
    const comment = "Theoretical time: " + name + " / Description: " + description + " / Delta: " + deltaString;
    await protocolValidationApi.invalidateProtocol(groupId, constants.PROTOCOL_ACTION_UPDATE, comment)

    return returnMessage
}

export async function getProtocolTask(id) {
    try {
        const result = await API.graphql(graphqlOperation(queries.getProtocolTask, { id: id }));
        return result
    } catch (e) {
        console.log(e)
        return false
    }
}
