import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './AddProtocolTask.css';

import * as protocolTaskApi from '../../utils/api/protocolTask'
import * as taskTypeApi from '../../utils/api/taskType'

// UI
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { Box, Select, MenuItem } from '@material-ui/core';

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker
} from '@material-ui/pickers';
import moment from 'moment';
import Utils from '../../utils/utils';

class AddProtocolTaskDialog extends React.PureComponent {
    state = {
        name: '',
        description: "",
        errors: [],
        loading: false,
        daysToT0: 0,
        hoursToT0: 0,
        minutesToT0: 0,
        taskTypeId: '',
        taskTypeList: [],
        indicativeDateTime: moment(new Date()).toISOString(),
    };

    componentDidMount() {
        // Get the task type list
        this.getTaskType();
    }

    handleEntering() {
        this.setState({
            indicativeDateTime: this.calculateIndicativeDateTime(this.props.indicativet0,
                this.state.daysToT0,
                this.state.hoursToT0,
                this.state.minutesToT0),
        })
    }

    calculateIndicativeDateTime(T0, days, hours, minutes) {
        const deltaT0 = Number(days) * 24 * 60 + Number(hours) * 60 + Number(minutes);
        return Utils.dateFromDelta(T0, deltaT0).toISOString()
    }

    async getTaskType() {
        let taskTypeList = await taskTypeApi.listTaskTypes();
        const nullEntry = { id: '', name: "Undefined" };
        taskTypeList.push(nullEntry)
        this.setState({
            taskTypeList: taskTypeList,
        })
    }

    handleCancel = () => {
        this.props.onClose();
    };

    async handleConfirmation() {
        let errors = [];

        if (!this.props.groupID || !this.state.name || !this.state.description) {
            errors.push('Please fill all mandatory fields (*)');
            this.setState({
                errors: errors,
                loading: false,
            });
            return
        }
        this.setState({ loading: true });
        let deltaToT0 = this.state.daysToT0 * 24 * 60 + this.state.hoursToT0 * 60 + this.state.minutesToT0 * 1;

        let taskTypeId = this.state.taskTypeId === "" ? null : this.state.taskTypeId;

        try {
            const createParticipantResult = await protocolTaskApi.createProtocolTask(this.props.groupID,
                this.state.name,
                this.state.description,
                deltaToT0,
                taskTypeId);
            if (createParticipantResult.code !== "201") {
                if (createParticipantResult.message) {
                    createParticipantResult.messages.forEach(message => {
                        errors.push(message)
                    });
                }
                else {
                    errors.push("An unknown error occured.")
                }
            }
        } catch (e) {
            console.log(e)
            if (e.errors) {
                e.errors.forEach(error => {
                    errors.push(error.message)
                });
            } else {
                errors.push("An unknown error occured.")
            }
        }

        this.setState({ loading: false });
        if (errors.length > 0) {
            this.setState({
                errors: errors,
            });
        } else {
            this.props.onClose();
        }
    }

    handleOk = () => {
        this.props.onClose();
    };

    handleChange = stateName => event => {
        this.setState({
            [stateName]: event.target.value,
        });
    };

    handleChangeDelta = stateName => event => {
        let days = stateName === 'daysToT0' ? event.target.value : this.state.daysToT0;
        let hours = stateName === 'hoursToT0' ? event.target.value : this.state.hoursToT0;
        let minutes = stateName === 'minutesToT0' ? event.target.value : this.state.minutesToT0;
        let newIndicativeDateTime = this.calculateIndicativeDateTime(this.props.indicativet0, days, hours, minutes)
        this.setState({
            [stateName]: event.target.value,
            indicativeDateTime: newIndicativeDateTime,
        });
    };

    handleIndicativeDateTimeChange = newDate => {
        let [daysToT0, hoursToT0, minutesToT0] = Utils.calculateDaysHoursMinutesBetween(this.props.indicativet0, newDate)
        this.setState({
            daysToT0: daysToT0,
            hoursToT0: hoursToT0,
            minutesToT0: minutesToT0,
            indicativeDateTime: newDate,
        });
    };

    render() {
        const { groupID, ...other } = this.props;
        const { errors, loading, description, name, daysToT0, hoursToT0, minutesToT0, taskTypeId, taskTypeList, indicativeDateTime } = this.state;

        let loadingDisplay;
        if (loading) {
            loadingDisplay = <DialogContent><CircularProgress /></DialogContent>;
        }

        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xl"
                onEntering={() => this.handleEntering()}
                aria-labelledby="confirmation-dialog-title"
                {...other}
            >
                <DialogTitle id="confirmation-dialog-title">You are about to add a new protocol task to this group</DialogTitle>
                <DialogContent>
                    Please fill the information below:
          <form noValidate autoComplete="off"
                    >
                        <TextField
                            required
                            id="name"
                            label="Theoretical time"
                            value={name}
                            onChange={this.handleChange('name')}
                            margin="normal"
                        />
                        <br />
                        <TextField
                            required
                            id="description"
                            label="Description"
                            value={description}
                            onChange={this.handleChange('description')}
                            margin="normal"
                            multiline
                            fullWidth
                        />
                        <br />
                        <h4>Time to T0</h4>
                        <FormControl>
                            <InputLabel shrink>
                                Days
                                </InputLabel>
                            <TextField
                                id="standard-number-days"
                                value={daysToT0}
                                onChange={this.handleChangeDelta('daysToT0')}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel shrink htmlFor="hoursToT0">
                                Hours
                                </InputLabel>
                            <TextField
                                id="standard-number-hours"
                                value={hoursToT0}
                                onChange={this.handleChangeDelta('hoursToT0')}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel shrink htmlFor="minutesToT0">
                                Minutes
                                </InputLabel>
                            <TextField
                                id="standard-number-minutes"
                                value={minutesToT0}
                                onChange={this.handleChangeDelta('minutesToT0')}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                            />
                        </FormControl>
                        <Box marginBottom={1} marginTop={1}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDateTimePicker
                                    required
                                    margin="dense"
                                    ampm={false}
                                    label="Indicative date time"
                                    format="dd/MM/yyyy HH:mm"
                                    value={indicativeDateTime}
                                    onChange={this.handleIndicativeDateTimeChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            <Box>
                                Indicative T0: {moment(this.props.indicativet0).format("DD/MM/YY HH:mm")}
                            </Box>
                        </Box>
                        <br />
                        <Box marginBottom={2} marginTop={2}>
                            <InputLabel id="select-taskType-label">Task type</InputLabel>
                            <Select
                                labelId="select-taskType-label"
                                id="select-taskType"
                                value={taskTypeId}
                                onChange={this.handleChange('taskTypeId')}
                            >
                                {taskTypeList.map((value, index) => {
                                    return <MenuItem value={value.id} key={index}>{value.name}</MenuItem>
                                })}
                            </Select>
                        </Box>
                    </form>
                </DialogContent>
                {loadingDisplay}
                <DialogContent>
                    <ul>
                        {errors.map((value, index) => {
                            return <li style={{ color: "red" }} key={index}>{value}</li>
                        })}
                    </ul>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={this.handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={loading} onClick={() => this.handleConfirmation()} color="primary">
                        Add!
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

AddProtocolTaskDialog.propTypes = {
    onClose: PropTypes.func
};

class AddProtocolTask extends PureComponent {
    state = {
        dialogOpen: false,
    };

    handleClose = () => {
        this.setState({ dialogOpen: false });
    };

    handleOpen() {
        this.setState({ dialogOpen: true });
    }

    render() {
        return (
            <div>
                <Button variant="contained" color="primary" onClick={() => this.handleOpen()} disabled={this.props.loading}>
                    <AddIcon />
                    Add a protocol task to this group
                </Button>

                <AddProtocolTaskDialog
                    open={this.state.dialogOpen}
                    onClose={this.handleClose}
                    groupID={this.props.groupID}
                    indicativet0={this.props.indicativet0}
                />
            </div>
        );
    }
}

AddProtocolTask.propTypes = {
    groupID: PropTypes.string.isRequired,
    indicativet0: PropTypes.string.isRequired,
};

export default AddProtocolTask;