import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './EditGroupButton.css';

import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';

import * as groupApi from '../../utils/api/group'
import { TextField } from '@material-ui/core';
import DeleteGroupButton from '../deleteGroupButton/DeleteGroupButton';

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import * as constants from '../../utils/constants';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
class ConfirmationDialogRaw extends React.PureComponent {
    state = {
        loading: false,
        name: "",
        description: "",
        selectedDate: null,
        active: constants.STATUS_ONLINE
    };

    componentDidMount() {
        this.setState({
            name: this.props.group.name,
            active: this.props.group.active || constants.STATUS_ONLINE,
        })
        if (this.props.group.description) {
            this.setState({
                description: this.props.group.description,
            })
        }
        const nullDate = new Date(null).toISOString();
        if (this.props.group.date && this.props.group.date !== nullDate) {
            this.setState({
                selectedDate: this.props.group.date,
            })
        }
    }

    handleCancel = () => {
        this.props.onClose();
    };

    handleChange = stateName => event => {
        this.setState({
            [stateName]: event.target.value,
        });
    };

    handleDateChange = date => {
        this.setState({
            selectedDate: date,
        });
    };

    async handleConfirmation(group) {
        this.setState({ loading: true });
        if (this.state.name) {
            let resultUpdateGroup = await groupApi.updateGroup(group.id, this.state.name, this.state.active, this.state.description, this.state.selectedDate);
            if (!resultUpdateGroup) {
                alert("Fail to edit the group.")
            } else {
                window.location.reload();
            }
        }
        this.setState({ loading: false });
        this.props.onClose();
    }

    render() {
        const { group, ...other } = this.props;
        const { loading, selectedDate } = this.state;

        let loadingDisplay;
        if (loading) {
            loadingDisplay = <DialogContent><CircularProgress /></DialogContent>;
        }
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="md"
                onEntering={this.handleEntering}
                aria-labelledby="confirmation-dialog-title"
                {...other}
            >
                <DeleteGroupButton group={this.props.group} />
                
                <DialogTitle id="confirmation-dialog-title">You are about to edit {group.name}</DialogTitle>
                <DialogContent>
                    Please update the information below:
                    <form noValidate autoComplete="off">
                        <TextField
                            required
                            id="standard-name"
                            label="Name"
                            value={this.state.name}
                            onChange={this.handleChange('name')}
                            margin="normal"
                        /><br/>
                        <TextField
                            id="description"
                            label="Description"
                            value={this.state.description}
                            onChange={this.handleChange('description')}
                            margin="normal"
                            multiline
                        /><br/>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label="Set group date"
                                format="dd/MM/yyyy"
                                value={selectedDate}
                                onChange={this.handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                        <br />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.active === constants.STATUS_ONLINE}
                                    onChange={() => this.setState((prev) => {
                                        const newStatus = prev.active === constants.STATUS_ONLINE
                                            ? constants.STATUS_OFFLINE
                                            : constants.STATUS_ONLINE;
                                        return { active: newStatus }
                                    })
                                    }
                                    name="active"
                                />}
                            label="Active"
                        />
                    </form>
                </DialogContent>
                {loadingDisplay}
                <DialogActions>
                    <Button disabled={loading} onClick={this.handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={loading} onClick={() => this.handleConfirmation(group)} color="primary">
                        Done!
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

ConfirmationDialogRaw.propTypes = {
    onClose: PropTypes.func
};


class EditGroupButton extends PureComponent {
    state = {
        open: false,
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    editGroup() {
        this.setState({ open: true });
    }
    render() {
        const { group } = this.props;
        return (
            <div>
                <IconButton aria-label="edit" onClick={() => this.editGroup()}>
                    <EditIcon />
                </IconButton>

                <ConfirmationDialogRaw
                    open={this.state.open}
                    onClose={this.handleClose}
                    group={group}
                />
            </div>
        );
    }
}

export default EditGroupButton;