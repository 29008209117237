import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import * as participantApi from '../../../utils/api/participant'
import * as constants from '../../../utils/constants';

// UI
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { InputLabel, Select, MenuItem, Divider, Box } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import * as users from '../../../utils/api/user';

class EditParticipantDialog extends PureComponent {
  state = {
    errors: [],
    loading: false,
    selection: "",
    code: "",
    sex: constants.SEX_OTHER,
    birthday: null,
    room: "",
    inDate: null,
    outDate: null,
    validate: false,
    positionId: '',
    positionList: [],
    id: null,
    groupId: null,
    isParticipantManager: false,
    isParticipantValidator: false,
  };

  componentDidMount() {

    this.checkGroups();
  }

  async checkGroups() {
    const isParticipantManager = await users.isParticipantManager();
    const isParticipantValidator = await users.isParticipantValidator();
    this.setState({
      isParticipantManager: isParticipantManager,
      isParticipantValidator: isParticipantValidator,
    })
  }

  componentDidUpdate(prevProps) {
    const nullDate = new Date(null).toISOString();
    if (this.props.participant.id && this.props.participant.id !== prevProps.participant.id) {
      this.setState({
        id: this.props.participant.id,
        groupId: this.props.participant.groupId,
        selection: this.props.participant.selection,
        code: this.props.participant.code,
        sex: this.props.participant.sex ? this.props.participant.sex : '',
        birthday: this.props.participant.birthday !== nullDate ? this.props.participant.birthday : null,
        room: this.props.participant.room ? this.props.participant.room : '',
        inDate: this.props.participant.inDate !== nullDate ? this.props.participant.inDate : null,
        outDate: this.props.participant.outDate !== nullDate ? this.props.participant.outDate : null,
        validate: this.props.participant.validate,
        positionId: this.props.participant.positionId ? this.props.participant.positionId : '',
      })
    }
  }

  handleCancel = () => {
    this.props.onClose();
  };

  async handleConfirmation() {
    let errors = [];

    if (!this.state.selection || !this.state.code || !this.state.id || !this.state.groupId) {
      errors.push('Please fill all mandatory fields (*)');
      this.setState({
        errors: errors,
      });
      return
    }
    this.setState({ loading: true });

    try {
      await participantApi.updateParticipant(this.state.id,
        this.state.groupId,
        this.state.selection,
        this.state.code,
        this.state.sex,
        this.state.birthday,
        this.state.room,
        this.state.inDate,
        this.state.outDate,);
    } catch (e) {
      console.log(e)
      e.errors.forEach(error => {
        errors.push(error.message)
      });
    }

    this.setState({ loading: false });
    if (errors.length > 0) {
      this.setState({
        errors: errors,
      });
    } else {
      this.props.onClose();
    }
  }

  handleOk = () => {
    this.props.onClose();
  };

  handleChange = stateName => event => {
    this.setState({
      [stateName]: event.target.value,
    });
  };
  handleDateChange = stateName => date => {
    this.setState({
      [stateName]: date,
    });
  };

  render() {
    const { participant, ...other } = this.props;
    const { loading,
      selection,
      code,
      sex,
      birthday,
      room,
      inDate,
      outDate,
      errors,
      isParticipantManager,
    } = this.state;

    let loadingDisplay;
    if (loading) {
      loadingDisplay = <DialogContent><CircularProgress /></DialogContent>;
    }
    return (
      <Dialog
        maxWidth="md"
        onEntering={this.handleEntering}
        aria-labelledby="confirmation-dialog-title"
        {...other}
      >
        <DialogTitle id="confirmation-dialog-title">Here are all information about this participant</DialogTitle>
        <DialogContent>
          If you have the correct access rights, you can update information below:
          <form noValidate autoComplete="off">
            <Box marginBottom={2}>
              <TextField
                required
                disabled={!isParticipantManager}
                id="selection"
                label="Selection number"
                value={selection}
                onChange={this.handleChange('selection')}
                margin="normal"
              />
            </Box>
            <Divider />
            <Box marginBottom={2}>
              <TextField
                required
                disabled={!isParticipantManager}
                id="code"
                label="Code"
                value={code}
                onChange={this.handleChange('code')}
                margin="normal"
              />
            </Box>
            <Divider />
            <Box marginBottom={2}>
              <TextField
                id="room"
                label="Room"
                disabled={!isParticipantManager}
                value={room}
                onChange={this.handleChange('room')}
                margin="normal"
              />
            </Box>
            <Divider />
            <Box marginBottom={2} marginTop={2}>
              <InputLabel id="select-sex-label">Sex</InputLabel>
              <Select
                labelId="select-sex-label"
                disabled={!isParticipantManager}
                id="select-sex"
                value={sex}
                onChange={this.handleChange('sex')}
              >
                <MenuItem value={constants.SEX_OTHER}>Unkown</MenuItem>
                <MenuItem value={constants.SEX_WOMAN}>Female</MenuItem>
                <MenuItem value={constants.SEX_MAN}>Male</MenuItem>
              </Select>
            </Box>
            <Divider />
            <Box marginBottom={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  disabled={!isParticipantManager}
                  id="date-picker-birthday"
                  label="Date of birth"
                  format="dd/MM/yyyy"
                  value={birthday}
                  onChange={this.handleDateChange('birthday')}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Box>
            <Divider />
            <Box marginBottom={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  margin="dense"
                  disabled={!isParticipantManager}
                  ampm={false}
                  label="In date"
                  format="dd/MM/yyyy HH:mm"
                  value={inDate}
                  onChange={this.handleDateChange('inDate')}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Box>
            <Divider />
            <Box marginBottom={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  margin="dense"
                  disabled={!isParticipantManager}
                  ampm={false}
                  label="Out date"
                  format="dd/MM/yyyy HH:mm"
                  value={outDate}
                  onChange={this.handleDateChange('outDate')}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Box>
          </form>
        </DialogContent>
        {loadingDisplay}
        <DialogContent>
          <ul>
            {errors.map((value, index) => {
              return <li style={{ color: "red" }} key={index}>{value}</li>
            })}
          </ul>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={this.handleCancel} color="primary">
            {isParticipantManager ? "Cancel" : "Close"}
          </Button>
          {
            isParticipantManager ?
              <Button variant="contained" disabled={loading} onClick={() => this.handleConfirmation()} color="primary">
                Update!
            </Button>
              :
              null
          }

        </DialogActions>
      </Dialog>
    );
  }
}

EditParticipantDialog.propTypes = {
  onClose: PropTypes.func
};

export default EditParticipantDialog;