import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import Utils from '../utils';
import * as constants from '../constants';
import * as userApi from './user';
import uuid from 'uuid/v4';

export async function deleteTaskValidation(taskValidationId) {
    let returnMessage = {
        code: "201",
        messages: [],
    }

    try {
        await API.graphql(graphqlOperation(mutations.deleteTaskValidation, { input: { id: taskValidationId } }));
    } catch (e) {
        returnMessage.code = "400";
        returnMessage.messages = e.errors;
        return returnMessage
    }
    let newMessages = returnMessage.messages;
    newMessages.push("Deleted the task validation")
    returnMessage.messages = newMessages;
    return returnMessage
}

export async function listTaskValidationByAssignableTask(assignableTaskId) {
    try {
        const rawTaskValidationByAssignableTask = await API.graphql(graphqlOperation(queries.validationByTask,
            {
                assignableTaskId: assignableTaskId,
                sortDirection: constants.ORDER_DESC,
                limit: constants.LIMIT
            }));
        let validationByTask = Utils.checkNested(rawTaskValidationByAssignableTask, 'data', 'validationByTask')
        return validationByTask
    } catch (e) {
        console.log(e)
        return null
    }
}

export async function listTaskValidationByTimeFrame(isoDateBeginning, isoDateEnd) {
    try {
        console.log(isoDateBeginning)
        console.log(isoDateEnd)
        const rawValidationTaskByTimeFrame = await API.graphql(graphqlOperation(queries.validationByTime,
            {
                active: constants.VALIDATION_TASK_ACTIVE,
                date: { between: [isoDateBeginning, isoDateEnd] },
                sortDirection: constants.ORDER_ASC,
                limit: constants.LIMIT * 2
            }));
        let validationTaskByTimeFrame = Utils.checkNested(rawValidationTaskByTimeFrame, 'data', 'validationByTime')
        console.log(validationTaskByTimeFrame)
        return validationTaskByTimeFrame
    } catch (e) {
        console.log(e)
        return { items: [], nextToken: null }
    }
}

export async function listTaskValidationByPosition(positionId) {
    try {
        const rawValidationTaskByPosition = await API.graphql(graphqlOperation(queries.validationByPosition,
            {
                positionId: positionId,
                sortDirection: constants.ORDER_ASC,
                limit: constants.LIMIT
            }));
        let validationTaskByPosition = Utils.checkNested(rawValidationTaskByPosition, 'data', 'validationByPosition')
        return validationTaskByPosition
    } catch (e) {
        console.log(e)
        return { items: [], nextToken: null }
    }
}

export async function validateTask(assignableTaskId, positionId, isoDateTask) {
    // TODO: the date saved is not the time when the validation occured but the applicable time of the task
    // The validation time should be added
    const userId = await userApi.getCurrentUsername();
    const input = {
        id: uuid(),
        assignableTaskId: assignableTaskId,
        positionId: positionId,
        date: isoDateTask,
        userId: userId,
        active: constants.VALIDATION_TASK_ACTIVE,
    }

    let result = await API.graphql(graphqlOperation(mutations.createTaskValidation, { input: input }));
    return result
}

export async function deleteValidateTask(taskValidationId, taskUser) {
    const userId = await userApi.getCurrentUsername();
    if (userId !== taskUser) return;
    const input = {
        id: taskValidationId
    }
    let result = await API.graphql(graphqlOperation(mutations.deleteTaskValidation, { input: input }));
    return result
}