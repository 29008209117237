import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import * as constants from '../constants';
import * as taskValidationApi from './taskValidation';
import { API, graphqlOperation } from 'aws-amplify';
import Utils from '../../utils/utils';
import moment from 'moment';
import uuid from 'uuid/v4';

export async function deleteAssignableTask(assignableTaskId) {
    let returnMessage = {
        code: "201",
        messages: [],
    }

    // When we delete a assignable task we also want to delete:
    // -> Task Validation

    // ------------------- Task Validation ---------------------
    let nmbDeleted = 0;
    // First get all Task Validation for this assignable task
    const listTaskValidationByAssignableTask = await taskValidationApi.listTaskValidationByAssignableTask(assignableTaskId);
    // If listAssignableTaskByPosition is null, then an error occurs so we don't delete the group
    if (listTaskValidationByAssignableTask === null) {
        returnMessage.code = "400";
        returnMessage.messages = "Fail to retreive task validation, cancel assignable task deletion";
    } else {
        // Delete all task validation link to this position
        let remainingList = [...listTaskValidationByAssignableTask.items];
        for (let i = 0; i < listTaskValidationByAssignableTask.items.length; i++) {
            if (remainingList.length === 0) break
            // Batch 100 tasks together
            const batchList = remainingList.slice(0, 100);
            remainingList.splice(0, 100)
            await Promise.all(batchList.map(async (taskValidation) => {
                let resultDeleteTaskValidation = await taskValidationApi.deleteTaskValidation(taskValidation.id);
                if (resultDeleteTaskValidation.code !== '201') {
                    returnMessage.code = "400";
                    let newMessages = returnMessage.messages;
                    resultDeleteTaskValidation.messages.forEach(message => {
                        newMessages.push(message)
                    });
                    returnMessage.messages = newMessages;
                } else {
                    nmbDeleted++
                }
            }));
        }
    }

    try {
        await API.graphql(graphqlOperation(mutations.deleteAssignableTask, { input: { id: assignableTaskId } }));
    } catch (e) {
        returnMessage.code = "400";
        returnMessage.messages.push(e);
        console.log(e)
        return returnMessage
    }
    let newMessages = returnMessage.messages;
    newMessages.push("Deleted the assignable task + " + nmbDeleted + 'task validation')
    returnMessage.messages = newMessages;
    return returnMessage
}

export async function listAssignableTaskByPosition(positionId) {
    try {
        const rawAssignableTaskByPosition = await API.graphql(graphqlOperation(queries.assignableTaskByPosition,
            {
                positionId: positionId,
                sortDirection: constants.ORDER_ASC,
                limit: constants.LIMIT
            }));
        let assignableTaskByPosition = Utils.checkNested(rawAssignableTaskByPosition, 'data', 'assignableTaskByPosition')
        return assignableTaskByPosition
    } catch (e) {
        console.log(e)
        return {items: [], nextToken: true}
    }
}

export async function listAssignableTaskByProtocolTask(protocolTaskId) {
    try {
        const rawAssignableTaskByProtocolTask = await API.graphql(graphqlOperation(queries.assignableTaskByProtocolTask,
            {
                protocolTaskId: protocolTaskId,
                sortDirection: constants.ORDER_ASC,
                limit: constants.LIMIT
            }));
        let assignableTaskByProtocolTask = Utils.checkNested(rawAssignableTaskByProtocolTask, 'data', 'assignableTaskByProtocolTask')
        return assignableTaskByProtocolTask
    } catch (e) {
        console.log(e)
        return { items: [], nextToken: null }
    }
}

export async function listAssignableTaskByTimeFrame(isoDateBeginning, isoDateEnd) {
    try {
        const rawAssignableTaskByTimeFrame = await API.graphql(graphqlOperation(queries.assignableTaskByActivation,
            {
                activationStatus: constants.ASSIGNABLE_TASK_ACTIVE,
                applicableDate: { between: [isoDateBeginning, isoDateEnd] },
                sortDirection: constants.ORDER_ASC,
                limit: constants.LIMIT
            }));
        let assignableTaskByTimeFrame = Utils.checkNested(rawAssignableTaskByTimeFrame, 'data', 'assignableTaskByActivation')
        return assignableTaskByTimeFrame
    } catch (e) {
        console.log(e)
        return { items: [], nextToken: null }
    }
}

export async function listAssignableTaskByUserAssigned(userId, isoDateBeginning, isoDateEnd) {
    try {
        const rawAssignableTaskByUserAssigned = await API.graphql(graphqlOperation(queries.assignableTaskByUserAssigned,
            {
                userId: userId,
                applicableDate: { between: [isoDateBeginning, isoDateEnd] },
                sortDirection: constants.ORDER_ASC,
                limit: constants.LIMIT
            }));
        let assignableTaskByUserAssigned = Utils.checkNested(rawAssignableTaskByUserAssigned, 'data', 'assignableTaskByUserAssigned')
        return assignableTaskByUserAssigned
    } catch (e) {
        console.log(e)
        return { items: [], nextToken: null }
    }
}

export async function createAssignableTask(positionId, protocolTaskId = null, positionT0 = null, protocolTimeToT0 = null) {
    // When creating a new assignable task, we also want to calculate at what time this task need to be performed
    // First create the assignable task
    let calculatedApplicableDateString = new Date(null).toISOString();
    if (positionT0 !== null && protocolTimeToT0 !== null) {
        calculatedApplicableDateString = calculateApplicableDate(positionT0, protocolTimeToT0, 0);
    }
    let input = {
        id: uuid(),
        applicableDate: calculatedApplicableDateString,
        positionId: positionId,
        activationStatus: constants.ASSIGNABLE_TASK_ACTIVE,
        notLinkToProtocol: true,
        delta: 0,
    }
    if (protocolTaskId) {
        input.protocolTaskId = protocolTaskId;
        input.notLinkToProtocol = false;
    }
    let returnMessage = {
        code: "201",
        messages: [],
    }

    let createAssignableResult;
    try {
        createAssignableResult = await API.graphql(graphqlOperation(mutations.createAssignableTask, { input: input }));
    } catch (e) {
        returnMessage.code = "400";
        returnMessage.messages.push("Fail to create assignable task.");
        console.log(e)
        return returnMessage
    }

    // Now update the time this task need to be performed
    if (positionT0 === null || protocolTimeToT0 === null) {
        const assignableTaskId = Utils.checkNested(createAssignableResult, 'data', 'createAssignableTask', 'id');
        const deltaAssignableTask = 0;
        const currentApplicableDate = calculatedApplicableDateString;
        const t0 = positionT0;
        const timeToT0 = protocolTimeToT0;

        if (assignableTaskId) {
            let resultUpdate = await updateAssignableTaskTime(assignableTaskId,
                positionId,
                protocolTaskId,
                deltaAssignableTask,
                currentApplicableDate,
                t0,
                timeToT0);
            if (resultUpdate.code === "201") {
                returnMessage.code = "201";
                returnMessage.messages.push("New assignable task created and updated");
                return returnMessage
            } else {
                returnMessage.code = "400";
                returnMessage.messages = resultUpdate.messages;
                return returnMessage
            }
        } else {
            returnMessage.code = "400";
            returnMessage.messages.push("Unable to find assignable task id");
            console.log(createAssignableResult)
            return returnMessage
        }
    } else {
        returnMessage.code = "201";
        returnMessage.messages.push("New assignable task created and updated");
        return returnMessage
    }
}

export async function createNotLinkedAssignableTask(positionId, description, delta) {
    let returnMessage = {
        code: "201",
        messages: [],
    }
    // Get the position to get the T0
    let positionT0 = new Date().toISOString();
    let getPositionResult;
    try {
        getPositionResult = await API.graphql(graphqlOperation(queries.getPosition, { id: positionId }));
    } catch (e) {
        returnMessage.code = "400";
        returnMessage.messages.push(e);
        console.log(e)
        return returnMessage
    }
    positionT0 = Utils.checkNested(getPositionResult, 'data', 'getPosition', 't0');

    // Calculate the applicable date
    const calculatedApplicableDateString = calculateApplicableDate(positionT0, 0, delta);

    // Push the new task
    let input = {
        id: uuid(),
        positionId: positionId,
        description: description ? description : "",
        delta: delta ? delta : 0,
        applicableDate: calculatedApplicableDateString,
        activationStatus: constants.ASSIGNABLE_TASK_ACTIVE,
        notLinkToProtocol: true,
    };

    console.log(input)

    try {
        await API.graphql(graphqlOperation(mutations.createAssignableTask, { input: input }));
    } catch (e) {
        returnMessage.code = "400";
        returnMessage.messages.push("Fail to create assignable task.");
        console.log(e)
        return returnMessage
    }
}

export function calculateApplicableDate(T0, timeToT0, deltaAssignableTask) {
    // Calculate the applicable time for the assignable task
    const totalDelta = timeToT0 + deltaAssignableTask;
    let calculatedApplicableDate = Utils.dateFromDelta(T0, totalDelta);
    return calculatedApplicableDate.toISOString();
}

export async function updateAssignableTaskTime(assignableTaskId,
    positionIdArg = null,
    protocolTaskIdArg = null,
    deltaAssignableTaskArg = null,
    currentApplicableDateArg = null,
    t0Arg = null,
    timeToT0Arg = null) {
    // To update the assignable task, we need to insure that applicableDate = position.t0 + protocolTask.timeToT0 + deltaAssignableTask
    let returnMessage = {
        code: "201",
        messages: [],
    }

    // First get the assignable task
    let deltaAssignableTask = 0;
    let currentApplicableDate = 0;
    let positionId = null;
    let protocolTaskId = null;
    if (deltaAssignableTaskArg !== null && currentApplicableDateArg !== null) {
        deltaAssignableTask = deltaAssignableTaskArg;
        currentApplicableDate = currentApplicableDateArg;
        positionId = positionIdArg;
        protocolTaskId = protocolTaskIdArg;
    } else {
        let getAssignableTaskResult;
        try {
            getAssignableTaskResult = await API.graphql(graphqlOperation(queries.getAssignableTask, { id: assignableTaskId }));
        } catch (e) {
            returnMessage.code = "400";
            returnMessage.messages.push(e);
            console.log(e)
            return returnMessage
        }
        deltaAssignableTask = Utils.checkNested(getAssignableTaskResult, 'data', 'getAssignableTask', 'delta');
        currentApplicableDate = Utils.checkNested(getAssignableTaskResult, 'data', 'getAssignableTask', 'applicableDate');
        positionId = Utils.checkNested(getAssignableTaskResult, 'data', 'getAssignableTask', 'positionId');
        protocolTaskId = Utils.checkNested(getAssignableTaskResult, 'data', 'getAssignableTask', 'protocolTaskId');
    }

    // Get the position to get the T0
    let positionT0 = new Date().toISOString();
    if (t0Arg !== null) {
        positionT0 = t0Arg;
    } else {
        let getPositionResult;

        try {
            getPositionResult = await API.graphql(graphqlOperation(queries.getPosition, { id: positionId }));
            console.log("updateTask")
        } catch (e) {
            returnMessage.code = "400";
            returnMessage.messages.push("Fail to get the position to update assignable task.");
            console.log(e)
            return returnMessage
        }
        positionT0 = Utils.checkNested(getPositionResult, 'data', 'getPosition', 't0');
    }

    // Get the protocol task to know the delta to T0
    let timeToT0 = 0;
    if (timeToT0Arg !== null) {
        timeToT0 = timeToT0Arg;
    } else {
        // An assignable task is not necessarly linked to a protocol task
        if (protocolTaskId) {
            let getProtocolTaskResult;
            try {
                getProtocolTaskResult = await API.graphql(graphqlOperation(queries.getProtocolTask, { id: protocolTaskId }));
            } catch (e) {
                returnMessage.code = "400";
                returnMessage.messages.push(e);
                console.log(e)
                return returnMessage
            }
            timeToT0 = Utils.checkNested(getProtocolTaskResult, 'data', 'getProtocolTask', 'timeToT0');
        }
    }

    // Calculate the applicable time for the assignable task
    const calculatedApplicableDateString = calculateApplicableDate(positionT0, timeToT0, deltaAssignableTask);

    // Update the assignable task
    if (calculatedApplicableDateString !== currentApplicableDate) {
        let input = {
            id: assignableTaskId,
            applicableDate: calculatedApplicableDateString,
        }
        try {
            await API.graphql(graphqlOperation(mutations.updateAssignableTask, { input: input }));
            returnMessage.code = "201";
            returnMessage.messages.push("Assignable task updated, applicable time updated to: " + calculatedApplicableDateString);
            return returnMessage
        } catch (e) {
            returnMessage.code = "400";
            returnMessage.messages.push("Fail to update applicable task with new date0");
            console.log(e)
            return returnMessage
        }
    }

    returnMessage.code = "201";
    returnMessage.messages.push("Assignable task updated, no need to update applicable time.");
    return returnMessage
}


export async function activateAssignableTask(id, active) {
    let returnMessage = {
        code: "201",
        messages: [],
    }
    let activationStatus = constants.ASSIGNABLE_TASK_ACTIVE;
    if (active === false) activationStatus = constants.ASSIGNABLE_TASK_INACTIVE;

    let input = {
        id: id,
        activationStatus: activationStatus,
    }
    try {
        await API.graphql(graphqlOperation(mutations.updateAssignableTask, { input: input }));
        returnMessage.code = "201";
        returnMessage.messages.push("Assignable task updated, now activated: " + active);
        return returnMessage
    } catch (e) {
        returnMessage.code = "400";
        returnMessage.messages.push("Fail to update applicable task with activation");
        console.log(e)
        return returnMessage
    }
}

export async function updateAssignableTaskDelta(id, newDelta, newApplicableDate) {
    let returnMessage = {
        code: "201",
        messages: [],
    }

    let input = {
        id: id,
        delta: newDelta,
        applicableDate: newApplicableDate,
    }
    try {
        await API.graphql(graphqlOperation(mutations.updateAssignableTask, { input: input }));
        returnMessage.code = "201";
        returnMessage.messages.push("Assignable task delta updated");
        return returnMessage
    } catch (e) {
        returnMessage.code = "400";
        returnMessage.messages.push("Fail to update applicable task with new delta");
        console.log(e)
        return returnMessage
    }
}

export async function updateAssignableDescription(id, description) {
    let returnMessage = {
        code: "201",
        messages: [],
    }

    let input = {
        id: id,
        description: description,
    }
    try {
        await API.graphql(graphqlOperation(mutations.updateAssignableTask, { input: input }));
        returnMessage.code = "201";
        returnMessage.messages = "Assignable task description updated";
        return returnMessage
    } catch (e) {
        returnMessage.code = "400";
        returnMessage.messages.push("Fail to update applicable task with new description");
        console.log(e)
        return returnMessage
    }
}

export async function assignAssignableTask(id, userId) {
    let returnMessage = {
        code: "201",
        messages: [],
    }

    let input = {
        id: id,
        userId: userId,
    }
    try {
        await API.graphql(graphqlOperation(mutations.updateAssignableTask, { input: input }));
        returnMessage.code = "201";
        returnMessage.messages = "Assignable task assignation updated";
        return returnMessage
    } catch (e) {
        returnMessage.code = "400";
        returnMessage.messages.push("Fail to update applicable task with new assign");
        console.log(e)
        return returnMessage
    }
}

export async function unassignAssignableTask(id, userId) {
    let returnMessage = {
        code: "201",
        messages: [],
    }

    let input = {
        id: id,
        userId: null,
    }
    try {
        await API.graphql(graphqlOperation(mutations.updateAssignableTask, { input: input }));
        returnMessage.code = "201";
        returnMessage.messages.push("Assignable task assignation updated");
        return returnMessage
    } catch (e) {
        returnMessage.code = "400";
        returnMessage.messages.push("Fail to update applicable task with new assign");
        console.log(e)
        return returnMessage
    }
}

