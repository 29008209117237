import React, { useContext } from 'react';

import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';

import {
    Box,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Divider,
    Button,
    CircularProgress,
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import moment from 'moment';
import * as constants from '../../utils/constants'

import { ValidateProtocolButtonContext } from './ValidateProtocolButtonContextProvider';

const TrackerList = () => {
    const currentCtx = useContext(ValidateProtocolButtonContext);

    const Pagination = () => (
        <Box display="flex" flexWrap="wrap" alignItems="center" marginBottom={2} marginTop={2}>
            <Box>
                <Button
                    startIcon={<ArrowBackIosIcon />}
                    disabled={!currentCtx.paginationTracker
                        || currentCtx.paginationTracker?.index === 0
                        || currentCtx.isLoading}
                    onClick={() => currentCtx.prevPageTracker()}>
                    prev
                    </Button>
            </Box>
            <Box flexGrow={1} />
            {currentCtx.isLoading && <CircularProgress />}
            <Box flexGrow={1} />
            <Box>
                <Button
                    endIcon={<ArrowForwardIosIcon />}
                    disabled={!currentCtx.paginationTracker
                        || currentCtx.paginationTracker?.index >= currentCtx.paginationTracker?.tokens.length - 1
                        || currentCtx.isLoading}
                    onClick={() => currentCtx.nextPageTracker()}>
                    next
                    </Button>
            </Box>
        </Box>
    )

    return (
        <React.Fragment>
            <Pagination />
            <List dense={true}>
                {currentCtx.trackerItems.map((item, id) =>
                    <Box key={id} style={{ whiteSpace: "pre" }}>
                        <ListItem>
                            <ListItemIcon>
                                {
                                    item.validate === false ?
                                        <CancelIcon />
                                        :
                                        <DoneIcon />
                                }
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
                                primary={
                                    item.action === constants.PROTOCOL_ACTION_VALIDATION || item.action === constants.PROTOCOL_ACTION_IMPORT ?
                                        <span><b>{item.action}</b>{" V" + item.version}</span>
                                        :
                                        <span><b>{item.action}</b>{" " + item.comment}</span>
                                }
                                secondary={item.user + " " + moment(item.date).format("HH:mm DD/MM/YYYY")}
                            />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </Box>
                )}
            </List>
            <Pagination />
        </React.Fragment>
    )
}

export default TrackerList