import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import {
  GetLocationQueryVariables,
  Label,
  LabelBySerialQueryVariables,
  Location,
} from "../../../utils/BarcodeClient/API";
import {
  getLocation,
  labelBySerial,
} from "../../../utils/BarcodeClient/graphql/queries";
import getQueryList from "../../../utils/BarcodeClient/genericApi/getQueryList";
import getQuery from "../../../utils/BarcodeClient/genericApi/getQuery";
import { theoreticalTimeFormatter } from "./helpers";
import { EventType } from "./event";
import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import ReturnIcon from "./ReturnIcon";
import { grey } from "@material-ui/core/colors";
import { BarcodeClientConfig } from "../../../utils/BarcodeClient/initClient";
import { IconQrCodeScanner } from "@aws-amplify/ui-react";

const ScanBarcode: React.FC<{
  onFindLabel: (label: Label) => void;
  onFindLocation: (location: Location, eventType: EventType) => void;
}> = ({ onFindLabel, onFindLocation }) => {
  const [loading, setLoading] = useState(false);
  const [multipleLabels, setMultipleLabels] = useState<Label[]>();

  const [scanMode, setScanMode] = useState<boolean>(true);

  const [scanText, setScanText] = useState<string>("");

  const [scanListening, setScanListening] = useState<boolean>(true);

  const [lastKeyEvent, setLastKeyEvent] = useState<KeyboardEvent>();
  const scanningLabel = useRef<{ [labelId: string]: boolean }>({});
  const [lastClickEvent, setLastClickEvent] = useState<MouseEvent>();

  const focusRef = useRef<HTMLButtonElement>(null);

  const { enqueueSnackbar } = useSnackbar();

  const handleFindLabel = async (labelId: string) => {
    const rowRes = await getQueryList<Label, LabelBySerialQueryVariables>({
      query: labelBySerial,
      key: "labelBySerial",
      variables: { serial: labelId },
      client: BarcodeClientConfig,
    });
    if (rowRes instanceof Error) {
      enqueueSnackbar(rowRes.message, { variant: "error" });
    } else if (rowRes.items.length === 0) {
      enqueueSnackbar(`No label found with the id ${labelId}`, {
        variant: "error",
      });
    } else if (rowRes.items.length > 1) {
      setMultipleLabels(rowRes.items);
    } else {
      onFindLabel(rowRes.items[0]);
    }
    delete scanningLabel.current[labelId];
  };

  const handleFindLocation = async (locationId: string, evType: EventType) => {
    const rowRes = await getQuery<Location, GetLocationQueryVariables>({
      query: getLocation,
      key: "getLocation",
      variables: { id: locationId },
      client: BarcodeClientConfig,
    });
    if (rowRes instanceof Error) {
      enqueueSnackbar(rowRes.message, { variant: "error" });
    } else if (!rowRes?.id) {
      enqueueSnackbar(`No location found with the id ${locationId}`, {
        variant: "error",
      });
    } else {
      onFindLocation(rowRes, evType);
    }
  };

  const handleFindBarcode = async (text: string) => {
    if (!text || text in scanningLabel.current) return;
    // Reset field to allow new scan directly
    scanningLabel.current[text] = true;
    const localNewBarcode = text;
    setScanText("");
    setLoading(true);

    // determine if label or location
    // Find patern
    // eslint-disable-next-line no-restricted-syntax
    for (const ev in EventType) {
      if (localNewBarcode.includes(`#${ev}#`)) {
        handleFindLocation(localNewBarcode, ev as EventType);
        setLoading(false);
        return;
      }
    }
    // Not location
    await handleFindLabel(localNewBarcode);
    setLoading(false);
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!scanText) return;
    handleFindBarcode(scanText);
  };

  const clearMultipleLabels = () => setMultipleLabels(undefined);

  const handleKey = (event: KeyboardEvent) => {
    if (!scanListening) return;
    const unsupportedKeyCharacters = [
      "Shift",
      "Escape",
      "ArrowUp",
      "ArrowDown",
      "ArrowLeft",
      "ArrowRight",
      "Backspace",
      "Tab",
      "CapsLock",
      "Meta",
      "Alt",
      "Control",
      "AltGraph",
      "Fn",
    ];
    if (unsupportedKeyCharacters.includes(event.key)) {
      return;
    }
    if (event.key === "Enter") {
      handleFindBarcode(scanText);
      setScanText("");
    } else {
      setScanText((prev) => {
        return `${prev}${event.key}`;
      });
    }
  };

  const handleClick = () => {
    if (focusRef.current !== document.activeElement) {
      setScanListening(false);
      if (scanMode) setScanText("");
    } else {
      setScanListening(true);
      setScanText("");
    }
  };

  useEffect(() => {
    if (lastKeyEvent) handleKey(lastKeyEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastKeyEvent]);

  useEffect(() => {
    handleClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastClickEvent]);

  useEffect(() => {
    focusRef.current?.focus();
    window.addEventListener("keydown", setLastKeyEvent);
    window.addEventListener("click", setLastClickEvent);
    return () => {
      window.removeEventListener("keydown", setLastKeyEvent);
      window.removeEventListener("click", setLastClickEvent);
      setScanText("");
    };
  }, []);

  useEffect(() => {
    if (!scanMode) {
      setScanText("");
    }
  }, [scanMode]);

  return (
    <>
      <form
        style={{
          display: "flex",
          marginTop: "0.5em",
          alignItems: "center",
        }}
        onSubmit={handleSubmit}
      >
        <IconButton
          style={{ color: scanMode ? "blue" : "red" }}
          onClick={() => {
            setScanMode((prev) => !prev);
          }}
        >
          <IconQrCodeScanner />
        </IconButton>
        {scanMode ? (
          <>
            <Typography
              style={{ minWidth: "50px", borderBottom: "1px solid grey" }}
            >
              {scanText}
            </Typography>
            <Button
              ref={focusRef}
              variant="outlined"
              size="small"
              style={{
                marginRight: "0.5em",
                marginLeft: "0.5em",
                color: scanListening ? "green" : "red",
              }}
            >
              {scanListening ? "scanner on" : "scanner off"}
              {scanListening && (
                <CircularProgress style={{ marginLeft: "1em" }} size={20} />
              )}
            </Button>
          </>
        ) : (
          <>
            <TextField
              autoFocus
              style={{ marginRight: "0.5em" }}
              id="scan"
              label="Scan"
              variant="outlined"
              value={scanText}
              onChange={(e) => {
                setScanText(e.target.value);
              }}
            />
            <Button type="submit" variant="contained" style={{ minWidth: 0 }}>
              <Search />
            </Button>
          </>
        )}

        {loading && <CircularProgress style={{ padding: "0.5em" }} />}
      </form>

      <Dialog
        open={!!multipleLabels}
        onClose={clearMultipleLabels}
        fullWidth
        maxWidth="xs"
      >
        <DialogContent>
          <List>
            {multipleLabels?.map((label) => (
              <div key={label.id}>
                <ListItem
                  button
                  onClick={() => {
                    setMultipleLabels(undefined);
                    onFindLabel(label);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: grey[200] }}>
                      <ReturnIcon event={label.lastEventType} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <div style={{ display: "flex" }}>
                        <Typography>{label.inclusion}</Typography>

                        <Typography style={{ marginLeft: "1em" }}>
                          {theoreticalTimeFormatter(label)}
                        </Typography>
                      </div>
                    }
                    secondary={`${label.index} ${label.matrixName}`}
                  />
                </ListItem>
                <Divider />
              </div>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ScanBarcode;
