import React, { Component } from 'react';
import './GroupPositions.css';

import * as groupApi from '../../utils/api/group';
import * as positionApi from '../../utils/api/position';
import * as participantApi from '../../utils/api/participant';
import { API, graphqlOperation } from 'aws-amplify';

import Utils from '../../utils/utils';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import AddPosition from '../../components/addPosition/AddPosition'
import { GroupRoute } from '../../Routing';

import ArrowBack from '@material-ui/icons/ArrowBack';
import { Link } from "react-router-dom";
import { Button, LinearProgress } from '@material-ui/core';
import * as subscriptions from '../../graphql/subscriptions';
import * as userApi from '../../utils/api/user';
import ListGroupPositions from '../../components/listGroupPositions/ListGroupPositions';

class GroupPositions extends Component {
    state = {
        groupID: "",
        groupInfo: null,
        listPositions: [],
        listParticipants: [],
        listParticipantsUnassigned: [],
        isStudyManager: false,
        isPositionUpdater: false,
        loading: false,
    };

    componentDidMount() {
        this.queryGroupInfo(this.props.match.params.id);
        this.checkGroups();

        // Subscribe to new Position
        this.subscriptionNew = this.subscribeNewPosition();
        // Subscribe to delete Position
        this.subscriptionDelete = this.subscribeDeletePosition();
        // Subscribe to edit Position
        this.subscriptionUpdate = this.subscribeUpdatePosition();
    }

    async checkGroups() {
        const isPositionUpdater = await userApi.isPositionUpdater()
        const isStudyManager = await userApi.isStudyManager()
        this.setState({
            isPositionUpdater: isPositionUpdater,
            isStudyManager: isStudyManager,
        })
    }

    async queryGroupInfo(groupID) {
        this.setState({ loading: true })
        const queryGroup = await groupApi.getGroupById(groupID);
        const queryPositions = await positionApi.listPositionByGroup(groupID);
        const queryParticipants = await participantApi.listParticipantByGroup(groupID);

        let listPositions = [];
        const listParticipants = Utils.checkNested(queryParticipants, "items");
        const listPositionsRaw = Utils.checkNested(queryPositions, "items");
        listPositions = this.upgradePositionsList(listPositionsRaw, listParticipants)
        const listParticipantsUnassigned = this.getListParticipantsUnassigned(listPositionsRaw, listParticipants)

        this.setState({
            groupInfo: queryGroup,
            groupID: groupID,
            listPositions: listPositions,
            listParticipants: listParticipants,
            listParticipantsUnassigned: listParticipantsUnassigned,
            loading: false,
        });
    }

    upgradePositionsList(listPositionsRaw, listParticipantsArg = null) {
        let listParticipants = listParticipantsArg;
        if (!listParticipants) {
            listParticipants = this.state.listParticipants;
        }

        let listPositions = [];
        listPositionsRaw.forEach(position => {
            let entry = position;
            for (let i = 0; i < listParticipants.length; i++) {
                if (listParticipants[i].id === position.participantId) {
                    entry.participantId = listParticipants[i].id;
                    entry.participantSelection = listParticipants[i].selection;
                    entry.participantCode = listParticipants[i].code;
                    entry.participantSex = listParticipants[i].sex;
                    entry.participantRoom = listParticipants[i].room;
                    entry.participantBirthday = listParticipants[i].birthday;
                    break
                }
            }
            listPositions.push(entry);
        });
        return listPositions
    }

    getListParticipantsUnassigned(listPositions, listParticipants) {
        let listParticipantsUnassigned = [...listParticipants];
        for (let j = 0; j < listPositions.length; j++) {
            const position = listPositions[j];
            if (!position.participantId) continue
            for (let i = 0; i < listParticipantsUnassigned.length; i++) {
                if (listParticipantsUnassigned[i].id === position.participantId) {
                    listParticipantsUnassigned.splice(i, 1);
                    break
                }
            }
        }
        return listParticipantsUnassigned
    }

    subscribeNewPosition() {
        return API.graphql(
            graphqlOperation(subscriptions.onCreatePosition)
        ).subscribe({
            next: (positionData) => {
                let newListPositions = [...this.state.listPositions];
                if (positionData.value.data.onCreatePosition.groupId === this.state.groupID) {
                    newListPositions.unshift(positionData.value.data.onCreatePosition);
                    newListPositions = this.upgradePositionsList(newListPositions);
                    const listParticipantsUnassigned = this.getListParticipantsUnassigned(this.state.listPositions, this.state.listParticipants)
                    this.setState({ listPositions: newListPositions, listParticipantsUnassigned: listParticipantsUnassigned });
                }
            },
            error: (err) => {
                window.location.reload()
            }
        });
    }

    subscribeDeletePosition() {
        return API.graphql(
            graphqlOperation(subscriptions.onDeletePosition)
        ).subscribe({
            next: (positionData) => {
                let newListPositions = [...this.state.listPositions];
                for (let i = 0; i < newListPositions.length; i++) {
                    if (newListPositions[i].id === positionData.value.data.onDeletePosition.id) {
                        newListPositions.splice(i, 1);
                    }
                }
                const listParticipantsUnassigned = this.getListParticipantsUnassigned(newListPositions, this.state.listParticipants)
                this.setState({ listPositions: newListPositions, listParticipantsUnassigned: listParticipantsUnassigned });
            }
        });
    }

    subscribeUpdatePosition() {
        return API.graphql(
            graphqlOperation(subscriptions.onUpdatePosition)
        ).subscribe({
            next: (positionData) => {
                let newListPositions = [...this.state.listPositions];
                for (let i = 0; i < newListPositions.length; i++) {
                    if (newListPositions[i].id === positionData.value.data.onUpdatePosition.id) {
                        newListPositions[i] = positionData.value.data.onUpdatePosition;
                    }
                }
                newListPositions = this.upgradePositionsList(newListPositions);
                const listParticipantsUnassigned = this.getListParticipantsUnassigned(newListPositions, this.state.listParticipants)
                this.setState({ listPositions: newListPositions, listParticipantsUnassigned: listParticipantsUnassigned });
            }
        });
    }

    componentWillUnmount() {
        this.subscriptionNew.unsubscribe();
        this.subscriptionDelete.unsubscribe();
        this.subscriptionUpdate.unsubscribe();
    }

    render() {
        const { groupInfo, groupID, listPositions, listParticipantsUnassigned, isStudyManager, loading } = this.state;
        const name = groupInfo ? groupInfo.name : null;
        return (
            <div>
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"></link>
                <Typography variant="h4" color="inherit" >
                    Positions list: {name}
                </Typography>
                <Divider variant="middle" />

                {
                    groupInfo ?
                        <Button color="primary" component={Link} to={GroupRoute + groupID} >
                            <ArrowBack />
                            Back to group
                        </Button>
                        :
                        <Button color="primary" >
                            <ArrowBack />
                            Loading...
                        </Button>
                }
                {
                    isStudyManager ?
                        <div className="add-button">
                            <AddPosition loading={loading} groupID={groupID}></AddPosition>
                        </div>
                        :
                        null
                }
                {
                    loading ?
                        <LinearProgress />
                        : null
                }
                <ListGroupPositions listPositions={listPositions} listParticipantsUnassigned={listParticipantsUnassigned} />
            </div>
        );
    }
}

export default GroupPositions;