import { Box, Divider, LinearProgress } from "@material-ui/core";
import MaterialTable from "material-table";
import moment from "moment";
import { FC } from "react";
import ReturnIcon from "../../../components/labelEvent/Events/ReturnIcon";
import {
  Event,
  EventByOwnerQueryVariables,
  ModelSortDirection,
} from "../../../utils/BarcodeClient/API";
import { eventByOwner } from "../../../utils/BarcodeClient/graphql/queries";
import {
  onCreateEvent,
  onDeleteEvent,
  onUpdateEvent,
} from "../../../utils/BarcodeClient/graphql/subscriptions";
import useAmplifyList from "../../../utils/BarcodeClient/hooks/useAmplifyList";
import { BarcodeClientConfig } from "../../../utils/BarcodeClient/initClient";

const ListScannedTubes: FC<{ username: string }> = ({ username }) => {
  const { list: EventList, loading } = useAmplifyList<
    Event,
    EventByOwnerQueryVariables
  >({
    list: {
      query: eventByOwner,
      key: "eventByOwner",
      variables: {
        owner: username,
        limit: 15,
        sortDirection: ModelSortDirection.DESC,
      },
      client: BarcodeClientConfig,
    },
    create: {
      subscription: onCreateEvent,
      key: "onCreateEvent",
      client: BarcodeClientConfig,
      condition: (event) => event.owner === username,
    },
    update: {
      subscription: onUpdateEvent,
      key: "onUpdateEvent",
      client: BarcodeClientConfig,
      condition: (event) => event.owner === username,
    },
    delete: {
      subscription: onDeleteEvent,
      key: "onDeleteEvent",
      client: BarcodeClientConfig,
      condition: (event) => event.owner === username,
    },
  });

  return (
    <>
      <Divider variant="middle" />
      <br />

      {loading ? <LinearProgress variant="determinate" value={0} /> : null}
      <MaterialTable
        options={{
          actionsColumnIndex: -1,
          paging: true,
          pageSizeOptions: [50, 100, 200, 500, 1000],
          pageSize: 50,
          sorting: true,
          search: false,
          draggable: false,
        }}
        title=""
        columns={[
          {
            title: "Type",
            field: "type",
            render: (rowData) => (
              <Box>
                <ReturnIcon
                  event={rowData.type}
                  style={{ fontSize: "2em", verticalAlign: "middle" }}
                />
              </Box>
            ),
          },
          {
            title: "Date",
            field: "usedDate",
            render: (rowData) => (
              <Box>{moment(rowData.usedDate).format("HH:mm DD/MM/YY")}</Box>
            ),
          },
          { title: "LabelData", field: "labelData" },
          { title: "Description", field: "description" },
        ]}
        data={EventList}
      />
    </>
  );
};

export default ListScannedTubes;
