import React, { PureComponent } from 'react';

import './ListSupervisor.scss';

import MaterialTable from 'material-table'
import * as constants from '../../utils/constants';
import * as taskValidationApi from '../../utils/api/taskValidation';

import { LinearProgress, Tooltip } from '@material-ui/core';
import moment from 'moment';
import Utils from '../../utils/utils';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';

class ListSupervisor extends PureComponent {
  state = {
    listAssignableTask: [],
    selectedUserId: "",
    loaded: 0,
    loading: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.lastUpdate !== prevProps.lastUpdate) {
      this.setState({ listAssignableTask: this.props.listTasks })
    }
  }

  async setValidationTaskDone(rowSelected) {
    const res = await taskValidationApi.validateTask(rowSelected.id, rowSelected.positionId, rowSelected.applicableDate);
    let found = false;
    let newList = [...this.state.listAssignableTask]
    if (Utils.checkNested(res, "data", 'createTaskValidation')) {
      this.state.listAssignableTask.forEach((task, index) => {
        if (task.id === res.data.createTaskValidation.assignableTaskId) {
          found = true;
          newList[index].isDone = true;
          newList[index].doneUser = res.data.createTaskValidation.userId;
        }
      });
    }
    if (found) {
      this.setState({ listAssignableTask: newList })
    }
  }

  render() {
    const { listAssignableTask, loading, loaded } = this.state;
    return (
      <div id='ListSupervisor'>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"></link>
        <br />
        {
          loading ?
            <LinearProgress variant="determinate" value={loaded} />
            : null
        }
        <MaterialTable
          columns={[
            {
              title: 'Time',
              field: 'applicableDate',
              width: null,
              defaultSort: 'asc',
              render: rowData => {
                let color;
                if (rowData.activationStatus === constants.ASSIGNABLE_TASK_INACTIVE) {
                  color = "grey";
                }
                return (
                  <div style={{ color: color }}>
                    <b>
                      {moment(rowData.applicableDate).format('HH:mm')}
                    </b>
                  </div>
                )
              },
            },
            {
              title: 'Study',
              field: 'studyName',
              sorting: false,
              render: rowData => {
                let color = "#ff6666";
                if (rowData.groupValidate === true) {
                  color = "";
                }
                return (
                  <Tooltip title={color ? "Warning: Group not validated." : "Group validate."}>
                    <div style={{ width: '100%', backgroundColor: color, height: '100%' }} >
                      {rowData.studyName}{rowData.groupDescription ? '/' + rowData.groupDescription : null}
                    </div>
                  </Tooltip>
                )
              },
            },
            { title: 'TheoTime', field: 'protocolName', sorting: false, },
            {
              title: 'Participant',
              field: 'participantCode',
              sorting: false,
              render: rowData => {
                let color = "#ff6666";
                if (rowData.positionValidate === true) {
                  color = "";
                }
                return (
                  <Tooltip title={color ? "Warning: Position not validated." : "Position validate."}>
                    <div style={{ width: '100%', backgroundColor: color, height: '100%' }} >
                      {
                        rowData.positionInclusion ?
                          rowData.participantCode + "/ " + rowData.participantSelection + "/ " + rowData.positionInclusion
                          :
                          rowData.participantCode + "/ " + rowData.participantSelection + "/ " + rowData.positionName
                      }
                    </div>
                  </Tooltip>
                )
              },
            },
            { title: 'Sex', field: 'participantSex', sorting: false, },
            { title: 'Room', field: 'participantRoom', sorting: false, },
            {
              title: 'Act',
              field: 'protocolDescription',
              sorting: false,
              render: rowData => {
                return (
                  <div>
                    <div hidden={!rowData.protocolDescription}>{rowData.protocolDescription}<br /></div>
                    <div hidden={!rowData.specificDescription}><span style={{ fontWeight: "bold" }}>Specific description:</span> {rowData.specificDescription}</div>
                  </div>
                )
              },
            },
            {
              title: 'Staff',
              field: 'staffName',
              render: rowData => {
                const color = rowData.staffColor
                return (
                  <div style={{ width: '100%', backgroundColor: color, height: '100%', fontSize: "1.2em" }}>
                    {rowData.staffPseudo || rowData.staffUserName}
                  </div>
                )
              },
            },
            {
              title: 'Done?',
              field: 'isDone',
              render: rowData => {
                return (
                  <div style={{ textAlign: "center" }}>
                    {
                      rowData.applicableDate < new Date().toISOString() ?
                        <div>
                          {
                            rowData.isDone ?
                              <Tooltip title={"Done by " + rowData.doneUser}>
                                <CheckCircleIcon color='primary' />
                              </Tooltip>
                              :
                              <Tooltip title={"Not done yet!"}>
                                <WarningIcon color='secondary' />
                              </Tooltip>
                          }
                        </div>
                        :
                        null
                    }

                  </div>
                )
              },
            }
          ]}
          options={{
            actionsColumnIndex: -1,
            paging: true,
            pageSizeOptions: [50, 100, 200, 500, 1000],
            pageSize: 50,
            sorting: true,
            search: false,
            draggable: false,
          }}
          localization={{
            header: {
              actions: 'Done?'
            },
          }}
          data={listAssignableTask}
          title=""
        />
      </div>
    );
  }
}

export default ListSupervisor;