import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './AddGroup.css';

import * as groupApi from '../../utils/api/group';

// UI
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

class AddGroupDialog extends React.PureComponent {
    state = {
        name: '',
        description: '',
        errors: "",
        loading: false,
        selectedDate: null,
    };

    handleCancel = () => {
        this.props.onClose();
    };

    async handleConfirmation() {
        let errors;

        this.setState({ loading: true });
        
        
        if (!this.props.partID) {
            return
        }
        try {
            await groupApi.createGroup(this.props.partID, this.state.name, this.state.description, this.state.selectedDate)
        } catch (e) {
            console.log(e)
            errors = e.errors;
        }

        this.setState({ loading: false });
        if (errors) {
            this.setState({
                errors: errors,
            });
            console.log(errors)
        } else {
            this.props.onClose();
        }
    }

    handleChange = stateName => event => {
        this.setState({
            [stateName]: event.target.value,
        });
    };

    handleDateChange = date => {
        this.setState({
            selectedDate: date,
        });
    };

    render() {
        const { partID, ...other } = this.props;
        const { loading, selectedDate } = this.state;

        let loadingDisplay;
        if (loading) {
            loadingDisplay = <DialogContent><CircularProgress /></DialogContent>;
        }

        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xs"
                onEntering={this.handleEntering}
                aria-labelledby="confirmation-dialog-title"
                {...other}
            >
                <DialogTitle id="confirmation-dialog-title">You are about to add a new group to the part</DialogTitle>
                <DialogContent>
                    Please fill the information below:
                    <form noValidate autoComplete="off">
                        <TextField
                            required
                            id="standard-name"
                            label="Name"
                            value={this.state.name}
                            onChange={this.handleChange('name')}
                            margin="normal"
                        /><br/>
                        <TextField
                            id="description"
                            label="Description"
                            value={this.state.description}
                            onChange={this.handleChange('description')}
                            margin="normal"
                            multiline
                        /><br/>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label="Set group date"
                                format="dd/MM/yyyy"
                                value={selectedDate}
                                onChange={this.handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </form>
                </DialogContent>
                {loadingDisplay}
                <DialogActions>
                    <Button disabled={loading} onClick={this.handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={loading} onClick={() => this.handleConfirmation()} color="primary">
                        Add!
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

AddGroupDialog.propTypes = {
    onClose: PropTypes.func
};

class AddGroup extends PureComponent {
    state = {
        dialogOpen: false,
    };

    handleClose = () => {
        this.setState({ dialogOpen: false });
    };

    handleOpen() {
        this.setState({ dialogOpen: true });
    }

    handleChange(name, ev) {
        this.setState({ [name]: ev.target.value });
    }

    render() {
        return (
            <div>
                <Button variant="contained" color="primary" onClick={() => this.handleOpen()}>
                    <AddIcon />
                    Add a group to this part
        </Button>

                <AddGroupDialog
                    open={this.state.dialogOpen}
                    onClose={this.handleClose}
                    partID={this.props.partID}
                />
            </div>
        );
    }
}

export default AddGroup;