/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type PrintTemplate = {
  __typename: "PrintTemplate",
  id: string,
  templatePath: string,
  name: string,
  printerId: string,
  printer: Printer,
  active: ActiveStatus,
  createdAt: string,
  updatedAt: string,
};

export type Printer = {
  __typename: "Printer",
  id: string,
  printerNetworkId: string,
  friendlyName: string,
  active: ActiveStatus,
  createdAt: string,
  updatedAt: string,
};

export enum ActiveStatus {
  active = "active",
  inactive = "inactive",
}


export enum PrintedStatus {
  printed = "printed",
  notPrinted = "notPrinted",
}


export type ModelLabelByStatusInclusionCompositeKeyConditionInput = {
  eq?: ModelLabelByStatusInclusionCompositeKeyInput | null,
  le?: ModelLabelByStatusInclusionCompositeKeyInput | null,
  lt?: ModelLabelByStatusInclusionCompositeKeyInput | null,
  ge?: ModelLabelByStatusInclusionCompositeKeyInput | null,
  gt?: ModelLabelByStatusInclusionCompositeKeyInput | null,
  between?: Array<ModelLabelByStatusInclusionCompositeKeyInput | null> | null,
  beginsWith?: ModelLabelByStatusInclusionCompositeKeyInput | null,
};

export type ModelLabelByStatusInclusionCompositeKeyInput = {
  cohortId?: string | null,
  inclusion?: string | null,
  period?: string | null,
  theoreticalTime?: string | null,
  index?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelLabelFilterInput = {
  id?: ModelIDInput | null,
  cohortId?: ModelIDInput | null,
  serial?: ModelStringInput | null,
  theoreticalTime?: ModelStringInput | null,
  theoreticalTimeLabel?: ModelStringInput | null,
  theoreticalTimeEnd?: ModelStringInput | null,
  period?: ModelStringInput | null,
  matrixName?: ModelStringInput | null,
  index?: ModelStringInput | null,
  printTemplateId?: ModelIDInput | null,
  printed?: ModelPrintedStatusInput | null,
  active?: ModelActiveStatusInput | null,
  studyName?: ModelStringInput | null,
  sponsorName?: ModelStringInput | null,
  inclusion?: ModelStringInput | null,
  parentId?: ModelIDInput | null,
  firstFillEventTime?: ModelStringInput | null,
  firstFillEventUserAndPseudo?: ModelStringInput | null,
  firstCentriEventTime?: ModelStringInput | null,
  firstCentriEventUserAndPseudo?: ModelStringInput | null,
  firstFreezeEventTime?: ModelStringInput | null,
  firstFreezeEventUserAndPseudo?: ModelStringInput | null,
  firstOutEventTime?: ModelStringInput | null,
  firstOutEventUserAndPseudo?: ModelStringInput | null,
  lastEventLocationID?: ModelStringInput | null,
  lastEventType?: ModelStringInput | null,
  lastEventTime?: ModelStringInput | null,
  lastEventAuthor?: ModelStringInput | null,
  comments?: ModelStringInput | null,
  and?: Array<ModelLabelFilterInput | null> | null,
  or?: Array<ModelLabelFilterInput | null> | null,
  not?: ModelLabelFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array<string | null> | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array<number | null> | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array<string | null> | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelPrintedStatusInput = {
  eq?: PrintedStatus | null,
  ne?: PrintedStatus | null,
};

export type ModelActiveStatusInput = {
  eq?: ActiveStatus | null,
  ne?: ActiveStatus | null,
};

export type ModelLabelConnection = {
  __typename: "ModelLabelConnection",
  items: Array<Label | null>,
  nextToken?: string | null,
};

export type Label = {
  __typename: "Label",
  id: string,
  cohortId: string,
  serial: string,
  theoreticalTime: string,
  theoreticalTimeLabel?: string | null,
  theoreticalTimeEnd?: string | null,
  period: string,
  matrixName?: string | null,
  index?: string | null,
  printTemplateId: string,
  printTemplate?: PrintTemplate | null,
  printed: PrintedStatus,
  active: ActiveStatus,
  studyName: string,
  sponsorName: string,
  inclusion: string,
  parentId?: string | null,
  firstFillEventTime?: string | null,
  firstFillEventUserAndPseudo?: string | null,
  firstCentriEventTime?: string | null,
  firstCentriEventUserAndPseudo?: string | null,
  firstFreezeEventTime?: string | null,
  firstFreezeEventUserAndPseudo?: string | null,
  firstOutEventTime?: string | null,
  firstOutEventUserAndPseudo?: string | null,
  lastEventLocationID: string,
  lastEventType: string,
  lastEventTime: string,
  lastEventAuthor: string,
  comments?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelLabelByStatusPrintedCompositeKeyConditionInput = {
  eq?: ModelLabelByStatusPrintedCompositeKeyInput | null,
  le?: ModelLabelByStatusPrintedCompositeKeyInput | null,
  lt?: ModelLabelByStatusPrintedCompositeKeyInput | null,
  ge?: ModelLabelByStatusPrintedCompositeKeyInput | null,
  gt?: ModelLabelByStatusPrintedCompositeKeyInput | null,
  between?: Array<ModelLabelByStatusPrintedCompositeKeyInput | null> | null,
  beginsWith?: ModelLabelByStatusPrintedCompositeKeyInput | null,
};

export type ModelLabelByStatusPrintedCompositeKeyInput = {
  cohortId?: string | null,
  printed?: PrintedStatus | null,
  inclusion?: string | null,
  period?: string | null,
  theoreticalTime?: string | null,
  index?: string | null,
};

export type ModelLabelByStatusCohortCompositeKeyConditionInput = {
  eq?: ModelLabelByStatusCohortCompositeKeyInput | null,
  le?: ModelLabelByStatusCohortCompositeKeyInput | null,
  lt?: ModelLabelByStatusCohortCompositeKeyInput | null,
  ge?: ModelLabelByStatusCohortCompositeKeyInput | null,
  gt?: ModelLabelByStatusCohortCompositeKeyInput | null,
  between?: Array<ModelLabelByStatusCohortCompositeKeyInput | null> | null,
  beginsWith?: ModelLabelByStatusCohortCompositeKeyInput | null,
};

export type ModelLabelByStatusCohortCompositeKeyInput = {
  cohortId?: string | null,
  period?: string | null,
  theoreticalTime?: string | null,
  inclusion?: string | null,
  index?: string | null,
};

export type CreatePrintTemplateInput = {
  id?: string | null,
  templatePath: string,
  name: string,
  printerId: string,
  active: ActiveStatus,
};

export type ModelPrintTemplateConditionInput = {
  templatePath?: ModelStringInput | null,
  name?: ModelStringInput | null,
  printerId?: ModelIDInput | null,
  active?: ModelActiveStatusInput | null,
  and?: Array<ModelPrintTemplateConditionInput | null> | null,
  or?: Array<ModelPrintTemplateConditionInput | null> | null,
  not?: ModelPrintTemplateConditionInput | null,
};

export type UpdatePrintTemplateInput = {
  id: string,
  templatePath?: string | null,
  name?: string | null,
  printerId?: string | null,
  active?: ActiveStatus | null,
};

export type DeletePrintTemplateInput = {
  id: string,
};

export type CreatePrinterInput = {
  id?: string | null,
  printerNetworkId: string,
  friendlyName: string,
  active: ActiveStatus,
};

export type ModelPrinterConditionInput = {
  printerNetworkId?: ModelStringInput | null,
  friendlyName?: ModelStringInput | null,
  active?: ModelActiveStatusInput | null,
  and?: Array<ModelPrinterConditionInput | null> | null,
  or?: Array<ModelPrinterConditionInput | null> | null,
  not?: ModelPrinterConditionInput | null,
};

export type UpdatePrinterInput = {
  id: string,
  printerNetworkId?: string | null,
  friendlyName?: string | null,
  active?: ActiveStatus | null,
};

export type DeletePrinterInput = {
  id: string,
};

export type CreateLocationCatInput = {
  id?: string | null,
  name: string,
  prefix?: string | null,
  active: ActiveStatus,
};

export type ModelLocationCatConditionInput = {
  name?: ModelStringInput | null,
  prefix?: ModelStringInput | null,
  active?: ModelActiveStatusInput | null,
  and?: Array<ModelLocationCatConditionInput | null> | null,
  or?: Array<ModelLocationCatConditionInput | null> | null,
  not?: ModelLocationCatConditionInput | null,
};

export type LocationCat = {
  __typename: "LocationCat",
  id: string,
  name: string,
  prefix?: string | null,
  active: ActiveStatus,
  createdAt: string,
  updatedAt: string,
};

export type UpdateLocationCatInput = {
  id: string,
  name?: string | null,
  prefix?: string | null,
  active?: ActiveStatus | null,
};

export type DeleteLocationCatInput = {
  id: string,
};

export type CreateLocationInput = {
  id?: string | null,
  locationCatId: string,
  name: string,
  friendlyName: string,
  active: ActiveStatus,
};

export type ModelLocationConditionInput = {
  locationCatId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  friendlyName?: ModelStringInput | null,
  active?: ModelActiveStatusInput | null,
  and?: Array<ModelLocationConditionInput | null> | null,
  or?: Array<ModelLocationConditionInput | null> | null,
  not?: ModelLocationConditionInput | null,
};

export type Location = {
  __typename: "Location",
  id: string,
  locationCatId: string,
  name: string,
  friendlyName: string,
  active: ActiveStatus,
  createdAt: string,
  updatedAt: string,
};

export type UpdateLocationInput = {
  id: string,
  locationCatId?: string | null,
  name?: string | null,
  friendlyName?: string | null,
  active?: ActiveStatus | null,
};

export type DeleteLocationInput = {
  id: string,
};

export type CreateCommentInput = {
  id?: string | null,
  selector: string,
  active: ActiveStatus,
  comment: string,
};

export type ModelCommentConditionInput = {
  selector?: ModelStringInput | null,
  active?: ModelActiveStatusInput | null,
  comment?: ModelStringInput | null,
  and?: Array<ModelCommentConditionInput | null> | null,
  or?: Array<ModelCommentConditionInput | null> | null,
  not?: ModelCommentConditionInput | null,
};

export type Comment = {
  __typename: "Comment",
  id: string,
  selector: string,
  active: ActiveStatus,
  comment: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateCommentInput = {
  id: string,
  selector?: string | null,
  active?: ActiveStatus | null,
  comment?: string | null,
};

export type DeleteCommentInput = {
  id: string,
};

export type CreateSampleProtocolTemplateInput = {
  id?: string | null,
  partId: string,
  active: ActiveStatus,
  name: string,
  sponsorName?: string | null,
  studyName?: string | null,
  validationDate?: string | null,
  validateUserId?: string | null,
  validationDateSecond?: string | null,
  validateUserIdSecond?: string | null,
};

export type ModelSampleProtocolTemplateConditionInput = {
  partId?: ModelStringInput | null,
  active?: ModelActiveStatusInput | null,
  name?: ModelStringInput | null,
  sponsorName?: ModelStringInput | null,
  studyName?: ModelStringInput | null,
  validationDate?: ModelStringInput | null,
  validateUserId?: ModelStringInput | null,
  validationDateSecond?: ModelStringInput | null,
  validateUserIdSecond?: ModelStringInput | null,
  and?: Array<ModelSampleProtocolTemplateConditionInput | null> | null,
  or?: Array<ModelSampleProtocolTemplateConditionInput | null> | null,
  not?: ModelSampleProtocolTemplateConditionInput | null,
};

export type SampleProtocolTemplate = {
  __typename: "SampleProtocolTemplate",
  id: string,
  partId: string,
  active: ActiveStatus,
  name: string,
  sponsorName?: string | null,
  studyName?: string | null,
  validationDate?: string | null,
  validateUserId?: string | null,
  validationDateSecond?: string | null,
  validateUserIdSecond?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateSampleProtocolTemplateInput = {
  id: string,
  partId?: string | null,
  active?: ActiveStatus | null,
  name?: string | null,
  sponsorName?: string | null,
  studyName?: string | null,
  validationDate?: string | null,
  validateUserId?: string | null,
  validationDateSecond?: string | null,
  validateUserIdSecond?: string | null,
};

export type DeleteSampleProtocolTemplateInput = {
  id: string,
};

export type CreateListingInput = {
  id?: string | null,
  sampleProtocolTemplateId: string,
  name: string,
  printTemplateId: string,
};

export type ModelListingConditionInput = {
  sampleProtocolTemplateId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  printTemplateId?: ModelIDInput | null,
  and?: Array<ModelListingConditionInput | null> | null,
  or?: Array<ModelListingConditionInput | null> | null,
  not?: ModelListingConditionInput | null,
};

export type Listing = {
  __typename: "Listing",
  id: string,
  sampleProtocolTemplateId: string,
  name: string,
  printTemplateId: string,
  printTemplate?: PrintTemplate | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateListingInput = {
  id: string,
  sampleProtocolTemplateId?: string | null,
  name?: string | null,
  printTemplateId?: string | null,
};

export type DeleteListingInput = {
  id: string,
};

export type CreateSampleTemplateInput = {
  id?: string | null,
  listingId: string,
  theoreticalTime: string,
  theoreticalTimeEnd?: string | null,
  theoreticalTimeLabel?: string | null,
  period: string,
  matrixName?: string | null,
  index?: string | null,
};

export type ModelSampleTemplateConditionInput = {
  listingId?: ModelIDInput | null,
  theoreticalTime?: ModelStringInput | null,
  theoreticalTimeEnd?: ModelStringInput | null,
  theoreticalTimeLabel?: ModelStringInput | null,
  period?: ModelStringInput | null,
  matrixName?: ModelStringInput | null,
  index?: ModelStringInput | null,
  and?: Array<ModelSampleTemplateConditionInput | null> | null,
  or?: Array<ModelSampleTemplateConditionInput | null> | null,
  not?: ModelSampleTemplateConditionInput | null,
};

export type SampleTemplate = {
  __typename: "SampleTemplate",
  id: string,
  listingId: string,
  theoreticalTime: string,
  theoreticalTimeEnd?: string | null,
  theoreticalTimeLabel?: string | null,
  period: string,
  matrixName?: string | null,
  index?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateSampleTemplateInput = {
  id: string,
  listingId?: string | null,
  theoreticalTime?: string | null,
  theoreticalTimeEnd?: string | null,
  theoreticalTimeLabel?: string | null,
  period?: string | null,
  matrixName?: string | null,
  index?: string | null,
};

export type DeleteSampleTemplateInput = {
  id: string,
};

export type CreateAlicotTemplateInput = {
  id?: string | null,
  listingId: string,
  indexPrefix: string,
  indexFirst: number,
  number: number,
  matrixName?: string | null,
  printTemplateId: string,
};

export type ModelAlicotTemplateConditionInput = {
  listingId?: ModelIDInput | null,
  indexPrefix?: ModelStringInput | null,
  indexFirst?: ModelIntInput | null,
  number?: ModelIntInput | null,
  matrixName?: ModelStringInput | null,
  printTemplateId?: ModelIDInput | null,
  and?: Array<ModelAlicotTemplateConditionInput | null> | null,
  or?: Array<ModelAlicotTemplateConditionInput | null> | null,
  not?: ModelAlicotTemplateConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array<number | null> | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type AlicotTemplate = {
  __typename: "AlicotTemplate",
  id: string,
  listingId: string,
  indexPrefix: string,
  indexFirst: number,
  number: number,
  matrixName?: string | null,
  printTemplateId: string,
  printTemplate?: PrintTemplate | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateAlicotTemplateInput = {
  id: string,
  listingId?: string | null,
  indexPrefix?: string | null,
  indexFirst?: number | null,
  number?: number | null,
  matrixName?: string | null,
  printTemplateId?: string | null,
};

export type DeleteAlicotTemplateInput = {
  id: string,
};

export type CreateCohortInput = {
  id?: string | null,
  partId: string,
  name: string,
  positionList?: Array<string | null> | null,
  active: ActiveStatus,
};

export type ModelCohortConditionInput = {
  partId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  positionList?: ModelStringInput | null,
  active?: ModelActiveStatusInput | null,
  and?: Array<ModelCohortConditionInput | null> | null,
  or?: Array<ModelCohortConditionInput | null> | null,
  not?: ModelCohortConditionInput | null,
};

export type Cohort = {
  __typename: "Cohort",
  id: string,
  partId: string,
  name: string,
  positionList?: Array<string | null> | null,
  active: ActiveStatus,
  createdAt: string,
  updatedAt: string,
};

export type UpdateCohortInput = {
  id: string,
  partId?: string | null,
  name?: string | null,
  positionList?: Array<string | null> | null,
  active?: ActiveStatus | null,
};

export type DeleteCohortInput = {
  id: string,
};

export type CreateLabelInput = {
  id?: string | null,
  cohortId: string,
  serial: string,
  theoreticalTime: string,
  theoreticalTimeLabel?: string | null,
  theoreticalTimeEnd?: string | null,
  period: string,
  matrixName?: string | null,
  index?: string | null,
  printTemplateId: string,
  printed: PrintedStatus,
  active: ActiveStatus,
  studyName: string,
  sponsorName: string,
  inclusion: string,
  parentId?: string | null,
  firstFillEventTime?: string | null,
  firstFillEventUserAndPseudo?: string | null,
  firstCentriEventTime?: string | null,
  firstCentriEventUserAndPseudo?: string | null,
  firstFreezeEventTime?: string | null,
  firstFreezeEventUserAndPseudo?: string | null,
  firstOutEventTime?: string | null,
  firstOutEventUserAndPseudo?: string | null,
  lastEventLocationID: string,
  lastEventType: string,
  lastEventTime: string,
  lastEventAuthor: string,
  comments?: string | null,
};

export type ModelLabelConditionInput = {
  cohortId?: ModelIDInput | null,
  serial?: ModelStringInput | null,
  theoreticalTime?: ModelStringInput | null,
  theoreticalTimeLabel?: ModelStringInput | null,
  theoreticalTimeEnd?: ModelStringInput | null,
  period?: ModelStringInput | null,
  matrixName?: ModelStringInput | null,
  index?: ModelStringInput | null,
  printTemplateId?: ModelIDInput | null,
  printed?: ModelPrintedStatusInput | null,
  active?: ModelActiveStatusInput | null,
  studyName?: ModelStringInput | null,
  sponsorName?: ModelStringInput | null,
  inclusion?: ModelStringInput | null,
  parentId?: ModelIDInput | null,
  firstFillEventTime?: ModelStringInput | null,
  firstFillEventUserAndPseudo?: ModelStringInput | null,
  firstCentriEventTime?: ModelStringInput | null,
  firstCentriEventUserAndPseudo?: ModelStringInput | null,
  firstFreezeEventTime?: ModelStringInput | null,
  firstFreezeEventUserAndPseudo?: ModelStringInput | null,
  firstOutEventTime?: ModelStringInput | null,
  firstOutEventUserAndPseudo?: ModelStringInput | null,
  lastEventLocationID?: ModelStringInput | null,
  lastEventType?: ModelStringInput | null,
  lastEventTime?: ModelStringInput | null,
  lastEventAuthor?: ModelStringInput | null,
  comments?: ModelStringInput | null,
  and?: Array<ModelLabelConditionInput | null> | null,
  or?: Array<ModelLabelConditionInput | null> | null,
  not?: ModelLabelConditionInput | null,
};

export type UpdateLabelInput = {
  id: string,
  cohortId?: string | null,
  serial?: string | null,
  theoreticalTime?: string | null,
  theoreticalTimeLabel?: string | null,
  theoreticalTimeEnd?: string | null,
  period?: string | null,
  matrixName?: string | null,
  index?: string | null,
  printTemplateId?: string | null,
  printed?: PrintedStatus | null,
  active?: ActiveStatus | null,
  studyName?: string | null,
  sponsorName?: string | null,
  inclusion?: string | null,
  parentId?: string | null,
  firstFillEventTime?: string | null,
  firstFillEventUserAndPseudo?: string | null,
  firstCentriEventTime?: string | null,
  firstCentriEventUserAndPseudo?: string | null,
  firstFreezeEventTime?: string | null,
  firstFreezeEventUserAndPseudo?: string | null,
  firstOutEventTime?: string | null,
  firstOutEventUserAndPseudo?: string | null,
  lastEventLocationID?: string | null,
  lastEventType?: string | null,
  lastEventTime?: string | null,
  lastEventAuthor?: string | null,
  comments?: string | null,
};

export type DeleteLabelInput = {
  id: string,
};

export type CreateEventInput = {
  id?: string | null,
  labelId: string,
  owner: string,
  pseudo: string,
  usedDate: string,
  locationId?: string | null,
  type: string,
  description?: string | null,
  labelData?: string | null,
};

export type ModelEventConditionInput = {
  labelId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  pseudo?: ModelStringInput | null,
  usedDate?: ModelStringInput | null,
  locationId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  description?: ModelStringInput | null,
  labelData?: ModelStringInput | null,
  and?: Array<ModelEventConditionInput | null> | null,
  or?: Array<ModelEventConditionInput | null> | null,
  not?: ModelEventConditionInput | null,
};

export type Event = {
  __typename: "Event",
  id: string,
  labelId: string,
  owner: string,
  pseudo: string,
  usedDate: string,
  locationId?: string | null,
  type: string,
  description?: string | null,
  labelData?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateEventInput = {
  id: string,
  labelId?: string | null,
  owner?: string | null,
  pseudo?: string | null,
  usedDate?: string | null,
  locationId?: string | null,
  type?: string | null,
  description?: string | null,
  labelData?: string | null,
};

export type DeleteEventInput = {
  id: string,
};

export type CreatePrintServerInput = {
  id?: string | null,
  endpoint: string,
};

export type ModelPrintServerConditionInput = {
  endpoint?: ModelStringInput | null,
  and?: Array<ModelPrintServerConditionInput | null> | null,
  or?: Array<ModelPrintServerConditionInput | null> | null,
  not?: ModelPrintServerConditionInput | null,
};

export type PrintServer = {
  __typename: "PrintServer",
  id: string,
  endpoint: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdatePrintServerInput = {
  id: string,
  endpoint?: string | null,
};

export type DeletePrintServerInput = {
  id: string,
};

export type ModelPrintTemplateFilterInput = {
  id?: ModelIDInput | null,
  templatePath?: ModelStringInput | null,
  name?: ModelStringInput | null,
  printerId?: ModelIDInput | null,
  active?: ModelActiveStatusInput | null,
  and?: Array<ModelPrintTemplateFilterInput | null> | null,
  or?: Array<ModelPrintTemplateFilterInput | null> | null,
  not?: ModelPrintTemplateFilterInput | null,
};

export type ModelPrintTemplateConnection = {
  __typename: "ModelPrintTemplateConnection",
  items: Array<PrintTemplate | null>,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array<string | null> | null,
  beginsWith?: string | null,
};

export type ModelPrinterFilterInput = {
  id?: ModelIDInput | null,
  printerNetworkId?: ModelStringInput | null,
  friendlyName?: ModelStringInput | null,
  active?: ModelActiveStatusInput | null,
  and?: Array<ModelPrinterFilterInput | null> | null,
  or?: Array<ModelPrinterFilterInput | null> | null,
  not?: ModelPrinterFilterInput | null,
};

export type ModelPrinterConnection = {
  __typename: "ModelPrinterConnection",
  items: Array<Printer | null>,
  nextToken?: string | null,
};

export type ModelLocationCatFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  prefix?: ModelStringInput | null,
  active?: ModelActiveStatusInput | null,
  and?: Array<ModelLocationCatFilterInput | null> | null,
  or?: Array<ModelLocationCatFilterInput | null> | null,
  not?: ModelLocationCatFilterInput | null,
};

export type ModelLocationCatConnection = {
  __typename: "ModelLocationCatConnection",
  items: Array<LocationCat | null>,
  nextToken?: string | null,
};

export type ModelLocationFilterInput = {
  id?: ModelIDInput | null,
  locationCatId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  friendlyName?: ModelStringInput | null,
  active?: ModelActiveStatusInput | null,
  and?: Array<ModelLocationFilterInput | null> | null,
  or?: Array<ModelLocationFilterInput | null> | null,
  not?: ModelLocationFilterInput | null,
};

export type ModelLocationConnection = {
  __typename: "ModelLocationConnection",
  items: Array<Location | null>,
  nextToken?: string | null,
};

export type ModelLocationByStatusCatCompositeKeyConditionInput = {
  eq?: ModelLocationByStatusCatCompositeKeyInput | null,
  le?: ModelLocationByStatusCatCompositeKeyInput | null,
  lt?: ModelLocationByStatusCatCompositeKeyInput | null,
  ge?: ModelLocationByStatusCatCompositeKeyInput | null,
  gt?: ModelLocationByStatusCatCompositeKeyInput | null,
  between?: Array<ModelLocationByStatusCatCompositeKeyInput | null> | null,
  beginsWith?: ModelLocationByStatusCatCompositeKeyInput | null,
};

export type ModelLocationByStatusCatCompositeKeyInput = {
  locationCatId?: string | null,
  name?: string | null,
};

export type ModelCommentFilterInput = {
  id?: ModelIDInput | null,
  selector?: ModelStringInput | null,
  active?: ModelActiveStatusInput | null,
  comment?: ModelStringInput | null,
  and?: Array<ModelCommentFilterInput | null> | null,
  or?: Array<ModelCommentFilterInput | null> | null,
  not?: ModelCommentFilterInput | null,
};

export type ModelCommentConnection = {
  __typename: "ModelCommentConnection",
  items: Array<Comment | null>,
  nextToken?: string | null,
};

export type ModelSampleProtocolTemplateFilterInput = {
  id?: ModelIDInput | null,
  partId?: ModelStringInput | null,
  active?: ModelActiveStatusInput | null,
  name?: ModelStringInput | null,
  sponsorName?: ModelStringInput | null,
  studyName?: ModelStringInput | null,
  validationDate?: ModelStringInput | null,
  validateUserId?: ModelStringInput | null,
  validationDateSecond?: ModelStringInput | null,
  validateUserIdSecond?: ModelStringInput | null,
  and?: Array<ModelSampleProtocolTemplateFilterInput | null> | null,
  or?: Array<ModelSampleProtocolTemplateFilterInput | null> | null,
  not?: ModelSampleProtocolTemplateFilterInput | null,
};

export type ModelSampleProtocolTemplateConnection = {
  __typename: "ModelSampleProtocolTemplateConnection",
  items: Array<SampleProtocolTemplate | null>,
  nextToken?: string | null,
};

export type ModelListingFilterInput = {
  id?: ModelIDInput | null,
  sampleProtocolTemplateId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  printTemplateId?: ModelIDInput | null,
  and?: Array<ModelListingFilterInput | null> | null,
  or?: Array<ModelListingFilterInput | null> | null,
  not?: ModelListingFilterInput | null,
};

export type ModelListingConnection = {
  __typename: "ModelListingConnection",
  items: Array<Listing | null>,
  nextToken?: string | null,
};

export type ModelSampleTemplateFilterInput = {
  id?: ModelIDInput | null,
  listingId?: ModelIDInput | null,
  theoreticalTime?: ModelStringInput | null,
  theoreticalTimeEnd?: ModelStringInput | null,
  theoreticalTimeLabel?: ModelStringInput | null,
  period?: ModelStringInput | null,
  matrixName?: ModelStringInput | null,
  index?: ModelStringInput | null,
  and?: Array<ModelSampleTemplateFilterInput | null> | null,
  or?: Array<ModelSampleTemplateFilterInput | null> | null,
  not?: ModelSampleTemplateFilterInput | null,
};

export type ModelSampleTemplateConnection = {
  __typename: "ModelSampleTemplateConnection",
  items: Array<SampleTemplate | null>,
  nextToken?: string | null,
};

export type ModelSampleTemplateByListingCompositeKeyConditionInput = {
  eq?: ModelSampleTemplateByListingCompositeKeyInput | null,
  le?: ModelSampleTemplateByListingCompositeKeyInput | null,
  lt?: ModelSampleTemplateByListingCompositeKeyInput | null,
  ge?: ModelSampleTemplateByListingCompositeKeyInput | null,
  gt?: ModelSampleTemplateByListingCompositeKeyInput | null,
  between?: Array<ModelSampleTemplateByListingCompositeKeyInput | null> | null,
  beginsWith?: ModelSampleTemplateByListingCompositeKeyInput | null,
};

export type ModelSampleTemplateByListingCompositeKeyInput = {
  period?: string | null,
  theoreticalTime?: string | null,
};

export type ModelAlicotTemplateFilterInput = {
  id?: ModelIDInput | null,
  listingId?: ModelIDInput | null,
  indexPrefix?: ModelStringInput | null,
  indexFirst?: ModelIntInput | null,
  number?: ModelIntInput | null,
  matrixName?: ModelStringInput | null,
  printTemplateId?: ModelIDInput | null,
  and?: Array<ModelAlicotTemplateFilterInput | null> | null,
  or?: Array<ModelAlicotTemplateFilterInput | null> | null,
  not?: ModelAlicotTemplateFilterInput | null,
};

export type ModelAlicotTemplateConnection = {
  __typename: "ModelAlicotTemplateConnection",
  items: Array<AlicotTemplate | null>,
  nextToken?: string | null,
};

export type ModelCohortFilterInput = {
  id?: ModelIDInput | null,
  partId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  positionList?: ModelStringInput | null,
  active?: ModelActiveStatusInput | null,
  and?: Array<ModelCohortFilterInput | null> | null,
  or?: Array<ModelCohortFilterInput | null> | null,
  not?: ModelCohortFilterInput | null,
};

export type ModelCohortConnection = {
  __typename: "ModelCohortConnection",
  items: Array<Cohort | null>,
  nextToken?: string | null,
};

export type ModelCohortByPartCompositeKeyConditionInput = {
  eq?: ModelCohortByPartCompositeKeyInput | null,
  le?: ModelCohortByPartCompositeKeyInput | null,
  lt?: ModelCohortByPartCompositeKeyInput | null,
  ge?: ModelCohortByPartCompositeKeyInput | null,
  gt?: ModelCohortByPartCompositeKeyInput | null,
  between?: Array<ModelCohortByPartCompositeKeyInput | null> | null,
  beginsWith?: ModelCohortByPartCompositeKeyInput | null,
};

export type ModelCohortByPartCompositeKeyInput = {
  partId?: string | null,
  name?: string | null,
};

export type ModelLabelByLocationCompositeKeyConditionInput = {
  eq?: ModelLabelByLocationCompositeKeyInput | null,
  le?: ModelLabelByLocationCompositeKeyInput | null,
  lt?: ModelLabelByLocationCompositeKeyInput | null,
  ge?: ModelLabelByLocationCompositeKeyInput | null,
  gt?: ModelLabelByLocationCompositeKeyInput | null,
  between?: Array<ModelLabelByLocationCompositeKeyInput | null> | null,
  beginsWith?: ModelLabelByLocationCompositeKeyInput | null,
};

export type ModelLabelByLocationCompositeKeyInput = {
  lastEventLocationID?: string | null,
  inclusion?: string | null,
  period?: string | null,
  theoreticalTime?: string | null,
};

export type ModelEventFilterInput = {
  id?: ModelIDInput | null,
  labelId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  pseudo?: ModelStringInput | null,
  usedDate?: ModelStringInput | null,
  locationId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  description?: ModelStringInput | null,
  labelData?: ModelStringInput | null,
  and?: Array<ModelEventFilterInput | null> | null,
  or?: Array<ModelEventFilterInput | null> | null,
  not?: ModelEventFilterInput | null,
};

export type ModelEventConnection = {
  __typename: "ModelEventConnection",
  items: Array<Event | null>,
  nextToken?: string | null,
};

export type ModelPrintServerFilterInput = {
  id?: ModelIDInput | null,
  endpoint?: ModelStringInput | null,
  and?: Array<ModelPrintServerFilterInput | null> | null,
  or?: Array<ModelPrintServerFilterInput | null> | null,
  not?: ModelPrintServerFilterInput | null,
};

export type ModelPrintServerConnection = {
  __typename: "ModelPrintServerConnection",
  items: Array<PrintServer | null>,
  nextToken?: string | null,
};

export type ModelSubscriptionPrintTemplateFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  templatePath?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  printerId?: ModelSubscriptionIDInput | null,
  active?: ModelSubscriptionStringInput | null,
  and?: Array<ModelSubscriptionPrintTemplateFilterInput | null> | null,
  or?: Array<ModelSubscriptionPrintTemplateFilterInput | null> | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array<string | null> | null,
  beginsWith?: string | null,
  in?: Array<string | null> | null,
  notIn?: Array<string | null> | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array<string | null> | null,
  beginsWith?: string | null,
  in?: Array<string | null> | null,
  notIn?: Array<string | null> | null,
};

export type ModelSubscriptionPrinterFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  printerNetworkId?: ModelSubscriptionStringInput | null,
  friendlyName?: ModelSubscriptionStringInput | null,
  active?: ModelSubscriptionStringInput | null,
  and?: Array<ModelSubscriptionPrinterFilterInput | null> | null,
  or?: Array<ModelSubscriptionPrinterFilterInput | null> | null,
};

export type ModelSubscriptionLocationCatFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  prefix?: ModelSubscriptionStringInput | null,
  active?: ModelSubscriptionStringInput | null,
  and?: Array<ModelSubscriptionLocationCatFilterInput | null> | null,
  or?: Array<ModelSubscriptionLocationCatFilterInput | null> | null,
};

export type ModelSubscriptionLocationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  locationCatId?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  friendlyName?: ModelSubscriptionStringInput | null,
  active?: ModelSubscriptionStringInput | null,
  and?: Array<ModelSubscriptionLocationFilterInput | null> | null,
  or?: Array<ModelSubscriptionLocationFilterInput | null> | null,
};

export type ModelSubscriptionCommentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  selector?: ModelSubscriptionStringInput | null,
  active?: ModelSubscriptionStringInput | null,
  comment?: ModelSubscriptionStringInput | null,
  and?: Array<ModelSubscriptionCommentFilterInput | null> | null,
  or?: Array<ModelSubscriptionCommentFilterInput | null> | null,
};

export type ModelSubscriptionSampleProtocolTemplateFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  partId?: ModelSubscriptionStringInput | null,
  active?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  sponsorName?: ModelSubscriptionStringInput | null,
  studyName?: ModelSubscriptionStringInput | null,
  validationDate?: ModelSubscriptionStringInput | null,
  validateUserId?: ModelSubscriptionStringInput | null,
  validationDateSecond?: ModelSubscriptionStringInput | null,
  validateUserIdSecond?: ModelSubscriptionStringInput | null,
  and?: Array<ModelSubscriptionSampleProtocolTemplateFilterInput | null> | null,
  or?: Array<ModelSubscriptionSampleProtocolTemplateFilterInput | null> | null,
};

export type ModelSubscriptionListingFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  sampleProtocolTemplateId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  printTemplateId?: ModelSubscriptionIDInput | null,
  and?: Array<ModelSubscriptionListingFilterInput | null> | null,
  or?: Array<ModelSubscriptionListingFilterInput | null> | null,
};

export type ModelSubscriptionSampleTemplateFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  listingId?: ModelSubscriptionIDInput | null,
  theoreticalTime?: ModelSubscriptionStringInput | null,
  theoreticalTimeEnd?: ModelSubscriptionStringInput | null,
  theoreticalTimeLabel?: ModelSubscriptionStringInput | null,
  period?: ModelSubscriptionStringInput | null,
  matrixName?: ModelSubscriptionStringInput | null,
  index?: ModelSubscriptionStringInput | null,
  and?: Array<ModelSubscriptionSampleTemplateFilterInput | null> | null,
  or?: Array<ModelSubscriptionSampleTemplateFilterInput | null> | null,
};

export type ModelSubscriptionAlicotTemplateFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  listingId?: ModelSubscriptionIDInput | null,
  indexPrefix?: ModelSubscriptionStringInput | null,
  indexFirst?: ModelSubscriptionIntInput | null,
  number?: ModelSubscriptionIntInput | null,
  matrixName?: ModelSubscriptionStringInput | null,
  printTemplateId?: ModelSubscriptionIDInput | null,
  and?: Array<ModelSubscriptionAlicotTemplateFilterInput | null> | null,
  or?: Array<ModelSubscriptionAlicotTemplateFilterInput | null> | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array<number | null> | null,
  in?: Array<number | null> | null,
  notIn?: Array<number | null> | null,
};

export type ModelSubscriptionCohortFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  partId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  positionList?: ModelSubscriptionStringInput | null,
  active?: ModelSubscriptionStringInput | null,
  and?: Array<ModelSubscriptionCohortFilterInput | null> | null,
  or?: Array<ModelSubscriptionCohortFilterInput | null> | null,
};

export type ModelSubscriptionLabelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  cohortId?: ModelSubscriptionIDInput | null,
  serial?: ModelSubscriptionStringInput | null,
  theoreticalTime?: ModelSubscriptionStringInput | null,
  theoreticalTimeLabel?: ModelSubscriptionStringInput | null,
  theoreticalTimeEnd?: ModelSubscriptionStringInput | null,
  period?: ModelSubscriptionStringInput | null,
  matrixName?: ModelSubscriptionStringInput | null,
  index?: ModelSubscriptionStringInput | null,
  printTemplateId?: ModelSubscriptionIDInput | null,
  printed?: ModelSubscriptionStringInput | null,
  active?: ModelSubscriptionStringInput | null,
  studyName?: ModelSubscriptionStringInput | null,
  sponsorName?: ModelSubscriptionStringInput | null,
  inclusion?: ModelSubscriptionStringInput | null,
  parentId?: ModelSubscriptionIDInput | null,
  firstFillEventTime?: ModelSubscriptionStringInput | null,
  firstFillEventUserAndPseudo?: ModelSubscriptionStringInput | null,
  firstCentriEventTime?: ModelSubscriptionStringInput | null,
  firstCentriEventUserAndPseudo?: ModelSubscriptionStringInput | null,
  firstFreezeEventTime?: ModelSubscriptionStringInput | null,
  firstFreezeEventUserAndPseudo?: ModelSubscriptionStringInput | null,
  firstOutEventTime?: ModelSubscriptionStringInput | null,
  firstOutEventUserAndPseudo?: ModelSubscriptionStringInput | null,
  lastEventLocationID?: ModelSubscriptionStringInput | null,
  lastEventType?: ModelSubscriptionStringInput | null,
  lastEventTime?: ModelSubscriptionStringInput | null,
  lastEventAuthor?: ModelSubscriptionStringInput | null,
  comments?: ModelSubscriptionStringInput | null,
  and?: Array<ModelSubscriptionLabelFilterInput | null> | null,
  or?: Array<ModelSubscriptionLabelFilterInput | null> | null,
};

export type ModelSubscriptionEventFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  labelId?: ModelSubscriptionIDInput | null,
  owner?: ModelSubscriptionStringInput | null,
  pseudo?: ModelSubscriptionStringInput | null,
  usedDate?: ModelSubscriptionStringInput | null,
  locationId?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  labelData?: ModelSubscriptionStringInput | null,
  and?: Array<ModelSubscriptionEventFilterInput | null> | null,
  or?: Array<ModelSubscriptionEventFilterInput | null> | null,
};

export type ModelSubscriptionPrintServerFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  endpoint?: ModelSubscriptionStringInput | null,
  and?: Array<ModelSubscriptionPrintServerFilterInput | null> | null,
  or?: Array<ModelSubscriptionPrintServerFilterInput | null> | null,
};

export type LabelByStatusInclusionCustomQueryVariables = {
  active: ActiveStatus,
  cohortIdInclusionPeriodTheoreticalTimeIndex?: ModelLabelByStatusInclusionCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLabelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LabelByStatusInclusionCustomQuery = {
  labelByStatusInclusion?: {
    __typename: "ModelLabelConnection",
    items: Array<{
      __typename: "Label",
      id: string,
      cohortId: string,
      serial: string,
      theoreticalTime: string,
      theoreticalTimeEnd?: string | null,
      theoreticalTimeLabel?: string | null,
      period: string,
      matrixName?: string | null,
      index?: string | null,
      printTemplateId: string,
      printTemplate?: {
        __typename: "PrintTemplate",
        id: string,
        templatePath: string,
        name: string,
        printerId: string,
        printer: {
          __typename: "Printer",
          printerNetworkId: string,
        },
      } | null,
      printed: PrintedStatus,
      active: ActiveStatus,
      studyName: string,
      sponsorName: string,
      inclusion: string,
      parentId?: string | null,
      firstFillEventTime?: string | null,
      firstFillEventUserAndPseudo?: string | null,
      firstCentriEventTime?: string | null,
      firstCentriEventUserAndPseudo?: string | null,
      firstFreezeEventTime?: string | null,
      firstFreezeEventUserAndPseudo?: string | null,
      firstOutEventTime?: string | null,
      firstOutEventUserAndPseudo?: string | null,
      lastEventLocationID: string,
      lastEventType: string,
      lastEventTime: string,
      lastEventAuthor: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type LabelByStatusPrintedCustomQueryVariables = {
  active: ActiveStatus,
  cohortIdPrintedInclusionPeriodTheoreticalTimeIndex?: ModelLabelByStatusPrintedCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLabelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LabelByStatusPrintedCustomQuery = {
  labelByStatusPrinted?: {
    __typename: "ModelLabelConnection",
    items: Array<{
      __typename: "Label",
      id: string,
      cohortId: string,
      serial: string,
      theoreticalTime: string,
      theoreticalTimeEnd?: string | null,
      theoreticalTimeLabel?: string | null,
      period: string,
      matrixName?: string | null,
      index?: string | null,
      printTemplateId: string,
      printTemplate?: {
        __typename: "PrintTemplate",
        id: string,
        templatePath: string,
        name: string,
        printerId: string,
        printer: {
          __typename: "Printer",
          printerNetworkId: string,
        },
      } | null,
      printed: PrintedStatus,
      active: ActiveStatus,
      studyName: string,
      sponsorName: string,
      inclusion: string,
      parentId?: string | null,
      firstFillEventTime?: string | null,
      firstFillEventUserAndPseudo?: string | null,
      firstCentriEventTime?: string | null,
      firstCentriEventUserAndPseudo?: string | null,
      firstFreezeEventTime?: string | null,
      firstFreezeEventUserAndPseudo?: string | null,
      firstOutEventTime?: string | null,
      firstOutEventUserAndPseudo?: string | null,
      lastEventLocationID: string,
      lastEventType: string,
      lastEventTime: string,
      lastEventAuthor: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type LabelByStatusCohortCustomQueryVariables = {
  active: ActiveStatus,
  cohortIdPeriodTheoreticalTimeInclusionIndex?: ModelLabelByStatusCohortCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLabelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LabelByStatusCohortCustomQuery = {
  labelByStatusCohort?: {
    __typename: "ModelLabelConnection",
    items: Array<{
      __typename: "Label",
      id: string,
      cohortId: string,
      serial: string,
      theoreticalTime: string,
      theoreticalTimeEnd?: string | null,
      theoreticalTimeLabel?: string | null,
      period: string,
      matrixName?: string | null,
      index?: string | null,
      printTemplateId: string,
      printTemplate?: {
        __typename: "PrintTemplate",
        id: string,
        templatePath: string,
        name: string,
        printerId: string,
        printer: {
          __typename: "Printer",
          printerNetworkId: string,
        },
      } | null,
      printed: PrintedStatus,
      active: ActiveStatus,
      studyName: string,
      sponsorName: string,
      inclusion: string,
      parentId?: string | null,
      firstFillEventTime?: string | null,
      firstFillEventUserAndPseudo?: string | null,
      firstCentriEventTime?: string | null,
      firstCentriEventUserAndPseudo?: string | null,
      firstFreezeEventTime?: string | null,
      firstFreezeEventUserAndPseudo?: string | null,
      firstOutEventTime?: string | null,
      firstOutEventUserAndPseudo?: string | null,
      lastEventLocationID: string,
      lastEventType: string,
      lastEventTime: string,
      lastEventAuthor: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type CreatePrintTemplateMutationVariables = {
  input: CreatePrintTemplateInput,
  condition?: ModelPrintTemplateConditionInput | null,
};

export type CreatePrintTemplateMutation = {
  createPrintTemplate?: {
    __typename: "PrintTemplate",
    id: string,
    templatePath: string,
    name: string,
    printerId: string,
    printer: {
      __typename: "Printer",
      id: string,
      printerNetworkId: string,
      friendlyName: string,
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    },
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePrintTemplateMutationVariables = {
  input: UpdatePrintTemplateInput,
  condition?: ModelPrintTemplateConditionInput | null,
};

export type UpdatePrintTemplateMutation = {
  updatePrintTemplate?: {
    __typename: "PrintTemplate",
    id: string,
    templatePath: string,
    name: string,
    printerId: string,
    printer: {
      __typename: "Printer",
      id: string,
      printerNetworkId: string,
      friendlyName: string,
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    },
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePrintTemplateMutationVariables = {
  input: DeletePrintTemplateInput,
  condition?: ModelPrintTemplateConditionInput | null,
};

export type DeletePrintTemplateMutation = {
  deletePrintTemplate?: {
    __typename: "PrintTemplate",
    id: string,
    templatePath: string,
    name: string,
    printerId: string,
    printer: {
      __typename: "Printer",
      id: string,
      printerNetworkId: string,
      friendlyName: string,
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    },
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePrinterMutationVariables = {
  input: CreatePrinterInput,
  condition?: ModelPrinterConditionInput | null,
};

export type CreatePrinterMutation = {
  createPrinter?: {
    __typename: "Printer",
    id: string,
    printerNetworkId: string,
    friendlyName: string,
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePrinterMutationVariables = {
  input: UpdatePrinterInput,
  condition?: ModelPrinterConditionInput | null,
};

export type UpdatePrinterMutation = {
  updatePrinter?: {
    __typename: "Printer",
    id: string,
    printerNetworkId: string,
    friendlyName: string,
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePrinterMutationVariables = {
  input: DeletePrinterInput,
  condition?: ModelPrinterConditionInput | null,
};

export type DeletePrinterMutation = {
  deletePrinter?: {
    __typename: "Printer",
    id: string,
    printerNetworkId: string,
    friendlyName: string,
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateLocationCatMutationVariables = {
  input: CreateLocationCatInput,
  condition?: ModelLocationCatConditionInput | null,
};

export type CreateLocationCatMutation = {
  createLocationCat?: {
    __typename: "LocationCat",
    id: string,
    name: string,
    prefix?: string | null,
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateLocationCatMutationVariables = {
  input: UpdateLocationCatInput,
  condition?: ModelLocationCatConditionInput | null,
};

export type UpdateLocationCatMutation = {
  updateLocationCat?: {
    __typename: "LocationCat",
    id: string,
    name: string,
    prefix?: string | null,
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteLocationCatMutationVariables = {
  input: DeleteLocationCatInput,
  condition?: ModelLocationCatConditionInput | null,
};

export type DeleteLocationCatMutation = {
  deleteLocationCat?: {
    __typename: "LocationCat",
    id: string,
    name: string,
    prefix?: string | null,
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateLocationMutationVariables = {
  input: CreateLocationInput,
  condition?: ModelLocationConditionInput | null,
};

export type CreateLocationMutation = {
  createLocation?: {
    __typename: "Location",
    id: string,
    locationCatId: string,
    name: string,
    friendlyName: string,
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateLocationMutationVariables = {
  input: UpdateLocationInput,
  condition?: ModelLocationConditionInput | null,
};

export type UpdateLocationMutation = {
  updateLocation?: {
    __typename: "Location",
    id: string,
    locationCatId: string,
    name: string,
    friendlyName: string,
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteLocationMutationVariables = {
  input: DeleteLocationInput,
  condition?: ModelLocationConditionInput | null,
};

export type DeleteLocationMutation = {
  deleteLocation?: {
    __typename: "Location",
    id: string,
    locationCatId: string,
    name: string,
    friendlyName: string,
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCommentMutationVariables = {
  input: CreateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type CreateCommentMutation = {
  createComment?: {
    __typename: "Comment",
    id: string,
    selector: string,
    active: ActiveStatus,
    comment: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCommentMutationVariables = {
  input: UpdateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type UpdateCommentMutation = {
  updateComment?: {
    __typename: "Comment",
    id: string,
    selector: string,
    active: ActiveStatus,
    comment: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCommentMutationVariables = {
  input: DeleteCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type DeleteCommentMutation = {
  deleteComment?: {
    __typename: "Comment",
    id: string,
    selector: string,
    active: ActiveStatus,
    comment: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSampleProtocolTemplateMutationVariables = {
  input: CreateSampleProtocolTemplateInput,
  condition?: ModelSampleProtocolTemplateConditionInput | null,
};

export type CreateSampleProtocolTemplateMutation = {
  createSampleProtocolTemplate?: {
    __typename: "SampleProtocolTemplate",
    id: string,
    partId: string,
    active: ActiveStatus,
    name: string,
    sponsorName?: string | null,
    studyName?: string | null,
    validationDate?: string | null,
    validateUserId?: string | null,
    validationDateSecond?: string | null,
    validateUserIdSecond?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSampleProtocolTemplateMutationVariables = {
  input: UpdateSampleProtocolTemplateInput,
  condition?: ModelSampleProtocolTemplateConditionInput | null,
};

export type UpdateSampleProtocolTemplateMutation = {
  updateSampleProtocolTemplate?: {
    __typename: "SampleProtocolTemplate",
    id: string,
    partId: string,
    active: ActiveStatus,
    name: string,
    sponsorName?: string | null,
    studyName?: string | null,
    validationDate?: string | null,
    validateUserId?: string | null,
    validationDateSecond?: string | null,
    validateUserIdSecond?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSampleProtocolTemplateMutationVariables = {
  input: DeleteSampleProtocolTemplateInput,
  condition?: ModelSampleProtocolTemplateConditionInput | null,
};

export type DeleteSampleProtocolTemplateMutation = {
  deleteSampleProtocolTemplate?: {
    __typename: "SampleProtocolTemplate",
    id: string,
    partId: string,
    active: ActiveStatus,
    name: string,
    sponsorName?: string | null,
    studyName?: string | null,
    validationDate?: string | null,
    validateUserId?: string | null,
    validationDateSecond?: string | null,
    validateUserIdSecond?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateListingMutationVariables = {
  input: CreateListingInput,
  condition?: ModelListingConditionInput | null,
};

export type CreateListingMutation = {
  createListing?: {
    __typename: "Listing",
    id: string,
    sampleProtocolTemplateId: string,
    name: string,
    printTemplateId: string,
    printTemplate?: {
      __typename: "PrintTemplate",
      id: string,
      templatePath: string,
      name: string,
      printerId: string,
      printer: {
        __typename: "Printer",
        id: string,
        printerNetworkId: string,
        friendlyName: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      },
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateListingMutationVariables = {
  input: UpdateListingInput,
  condition?: ModelListingConditionInput | null,
};

export type UpdateListingMutation = {
  updateListing?: {
    __typename: "Listing",
    id: string,
    sampleProtocolTemplateId: string,
    name: string,
    printTemplateId: string,
    printTemplate?: {
      __typename: "PrintTemplate",
      id: string,
      templatePath: string,
      name: string,
      printerId: string,
      printer: {
        __typename: "Printer",
        id: string,
        printerNetworkId: string,
        friendlyName: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      },
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteListingMutationVariables = {
  input: DeleteListingInput,
  condition?: ModelListingConditionInput | null,
};

export type DeleteListingMutation = {
  deleteListing?: {
    __typename: "Listing",
    id: string,
    sampleProtocolTemplateId: string,
    name: string,
    printTemplateId: string,
    printTemplate?: {
      __typename: "PrintTemplate",
      id: string,
      templatePath: string,
      name: string,
      printerId: string,
      printer: {
        __typename: "Printer",
        id: string,
        printerNetworkId: string,
        friendlyName: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      },
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSampleTemplateMutationVariables = {
  input: CreateSampleTemplateInput,
  condition?: ModelSampleTemplateConditionInput | null,
};

export type CreateSampleTemplateMutation = {
  createSampleTemplate?: {
    __typename: "SampleTemplate",
    id: string,
    listingId: string,
    theoreticalTime: string,
    theoreticalTimeEnd?: string | null,
    theoreticalTimeLabel?: string | null,
    period: string,
    matrixName?: string | null,
    index?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSampleTemplateMutationVariables = {
  input: UpdateSampleTemplateInput,
  condition?: ModelSampleTemplateConditionInput | null,
};

export type UpdateSampleTemplateMutation = {
  updateSampleTemplate?: {
    __typename: "SampleTemplate",
    id: string,
    listingId: string,
    theoreticalTime: string,
    theoreticalTimeEnd?: string | null,
    theoreticalTimeLabel?: string | null,
    period: string,
    matrixName?: string | null,
    index?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSampleTemplateMutationVariables = {
  input: DeleteSampleTemplateInput,
  condition?: ModelSampleTemplateConditionInput | null,
};

export type DeleteSampleTemplateMutation = {
  deleteSampleTemplate?: {
    __typename: "SampleTemplate",
    id: string,
    listingId: string,
    theoreticalTime: string,
    theoreticalTimeEnd?: string | null,
    theoreticalTimeLabel?: string | null,
    period: string,
    matrixName?: string | null,
    index?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAlicotTemplateMutationVariables = {
  input: CreateAlicotTemplateInput,
  condition?: ModelAlicotTemplateConditionInput | null,
};

export type CreateAlicotTemplateMutation = {
  createAlicotTemplate?: {
    __typename: "AlicotTemplate",
    id: string,
    listingId: string,
    indexPrefix: string,
    indexFirst: number,
    number: number,
    matrixName?: string | null,
    printTemplateId: string,
    printTemplate?: {
      __typename: "PrintTemplate",
      id: string,
      templatePath: string,
      name: string,
      printerId: string,
      printer: {
        __typename: "Printer",
        id: string,
        printerNetworkId: string,
        friendlyName: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      },
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAlicotTemplateMutationVariables = {
  input: UpdateAlicotTemplateInput,
  condition?: ModelAlicotTemplateConditionInput | null,
};

export type UpdateAlicotTemplateMutation = {
  updateAlicotTemplate?: {
    __typename: "AlicotTemplate",
    id: string,
    listingId: string,
    indexPrefix: string,
    indexFirst: number,
    number: number,
    matrixName?: string | null,
    printTemplateId: string,
    printTemplate?: {
      __typename: "PrintTemplate",
      id: string,
      templatePath: string,
      name: string,
      printerId: string,
      printer: {
        __typename: "Printer",
        id: string,
        printerNetworkId: string,
        friendlyName: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      },
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAlicotTemplateMutationVariables = {
  input: DeleteAlicotTemplateInput,
  condition?: ModelAlicotTemplateConditionInput | null,
};

export type DeleteAlicotTemplateMutation = {
  deleteAlicotTemplate?: {
    __typename: "AlicotTemplate",
    id: string,
    listingId: string,
    indexPrefix: string,
    indexFirst: number,
    number: number,
    matrixName?: string | null,
    printTemplateId: string,
    printTemplate?: {
      __typename: "PrintTemplate",
      id: string,
      templatePath: string,
      name: string,
      printerId: string,
      printer: {
        __typename: "Printer",
        id: string,
        printerNetworkId: string,
        friendlyName: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      },
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCohortMutationVariables = {
  input: CreateCohortInput,
  condition?: ModelCohortConditionInput | null,
};

export type CreateCohortMutation = {
  createCohort?: {
    __typename: "Cohort",
    id: string,
    partId: string,
    name: string,
    positionList?: Array<string | null> | null,
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCohortMutationVariables = {
  input: UpdateCohortInput,
  condition?: ModelCohortConditionInput | null,
};

export type UpdateCohortMutation = {
  updateCohort?: {
    __typename: "Cohort",
    id: string,
    partId: string,
    name: string,
    positionList?: Array<string | null> | null,
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCohortMutationVariables = {
  input: DeleteCohortInput,
  condition?: ModelCohortConditionInput | null,
};

export type DeleteCohortMutation = {
  deleteCohort?: {
    __typename: "Cohort",
    id: string,
    partId: string,
    name: string,
    positionList?: Array<string | null> | null,
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateLabelMutationVariables = {
  input: CreateLabelInput,
  condition?: ModelLabelConditionInput | null,
};

export type CreateLabelMutation = {
  createLabel?: {
    __typename: "Label",
    id: string,
    cohortId: string,
    serial: string,
    theoreticalTime: string,
    theoreticalTimeLabel?: string | null,
    theoreticalTimeEnd?: string | null,
    period: string,
    matrixName?: string | null,
    index?: string | null,
    printTemplateId: string,
    printTemplate?: {
      __typename: "PrintTemplate",
      id: string,
      templatePath: string,
      name: string,
      printerId: string,
      printer: {
        __typename: "Printer",
        id: string,
        printerNetworkId: string,
        friendlyName: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      },
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    printed: PrintedStatus,
    active: ActiveStatus,
    studyName: string,
    sponsorName: string,
    inclusion: string,
    parentId?: string | null,
    firstFillEventTime?: string | null,
    firstFillEventUserAndPseudo?: string | null,
    firstCentriEventTime?: string | null,
    firstCentriEventUserAndPseudo?: string | null,
    firstFreezeEventTime?: string | null,
    firstFreezeEventUserAndPseudo?: string | null,
    firstOutEventTime?: string | null,
    firstOutEventUserAndPseudo?: string | null,
    lastEventLocationID: string,
    lastEventType: string,
    lastEventTime: string,
    lastEventAuthor: string,
    comments?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateLabelMutationVariables = {
  input: UpdateLabelInput,
  condition?: ModelLabelConditionInput | null,
};

export type UpdateLabelMutation = {
  updateLabel?: {
    __typename: "Label",
    id: string,
    cohortId: string,
    serial: string,
    theoreticalTime: string,
    theoreticalTimeLabel?: string | null,
    theoreticalTimeEnd?: string | null,
    period: string,
    matrixName?: string | null,
    index?: string | null,
    printTemplateId: string,
    printTemplate?: {
      __typename: "PrintTemplate",
      id: string,
      templatePath: string,
      name: string,
      printerId: string,
      printer: {
        __typename: "Printer",
        id: string,
        printerNetworkId: string,
        friendlyName: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      },
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    printed: PrintedStatus,
    active: ActiveStatus,
    studyName: string,
    sponsorName: string,
    inclusion: string,
    parentId?: string | null,
    firstFillEventTime?: string | null,
    firstFillEventUserAndPseudo?: string | null,
    firstCentriEventTime?: string | null,
    firstCentriEventUserAndPseudo?: string | null,
    firstFreezeEventTime?: string | null,
    firstFreezeEventUserAndPseudo?: string | null,
    firstOutEventTime?: string | null,
    firstOutEventUserAndPseudo?: string | null,
    lastEventLocationID: string,
    lastEventType: string,
    lastEventTime: string,
    lastEventAuthor: string,
    comments?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteLabelMutationVariables = {
  input: DeleteLabelInput,
  condition?: ModelLabelConditionInput | null,
};

export type DeleteLabelMutation = {
  deleteLabel?: {
    __typename: "Label",
    id: string,
    cohortId: string,
    serial: string,
    theoreticalTime: string,
    theoreticalTimeLabel?: string | null,
    theoreticalTimeEnd?: string | null,
    period: string,
    matrixName?: string | null,
    index?: string | null,
    printTemplateId: string,
    printTemplate?: {
      __typename: "PrintTemplate",
      id: string,
      templatePath: string,
      name: string,
      printerId: string,
      printer: {
        __typename: "Printer",
        id: string,
        printerNetworkId: string,
        friendlyName: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      },
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    printed: PrintedStatus,
    active: ActiveStatus,
    studyName: string,
    sponsorName: string,
    inclusion: string,
    parentId?: string | null,
    firstFillEventTime?: string | null,
    firstFillEventUserAndPseudo?: string | null,
    firstCentriEventTime?: string | null,
    firstCentriEventUserAndPseudo?: string | null,
    firstFreezeEventTime?: string | null,
    firstFreezeEventUserAndPseudo?: string | null,
    firstOutEventTime?: string | null,
    firstOutEventUserAndPseudo?: string | null,
    lastEventLocationID: string,
    lastEventType: string,
    lastEventTime: string,
    lastEventAuthor: string,
    comments?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateEventMutationVariables = {
  input: CreateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type CreateEventMutation = {
  createEvent?: {
    __typename: "Event",
    id: string,
    labelId: string,
    owner: string,
    pseudo: string,
    usedDate: string,
    locationId?: string | null,
    type: string,
    description?: string | null,
    labelData?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateEventMutationVariables = {
  input: UpdateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type UpdateEventMutation = {
  updateEvent?: {
    __typename: "Event",
    id: string,
    labelId: string,
    owner: string,
    pseudo: string,
    usedDate: string,
    locationId?: string | null,
    type: string,
    description?: string | null,
    labelData?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteEventMutationVariables = {
  input: DeleteEventInput,
  condition?: ModelEventConditionInput | null,
};

export type DeleteEventMutation = {
  deleteEvent?: {
    __typename: "Event",
    id: string,
    labelId: string,
    owner: string,
    pseudo: string,
    usedDate: string,
    locationId?: string | null,
    type: string,
    description?: string | null,
    labelData?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePrintServerMutationVariables = {
  input: CreatePrintServerInput,
  condition?: ModelPrintServerConditionInput | null,
};

export type CreatePrintServerMutation = {
  createPrintServer?: {
    __typename: "PrintServer",
    id: string,
    endpoint: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePrintServerMutationVariables = {
  input: UpdatePrintServerInput,
  condition?: ModelPrintServerConditionInput | null,
};

export type UpdatePrintServerMutation = {
  updatePrintServer?: {
    __typename: "PrintServer",
    id: string,
    endpoint: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePrintServerMutationVariables = {
  input: DeletePrintServerInput,
  condition?: ModelPrintServerConditionInput | null,
};

export type DeletePrintServerMutation = {
  deletePrintServer?: {
    __typename: "PrintServer",
    id: string,
    endpoint: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetPrintTemplateQueryVariables = {
  id: string,
};

export type GetPrintTemplateQuery = {
  getPrintTemplate?: {
    __typename: "PrintTemplate",
    id: string,
    templatePath: string,
    name: string,
    printerId: string,
    printer: {
      __typename: "Printer",
      id: string,
      printerNetworkId: string,
      friendlyName: string,
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    },
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPrintTemplatesQueryVariables = {
  filter?: ModelPrintTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPrintTemplatesQuery = {
  listPrintTemplates?: {
    __typename: "ModelPrintTemplateConnection",
    items: Array<{
      __typename: "PrintTemplate",
      id: string,
      templatePath: string,
      name: string,
      printerId: string,
      printer: {
        __typename: "Printer",
        id: string,
        printerNetworkId: string,
        friendlyName: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      },
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type PrinterTemplateByStatusQueryVariables = {
  active: ActiveStatus,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPrintTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PrinterTemplateByStatusQuery = {
  printerTemplateByStatus?: {
    __typename: "ModelPrintTemplateConnection",
    items: Array<{
      __typename: "PrintTemplate",
      id: string,
      templatePath: string,
      name: string,
      printerId: string,
      printer: {
        __typename: "Printer",
        id: string,
        printerNetworkId: string,
        friendlyName: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      },
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type GetPrinterQueryVariables = {
  id: string,
};

export type GetPrinterQuery = {
  getPrinter?: {
    __typename: "Printer",
    id: string,
    printerNetworkId: string,
    friendlyName: string,
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPrintersQueryVariables = {
  filter?: ModelPrinterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPrintersQuery = {
  listPrinters?: {
    __typename: "ModelPrinterConnection",
    items: Array<{
      __typename: "Printer",
      id: string,
      printerNetworkId: string,
      friendlyName: string,
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type PrinterByStatusQueryVariables = {
  active: ActiveStatus,
  friendlyName?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPrinterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PrinterByStatusQuery = {
  printerByStatus?: {
    __typename: "ModelPrinterConnection",
    items: Array<{
      __typename: "Printer",
      id: string,
      printerNetworkId: string,
      friendlyName: string,
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type GetLocationCatQueryVariables = {
  id: string,
};

export type GetLocationCatQuery = {
  getLocationCat?: {
    __typename: "LocationCat",
    id: string,
    name: string,
    prefix?: string | null,
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListLocationCatsQueryVariables = {
  filter?: ModelLocationCatFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLocationCatsQuery = {
  listLocationCats?: {
    __typename: "ModelLocationCatConnection",
    items: Array<{
      __typename: "LocationCat",
      id: string,
      name: string,
      prefix?: string | null,
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type LocationCatByStatusQueryVariables = {
  active: ActiveStatus,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLocationCatFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LocationCatByStatusQuery = {
  locationCatByStatus?: {
    __typename: "ModelLocationCatConnection",
    items: Array<{
      __typename: "LocationCat",
      id: string,
      name: string,
      prefix?: string | null,
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type GetLocationQueryVariables = {
  id: string,
};

export type GetLocationQuery = {
  getLocation?: {
    __typename: "Location",
    id: string,
    locationCatId: string,
    name: string,
    friendlyName: string,
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListLocationsQueryVariables = {
  filter?: ModelLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLocationsQuery = {
  listLocations?: {
    __typename: "ModelLocationConnection",
    items: Array<{
      __typename: "Location",
      id: string,
      locationCatId: string,
      name: string,
      friendlyName: string,
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type LocationByStatusCatQueryVariables = {
  active: ActiveStatus,
  locationCatIdName?: ModelLocationByStatusCatCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LocationByStatusCatQuery = {
  locationByStatusCat?: {
    __typename: "ModelLocationConnection",
    items: Array<{
      __typename: "Location",
      id: string,
      locationCatId: string,
      name: string,
      friendlyName: string,
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type GetCommentQueryVariables = {
  id: string,
};

export type GetCommentQuery = {
  getComment?: {
    __typename: "Comment",
    id: string,
    selector: string,
    active: ActiveStatus,
    comment: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCommentsQueryVariables = {
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommentsQuery = {
  listComments?: {
    __typename: "ModelCommentConnection",
    items: Array<{
      __typename: "Comment",
      id: string,
      selector: string,
      active: ActiveStatus,
      comment: string,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type CommentBySelectorQueryVariables = {
  active: ActiveStatus,
  selector?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CommentBySelectorQuery = {
  commentBySelector?: {
    __typename: "ModelCommentConnection",
    items: Array<{
      __typename: "Comment",
      id: string,
      selector: string,
      active: ActiveStatus,
      comment: string,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type GetSampleProtocolTemplateQueryVariables = {
  id: string,
};

export type GetSampleProtocolTemplateQuery = {
  getSampleProtocolTemplate?: {
    __typename: "SampleProtocolTemplate",
    id: string,
    partId: string,
    active: ActiveStatus,
    name: string,
    sponsorName?: string | null,
    studyName?: string | null,
    validationDate?: string | null,
    validateUserId?: string | null,
    validationDateSecond?: string | null,
    validateUserIdSecond?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSampleProtocolTemplatesQueryVariables = {
  filter?: ModelSampleProtocolTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSampleProtocolTemplatesQuery = {
  listSampleProtocolTemplates?: {
    __typename: "ModelSampleProtocolTemplateConnection",
    items: Array<{
      __typename: "SampleProtocolTemplate",
      id: string,
      partId: string,
      active: ActiveStatus,
      name: string,
      sponsorName?: string | null,
      studyName?: string | null,
      validationDate?: string | null,
      validateUserId?: string | null,
      validationDateSecond?: string | null,
      validateUserIdSecond?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type SampleProtocolTemplateByPartQueryVariables = {
  partId: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSampleProtocolTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SampleProtocolTemplateByPartQuery = {
  sampleProtocolTemplateByPart?: {
    __typename: "ModelSampleProtocolTemplateConnection",
    items: Array<{
      __typename: "SampleProtocolTemplate",
      id: string,
      partId: string,
      active: ActiveStatus,
      name: string,
      sponsorName?: string | null,
      studyName?: string | null,
      validationDate?: string | null,
      validateUserId?: string | null,
      validationDateSecond?: string | null,
      validateUserIdSecond?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type GetListingQueryVariables = {
  id: string,
};

export type GetListingQuery = {
  getListing?: {
    __typename: "Listing",
    id: string,
    sampleProtocolTemplateId: string,
    name: string,
    printTemplateId: string,
    printTemplate?: {
      __typename: "PrintTemplate",
      id: string,
      templatePath: string,
      name: string,
      printerId: string,
      printer: {
        __typename: "Printer",
        id: string,
        printerNetworkId: string,
        friendlyName: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      },
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListListingsQueryVariables = {
  filter?: ModelListingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListListingsQuery = {
  listListings?: {
    __typename: "ModelListingConnection",
    items: Array<{
      __typename: "Listing",
      id: string,
      sampleProtocolTemplateId: string,
      name: string,
      printTemplateId: string,
      printTemplate?: {
        __typename: "PrintTemplate",
        id: string,
        templatePath: string,
        name: string,
        printerId: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type ListingBySampleProtocolTemplateQueryVariables = {
  sampleProtocolTemplateId: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelListingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListingBySampleProtocolTemplateQuery = {
  listingBySampleProtocolTemplate?: {
    __typename: "ModelListingConnection",
    items: Array<{
      __typename: "Listing",
      id: string,
      sampleProtocolTemplateId: string,
      name: string,
      printTemplateId: string,
      printTemplate?: {
        __typename: "PrintTemplate",
        id: string,
        templatePath: string,
        name: string,
        printerId: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type GetSampleTemplateQueryVariables = {
  id: string,
};

export type GetSampleTemplateQuery = {
  getSampleTemplate?: {
    __typename: "SampleTemplate",
    id: string,
    listingId: string,
    theoreticalTime: string,
    theoreticalTimeEnd?: string | null,
    theoreticalTimeLabel?: string | null,
    period: string,
    matrixName?: string | null,
    index?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSampleTemplatesQueryVariables = {
  filter?: ModelSampleTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSampleTemplatesQuery = {
  listSampleTemplates?: {
    __typename: "ModelSampleTemplateConnection",
    items: Array<{
      __typename: "SampleTemplate",
      id: string,
      listingId: string,
      theoreticalTime: string,
      theoreticalTimeEnd?: string | null,
      theoreticalTimeLabel?: string | null,
      period: string,
      matrixName?: string | null,
      index?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type SampleTemplateByListingQueryVariables = {
  listingId: string,
  periodTheoreticalTime?: ModelSampleTemplateByListingCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSampleTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SampleTemplateByListingQuery = {
  sampleTemplateByListing?: {
    __typename: "ModelSampleTemplateConnection",
    items: Array<{
      __typename: "SampleTemplate",
      id: string,
      listingId: string,
      theoreticalTime: string,
      theoreticalTimeEnd?: string | null,
      theoreticalTimeLabel?: string | null,
      period: string,
      matrixName?: string | null,
      index?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type GetAlicotTemplateQueryVariables = {
  id: string,
};

export type GetAlicotTemplateQuery = {
  getAlicotTemplate?: {
    __typename: "AlicotTemplate",
    id: string,
    listingId: string,
    indexPrefix: string,
    indexFirst: number,
    number: number,
    matrixName?: string | null,
    printTemplateId: string,
    printTemplate?: {
      __typename: "PrintTemplate",
      id: string,
      templatePath: string,
      name: string,
      printerId: string,
      printer: {
        __typename: "Printer",
        id: string,
        printerNetworkId: string,
        friendlyName: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      },
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAlicotTemplatesQueryVariables = {
  filter?: ModelAlicotTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAlicotTemplatesQuery = {
  listAlicotTemplates?: {
    __typename: "ModelAlicotTemplateConnection",
    items: Array<{
      __typename: "AlicotTemplate",
      id: string,
      listingId: string,
      indexPrefix: string,
      indexFirst: number,
      number: number,
      matrixName?: string | null,
      printTemplateId: string,
      printTemplate?: {
        __typename: "PrintTemplate",
        id: string,
        templatePath: string,
        name: string,
        printerId: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type AlicotTemplateByListingQueryVariables = {
  listingId: string,
  indexPrefix?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAlicotTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AlicotTemplateByListingQuery = {
  alicotTemplateByListing?: {
    __typename: "ModelAlicotTemplateConnection",
    items: Array<{
      __typename: "AlicotTemplate",
      id: string,
      listingId: string,
      indexPrefix: string,
      indexFirst: number,
      number: number,
      matrixName?: string | null,
      printTemplateId: string,
      printTemplate?: {
        __typename: "PrintTemplate",
        id: string,
        templatePath: string,
        name: string,
        printerId: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type GetCohortQueryVariables = {
  id: string,
};

export type GetCohortQuery = {
  getCohort?: {
    __typename: "Cohort",
    id: string,
    partId: string,
    name: string,
    positionList?: Array<string | null> | null,
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCohortsQueryVariables = {
  filter?: ModelCohortFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCohortsQuery = {
  listCohorts?: {
    __typename: "ModelCohortConnection",
    items: Array<{
      __typename: "Cohort",
      id: string,
      partId: string,
      name: string,
      positionList?: Array<string | null> | null,
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type CohortByPartQueryVariables = {
  active: ActiveStatus,
  partIdName?: ModelCohortByPartCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCohortFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CohortByPartQuery = {
  cohortByPart?: {
    __typename: "ModelCohortConnection",
    items: Array<{
      __typename: "Cohort",
      id: string,
      partId: string,
      name: string,
      positionList?: Array<string | null> | null,
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type GetLabelQueryVariables = {
  id: string,
};

export type GetLabelQuery = {
  getLabel?: {
    __typename: "Label",
    id: string,
    cohortId: string,
    serial: string,
    theoreticalTime: string,
    theoreticalTimeLabel?: string | null,
    theoreticalTimeEnd?: string | null,
    period: string,
    matrixName?: string | null,
    index?: string | null,
    printTemplateId: string,
    printTemplate?: {
      __typename: "PrintTemplate",
      id: string,
      templatePath: string,
      name: string,
      printerId: string,
      printer: {
        __typename: "Printer",
        id: string,
        printerNetworkId: string,
        friendlyName: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      },
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    printed: PrintedStatus,
    active: ActiveStatus,
    studyName: string,
    sponsorName: string,
    inclusion: string,
    parentId?: string | null,
    firstFillEventTime?: string | null,
    firstFillEventUserAndPseudo?: string | null,
    firstCentriEventTime?: string | null,
    firstCentriEventUserAndPseudo?: string | null,
    firstFreezeEventTime?: string | null,
    firstFreezeEventUserAndPseudo?: string | null,
    firstOutEventTime?: string | null,
    firstOutEventUserAndPseudo?: string | null,
    lastEventLocationID: string,
    lastEventType: string,
    lastEventTime: string,
    lastEventAuthor: string,
    comments?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListLabelsQueryVariables = {
  filter?: ModelLabelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLabelsQuery = {
  listLabels?: {
    __typename: "ModelLabelConnection",
    items: Array<{
      __typename: "Label",
      id: string,
      cohortId: string,
      serial: string,
      theoreticalTime: string,
      theoreticalTimeLabel?: string | null,
      theoreticalTimeEnd?: string | null,
      period: string,
      matrixName?: string | null,
      index?: string | null,
      printTemplateId: string,
      printTemplate?: {
        __typename: "PrintTemplate",
        id: string,
        templatePath: string,
        name: string,
        printerId: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      } | null,
      printed: PrintedStatus,
      active: ActiveStatus,
      studyName: string,
      sponsorName: string,
      inclusion: string,
      parentId?: string | null,
      firstFillEventTime?: string | null,
      firstFillEventUserAndPseudo?: string | null,
      firstCentriEventTime?: string | null,
      firstCentriEventUserAndPseudo?: string | null,
      firstFreezeEventTime?: string | null,
      firstFreezeEventUserAndPseudo?: string | null,
      firstOutEventTime?: string | null,
      firstOutEventUserAndPseudo?: string | null,
      lastEventLocationID: string,
      lastEventType: string,
      lastEventTime: string,
      lastEventAuthor: string,
      comments?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type LabelBySerialQueryVariables = {
  serial: string,
  theoreticalTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLabelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LabelBySerialQuery = {
  labelBySerial?: {
    __typename: "ModelLabelConnection",
    items: Array<{
      __typename: "Label",
      id: string,
      cohortId: string,
      serial: string,
      theoreticalTime: string,
      theoreticalTimeLabel?: string | null,
      theoreticalTimeEnd?: string | null,
      period: string,
      matrixName?: string | null,
      index?: string | null,
      printTemplateId: string,
      printTemplate?: {
        __typename: "PrintTemplate",
        id: string,
        templatePath: string,
        name: string,
        printerId: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      } | null,
      printed: PrintedStatus,
      active: ActiveStatus,
      studyName: string,
      sponsorName: string,
      inclusion: string,
      parentId?: string | null,
      firstFillEventTime?: string | null,
      firstFillEventUserAndPseudo?: string | null,
      firstCentriEventTime?: string | null,
      firstCentriEventUserAndPseudo?: string | null,
      firstFreezeEventTime?: string | null,
      firstFreezeEventUserAndPseudo?: string | null,
      firstOutEventTime?: string | null,
      firstOutEventUserAndPseudo?: string | null,
      lastEventLocationID: string,
      lastEventType: string,
      lastEventTime: string,
      lastEventAuthor: string,
      comments?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type LabelByStatusCohortQueryVariables = {
  active: ActiveStatus,
  cohortIdPeriodTheoreticalTimeInclusionIndex?: ModelLabelByStatusCohortCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLabelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LabelByStatusCohortQuery = {
  labelByStatusCohort?: {
    __typename: "ModelLabelConnection",
    items: Array<{
      __typename: "Label",
      id: string,
      cohortId: string,
      serial: string,
      theoreticalTime: string,
      theoreticalTimeLabel?: string | null,
      theoreticalTimeEnd?: string | null,
      period: string,
      matrixName?: string | null,
      index?: string | null,
      printTemplateId: string,
      printTemplate?: {
        __typename: "PrintTemplate",
        id: string,
        templatePath: string,
        name: string,
        printerId: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      } | null,
      printed: PrintedStatus,
      active: ActiveStatus,
      studyName: string,
      sponsorName: string,
      inclusion: string,
      parentId?: string | null,
      firstFillEventTime?: string | null,
      firstFillEventUserAndPseudo?: string | null,
      firstCentriEventTime?: string | null,
      firstCentriEventUserAndPseudo?: string | null,
      firstFreezeEventTime?: string | null,
      firstFreezeEventUserAndPseudo?: string | null,
      firstOutEventTime?: string | null,
      firstOutEventUserAndPseudo?: string | null,
      lastEventLocationID: string,
      lastEventType: string,
      lastEventTime: string,
      lastEventAuthor: string,
      comments?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type LabelByStatusInclusionQueryVariables = {
  active: ActiveStatus,
  cohortIdInclusionPeriodTheoreticalTimeIndex?: ModelLabelByStatusInclusionCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLabelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LabelByStatusInclusionQuery = {
  labelByStatusInclusion?: {
    __typename: "ModelLabelConnection",
    items: Array<{
      __typename: "Label",
      id: string,
      cohortId: string,
      serial: string,
      theoreticalTime: string,
      theoreticalTimeLabel?: string | null,
      theoreticalTimeEnd?: string | null,
      period: string,
      matrixName?: string | null,
      index?: string | null,
      printTemplateId: string,
      printTemplate?: {
        __typename: "PrintTemplate",
        id: string,
        templatePath: string,
        name: string,
        printerId: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      } | null,
      printed: PrintedStatus,
      active: ActiveStatus,
      studyName: string,
      sponsorName: string,
      inclusion: string,
      parentId?: string | null,
      firstFillEventTime?: string | null,
      firstFillEventUserAndPseudo?: string | null,
      firstCentriEventTime?: string | null,
      firstCentriEventUserAndPseudo?: string | null,
      firstFreezeEventTime?: string | null,
      firstFreezeEventUserAndPseudo?: string | null,
      firstOutEventTime?: string | null,
      firstOutEventUserAndPseudo?: string | null,
      lastEventLocationID: string,
      lastEventType: string,
      lastEventTime: string,
      lastEventAuthor: string,
      comments?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type LabelByStatusPrintedQueryVariables = {
  active: ActiveStatus,
  cohortIdPrintedInclusionPeriodTheoreticalTimeIndex?: ModelLabelByStatusPrintedCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLabelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LabelByStatusPrintedQuery = {
  labelByStatusPrinted?: {
    __typename: "ModelLabelConnection",
    items: Array<{
      __typename: "Label",
      id: string,
      cohortId: string,
      serial: string,
      theoreticalTime: string,
      theoreticalTimeLabel?: string | null,
      theoreticalTimeEnd?: string | null,
      period: string,
      matrixName?: string | null,
      index?: string | null,
      printTemplateId: string,
      printTemplate?: {
        __typename: "PrintTemplate",
        id: string,
        templatePath: string,
        name: string,
        printerId: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      } | null,
      printed: PrintedStatus,
      active: ActiveStatus,
      studyName: string,
      sponsorName: string,
      inclusion: string,
      parentId?: string | null,
      firstFillEventTime?: string | null,
      firstFillEventUserAndPseudo?: string | null,
      firstCentriEventTime?: string | null,
      firstCentriEventUserAndPseudo?: string | null,
      firstFreezeEventTime?: string | null,
      firstFreezeEventUserAndPseudo?: string | null,
      firstOutEventTime?: string | null,
      firstOutEventUserAndPseudo?: string | null,
      lastEventLocationID: string,
      lastEventType: string,
      lastEventTime: string,
      lastEventAuthor: string,
      comments?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type LabelByLocationQueryVariables = {
  active: ActiveStatus,
  lastEventLocationIDInclusionPeriodTheoreticalTime?: ModelLabelByLocationCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLabelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LabelByLocationQuery = {
  labelByLocation?: {
    __typename: "ModelLabelConnection",
    items: Array<{
      __typename: "Label",
      id: string,
      cohortId: string,
      serial: string,
      theoreticalTime: string,
      theoreticalTimeLabel?: string | null,
      theoreticalTimeEnd?: string | null,
      period: string,
      matrixName?: string | null,
      index?: string | null,
      printTemplateId: string,
      printTemplate?: {
        __typename: "PrintTemplate",
        id: string,
        templatePath: string,
        name: string,
        printerId: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      } | null,
      printed: PrintedStatus,
      active: ActiveStatus,
      studyName: string,
      sponsorName: string,
      inclusion: string,
      parentId?: string | null,
      firstFillEventTime?: string | null,
      firstFillEventUserAndPseudo?: string | null,
      firstCentriEventTime?: string | null,
      firstCentriEventUserAndPseudo?: string | null,
      firstFreezeEventTime?: string | null,
      firstFreezeEventUserAndPseudo?: string | null,
      firstOutEventTime?: string | null,
      firstOutEventUserAndPseudo?: string | null,
      lastEventLocationID: string,
      lastEventType: string,
      lastEventTime: string,
      lastEventAuthor: string,
      comments?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type LabelByLastEventQueryVariables = {
  active: ActiveStatus,
  lastEventTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLabelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LabelByLastEventQuery = {
  labelByLastEvent?: {
    __typename: "ModelLabelConnection",
    items: Array<{
      __typename: "Label",
      id: string,
      cohortId: string,
      serial: string,
      theoreticalTime: string,
      theoreticalTimeLabel?: string | null,
      theoreticalTimeEnd?: string | null,
      period: string,
      matrixName?: string | null,
      index?: string | null,
      printTemplateId: string,
      printTemplate?: {
        __typename: "PrintTemplate",
        id: string,
        templatePath: string,
        name: string,
        printerId: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      } | null,
      printed: PrintedStatus,
      active: ActiveStatus,
      studyName: string,
      sponsorName: string,
      inclusion: string,
      parentId?: string | null,
      firstFillEventTime?: string | null,
      firstFillEventUserAndPseudo?: string | null,
      firstCentriEventTime?: string | null,
      firstCentriEventUserAndPseudo?: string | null,
      firstFreezeEventTime?: string | null,
      firstFreezeEventUserAndPseudo?: string | null,
      firstOutEventTime?: string | null,
      firstOutEventUserAndPseudo?: string | null,
      lastEventLocationID: string,
      lastEventType: string,
      lastEventTime: string,
      lastEventAuthor: string,
      comments?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type LabelByParentQueryVariables = {
  parentId: string,
  index?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLabelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LabelByParentQuery = {
  labelByParent?: {
    __typename: "ModelLabelConnection",
    items: Array<{
      __typename: "Label",
      id: string,
      cohortId: string,
      serial: string,
      theoreticalTime: string,
      theoreticalTimeLabel?: string | null,
      theoreticalTimeEnd?: string | null,
      period: string,
      matrixName?: string | null,
      index?: string | null,
      printTemplateId: string,
      printTemplate?: {
        __typename: "PrintTemplate",
        id: string,
        templatePath: string,
        name: string,
        printerId: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      } | null,
      printed: PrintedStatus,
      active: ActiveStatus,
      studyName: string,
      sponsorName: string,
      inclusion: string,
      parentId?: string | null,
      firstFillEventTime?: string | null,
      firstFillEventUserAndPseudo?: string | null,
      firstCentriEventTime?: string | null,
      firstCentriEventUserAndPseudo?: string | null,
      firstFreezeEventTime?: string | null,
      firstFreezeEventUserAndPseudo?: string | null,
      firstOutEventTime?: string | null,
      firstOutEventUserAndPseudo?: string | null,
      lastEventLocationID: string,
      lastEventType: string,
      lastEventTime: string,
      lastEventAuthor: string,
      comments?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type GetEventQueryVariables = {
  id: string,
};

export type GetEventQuery = {
  getEvent?: {
    __typename: "Event",
    id: string,
    labelId: string,
    owner: string,
    pseudo: string,
    usedDate: string,
    locationId?: string | null,
    type: string,
    description?: string | null,
    labelData?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListEventsQueryVariables = {
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventsQuery = {
  listEvents?: {
    __typename: "ModelEventConnection",
    items: Array<{
      __typename: "Event",
      id: string,
      labelId: string,
      owner: string,
      pseudo: string,
      usedDate: string,
      locationId?: string | null,
      type: string,
      description?: string | null,
      labelData?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type EventByLabelQueryVariables = {
  labelId: string,
  usedDate?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventByLabelQuery = {
  eventByLabel?: {
    __typename: "ModelEventConnection",
    items: Array<{
      __typename: "Event",
      id: string,
      labelId: string,
      owner: string,
      pseudo: string,
      usedDate: string,
      locationId?: string | null,
      type: string,
      description?: string | null,
      labelData?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type EventByOwnerQueryVariables = {
  owner: string,
  usedDate?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventByOwnerQuery = {
  eventByOwner?: {
    __typename: "ModelEventConnection",
    items: Array<{
      __typename: "Event",
      id: string,
      labelId: string,
      owner: string,
      pseudo: string,
      usedDate: string,
      locationId?: string | null,
      type: string,
      description?: string | null,
      labelData?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type GetPrintServerQueryVariables = {
  id: string,
};

export type GetPrintServerQuery = {
  getPrintServer?: {
    __typename: "PrintServer",
    id: string,
    endpoint: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPrintServersQueryVariables = {
  filter?: ModelPrintServerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPrintServersQuery = {
  listPrintServers?: {
    __typename: "ModelPrintServerConnection",
    items: Array<{
      __typename: "PrintServer",
      id: string,
      endpoint: string,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type OnCreatePrintTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionPrintTemplateFilterInput | null,
};

export type OnCreatePrintTemplateSubscription = {
  onCreatePrintTemplate?: {
    __typename: "PrintTemplate",
    id: string,
    templatePath: string,
    name: string,
    printerId: string,
    printer: {
      __typename: "Printer",
      id: string,
      printerNetworkId: string,
      friendlyName: string,
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    },
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePrintTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionPrintTemplateFilterInput | null,
};

export type OnUpdatePrintTemplateSubscription = {
  onUpdatePrintTemplate?: {
    __typename: "PrintTemplate",
    id: string,
    templatePath: string,
    name: string,
    printerId: string,
    printer: {
      __typename: "Printer",
      id: string,
      printerNetworkId: string,
      friendlyName: string,
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    },
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePrintTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionPrintTemplateFilterInput | null,
};

export type OnDeletePrintTemplateSubscription = {
  onDeletePrintTemplate?: {
    __typename: "PrintTemplate",
    id: string,
    templatePath: string,
    name: string,
    printerId: string,
    printer: {
      __typename: "Printer",
      id: string,
      printerNetworkId: string,
      friendlyName: string,
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    },
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePrinterSubscriptionVariables = {
  filter?: ModelSubscriptionPrinterFilterInput | null,
};

export type OnCreatePrinterSubscription = {
  onCreatePrinter?: {
    __typename: "Printer",
    id: string,
    printerNetworkId: string,
    friendlyName: string,
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePrinterSubscriptionVariables = {
  filter?: ModelSubscriptionPrinterFilterInput | null,
};

export type OnUpdatePrinterSubscription = {
  onUpdatePrinter?: {
    __typename: "Printer",
    id: string,
    printerNetworkId: string,
    friendlyName: string,
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePrinterSubscriptionVariables = {
  filter?: ModelSubscriptionPrinterFilterInput | null,
};

export type OnDeletePrinterSubscription = {
  onDeletePrinter?: {
    __typename: "Printer",
    id: string,
    printerNetworkId: string,
    friendlyName: string,
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateLocationCatSubscriptionVariables = {
  filter?: ModelSubscriptionLocationCatFilterInput | null,
};

export type OnCreateLocationCatSubscription = {
  onCreateLocationCat?: {
    __typename: "LocationCat",
    id: string,
    name: string,
    prefix?: string | null,
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateLocationCatSubscriptionVariables = {
  filter?: ModelSubscriptionLocationCatFilterInput | null,
};

export type OnUpdateLocationCatSubscription = {
  onUpdateLocationCat?: {
    __typename: "LocationCat",
    id: string,
    name: string,
    prefix?: string | null,
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteLocationCatSubscriptionVariables = {
  filter?: ModelSubscriptionLocationCatFilterInput | null,
};

export type OnDeleteLocationCatSubscription = {
  onDeleteLocationCat?: {
    __typename: "LocationCat",
    id: string,
    name: string,
    prefix?: string | null,
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateLocationSubscriptionVariables = {
  filter?: ModelSubscriptionLocationFilterInput | null,
};

export type OnCreateLocationSubscription = {
  onCreateLocation?: {
    __typename: "Location",
    id: string,
    locationCatId: string,
    name: string,
    friendlyName: string,
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateLocationSubscriptionVariables = {
  filter?: ModelSubscriptionLocationFilterInput | null,
};

export type OnUpdateLocationSubscription = {
  onUpdateLocation?: {
    __typename: "Location",
    id: string,
    locationCatId: string,
    name: string,
    friendlyName: string,
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteLocationSubscriptionVariables = {
  filter?: ModelSubscriptionLocationFilterInput | null,
};

export type OnDeleteLocationSubscription = {
  onDeleteLocation?: {
    __typename: "Location",
    id: string,
    locationCatId: string,
    name: string,
    friendlyName: string,
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCommentSubscriptionVariables = {
  filter?: ModelSubscriptionCommentFilterInput | null,
};

export type OnCreateCommentSubscription = {
  onCreateComment?: {
    __typename: "Comment",
    id: string,
    selector: string,
    active: ActiveStatus,
    comment: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCommentSubscriptionVariables = {
  filter?: ModelSubscriptionCommentFilterInput | null,
};

export type OnUpdateCommentSubscription = {
  onUpdateComment?: {
    __typename: "Comment",
    id: string,
    selector: string,
    active: ActiveStatus,
    comment: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCommentSubscriptionVariables = {
  filter?: ModelSubscriptionCommentFilterInput | null,
};

export type OnDeleteCommentSubscription = {
  onDeleteComment?: {
    __typename: "Comment",
    id: string,
    selector: string,
    active: ActiveStatus,
    comment: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSampleProtocolTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionSampleProtocolTemplateFilterInput | null,
};

export type OnCreateSampleProtocolTemplateSubscription = {
  onCreateSampleProtocolTemplate?: {
    __typename: "SampleProtocolTemplate",
    id: string,
    partId: string,
    active: ActiveStatus,
    name: string,
    sponsorName?: string | null,
    studyName?: string | null,
    validationDate?: string | null,
    validateUserId?: string | null,
    validationDateSecond?: string | null,
    validateUserIdSecond?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSampleProtocolTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionSampleProtocolTemplateFilterInput | null,
};

export type OnUpdateSampleProtocolTemplateSubscription = {
  onUpdateSampleProtocolTemplate?: {
    __typename: "SampleProtocolTemplate",
    id: string,
    partId: string,
    active: ActiveStatus,
    name: string,
    sponsorName?: string | null,
    studyName?: string | null,
    validationDate?: string | null,
    validateUserId?: string | null,
    validationDateSecond?: string | null,
    validateUserIdSecond?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSampleProtocolTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionSampleProtocolTemplateFilterInput | null,
};

export type OnDeleteSampleProtocolTemplateSubscription = {
  onDeleteSampleProtocolTemplate?: {
    __typename: "SampleProtocolTemplate",
    id: string,
    partId: string,
    active: ActiveStatus,
    name: string,
    sponsorName?: string | null,
    studyName?: string | null,
    validationDate?: string | null,
    validateUserId?: string | null,
    validationDateSecond?: string | null,
    validateUserIdSecond?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateListingSubscriptionVariables = {
  filter?: ModelSubscriptionListingFilterInput | null,
};

export type OnCreateListingSubscription = {
  onCreateListing?: {
    __typename: "Listing",
    id: string,
    sampleProtocolTemplateId: string,
    name: string,
    printTemplateId: string,
    printTemplate?: {
      __typename: "PrintTemplate",
      id: string,
      templatePath: string,
      name: string,
      printerId: string,
      printer: {
        __typename: "Printer",
        id: string,
        printerNetworkId: string,
        friendlyName: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      },
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateListingSubscriptionVariables = {
  filter?: ModelSubscriptionListingFilterInput | null,
};

export type OnUpdateListingSubscription = {
  onUpdateListing?: {
    __typename: "Listing",
    id: string,
    sampleProtocolTemplateId: string,
    name: string,
    printTemplateId: string,
    printTemplate?: {
      __typename: "PrintTemplate",
      id: string,
      templatePath: string,
      name: string,
      printerId: string,
      printer: {
        __typename: "Printer",
        id: string,
        printerNetworkId: string,
        friendlyName: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      },
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteListingSubscriptionVariables = {
  filter?: ModelSubscriptionListingFilterInput | null,
};

export type OnDeleteListingSubscription = {
  onDeleteListing?: {
    __typename: "Listing",
    id: string,
    sampleProtocolTemplateId: string,
    name: string,
    printTemplateId: string,
    printTemplate?: {
      __typename: "PrintTemplate",
      id: string,
      templatePath: string,
      name: string,
      printerId: string,
      printer: {
        __typename: "Printer",
        id: string,
        printerNetworkId: string,
        friendlyName: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      },
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSampleTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionSampleTemplateFilterInput | null,
};

export type OnCreateSampleTemplateSubscription = {
  onCreateSampleTemplate?: {
    __typename: "SampleTemplate",
    id: string,
    listingId: string,
    theoreticalTime: string,
    theoreticalTimeEnd?: string | null,
    theoreticalTimeLabel?: string | null,
    period: string,
    matrixName?: string | null,
    index?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSampleTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionSampleTemplateFilterInput | null,
};

export type OnUpdateSampleTemplateSubscription = {
  onUpdateSampleTemplate?: {
    __typename: "SampleTemplate",
    id: string,
    listingId: string,
    theoreticalTime: string,
    theoreticalTimeEnd?: string | null,
    theoreticalTimeLabel?: string | null,
    period: string,
    matrixName?: string | null,
    index?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSampleTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionSampleTemplateFilterInput | null,
};

export type OnDeleteSampleTemplateSubscription = {
  onDeleteSampleTemplate?: {
    __typename: "SampleTemplate",
    id: string,
    listingId: string,
    theoreticalTime: string,
    theoreticalTimeEnd?: string | null,
    theoreticalTimeLabel?: string | null,
    period: string,
    matrixName?: string | null,
    index?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAlicotTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionAlicotTemplateFilterInput | null,
};

export type OnCreateAlicotTemplateSubscription = {
  onCreateAlicotTemplate?: {
    __typename: "AlicotTemplate",
    id: string,
    listingId: string,
    indexPrefix: string,
    indexFirst: number,
    number: number,
    matrixName?: string | null,
    printTemplateId: string,
    printTemplate?: {
      __typename: "PrintTemplate",
      id: string,
      templatePath: string,
      name: string,
      printerId: string,
      printer: {
        __typename: "Printer",
        id: string,
        printerNetworkId: string,
        friendlyName: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      },
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAlicotTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionAlicotTemplateFilterInput | null,
};

export type OnUpdateAlicotTemplateSubscription = {
  onUpdateAlicotTemplate?: {
    __typename: "AlicotTemplate",
    id: string,
    listingId: string,
    indexPrefix: string,
    indexFirst: number,
    number: number,
    matrixName?: string | null,
    printTemplateId: string,
    printTemplate?: {
      __typename: "PrintTemplate",
      id: string,
      templatePath: string,
      name: string,
      printerId: string,
      printer: {
        __typename: "Printer",
        id: string,
        printerNetworkId: string,
        friendlyName: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      },
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAlicotTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionAlicotTemplateFilterInput | null,
};

export type OnDeleteAlicotTemplateSubscription = {
  onDeleteAlicotTemplate?: {
    __typename: "AlicotTemplate",
    id: string,
    listingId: string,
    indexPrefix: string,
    indexFirst: number,
    number: number,
    matrixName?: string | null,
    printTemplateId: string,
    printTemplate?: {
      __typename: "PrintTemplate",
      id: string,
      templatePath: string,
      name: string,
      printerId: string,
      printer: {
        __typename: "Printer",
        id: string,
        printerNetworkId: string,
        friendlyName: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      },
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCohortSubscriptionVariables = {
  filter?: ModelSubscriptionCohortFilterInput | null,
};

export type OnCreateCohortSubscription = {
  onCreateCohort?: {
    __typename: "Cohort",
    id: string,
    partId: string,
    name: string,
    positionList?: Array<string | null> | null,
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCohortSubscriptionVariables = {
  filter?: ModelSubscriptionCohortFilterInput | null,
};

export type OnUpdateCohortSubscription = {
  onUpdateCohort?: {
    __typename: "Cohort",
    id: string,
    partId: string,
    name: string,
    positionList?: Array<string | null> | null,
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCohortSubscriptionVariables = {
  filter?: ModelSubscriptionCohortFilterInput | null,
};

export type OnDeleteCohortSubscription = {
  onDeleteCohort?: {
    __typename: "Cohort",
    id: string,
    partId: string,
    name: string,
    positionList?: Array<string | null> | null,
    active: ActiveStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateLabelSubscriptionVariables = {
  filter?: ModelSubscriptionLabelFilterInput | null,
};

export type OnCreateLabelSubscription = {
  onCreateLabel?: {
    __typename: "Label",
    id: string,
    cohortId: string,
    serial: string,
    theoreticalTime: string,
    theoreticalTimeLabel?: string | null,
    theoreticalTimeEnd?: string | null,
    period: string,
    matrixName?: string | null,
    index?: string | null,
    printTemplateId: string,
    printTemplate?: {
      __typename: "PrintTemplate",
      id: string,
      templatePath: string,
      name: string,
      printerId: string,
      printer: {
        __typename: "Printer",
        id: string,
        printerNetworkId: string,
        friendlyName: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      },
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    printed: PrintedStatus,
    active: ActiveStatus,
    studyName: string,
    sponsorName: string,
    inclusion: string,
    parentId?: string | null,
    firstFillEventTime?: string | null,
    firstFillEventUserAndPseudo?: string | null,
    firstCentriEventTime?: string | null,
    firstCentriEventUserAndPseudo?: string | null,
    firstFreezeEventTime?: string | null,
    firstFreezeEventUserAndPseudo?: string | null,
    firstOutEventTime?: string | null,
    firstOutEventUserAndPseudo?: string | null,
    lastEventLocationID: string,
    lastEventType: string,
    lastEventTime: string,
    lastEventAuthor: string,
    comments?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateLabelSubscriptionVariables = {
  filter?: ModelSubscriptionLabelFilterInput | null,
};

export type OnUpdateLabelSubscription = {
  onUpdateLabel?: {
    __typename: "Label",
    id: string,
    cohortId: string,
    serial: string,
    theoreticalTime: string,
    theoreticalTimeLabel?: string | null,
    theoreticalTimeEnd?: string | null,
    period: string,
    matrixName?: string | null,
    index?: string | null,
    printTemplateId: string,
    printTemplate?: {
      __typename: "PrintTemplate",
      id: string,
      templatePath: string,
      name: string,
      printerId: string,
      printer: {
        __typename: "Printer",
        id: string,
        printerNetworkId: string,
        friendlyName: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      },
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    printed: PrintedStatus,
    active: ActiveStatus,
    studyName: string,
    sponsorName: string,
    inclusion: string,
    parentId?: string | null,
    firstFillEventTime?: string | null,
    firstFillEventUserAndPseudo?: string | null,
    firstCentriEventTime?: string | null,
    firstCentriEventUserAndPseudo?: string | null,
    firstFreezeEventTime?: string | null,
    firstFreezeEventUserAndPseudo?: string | null,
    firstOutEventTime?: string | null,
    firstOutEventUserAndPseudo?: string | null,
    lastEventLocationID: string,
    lastEventType: string,
    lastEventTime: string,
    lastEventAuthor: string,
    comments?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteLabelSubscriptionVariables = {
  filter?: ModelSubscriptionLabelFilterInput | null,
};

export type OnDeleteLabelSubscription = {
  onDeleteLabel?: {
    __typename: "Label",
    id: string,
    cohortId: string,
    serial: string,
    theoreticalTime: string,
    theoreticalTimeLabel?: string | null,
    theoreticalTimeEnd?: string | null,
    period: string,
    matrixName?: string | null,
    index?: string | null,
    printTemplateId: string,
    printTemplate?: {
      __typename: "PrintTemplate",
      id: string,
      templatePath: string,
      name: string,
      printerId: string,
      printer: {
        __typename: "Printer",
        id: string,
        printerNetworkId: string,
        friendlyName: string,
        active: ActiveStatus,
        createdAt: string,
        updatedAt: string,
      },
      active: ActiveStatus,
      createdAt: string,
      updatedAt: string,
    } | null,
    printed: PrintedStatus,
    active: ActiveStatus,
    studyName: string,
    sponsorName: string,
    inclusion: string,
    parentId?: string | null,
    firstFillEventTime?: string | null,
    firstFillEventUserAndPseudo?: string | null,
    firstCentriEventTime?: string | null,
    firstCentriEventUserAndPseudo?: string | null,
    firstFreezeEventTime?: string | null,
    firstFreezeEventUserAndPseudo?: string | null,
    firstOutEventTime?: string | null,
    firstOutEventUserAndPseudo?: string | null,
    lastEventLocationID: string,
    lastEventType: string,
    lastEventTime: string,
    lastEventAuthor: string,
    comments?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateEventSubscriptionVariables = {
  filter?: ModelSubscriptionEventFilterInput | null,
};

export type OnCreateEventSubscription = {
  onCreateEvent?: {
    __typename: "Event",
    id: string,
    labelId: string,
    owner: string,
    pseudo: string,
    usedDate: string,
    locationId?: string | null,
    type: string,
    description?: string | null,
    labelData?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateEventSubscriptionVariables = {
  filter?: ModelSubscriptionEventFilterInput | null,
};

export type OnUpdateEventSubscription = {
  onUpdateEvent?: {
    __typename: "Event",
    id: string,
    labelId: string,
    owner: string,
    pseudo: string,
    usedDate: string,
    locationId?: string | null,
    type: string,
    description?: string | null,
    labelData?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteEventSubscriptionVariables = {
  filter?: ModelSubscriptionEventFilterInput | null,
};

export type OnDeleteEventSubscription = {
  onDeleteEvent?: {
    __typename: "Event",
    id: string,
    labelId: string,
    owner: string,
    pseudo: string,
    usedDate: string,
    locationId?: string | null,
    type: string,
    description?: string | null,
    labelData?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePrintServerSubscriptionVariables = {
  filter?: ModelSubscriptionPrintServerFilterInput | null,
};

export type OnCreatePrintServerSubscription = {
  onCreatePrintServer?: {
    __typename: "PrintServer",
    id: string,
    endpoint: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePrintServerSubscriptionVariables = {
  filter?: ModelSubscriptionPrintServerFilterInput | null,
};

export type OnUpdatePrintServerSubscription = {
  onUpdatePrintServer?: {
    __typename: "PrintServer",
    id: string,
    endpoint: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePrintServerSubscriptionVariables = {
  filter?: ModelSubscriptionPrintServerFilterInput | null,
};

export type OnDeletePrintServerSubscription = {
  onDeletePrintServer?: {
    __typename: "PrintServer",
    id: string,
    endpoint: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type LabelItemFragment = {
  __typename: "Label",
  id: string,
  cohortId: string,
  serial: string,
  theoreticalTime: string,
  theoreticalTimeEnd?: string | null,
  theoreticalTimeLabel?: string | null,
  period: string,
  matrixName?: string | null,
  index?: string | null,
  printTemplateId: string,
  printTemplate?: {
    __typename: "PrintTemplate",
    id: string,
    templatePath: string,
    name: string,
    printerId: string,
    printer: {
      __typename: "Printer",
      printerNetworkId: string,
    },
  } | null,
  printed: PrintedStatus,
  active: ActiveStatus,
  studyName: string,
  sponsorName: string,
  inclusion: string,
  parentId?: string | null,
  firstFillEventTime?: string | null,
  firstFillEventUserAndPseudo?: string | null,
  firstCentriEventTime?: string | null,
  firstCentriEventUserAndPseudo?: string | null,
  firstFreezeEventTime?: string | null,
  firstFreezeEventUserAndPseudo?: string | null,
  firstOutEventTime?: string | null,
  firstOutEventUserAndPseudo?: string | null,
  lastEventLocationID: string,
  lastEventType: string,
  lastEventTime: string,
  lastEventAuthor: string,
};
