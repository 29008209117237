import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { useState, useEffect } from "react";
import {
  ActiveStatus,
  Comment,
  CommentBySelectorQueryVariables,
  ModelSortDirection
} from "../../../utils/BarcodeClient/API";
import { commentBySelector } from "../../../utils/BarcodeClient/graphql/queries";
import {
  onCreateComment,
  onUpdateComment,
  onDeleteComment
} from "../../../utils/BarcodeClient/graphql/subscriptions";
import useAmplifyList from "../../../utils/BarcodeClient/hooks/useAmplifyList";
import { BarcodeClientConfig } from "../../../utils/BarcodeClient/initClient";
const FREE_TEXT_ID = "#free";

const CommentDropDown: React.FC<{
  callBack: (comment: string) => void;
}> = ({ callBack }) => {
  const [idSelector, setIdSelector] = useState<string>();

  const { list: commentList } = useAmplifyList<
    Comment,
    CommentBySelectorQueryVariables
  >({
    list: {
      query: commentBySelector,
      key: "commentBySelector",
      variables: {
        limit: 150,
        active: ActiveStatus.active,
        sortDirection: ModelSortDirection.ASC
      },
      client: BarcodeClientConfig,
    },
    create: {
      subscription: onCreateComment,
      key: "onCreateComment",
      client: BarcodeClientConfig,
    },
    update: {
      subscription: onUpdateComment,
      key: "onUpdateComment",
      client: BarcodeClientConfig,
    },
    delete: {
      subscription: onDeleteComment,
      key: "onDeleteComment",
      client: BarcodeClientConfig,
    }
  });

  useEffect(() => {
    if (idSelector !== undefined) {
      const index = commentList.findIndex(
        (comment) => comment.id === idSelector
      );
      callBack(commentList[index]?.comment || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSelector]);

  return (
    <div
      style={{
        flexGrow: "1"
      }}
    >
      <FormControl style={{ width: "80%" }}>
        <InputLabel id="select-event-comment">Comment</InputLabel>
        <Select
          labelId="select-event-comment-label"
          id="select-event-comment"
          value={idSelector || FREE_TEXT_ID}
          label="Comment"
          onChange={(e) => {
            setIdSelector((e.target as HTMLInputElement).value);
          }}
        >
          <MenuItem key={FREE_TEXT_ID} value={FREE_TEXT_ID}>
            Free text
          </MenuItem>
          {commentList.map((comment) => (
            <MenuItem key={comment.id} value={comment.id}>
              {comment.selector.toLowerCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default CommentDropDown;
