import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import Utils from '../utils';
import * as constants from '../constants';
import { getCurrentUsername } from './user';
import uuid from 'uuid/v4';

export async function deleteProtocolComment(protocolCommentId) {
    let returnMessage = {
        code: "201",
        messages: [],
    }

    try {
        await API.graphql(graphqlOperation(mutations.deleteProtocolComment, { input: { id: protocolCommentId } }));
    } catch (e) {
        returnMessage.code = "400";
        returnMessage.messages = e.errors;
        return returnMessage
    }
    let newMessages = returnMessage.messages;
    newMessages.push("Deleted the protocol comment")
    returnMessage.messages = newMessages;
    return returnMessage
}

export async function listProtocolCommentByGroup(groupId, token = null) {
    try {
        const rawProtocolCommentByGroup = await API.graphql(graphqlOperation(queries.commentsByGroup,
            {
                groupId: groupId,
                sortDirection: constants.ORDER_DESC,
                limit: constants.LIMIT / 100,
                nextToken: token
            }));
        let protocolCommentByGroup = Utils.checkNested(rawProtocolCommentByGroup, 'data', 'commentsByGroup')
        return protocolCommentByGroup
    } catch (e) {
        console.log(e)
        return null
    }
}

export async function createProtocolComment(groupId, comment) {
    const userId = await getCurrentUsername();
    const input = {
        id: uuid(),
        groupId: groupId,
        date: new Date().toISOString(),
        user: userId,
        comment: comment,
    }

    let result = await API.graphql(graphqlOperation(mutations.createProtocolComment, { input: input }));
    return result
}

export async function crossProtocolComment(commentItem) {
    const input = {
        id: commentItem.id,
        comment: constants.CROSS_KEYWORD + commentItem.comment,
    }

    let result = await API.graphql(graphqlOperation(mutations.updateProtocolComment, { input: input }));
    return result
}
