import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { ReactNode } from "react";

const ConfirmationDialog: React.FC<{
  message: ReactNode;
  loading: boolean;
  isOpen: boolean;
  cancel: () => void;
  confirm: () => void;
}> = ({ message, loading, isOpen, cancel, confirm }) => (
  <Dialog open={isOpen}>
    <DialogTitle>{message}</DialogTitle>
    <DialogActions style={{ display: "flex" }}>
      <Button autoFocus onClick={cancel} disabled={loading}>
        Cancel
      </Button>
      <div style={{ flexGrow: "1" }} />
      <CircularProgress style={{ display: loading ? "block" : "none" }} />
      <div style={{ flexGrow: "1" }} />
      <Button onClick={confirm} disabled={loading} color="primary">
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmationDialog;
