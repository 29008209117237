import React, { PureComponent } from 'react';

import * as participantApi from '../../utils/api/participant';
import * as groupApi from '../../utils/api/group';
import * as partApi from '../../utils/api/part';
import * as studyApi from '../../utils/api/study';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import Utils from '../../utils/utils';
import moment from 'moment';
import { Box, Fab, LinearProgress } from '@material-ui/core';

import PrintIcon from '@material-ui/icons/Print'
import ListPresenceReport from '../../components/listPresenceReport/ListPresenceReport';

class ReportPresence extends PureComponent {
  state = {
    listUser: [],
    listAssignableTasks: [{ userName: "All" }],
    dateFilterBeginning: new Date().toISOString(),
    dateFilterEnd: moment(new Date()).add(12, 'hours').toISOString(),
    isNextToken: false,
    loading: false,
    loaded: 0,

    loadedStaff: [],
    loadedProtocolTask: [],
    loadedTaskType: [],
    loadedPosition: [],
    loadedParticipant: [],
    loadedGroup: [],
    loadedPart: [],
    loadedStudy: [],
  };

  componentDidMount() {
    this.queryCompleteList(this.state.dateFilterBeginning, this.state.dateFilterEnd);
  }

  async queryCompleteList(dateBeginning, dateEnd) {
    this.setState({ loading: true, loaded: 0 });
    const filterBeginning = new Date(dateBeginning).toISOString();
    const filterEnd = new Date(dateEnd).toISOString();

    let participantListQuery = await participantApi.listParticipantByOutDate(filterBeginning);

    const isNextToken = Utils.checkNested(participantListQuery, 'nextToken') ? true : false;
    let participantList = Utils.checkNested(participantListQuery, 'items') ? participantListQuery.items : [];
    console.log(participantList)

    // Improve the list
    let upgradedList = [];
    let remainingList = [...participantList];
    let totalElements = 0;
    for (let i = 0; i < participantList.length; i++) {
      if (remainingList.length === 0) break
      // Batch 100 tasks together
      const batchList = remainingList.slice(0, 100);
      remainingList.splice(0, 100)
      totalElements += batchList.length;
      this.setState({ loaded: totalElements * 100 / participantList.length })
      await Promise.all(batchList.map(async (participant) => {
        const newElement = await this.formatAParticipant(participant);
        // For now we have all participant leaving after the report start filter
        // So to complete the list, we need to discard participant entering after the report end filter 
        if (newElement.inDate > filterEnd) {
          console.log("Enter and leave after the filter end report");
        } else {
          upgradedList.push(newElement);
        }
      }));
    }

    this.setState({
      participantList: participantList,
      upgradedList: upgradedList,
      isNextToken: isNextToken,
      lastUpdate: new Date().toISOString(),
      loading: false,
      loaded: 0,
    });
  }


  async formatAParticipant(participant) {
    let formatParticipant = participant;

    // Need  group
    const groupId = Utils.checkNested(participant, "groupId");
    formatParticipant.groupName = "";
    if (groupId) {
      let found = false;
      let group = null;
      for (let index = 0; index < this.state.loadedGroup.length; index++) {
        if (this.state.loadedGroup[index].id === groupId) {
          found = true;
          formatParticipant.groupName = this.state.loadedGroup[index].name;
          formatParticipant.groupDescription = this.state.loadedGroup[index].description;
          group = this.state.loadedGroup[index];
          break
        }
      }
      if (!found) {
        const newGroup = await this.loadGroup(groupId);
        formatParticipant.groupName = newGroup.name;
        formatParticipant.groupDescription = newGroup.description;
        group = newGroup;
      }

      // Need part
      const partId = Utils.checkNested(group, "partId")
      if (partId) {
        let found = false;
        let part = null;
        for (let index = 0; index < this.state.loadedPart.length; index++) {
          if (this.state.loadedPart[index].id === partId) {
            found = true;
            formatParticipant.partName = this.state.loadedPart[index].name;
            part = this.state.loadedPart[index];
            break
          }
        }
        if (!found) {
          const newPart = await this.loadPart(partId);
          formatParticipant.partName = newPart.name;
          part = newPart;
        }

        // Need study
        const studyId = Utils.checkNested(part, "studyId")
        if (studyId) {
          let found = false;
          for (let index = 0; index < this.state.loadedStudy.length; index++) {
            if (this.state.loadedStudy[index].id === studyId) {
              found = true;
              formatParticipant.studyName = this.state.loadedStudy[index].name;
              break
            }
          }
          if (!found) {
            const newStudy = await this.loadStudy(studyId);
            formatParticipant.studyName = newStudy.name;
          }
        }
      }
    }
    return formatParticipant
  }

  async loadGroup(id) {
    const group = await groupApi.getGroupById(id);
    if (group) this.state.loadedGroup.push(group);
    return group
  }

  async loadPart(id) {
    const partQuery = await partApi.getPart(id);
    const part = Utils.checkNested(partQuery, 'data', 'getPart');
    if (part) this.state.loadedPart.push(part);
    return part
  }

  async loadStudy(id) {
    const studyQuery = await studyApi.getStudy(id);
    const study = Utils.checkNested(studyQuery, 'data', 'getStudy');
    if (study) this.state.loadedStudy.push(study);
    return study
  }

  updateDateBeginning(date) {
    this.setState({
      dateFilterBeginning: new Date(date).toISOString(),
    });
    this.queryCompleteList(date, this.state.dateFilterEnd);
  };

  updateDateEnd(date) {
    this.setState({
      dateFilterEnd: new Date(date).toISOString(),
    });
    this.queryCompleteList(this.state.dateFilterBeginning, date);
  };

  render() {
    const { upgradedList, isNextToken, lastUpdate, dateFilterBeginning, dateFilterEnd, loaded, loading } = this.state;
    return (
      <div>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"></link>
        <Box marginBottom={2} className="no-print">
          <Typography variant="h4" color="inherit" >
            Participant presence report
          </Typography>
          <Divider variant="middle" />
          <br /><br />
          {
            isNextToken ?
              <Box style={{ color: "red" }}><Typography variant="h6">WARNING: Selected time range too wide. Too many elements to display (more than 2000).
           Please reduce the time range.</Typography></Box>
              :
              null
          }
          <form noValidate>
            <Box display='flex' flexWrap="wrap" alignItems="center" marginBottom={1}>
              <Box marginRight={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDateTimePicker
                    required
                    margin="dense"
                    ampm={false}
                    label="Report start"
                    format="dd/MM/yyyy HH:mm"
                    value={dateFilterBeginning}
                    onChange={(date) => this.updateDateBeginning(date)}

                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              <Box>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDateTimePicker
                    required
                    margin="dense"
                    ampm={false}
                    label="Report end"
                    format="dd/MM/yyyy HH:mm"
                    value={dateFilterEnd}
                    onChange={(date) => this.updateDateEnd(date)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              <Box flexGrow={1} />
              <Box>
                <Fab color="secondary" aria-label="print" onClick={() => { window.print(); return false; }}>
                  <PrintIcon />
                </Fab>
              </Box>
            </Box>
          </form>
        </Box>
        {
          loading ?
            <LinearProgress variant="determinate" value={loaded} />
            : null
        }
        <Box>
          <ListPresenceReport listParticipant={upgradedList} lastUpdate={lastUpdate} dateBeginning={dateFilterBeginning} dateEnd={dateFilterEnd} />
        </Box>
      </div >
    );
  }
}

export default ReportPresence;