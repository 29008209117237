import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import * as partApi from './part'
import { API, graphqlOperation } from 'aws-amplify';
import Utils from '../../utils/utils';
import uuid from 'uuid/v4';
import * as constants from '../constants';

export async function deleteStudy(studyId) {
    // When we delete a study also to delete all related parts
    let returnMessage = {
        code: "201",
        messages: [],
    }

    // First get all parts for this study
    const listPartsByStudy = await partApi.listPartsByStudy(studyId);
    const listPartsByStudyItems = Utils.checkNested(listPartsByStudy, 'items') || [];
    // If partsList is null, then an error occurs return without deleting anything
    if (listPartsByStudy === null) {
        returnMessage.code = "400";
        returnMessage.messages = "Fail to retreive parts, cancel deletion";
        return returnMessage
    }

    // Delete all parts link to this study
    let nmbDeleted = 0;
    let remainingList = [...listPartsByStudyItems];
    for (let i = 0; i < listPartsByStudyItems.length; i++) {
        if (remainingList.length === 0) break
        // Batch 100 tasks together
        const batchList = remainingList.slice(0, 100);
        remainingList.splice(0, 100)
        await Promise.all(batchList.map(async (part) => {
            // Use the custom delete part as we also want groups, protocol tasks and assignable tasks to be deleted as well
            let resultDeletePart = await partApi.deletePart(part.id);
            if (resultDeletePart.code !== '201') {
                returnMessage.code = "400";
                let newMessages = returnMessage.messages;
                resultDeletePart.messages.forEach(message => {
                    newMessages.push(message)
                });
                returnMessage.messages = newMessages;
            } else {
                nmbDeleted++
            }
        }));
    }

    // If all parts have been deleted, then we proceed to the study deletion
    if (returnMessage.code === "201") {
        try {
            await API.graphql(graphqlOperation(mutations.deleteStudy, { input: { id: studyId } }));
        } catch (e) {
            returnMessage.code = "400";
            returnMessage.messages = e.errors;
            return returnMessage
        }
    }
    let newMessages = returnMessage.messages;
    newMessages.push("Deleted the study as well as " + nmbDeleted + " parts.")
    returnMessage.messages = newMessages;
    return returnMessage
}

export async function getStudy(id) {
    try {
        const result = await API.graphql(graphqlOperation(queries.getStudy, { id: id }));
        return result
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function addStudy(name, description = null) {
    const date = new Date().toISOString();

    let input = {
        id: uuid(),
        name: name,
        date: date,
        active: constants.STATUS_ONLINE
    }
    if (description && description !== "") {
        input.description = description;
    }
    console.log(input)

    let result = await API.graphql(graphqlOperation(mutations.createStudy, { input: input }));
    console.log(result)
    return result
}

export async function updateStudy(id, name, active, description = null) {
    let input = {
        id: id,
        name: name,
        description: description,
        active: active
    }
    if (description === null || description === "") {
        input.description = " ";
    }
    try {
        let result = await API.graphql(graphqlOperation(mutations.updateStudy, { input: input }));
        console.log(result)
        return result
    } catch (e) {
        console.log(e)
        return false
    }
}


export async function listStudiesByName() {
    try {
        const listStudiesByName = await API.graphql(graphqlOperation(queries.studyByName,
            {
                active: constants.STATUS_ONLINE,
                sortDirection: constants.ORDER_DESC,
                limit: constants.LIMIT
            }));
        let studyByName = Utils.checkNested(listStudiesByName, 'data', 'studyByName')
        return studyByName
    } catch (e) {
        console.log(e)
        return { items: [], nextToken: null }
    }
}

export async function listInactiveStudiesByName() {
    try {
        const listStudiesByName = await API.graphql(graphqlOperation(queries.studyByName,
            {
                active: constants.STATUS_OFFLINE,
                sortDirection: constants.ORDER_DESC,
                limit: constants.LIMIT
            }));
        let studyByName = Utils.checkNested(listStudiesByName, 'data', 'studyByName')
        return studyByName
    } catch (e) {
        console.log(e)
        return { items: [], nextToken: null }
    }
}

export async function listStudiesByDate() {
    try {
        const listStudiesByDate = await API.graphql(graphqlOperation(queries.studyByDate,
            {
                active: constants.STATUS_ONLINE,
                sortDirection: constants.ORDER_DESC,
                limit: constants.LIMIT
            }));
        let studyByDate = Utils.checkNested(listStudiesByDate, 'data', 'studyByDate')
        return studyByDate
    } catch (e) {
        console.log(e)
        return { items: [], nextToken: null }
    }
}