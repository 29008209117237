import React, { PureComponent } from 'react';

import './ListPresenceReport.scss'

import MaterialTable from 'material-table'
import * as userApi from '../../utils/api/user';

import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import uuid from 'uuid/v4';

class ListPresenceReport extends PureComponent {
  state = {
    currentUser: "",
    listParticipant: [],
  };

  componentDidMount() {
    this.loadCurrentUser();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.lastUpdate !== prevProps.lastUpdate) {
      this.setState({
        listParticipant: this.props.listParticipant,
      });
    }
  }

  async loadCurrentUser() {
    const currentUser = await userApi.getCurrentUsername()
    this.setState({ currentUser: currentUser });
  }

  render() {
    const { listParticipant } = this.state;
    return (
      <div id="ListPresenceReport">
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"></link>
        <Box key={uuid()} marginBottom={2} className="page-break">
          <Typography>Participant presence between <b>{moment(this.props.dateBeginning).format("HH:mm DD/MM/Y")}</b> and <b>{moment(this.props.dateEnd).format("HH:mm DD/MM/Y")}</b></Typography>
          <Typography>Generated by <b>{this.state.currentUser}</b> at <b>{moment(this.props.lastUpdate).format("HH:mm DD/MM/Y")}</b></Typography>
          <MaterialTable
            columns={[
              {
                title: 'Participant',
                field: 'participantCode',
                width: null,
                defaultSort: 'asc',
                render: rowData => {
                  return (
                    <div>
                      {
                        rowData.code + "/ " + rowData.selection
                      }
                    </div>
                  )
                },
              },
              { title: 'Room', field: 'room', sorting: false, },
              {
                title: 'Study',
                field: 'studyName',
                sorting: false,
                render: rowData => {
                  return (
                    <div>
                      {rowData.studyName}{rowData.groupDescription ? '/' + rowData.groupDescription : null}
                    </div>
                  )
                },
              },
              {
                title: 'In Date',
                field: 'inDate',
                render: rowData => {
                  return (
                    <div>
                      <b>{moment(rowData.inDate).format('HH:mm')} </b>
                      {moment(rowData.inDate).format('DD/MM/YYYY')}
                    </div>
                  )
                },
              },
              {
                title: 'Out Date',
                field: 'outDate',
                render: rowData => {
                  return (
                    <div>
                      <b>{moment(rowData.outDate).format('HH:mm')} </b>
                      {moment(rowData.outDate).format('DD/MM/YYYY')}
                    </div>
                  )
                },
              },
            ]}
            options={{
              actionsColumnIndex: -1,
              paging: false,
              sorting: true,
              search: false,
              draggable: false,
            }}
            data={listParticipant}
            title=""
          />
          <Box className="no-print" marginBottom={5} />
        </Box>

      </div>
    );
  }
}

export default ListPresenceReport;