import { Auth } from "aws-amplify";
import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";

export interface InitClientInterface {
  url?: string;
  region?: string;
}
export const initClient = (config?: InitClientInterface) => {
  if (config?.url && config?.region)
    return new AWSAppSyncClient({
      url: config.url,
      region: config.region,
      disableOffline: true,
      auth: {
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        jwtToken: async () =>
          (await Auth.currentSession()).getIdToken().getJwtToken(),
      },
    });
  return undefined;
};

export const BarcodeClientConfig: InitClientInterface = {
  url: process.env.REACT_APP_BARCODE_API_URL,
  region: process.env.REACT_APP_REGION,
};
