import React, { PureComponent } from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { Link } from "react-router-dom";

// UI
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { ReportStaffRoute, ReportParticipantRoute, ReportTaskTypeRoute, ReportPresenceRoute, ReportTaskByStudyRoute } from '../../Routing';

class Reports extends PureComponent {
  render() {
    return (
      <div>
        <Typography variant="h4" color="inherit">
          Generate reports
        </Typography>
        <Divider variant="middle" />
        <List>
          <ListItem button divider component={Link} to={ReportStaffRoute}>
            <ListItemAvatar>
              <Avatar>
                <ListAltIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Staff report"
            />
          </ListItem>
          <ListItem button divider component={Link} to={ReportPresenceRoute}>
            <ListItemAvatar>
              <Avatar>
                <ListAltIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Presence report"
            />
          </ListItem>
          <ListItem button divider component={Link} to={ReportParticipantRoute}>
            <ListItemAvatar>
              <Avatar>
                <ListAltIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Participant report"
            />
          </ListItem>
          <ListItem button divider component={Link} to={ReportTaskTypeRoute}>
            <ListItemAvatar>
              <Avatar>
                <ListAltIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Task type report"
            />
          </ListItem>
          <ListItem button divider component={Link} to={ReportTaskByStudyRoute}>
            <ListItemAvatar>
              <Avatar>
                <ListAltIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Task by study report"
            />
          </ListItem>
        </List>
      </div>
    );
  }
}

export default Reports;