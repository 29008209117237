import React, { useContext, useState } from 'react';

import Button from '@material-ui/core/Button';
import { Box, Typography } from '@material-ui/core';
import ValidateProtocolDialog from './ValidateProtocolDialog';
import ValidateProtocolButtonContextProvider, { ValidateProtocolButtonContext } from './ValidateProtocolButtonContextProvider';

const ValidateProtocol = (props) => {
    return <ValidateProtocolButtonContextProvider group={props.group}>
        <ValidateProtocolButton/>
    </ValidateProtocolButtonContextProvider>
}

const ValidateProtocolButton = () => {
    const currentCtx = useContext(ValidateProtocolButtonContext);
    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <Box display="flex" flexWrap="wrap" alignItems="center" marginBottom={2}>
                <Box flexGrow={1} />
                {
                    currentCtx.validationStatus === null ?
                        <Typography>Loading...</Typography>
                        :
                        <Typography>{currentCtx.validationStatus.isValidate ? "Version: " + currentCtx.validationStatus.version : "Not validated"}</Typography>
                }

                <Box marginLeft={1}>
                    <Button variant="contained" onClick={() => setOpen(true)}>Versioning details</Button>
                </Box>
            </Box>

            <ValidateProtocolDialog
                open={open}
                onClose={() => setOpen(false)}
            />
        </React.Fragment>
    )
}

export default ValidateProtocol;