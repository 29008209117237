import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import * as assignableTaskApi from './assignableTask';
import { API, graphqlOperation } from 'aws-amplify';
import Utils from '../utils';
import * as constants from '../constants';
import uuid from 'uuid/v4';

export async function deletePosition(positionId) {
    let returnMessage = {
        code: "201",
        messages: [],
    }

    // When we delete a position we also want to delete:
    // -> Assignable Tasks

    // ------------------- Assignable Tasks ---------------------
    let nmbDeleted = 0;
    // First get all Assignable Tasks for this position
    const listAssignableTaskByPosition = await assignableTaskApi.listAssignableTaskByPosition(positionId);
    const listAssignableTaskByPositionItems = Utils.checkNested(listAssignableTaskByPosition, 'items');
    // If listAssignableTaskByPosition is null, then an error occurs so we don't delete the group
    if (listAssignableTaskByPosition === null) {
        returnMessage.code = "400";
        returnMessage.messages.push("Fail to retreive assignable task, cancel group deletion");
    } else {
        // Delete all assignable task link to this position
        let remainingList = [...listAssignableTaskByPositionItems];
        for (let i = 0; i < listAssignableTaskByPositionItems.length; i++) {
            if (remainingList.length === 0) break
            // Batch 100 tasks together
            const batchList = remainingList.slice(0, 100);
            remainingList.splice(0, 100)
            await Promise.all(batchList.map(async (assignableTask) => {
                let resultDeleteAssignableTask = await assignableTaskApi.deleteAssignableTask(assignableTask.id);
                if (resultDeleteAssignableTask.code !== '201') {
                    returnMessage.code = "400";
                    let newMessages = returnMessage.messages;
                    resultDeleteAssignableTask.messages.forEach(message => {
                        newMessages.push(message)
                    });
                    returnMessage.messages.push(newMessages);
                } else {
                    nmbDeleted++
                }
            }));
        }

        console.log("Deleted " + nmbDeleted + "assignable tasks for positionId: " + positionId)
    }

    try {
        await API.graphql(graphqlOperation(mutations.deletePosition, { input: { id: positionId } }));
    } catch (e) {
        returnMessage.code = "400";
        returnMessage.messages = e.errors;
        return returnMessage
    }
    let newMessages = returnMessage.messages;
    newMessages.push("Deleted the position")
    returnMessage.messages = newMessages;
    return returnMessage
}

export async function listPositionByGroup(groupId) {
    try {
        const rawPositionByGroup = await API.graphql(graphqlOperation(queries.positionByGroup,
            {
                groupId: groupId,
                sortDirection: constants.ORDER_DESC,
                limit: constants.LIMIT
            }));
        let positionByGroup = Utils.checkNested(rawPositionByGroup, 'data', 'positionByGroup')
        return positionByGroup
    } catch (e) {
        console.log(e)
        return null
    }
}

export async function createPosition(groupId,
    name,
    t0,
    inclusion = null,
    treatment = null,
    dose = null,
    participantId = null) {

    let returnMessage = {
        code: "201",
        messages: [],
    }
    const nullDate = new Date(null).toISOString();
    // Check that t0 is a valid date
    if (!t0 || new Date(t0).toISOString() === nullDate) {
        console.log("Invalid date");
        returnMessage.code = "400";
        let newMessages = returnMessage.messages;
        newMessages.push("Error, invalid t0");
        returnMessage.messages = newMessages;
        return returnMessage
    }

    // First create input with mandatory fields
    let input = {
        id: uuid(),
        groupId: groupId,
        name: name,
        t0: t0,
        validate: false,
    }

    if (inclusion && inclusion !== " ") {
        input.inclusion = inclusion;
    }
    if (treatment && treatment !== " ") {
        input.treatment = treatment;
    }
    if (dose && dose !== " ") {
        input.dose = dose;
    }
    if (participantId && participantId !== " ") {
        input.participantId = participantId;
    }

    // Our input is ready we can create the position
    let createPositionResult;
    try {
        createPositionResult = await API.graphql(graphqlOperation(mutations.createPosition, { input: input }));
        console.log(createPositionResult)
    } catch (e) {
        returnMessage.code = "400";
        returnMessage.messages = e.errors;
        returnMessage.messages.push("Position creation failed. Please check you input and try again.")
        console.log(e.errors)
        return returnMessage
    }

    // The position cration is done, we can now create all tasks for this position based on the protocol
    // First get all protocol task for this group
    let getGroupProtocol;
    try {
        getGroupProtocol = await API.graphql(graphqlOperation(queries.protocolTaskByGroup, {
            groupId: groupId,
            sortDirection: constants.ORDER_DESC,
            limit: constants.LIMIT
        }));
        console.log(getGroupProtocol)
    } catch (e) {
        returnMessage.code = "400";
        returnMessage.messages = e.errors;
        returnMessage.messages.push("The position have been created but the tasks creation failed. DO NOT recreate the position. Instead, run a consistancy check on the group to recreate missing tasks.");
        console.log(e.errors);
        return returnMessage
    }

    const protocolTaskItems = Utils.checkNested(getGroupProtocol, 'data', 'protocolTaskByGroup', 'items');
    // Create an assignable task for all protocol task
    let nbrCreated = 0;
    const positionId = Utils.checkNested(createPositionResult, 'data', 'createPosition', 'id');
    if (!positionId || !protocolTaskItems) {
        returnMessage.code = "400";
        returnMessage.messages.push("The position have been created but the tasks creation failed (cannot find position id). DO NOT recreate the position. Instead, run a consistancy check on the group to recreate missing tasks.");
        console.log(returnMessage);
        return returnMessage
    }

    // If their is too many tasks, fail ans rise an error
    const nextToken = Utils.checkNested(getGroupProtocol, 'data', 'protocolTaskByGroup', 'nextToken');
    if (nextToken) {
        returnMessage.code = "400";
        returnMessage.messages.push("There is too many tasks in this protocol. Fail to create associated assignable tasks.");
        console.log(returnMessage);
        return returnMessage
    }


    try {
        let remainingList = [...protocolTaskItems];
        for (let i = 0; i < protocolTaskItems.length; i++) {
            if (remainingList.length === 0) break
            // Batch 100 tasks together
            const batchList = remainingList.slice(0, 100);
            remainingList.splice(0, 100)
            await Promise.all(batchList.map(async (protocolTask) => {
                let createAssignableTaskMessage = await assignableTaskApi.createAssignableTask(positionId, protocolTask.id);
                if (createAssignableTaskMessage.code === "201") {
                    nbrCreated++;
                } else {
                    returnMessage.code = "400";
                    let newMessages = returnMessage.messages;
                    createAssignableTaskMessage.messages.forEach(message => {
                        newMessages.push(message)
                    });
                    returnMessage.messages = newMessages;
                    console.log(createAssignableTaskMessage)
                }
            }));
        }
    } catch (e) {
        returnMessage.code = "400";
        returnMessage.messages = e.errors;
        returnMessage.messages.push("The position have been created but an error pccurs during the tasks creation. DO NOT recreate the position. Instead, run a consistancy check on the group to recreate missing tasks.")
        console.log(e.errors)
        return returnMessage
    }

    let newMessages = returnMessage.messages;
    newMessages.push("Created the position as well as " + nbrCreated + " tasks.")
    returnMessage.messages = newMessages;
    console.log(returnMessage);
    return returnMessage
}

export async function updatePosition(id,
    name,
    t0 = null,
    inclusion = null,
    treatment = null,
    dose = null,
    participantId = null,
) {
    let returnMessage = {
        code: "201",
        messages: [],
    }
    const nullDate = new Date(null).toISOString();
    // First create input with mandatory fields
    let input = {
        id: id,
        name: name,
    }
    if (t0 && t0 !== nullDate) {
        input.t0 = t0;
    }
    if (inclusion !== null && inclusion !== undefined) {
        input.inclusion = inclusion;
    }
    if (treatment !== null && treatment !== undefined) {
        input.treatment = treatment;
    }
    if (dose !== null && dose !== undefined) {
        input.dose = dose;
    }
    if (participantId && participantId !== " ") {
        input.participantId = participantId;
    }
    if (participantId === constants.POSITION_UNASSIGNED) {
        input.participantId = null;
    }
    try {
        await API.graphql(graphqlOperation(mutations.updatePosition, { input: input }));
    } catch (e) {
        returnMessage.code = "400";
        returnMessage.messages = e.errors;
        returnMessage.messages.push("Fail to update the position.");
        console.log(e.errors);
        return returnMessage
    }

    // Now, if the t0 has been updated, we need to update all the planning (assignable tasks)
    // Get all assignable task for this position
    let nbrUpdate = 0;
    if (t0 && t0 !== nullDate) {
        let getAssignableTasks;
        try {
            getAssignableTasks = await API.graphql(graphqlOperation(queries.assignableTaskByPosition, {
                positionId: id,
                sortDirection: constants.ORDER_ASC,
                limit: constants.LIMIT
            }));
        } catch (e) {
            returnMessage.code = "400";
            returnMessage.messages.push("The position have been updated but assignable task applicable time upadte with the new t0 failed. Run a consistancy check on the group to reupdate missing tasks.");
            console.log(e);
            return returnMessage
        }

        const assignableTaskItems = Utils.checkNested(getAssignableTasks, 'data', 'assignableTaskByPosition', 'items') || [];
        // Update all assignable task
        try {
            let remainingList = [...assignableTaskItems];
            for (let i = 0; i < assignableTaskItems.length; i++) {
                if (remainingList.length === 0) break
                // Batch 100 tasks together
                const batchList = remainingList.slice(0, 100);
                remainingList.splice(0, 100)
                await Promise.all(batchList.map(async (assignableTask) => {
                    let updateAssignableTaskMessage = await assignableTaskApi.updateAssignableTaskTime(assignableTask.id,
                        id,
                        assignableTask.protocolTaskId,
                        assignableTask.delta,
                        assignableTask.applicableDate,
                        t0,
                        null);
                    if (updateAssignableTaskMessage.code === "201") {
                        nbrUpdate++;
                    } else {
                        returnMessage.code = "400";
                        let newMessages = returnMessage.messages;
                        updateAssignableTaskMessage.messages.forEach(message => {
                            newMessages.push(message)
                        });
                        returnMessage.messages = newMessages;
                    }
                }));
            }
        } catch (e) {
            returnMessage.code = "400";
            returnMessage.messages.push("The position have been updated but assignable task applicable time update with the new t0 failed. Run a consistancy check on the group to reupdate missing tasks.")
            console.log(e)
            return returnMessage
        }
    }

    let newMessages = returnMessage.messages;
    newMessages.push("Updated the position as well as " + nbrUpdate + " tasks.");
    console.log(newMessages)
    returnMessage.messages = newMessages;
    return returnMessage
}

export async function getPosition(id) {
    try {
        const result = await API.graphql(graphqlOperation(queries.getPosition, { id: id }));
        return result
    } catch (e) {
        console.log(e)
        return false
    }
}

export async function validatePosition(id,
    validate) {
    let input = {
        id: id,
        validate: validate
    }
    let result = await API.graphql(graphqlOperation(mutations.updatePosition, { input: input }));
    return result
}
