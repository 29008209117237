/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createStudy = /* GraphQL */ `
  mutation CreateStudy(
    $input: CreateStudyInput!
    $condition: ModelStudyConditionInput
  ) {
    createStudy(input: $input, condition: $condition) {
      id
      name
      date
      description
      active
    }
  }
`;
export const updateStudy = /* GraphQL */ `
  mutation UpdateStudy(
    $input: UpdateStudyInput!
    $condition: ModelStudyConditionInput
  ) {
    updateStudy(input: $input, condition: $condition) {
      id
      name
      date
      description
      active
    }
  }
`;
export const deleteStudy = /* GraphQL */ `
  mutation DeleteStudy(
    $input: DeleteStudyInput!
    $condition: ModelStudyConditionInput
  ) {
    deleteStudy(input: $input, condition: $condition) {
      id
      name
      date
      description
      active
    }
  }
`;
export const createPart = /* GraphQL */ `
  mutation CreatePart(
    $input: CreatePartInput!
    $condition: ModelPartConditionInput
  ) {
    createPart(input: $input, condition: $condition) {
      id
      studyId
      name
      date
      description
    }
  }
`;
export const updatePart = /* GraphQL */ `
  mutation UpdatePart(
    $input: UpdatePartInput!
    $condition: ModelPartConditionInput
  ) {
    updatePart(input: $input, condition: $condition) {
      id
      studyId
      name
      date
      description
    }
  }
`;
export const deletePart = /* GraphQL */ `
  mutation DeletePart(
    $input: DeletePartInput!
    $condition: ModelPartConditionInput
  ) {
    deletePart(input: $input, condition: $condition) {
      id
      studyId
      name
      date
      description
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      partId
      name
      date
      description
      indicativeT0
      active
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      partId
      name
      date
      description
      indicativeT0
      active
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      partId
      name
      date
      description
      indicativeT0
      active
    }
  }
`;
export const createProtocolValidation = /* GraphQL */ `
  mutation CreateProtocolValidation(
    $input: CreateProtocolValidationInput!
    $condition: ModelProtocolValidationConditionInput
  ) {
    createProtocolValidation(input: $input, condition: $condition) {
      id
      groupId
      date
      user
      validate
      action
      comment
      version
    }
  }
`;
export const updateProtocolValidation = /* GraphQL */ `
  mutation UpdateProtocolValidation(
    $input: UpdateProtocolValidationInput!
    $condition: ModelProtocolValidationConditionInput
  ) {
    updateProtocolValidation(input: $input, condition: $condition) {
      id
      groupId
      date
      user
      validate
      action
      comment
      version
    }
  }
`;
export const deleteProtocolValidation = /* GraphQL */ `
  mutation DeleteProtocolValidation(
    $input: DeleteProtocolValidationInput!
    $condition: ModelProtocolValidationConditionInput
  ) {
    deleteProtocolValidation(input: $input, condition: $condition) {
      id
      groupId
      date
      user
      validate
      action
      comment
      version
    }
  }
`;
export const createProtocolComment = /* GraphQL */ `
  mutation CreateProtocolComment(
    $input: CreateProtocolCommentInput!
    $condition: ModelProtocolCommentConditionInput
  ) {
    createProtocolComment(input: $input, condition: $condition) {
      id
      groupId
      date
      user
      comment
    }
  }
`;
export const updateProtocolComment = /* GraphQL */ `
  mutation UpdateProtocolComment(
    $input: UpdateProtocolCommentInput!
    $condition: ModelProtocolCommentConditionInput
  ) {
    updateProtocolComment(input: $input, condition: $condition) {
      id
      groupId
      date
      user
      comment
    }
  }
`;
export const deleteProtocolComment = /* GraphQL */ `
  mutation DeleteProtocolComment(
    $input: DeleteProtocolCommentInput!
    $condition: ModelProtocolCommentConditionInput
  ) {
    deleteProtocolComment(input: $input, condition: $condition) {
      id
      groupId
      date
      user
      comment
    }
  }
`;
export const createProtocolTask = /* GraphQL */ `
  mutation CreateProtocolTask(
    $input: CreateProtocolTaskInput!
    $condition: ModelProtocolTaskConditionInput
  ) {
    createProtocolTask(input: $input, condition: $condition) {
      id
      groupId
      name
      timeToT0
      description
      taskTypeId
    }
  }
`;
export const updateProtocolTask = /* GraphQL */ `
  mutation UpdateProtocolTask(
    $input: UpdateProtocolTaskInput!
    $condition: ModelProtocolTaskConditionInput
  ) {
    updateProtocolTask(input: $input, condition: $condition) {
      id
      groupId
      name
      timeToT0
      description
      taskTypeId
    }
  }
`;
export const deleteProtocolTask = /* GraphQL */ `
  mutation DeleteProtocolTask(
    $input: DeleteProtocolTaskInput!
    $condition: ModelProtocolTaskConditionInput
  ) {
    deleteProtocolTask(input: $input, condition: $condition) {
      id
      groupId
      name
      timeToT0
      description
      taskTypeId
    }
  }
`;
export const createTaskType = /* GraphQL */ `
  mutation CreateTaskType(
    $input: CreateTaskTypeInput!
    $condition: ModelTaskTypeConditionInput
  ) {
    createTaskType(input: $input, condition: $condition) {
      id
      name
    }
  }
`;
export const updateTaskType = /* GraphQL */ `
  mutation UpdateTaskType(
    $input: UpdateTaskTypeInput!
    $condition: ModelTaskTypeConditionInput
  ) {
    updateTaskType(input: $input, condition: $condition) {
      id
      name
    }
  }
`;
export const deleteTaskType = /* GraphQL */ `
  mutation DeleteTaskType(
    $input: DeleteTaskTypeInput!
    $condition: ModelTaskTypeConditionInput
  ) {
    deleteTaskType(input: $input, condition: $condition) {
      id
      name
    }
  }
`;
export const createAssignableTask = /* GraphQL */ `
  mutation CreateAssignableTask(
    $input: CreateAssignableTaskInput!
    $condition: ModelAssignableTaskConditionInput
  ) {
    createAssignableTask(input: $input, condition: $condition) {
      id
      description
      applicableDate
      delta
      notLinkToProtocol
      activationStatus
      protocolTaskId
      positionId
      userId
    }
  }
`;
export const updateAssignableTask = /* GraphQL */ `
  mutation UpdateAssignableTask(
    $input: UpdateAssignableTaskInput!
    $condition: ModelAssignableTaskConditionInput
  ) {
    updateAssignableTask(input: $input, condition: $condition) {
      id
      description
      applicableDate
      delta
      notLinkToProtocol
      activationStatus
      protocolTaskId
      positionId
      userId
    }
  }
`;
export const deleteAssignableTask = /* GraphQL */ `
  mutation DeleteAssignableTask(
    $input: DeleteAssignableTaskInput!
    $condition: ModelAssignableTaskConditionInput
  ) {
    deleteAssignableTask(input: $input, condition: $condition) {
      id
      description
      applicableDate
      delta
      notLinkToProtocol
      activationStatus
      protocolTaskId
      positionId
      userId
    }
  }
`;
export const createPosition = /* GraphQL */ `
  mutation CreatePosition(
    $input: CreatePositionInput!
    $condition: ModelPositionConditionInput
  ) {
    createPosition(input: $input, condition: $condition) {
      id
      groupId
      participantId
      name
      t0
      inclusion
      treatment
      dose
      validate
    }
  }
`;
export const updatePosition = /* GraphQL */ `
  mutation UpdatePosition(
    $input: UpdatePositionInput!
    $condition: ModelPositionConditionInput
  ) {
    updatePosition(input: $input, condition: $condition) {
      id
      groupId
      participantId
      name
      t0
      inclusion
      treatment
      dose
      validate
    }
  }
`;
export const deletePosition = /* GraphQL */ `
  mutation DeletePosition(
    $input: DeletePositionInput!
    $condition: ModelPositionConditionInput
  ) {
    deletePosition(input: $input, condition: $condition) {
      id
      groupId
      participantId
      name
      t0
      inclusion
      treatment
      dose
      validate
    }
  }
`;
export const createParticipant = /* GraphQL */ `
  mutation CreateParticipant(
    $input: CreateParticipantInput!
    $condition: ModelParticipantConditionInput
  ) {
    createParticipant(input: $input, condition: $condition) {
      id
      groupId
      selection
      code
      sex
      birthday
      room
      inDate
      outDate
      active
    }
  }
`;
export const updateParticipant = /* GraphQL */ `
  mutation UpdateParticipant(
    $input: UpdateParticipantInput!
    $condition: ModelParticipantConditionInput
  ) {
    updateParticipant(input: $input, condition: $condition) {
      id
      groupId
      selection
      code
      sex
      birthday
      room
      inDate
      outDate
      active
    }
  }
`;
export const deleteParticipant = /* GraphQL */ `
  mutation DeleteParticipant(
    $input: DeleteParticipantInput!
    $condition: ModelParticipantConditionInput
  ) {
    deleteParticipant(input: $input, condition: $condition) {
      id
      groupId
      selection
      code
      sex
      birthday
      room
      inDate
      outDate
      active
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      userName
      email
      create
      lastUpdate
      color
      active
      code
      pseudo
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      userName
      email
      create
      lastUpdate
      color
      active
      code
      pseudo
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      userName
      email
      create
      lastUpdate
      color
      active
      code
      pseudo
    }
  }
`;
export const createTaskValidation = /* GraphQL */ `
  mutation CreateTaskValidation(
    $input: CreateTaskValidationInput!
    $condition: ModelTaskValidationConditionInput
  ) {
    createTaskValidation(input: $input, condition: $condition) {
      id
      date
      assignableTaskId
      positionId
      active
      userId
      comment
    }
  }
`;
export const updateTaskValidation = /* GraphQL */ `
  mutation UpdateTaskValidation(
    $input: UpdateTaskValidationInput!
    $condition: ModelTaskValidationConditionInput
  ) {
    updateTaskValidation(input: $input, condition: $condition) {
      id
      date
      assignableTaskId
      positionId
      active
      userId
      comment
    }
  }
`;
export const deleteTaskValidation = /* GraphQL */ `
  mutation DeleteTaskValidation(
    $input: DeleteTaskValidationInput!
    $condition: ModelTaskValidationConditionInput
  ) {
    deleteTaskValidation(input: $input, condition: $condition) {
      id
      date
      assignableTaskId
      positionId
      active
      userId
      comment
    }
  }
`;
export const createAdminCredential = /* GraphQL */ `
  mutation CreateAdminCredential(
    $input: CreateAdminCredentialInput!
    $condition: ModelAdminCredentialConditionInput
  ) {
    createAdminCredential(input: $input, condition: $condition) {
      id
      accessKeyId
      secretAccessKey
      region
    }
  }
`;
export const updateAdminCredential = /* GraphQL */ `
  mutation UpdateAdminCredential(
    $input: UpdateAdminCredentialInput!
    $condition: ModelAdminCredentialConditionInput
  ) {
    updateAdminCredential(input: $input, condition: $condition) {
      id
      accessKeyId
      secretAccessKey
      region
    }
  }
`;
export const deleteAdminCredential = /* GraphQL */ `
  mutation DeleteAdminCredential(
    $input: DeleteAdminCredentialInput!
    $condition: ModelAdminCredentialConditionInput
  ) {
    deleteAdminCredential(input: $input, condition: $condition) {
      id
      accessKeyId
      secretAccessKey
      region
    }
  }
`;
