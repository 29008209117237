/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPrintTemplate = /* GraphQL */ `
  query GetPrintTemplate($id: ID!) {
    getPrintTemplate(id: $id) {
      id
      templatePath
      name
      printerId
      printer {
        id
        printerNetworkId
        friendlyName
        active
        createdAt
        updatedAt
      }
      active
      createdAt
      updatedAt
    }
  }
`;
export const listPrintTemplates = /* GraphQL */ `
  query ListPrintTemplates(
    $filter: ModelPrintTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrintTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        templatePath
        name
        printerId
        printer {
          id
          printerNetworkId
          friendlyName
          active
          createdAt
          updatedAt
        }
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const printerTemplateByStatus = /* GraphQL */ `
  query PrinterTemplateByStatus(
    $active: ActiveStatus!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPrintTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    printerTemplateByStatus(
      active: $active
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        templatePath
        name
        printerId
        printer {
          id
          printerNetworkId
          friendlyName
          active
          createdAt
          updatedAt
        }
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPrinter = /* GraphQL */ `
  query GetPrinter($id: ID!) {
    getPrinter(id: $id) {
      id
      printerNetworkId
      friendlyName
      active
      createdAt
      updatedAt
    }
  }
`;
export const listPrinters = /* GraphQL */ `
  query ListPrinters(
    $filter: ModelPrinterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrinters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        printerNetworkId
        friendlyName
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const printerByStatus = /* GraphQL */ `
  query PrinterByStatus(
    $active: ActiveStatus!
    $friendlyName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPrinterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    printerByStatus(
      active: $active
      friendlyName: $friendlyName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        printerNetworkId
        friendlyName
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLocationCat = /* GraphQL */ `
  query GetLocationCat($id: ID!) {
    getLocationCat(id: $id) {
      id
      name
      prefix
      active
      createdAt
      updatedAt
    }
  }
`;
export const listLocationCats = /* GraphQL */ `
  query ListLocationCats(
    $filter: ModelLocationCatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocationCats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        prefix
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const locationCatByStatus = /* GraphQL */ `
  query LocationCatByStatus(
    $active: ActiveStatus!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLocationCatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    locationCatByStatus(
      active: $active
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        prefix
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      locationCatId
      name
      friendlyName
      active
      createdAt
      updatedAt
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        locationCatId
        name
        friendlyName
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const locationByStatusCat = /* GraphQL */ `
  query LocationByStatusCat(
    $active: ActiveStatus!
    $locationCatIdName: ModelLocationByStatusCatCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    locationByStatusCat(
      active: $active
      locationCatIdName: $locationCatIdName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        locationCatId
        name
        friendlyName
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      selector
      active
      comment
      createdAt
      updatedAt
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        selector
        active
        comment
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const commentBySelector = /* GraphQL */ `
  query CommentBySelector(
    $active: ActiveStatus!
    $selector: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentBySelector(
      active: $active
      selector: $selector
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        selector
        active
        comment
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSampleProtocolTemplate = /* GraphQL */ `
  query GetSampleProtocolTemplate($id: ID!) {
    getSampleProtocolTemplate(id: $id) {
      id
      partId
      active
      name
      sponsorName
      studyName
      validationDate
      validateUserId
      validationDateSecond
      validateUserIdSecond
      createdAt
      updatedAt
    }
  }
`;
export const listSampleProtocolTemplates = /* GraphQL */ `
  query ListSampleProtocolTemplates(
    $filter: ModelSampleProtocolTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSampleProtocolTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partId
        active
        name
        sponsorName
        studyName
        validationDate
        validateUserId
        validationDateSecond
        validateUserIdSecond
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const sampleProtocolTemplateByPart = /* GraphQL */ `
  query SampleProtocolTemplateByPart(
    $partId: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSampleProtocolTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sampleProtocolTemplateByPart(
      partId: $partId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partId
        active
        name
        sponsorName
        studyName
        validationDate
        validateUserId
        validationDateSecond
        validateUserIdSecond
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getListing = /* GraphQL */ `
  query GetListing($id: ID!) {
    getListing(id: $id) {
      id
      sampleProtocolTemplateId
      name
      printTemplateId
      printTemplate {
        id
        templatePath
        name
        printerId
        printer {
          id
          printerNetworkId
          friendlyName
          active
          createdAt
          updatedAt
        }
        active
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listListings = /* GraphQL */ `
  query ListListings(
    $filter: ModelListingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listListings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sampleProtocolTemplateId
        name
        printTemplateId
        printTemplate {
          id
          templatePath
          name
          printerId
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listingBySampleProtocolTemplate = /* GraphQL */ `
  query ListingBySampleProtocolTemplate(
    $sampleProtocolTemplateId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelListingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listingBySampleProtocolTemplate(
      sampleProtocolTemplateId: $sampleProtocolTemplateId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sampleProtocolTemplateId
        name
        printTemplateId
        printTemplate {
          id
          templatePath
          name
          printerId
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSampleTemplate = /* GraphQL */ `
  query GetSampleTemplate($id: ID!) {
    getSampleTemplate(id: $id) {
      id
      listingId
      theoreticalTime
      theoreticalTimeEnd
      theoreticalTimeLabel
      period
      matrixName
      index
      createdAt
      updatedAt
    }
  }
`;
export const listSampleTemplates = /* GraphQL */ `
  query ListSampleTemplates(
    $filter: ModelSampleTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSampleTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        listingId
        theoreticalTime
        theoreticalTimeEnd
        theoreticalTimeLabel
        period
        matrixName
        index
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const sampleTemplateByListing = /* GraphQL */ `
  query SampleTemplateByListing(
    $listingId: ID!
    $periodTheoreticalTime: ModelSampleTemplateByListingCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSampleTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sampleTemplateByListing(
      listingId: $listingId
      periodTheoreticalTime: $periodTheoreticalTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        listingId
        theoreticalTime
        theoreticalTimeEnd
        theoreticalTimeLabel
        period
        matrixName
        index
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAlicotTemplate = /* GraphQL */ `
  query GetAlicotTemplate($id: ID!) {
    getAlicotTemplate(id: $id) {
      id
      listingId
      indexPrefix
      indexFirst
      number
      matrixName
      printTemplateId
      printTemplate {
        id
        templatePath
        name
        printerId
        printer {
          id
          printerNetworkId
          friendlyName
          active
          createdAt
          updatedAt
        }
        active
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAlicotTemplates = /* GraphQL */ `
  query ListAlicotTemplates(
    $filter: ModelAlicotTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlicotTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        listingId
        indexPrefix
        indexFirst
        number
        matrixName
        printTemplateId
        printTemplate {
          id
          templatePath
          name
          printerId
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const alicotTemplateByListing = /* GraphQL */ `
  query AlicotTemplateByListing(
    $listingId: ID!
    $indexPrefix: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAlicotTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    alicotTemplateByListing(
      listingId: $listingId
      indexPrefix: $indexPrefix
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        listingId
        indexPrefix
        indexFirst
        number
        matrixName
        printTemplateId
        printTemplate {
          id
          templatePath
          name
          printerId
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCohort = /* GraphQL */ `
  query GetCohort($id: ID!) {
    getCohort(id: $id) {
      id
      partId
      name
      positionList
      active
      createdAt
      updatedAt
    }
  }
`;
export const listCohorts = /* GraphQL */ `
  query ListCohorts(
    $filter: ModelCohortFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCohorts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        partId
        name
        positionList
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const cohortByPart = /* GraphQL */ `
  query CohortByPart(
    $active: ActiveStatus!
    $partIdName: ModelCohortByPartCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCohortFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cohortByPart(
      active: $active
      partIdName: $partIdName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partId
        name
        positionList
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLabel = /* GraphQL */ `
  query GetLabel($id: ID!) {
    getLabel(id: $id) {
      id
      cohortId
      serial
      theoreticalTime
      theoreticalTimeLabel
      theoreticalTimeEnd
      period
      matrixName
      index
      printTemplateId
      printTemplate {
        id
        templatePath
        name
        printerId
        printer {
          id
          printerNetworkId
          friendlyName
          active
          createdAt
          updatedAt
        }
        active
        createdAt
        updatedAt
      }
      printed
      active
      studyName
      sponsorName
      inclusion
      parentId
      firstFillEventTime
      firstFillEventUserAndPseudo
      firstCentriEventTime
      firstCentriEventUserAndPseudo
      firstFreezeEventTime
      firstFreezeEventUserAndPseudo
      firstOutEventTime
      firstOutEventUserAndPseudo
      lastEventLocationID
      lastEventType
      lastEventTime
      lastEventAuthor
      comments
      createdAt
      updatedAt
    }
  }
`;
export const listLabels = /* GraphQL */ `
  query ListLabels(
    $filter: ModelLabelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLabels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cohortId
        serial
        theoreticalTime
        theoreticalTimeLabel
        theoreticalTimeEnd
        period
        matrixName
        index
        printTemplateId
        printTemplate {
          id
          templatePath
          name
          printerId
          active
          createdAt
          updatedAt
        }
        printed
        active
        studyName
        sponsorName
        inclusion
        parentId
        firstFillEventTime
        firstFillEventUserAndPseudo
        firstCentriEventTime
        firstCentriEventUserAndPseudo
        firstFreezeEventTime
        firstFreezeEventUserAndPseudo
        firstOutEventTime
        firstOutEventUserAndPseudo
        lastEventLocationID
        lastEventType
        lastEventTime
        lastEventAuthor
        comments
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const labelBySerial = /* GraphQL */ `
  query LabelBySerial(
    $serial: String!
    $theoreticalTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLabelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    labelBySerial(
      serial: $serial
      theoreticalTime: $theoreticalTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cohortId
        serial
        theoreticalTime
        theoreticalTimeLabel
        theoreticalTimeEnd
        period
        matrixName
        index
        printTemplateId
        printTemplate {
          id
          templatePath
          name
          printerId
          active
          createdAt
          updatedAt
        }
        printed
        active
        studyName
        sponsorName
        inclusion
        parentId
        firstFillEventTime
        firstFillEventUserAndPseudo
        firstCentriEventTime
        firstCentriEventUserAndPseudo
        firstFreezeEventTime
        firstFreezeEventUserAndPseudo
        firstOutEventTime
        firstOutEventUserAndPseudo
        lastEventLocationID
        lastEventType
        lastEventTime
        lastEventAuthor
        comments
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const labelByStatusCohort = /* GraphQL */ `
  query LabelByStatusCohort(
    $active: ActiveStatus!
    $cohortIdPeriodTheoreticalTimeInclusionIndex: ModelLabelByStatusCohortCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLabelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    labelByStatusCohort(
      active: $active
      cohortIdPeriodTheoreticalTimeInclusionIndex: $cohortIdPeriodTheoreticalTimeInclusionIndex
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cohortId
        serial
        theoreticalTime
        theoreticalTimeLabel
        theoreticalTimeEnd
        period
        matrixName
        index
        printTemplateId
        printTemplate {
          id
          templatePath
          name
          printerId
          active
          createdAt
          updatedAt
        }
        printed
        active
        studyName
        sponsorName
        inclusion
        parentId
        firstFillEventTime
        firstFillEventUserAndPseudo
        firstCentriEventTime
        firstCentriEventUserAndPseudo
        firstFreezeEventTime
        firstFreezeEventUserAndPseudo
        firstOutEventTime
        firstOutEventUserAndPseudo
        lastEventLocationID
        lastEventType
        lastEventTime
        lastEventAuthor
        comments
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const labelByStatusInclusion = /* GraphQL */ `
  query LabelByStatusInclusion(
    $active: ActiveStatus!
    $cohortIdInclusionPeriodTheoreticalTimeIndex: ModelLabelByStatusInclusionCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLabelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    labelByStatusInclusion(
      active: $active
      cohortIdInclusionPeriodTheoreticalTimeIndex: $cohortIdInclusionPeriodTheoreticalTimeIndex
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cohortId
        serial
        theoreticalTime
        theoreticalTimeLabel
        theoreticalTimeEnd
        period
        matrixName
        index
        printTemplateId
        printTemplate {
          id
          templatePath
          name
          printerId
          active
          createdAt
          updatedAt
        }
        printed
        active
        studyName
        sponsorName
        inclusion
        parentId
        firstFillEventTime
        firstFillEventUserAndPseudo
        firstCentriEventTime
        firstCentriEventUserAndPseudo
        firstFreezeEventTime
        firstFreezeEventUserAndPseudo
        firstOutEventTime
        firstOutEventUserAndPseudo
        lastEventLocationID
        lastEventType
        lastEventTime
        lastEventAuthor
        comments
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const labelByStatusPrinted = /* GraphQL */ `
  query LabelByStatusPrinted(
    $active: ActiveStatus!
    $cohortIdPrintedInclusionPeriodTheoreticalTimeIndex: ModelLabelByStatusPrintedCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLabelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    labelByStatusPrinted(
      active: $active
      cohortIdPrintedInclusionPeriodTheoreticalTimeIndex: $cohortIdPrintedInclusionPeriodTheoreticalTimeIndex
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cohortId
        serial
        theoreticalTime
        theoreticalTimeLabel
        theoreticalTimeEnd
        period
        matrixName
        index
        printTemplateId
        printTemplate {
          id
          templatePath
          name
          printerId
          active
          createdAt
          updatedAt
        }
        printed
        active
        studyName
        sponsorName
        inclusion
        parentId
        firstFillEventTime
        firstFillEventUserAndPseudo
        firstCentriEventTime
        firstCentriEventUserAndPseudo
        firstFreezeEventTime
        firstFreezeEventUserAndPseudo
        firstOutEventTime
        firstOutEventUserAndPseudo
        lastEventLocationID
        lastEventType
        lastEventTime
        lastEventAuthor
        comments
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const labelByLocation = /* GraphQL */ `
  query LabelByLocation(
    $active: ActiveStatus!
    $lastEventLocationIDInclusionPeriodTheoreticalTime: ModelLabelByLocationCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLabelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    labelByLocation(
      active: $active
      lastEventLocationIDInclusionPeriodTheoreticalTime: $lastEventLocationIDInclusionPeriodTheoreticalTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cohortId
        serial
        theoreticalTime
        theoreticalTimeLabel
        theoreticalTimeEnd
        period
        matrixName
        index
        printTemplateId
        printTemplate {
          id
          templatePath
          name
          printerId
          active
          createdAt
          updatedAt
        }
        printed
        active
        studyName
        sponsorName
        inclusion
        parentId
        firstFillEventTime
        firstFillEventUserAndPseudo
        firstCentriEventTime
        firstCentriEventUserAndPseudo
        firstFreezeEventTime
        firstFreezeEventUserAndPseudo
        firstOutEventTime
        firstOutEventUserAndPseudo
        lastEventLocationID
        lastEventType
        lastEventTime
        lastEventAuthor
        comments
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const labelByLastEvent = /* GraphQL */ `
  query LabelByLastEvent(
    $active: ActiveStatus!
    $lastEventTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLabelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    labelByLastEvent(
      active: $active
      lastEventTime: $lastEventTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cohortId
        serial
        theoreticalTime
        theoreticalTimeLabel
        theoreticalTimeEnd
        period
        matrixName
        index
        printTemplateId
        printTemplate {
          id
          templatePath
          name
          printerId
          active
          createdAt
          updatedAt
        }
        printed
        active
        studyName
        sponsorName
        inclusion
        parentId
        firstFillEventTime
        firstFillEventUserAndPseudo
        firstCentriEventTime
        firstCentriEventUserAndPseudo
        firstFreezeEventTime
        firstFreezeEventUserAndPseudo
        firstOutEventTime
        firstOutEventUserAndPseudo
        lastEventLocationID
        lastEventType
        lastEventTime
        lastEventAuthor
        comments
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const labelByParent = /* GraphQL */ `
  query LabelByParent(
    $parentId: ID!
    $index: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLabelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    labelByParent(
      parentId: $parentId
      index: $index
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cohortId
        serial
        theoreticalTime
        theoreticalTimeLabel
        theoreticalTimeEnd
        period
        matrixName
        index
        printTemplateId
        printTemplate {
          id
          templatePath
          name
          printerId
          active
          createdAt
          updatedAt
        }
        printed
        active
        studyName
        sponsorName
        inclusion
        parentId
        firstFillEventTime
        firstFillEventUserAndPseudo
        firstCentriEventTime
        firstCentriEventUserAndPseudo
        firstFreezeEventTime
        firstFreezeEventUserAndPseudo
        firstOutEventTime
        firstOutEventUserAndPseudo
        lastEventLocationID
        lastEventType
        lastEventTime
        lastEventAuthor
        comments
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      labelId
      owner
      pseudo
      usedDate
      locationId
      type
      description
      labelData
      createdAt
      updatedAt
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        labelId
        owner
        pseudo
        usedDate
        locationId
        type
        description
        labelData
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const eventByLabel = /* GraphQL */ `
  query EventByLabel(
    $labelId: ID!
    $usedDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventByLabel(
      labelId: $labelId
      usedDate: $usedDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        labelId
        owner
        pseudo
        usedDate
        locationId
        type
        description
        labelData
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const eventByOwner = /* GraphQL */ `
  query EventByOwner(
    $owner: String!
    $usedDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventByOwner(
      owner: $owner
      usedDate: $usedDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        labelId
        owner
        pseudo
        usedDate
        locationId
        type
        description
        labelData
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPrintServer = /* GraphQL */ `
  query GetPrintServer($id: ID!) {
    getPrintServer(id: $id) {
      id
      endpoint
      createdAt
      updatedAt
    }
  }
`;
export const listPrintServers = /* GraphQL */ `
  query ListPrintServers(
    $filter: ModelPrintServerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrintServers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        endpoint
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
