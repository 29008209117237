import React, { Component } from 'react';
import './ListGroups.css';

import { Link } from "react-router-dom";

// UI
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import FolderIcon from '@material-ui/icons/Folder';
import { Box, Button } from '@material-ui/core';

import { isStudyManager } from '../../utils/api/user';

// Own components
import { GroupRoute } from '../../Routing.js'
import EditGroupButton from '../editGroupButton/EditGroupButton';
import moment from 'moment';

import * as constants from '../../utils/constants';
class ListGroups extends Component {
  componentDidMount() {
    this.checkGroups()
  }

  state = {
    StudyManager: false,
    activeStatus: constants.STATUS_ONLINE,
  };

  async checkGroups() {
    const StudyManager = await isStudyManager()
    this.setState({
      StudyManager: StudyManager,
    })
  }

  render() {
    const { groups } = this.props;
    let { StudyManager } = this.state;

    const ReturnSecondary = (props) => (
      <span>
        {props.version}
        {
          props.description && <><br />{props.description}</>
        }
      </span>
    )

    const ListView = ({ groups }) => (
      <div>
        <List>
          {groups.filter(c => {
            const status = c.active || constants.STATUS_ONLINE;
            return status ===  this.state.activeStatus
          }).map(group =>
            <ListItem button divider component={Link} to={GroupRoute + group.id} key={group.id}>
              <ListItemAvatar>
                <Avatar>
                  <FolderIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${group.name} - ${moment(group.date).format('DD/MM/YYYY')}`}
                secondary={<ReturnSecondary version={group.version} description={group.description} />}
              />
              {
                StudyManager ?
                  <ListItemSecondaryAction>
                    <EditGroupButton group={group} />
                  </ListItemSecondaryAction>
                  :
                  null
              }
            </ListItem>
          )}
        </List>
      </div>
    );
    return (
      <div>
        <Box paddingBottom='1.5em'>
          <Button
            variant={this.state.activeStatus === constants.STATUS_ONLINE ? 'contained' : 'outlined'}
            color='primary'
            onClick={() => this.setState({activeStatus: constants.STATUS_ONLINE})}>
            Active
          </Button>
          <Button
            variant={this.state.activeStatus === constants.STATUS_OFFLINE ? 'contained' : 'outlined'}
            color='primary'
            onClick={() => this.setState({activeStatus: constants.STATUS_OFFLINE})}>
            Inactive
          </Button>
        </Box>
        <ListView groups={groups}></ListView>
      </div>
    );
  }
}

export default ListGroups;