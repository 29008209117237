import React, { PureComponent } from 'react';

import './ListPlanning.scss';

import MaterialTable from 'material-table'
import * as constants from '../../utils/constants';
import * as taskValidationApi from '../../utils/api/taskValidation';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { LinearProgress, Tooltip } from '@material-ui/core';
import moment from 'moment';
import Utils from '../../utils/utils';

class ListPlanning extends PureComponent {
  state = {
    listAssignableTask: [],
    selectedUserId: "",
    loaded: 0,
    loading: false,
    validateDialog: false,
    rowSelected: null,
  };

  componentDidUpdate(prevProps) {
    if (this.props.lastUpdate !== prevProps.lastUpdate) {
      this.setState({ listAssignableTask: this.props.listTasks })
    }
  }

  async setValidationTaskDone(rowSelected) {
    const res = await taskValidationApi.validateTask(rowSelected.id, rowSelected.positionId, rowSelected.applicableDate);
    let found = false;
    let newList = [...this.state.listAssignableTask];
    if (Utils.checkNested(res, "data", 'createTaskValidation')) {
      this.state.listAssignableTask.forEach((task, index) => {
        if (task.id === res.data.createTaskValidation.assignableTaskId) {
          found = true;
          newList[index].isDone = true;
          newList[index].doneUser = res.data.createTaskValidation.userId;
          newList[index].doneId = res.data.createTaskValidation.id;
        }
      });
    }
    if (found) {
      this.setState({ listAssignableTask: newList })
    }
  }

  async setValidationTaskUnDone(rowSelected) {
    const res = await taskValidationApi.deleteValidateTask(rowSelected.doneId, rowSelected.doneUser);
    let found = false;
    let newList = [...this.state.listAssignableTask];
    if (Utils.checkNested(res, "data", 'deleteTaskValidation')) {
      this.state.listAssignableTask.forEach((task, index) => {
        if (task.id === res.data.deleteTaskValidation.assignableTaskId) {
          found = true;
          newList[index].isDone = false;
          newList[index].doneUser = null;
          newList[index].doneId = null;
        }
      });
    }
    if (found) {
      this.setState({ listAssignableTask: newList })
    }
  }

  render() {
    const { listAssignableTask, loading, loaded } = this.state;
    const { noInternet } = this.props;
    return (
      <div id='ListPlanning'>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"></link>
        <br />
        {
          loading ?
            <LinearProgress variant="determinate" value={loaded} />
            : null
        }
        <MaterialTable
          columns={[
            {
              title: 'Time',
              field: 'applicableDate',
              width: null,
              defaultSort: 'asc',
              render: rowData => {
                let color;
                if (rowData.activationStatus === constants.ASSIGNABLE_TASK_INACTIVE) {
                  color = "grey";
                }
                return (
                  <div style={{ color: color }}>
                    <b>
                      {moment(rowData.applicableDate).format('HH:mm')}
                    </b>
                  </div>
                )
              },
            },
            {
              title: 'Study',
              field: 'studyName',
              sorting: false,
              render: rowData => {
                let color = "#ff6666";
                if (rowData.groupValidate === true) {
                  color = "";
                }
                return (
                  <Tooltip title={color ? "Warning: Group not validated." : "Group validate."}>
                    <div style={{ width: '100%', backgroundColor: color, height: '100%' }} >
                      {rowData.studyName}{rowData.groupDescription ? '/' + rowData.groupDescription : null}
                    </div>
                  </Tooltip>
                )
              },
            },
            { title: 'TheoTime', field: 'protocolName', sorting: false, },
            {
              title: 'Participant',
              field: 'participantCode',
              sorting: false,
              render: rowData => {
                let color = "#ff6666";
                if (rowData.positionValidate === true) {
                  color = "";
                }
                return (
                  <Tooltip title={color ? "Warning: Position not validated." : "Position validate."}>
                    <div style={{ width: '100%', backgroundColor: color, height: '100%' }} >
                      {
                        rowData.positionInclusion ?
                          rowData.participantCode + "/ " + rowData.participantSelection + "/ " + rowData.positionInclusion
                          :
                          rowData.participantCode + "/ " + rowData.participantSelection + "/ " + rowData.positionName
                      }
                    </div>
                  </Tooltip>
                )
              },
            },
            { title: 'Sex', field: 'participantSex', sorting: false, },
            { title: 'Room', field: 'participantRoom', sorting: false, },
            {
              title: 'Act',
              field: 'protocolDescription',
              sorting: false,
              render: rowData => {
                return (
                  <div>
                    <div hidden={!rowData.protocolDescription}>{rowData.protocolDescription}<br /></div>
                    <div hidden={!rowData.specificDescription}><span style={{ fontWeight: "bold" }}>Specific description:</span> {rowData.specificDescription}</div>
                  </div>
                )
              },
            },
          ]}
          actions={[
            rowData => ({
              icon: 'warning_circle',
              tooltip: 'Set to done',
              iconProps: { color: rowData.applicableDate < new Date().toISOString() ? 'secondary' : 'primary' },
              onClick: (event, rowSelected) => this.setValidationTaskDone(rowSelected),
              hidden: noInternet || rowData.isDone || loading ? true : false,
            }),
            rowData => ({
              icon: 'check',
              tooltip: 'Done by ' + rowData.doneUser,
              iconProps: { style: { color: "#0E9528" } },
              onClick: (event, rowSelected) => this.setState({ rowSelected: rowSelected, validateDialog: true }),
              hidden: !noInternet && rowData.isDone ? false : true,
            })
          ]}
          options={{
            actionsColumnIndex: -1,
            paging: true,
            pageSizeOptions: [50, 100, 200, 500, 1000],
            pageSize: 50,
            sorting: true,
            search: false,
            draggable: false,
          }}
          localization={{
            header: {
              actions: 'Done?'
            },
          }}
          data={listAssignableTask}
          title=""
        />
        <Dialog
          open={this.state.validateDialog}
          onClose={() => this.setState({ validateDialog: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">You are about to unDone this task, are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`${moment(this.state.rowSelected && this.state.rowSelected.applicableDate).format('HH:mm')} - 
              ${this.state.rowSelected && this.state.rowSelected.studyName} - 
              ${this.state.rowSelected && this.state.rowSelected.protocolName} - 
              ${this.state.rowSelected && this.state.rowSelected.protocolDescription}`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ validateDialog: false })} color="primary">
              Cancel
          </Button>
            <Button onClick={() => {
              this.setValidationTaskUnDone(this.state.rowSelected)
              this.setState({ validateDialog: false })
            }} color="primary" variant="contained" autoFocus>
              Confirm
          </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default ListPlanning;