import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './DeletePartButton.css';

import * as partApi from '../../utils/api/part'

import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';

class ConfirmationDialogRaw extends React.PureComponent {
    state = {
        loading: false,
    };

    handleCancel = () => {
        this.props.onClose();
    };

    async handleConfirmation(part) {
        this.setState({ loading: true });
        if (part.id) {
            let resultDeletePart = await partApi.deletePart(part.id);
            console.log(resultDeletePart)
            if (resultDeletePart.code !== '201') {
                alert("Fail to delete the part, please retry later. " + resultDeletePart.messages[0])
            } else {
                alert('Successfully deleted the part')
            }
        }
        this.setState({ loading: false });
        this.props.onClose();
    }

    render() {
        const { part, ...other } = this.props;
        const { loading } = this.state;

        let loadingDisplay;
        if (loading) {
            loadingDisplay = <DialogContent><CircularProgress /></DialogContent>;
        }
        return (
            <Dialog
                disableEscapeKeyDown
                maxWidth="xs"
                onEntering={this.handleEntering}
                aria-labelledby="confirmation-dialog-title"
                {...other}
            >
                <DialogTitle id="confirmation-dialog-title">You are about to delete permanantly {part.name}</DialogTitle>
                <DialogContent>
                    This will delete all <b>Groups</b>, <b>Protocol tasks</b>, <b>Protocol validations</b>, <b>Position</b>
                    , <b>Participants</b>, <b>Assignable tasks</b> and <b>Task validations</b> inside this part. It will not be possible to
                    retrive those data.
                </DialogContent>
                <DialogContent>
                    Please take a deep breath and confirm that it is indeed what you want to do.
        </DialogContent>
                {loadingDisplay}
                <DialogActions>
                    <Button disabled={loading} onClick={this.handleCancel} color="primary">
                        Cancel
          </Button>
                    <Button disabled={loading} onClick={() => this.handleConfirmation(part)} color="primary">
                        Yes it is!
          </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

ConfirmationDialogRaw.propTypes = {
    onClose: PropTypes.func
};


class DeletePartButton extends PureComponent {
    state = {
        open: false,
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    deletePart(id) {
        this.setState({ open: true });
    }
    render() {
        const { part } = this.props;

        return (
            <div>
                <Button
                    style={{ color: "#d9534f" }}
                    startIcon={<DeleteIcon />}
                    onClick={() => this.deletePart(part.id)}
                >
                    Delete
                </Button>

                <ConfirmationDialogRaw
                    open={this.state.open}
                    onClose={this.handleClose}
                    part={part}
                />
            </div>
        );
    }
}

export default DeletePartButton;