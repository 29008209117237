import React from 'react';
import PropTypes from 'prop-types';

import * as assignableTasksApi from '../../../utils/api/assignableTask'

import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ClearIcon from '@material-ui/icons/Clear';

import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { Box, LinearProgress } from '@material-ui/core';
import moment from 'moment';

class TranslateAssignableTasksDialog extends React.PureComponent {
    state = {
        loading: false,
        deltaDays: 0,
        deltaHours: 0,
        deltaMinutes: 0,
        loaded: 0,
    };

    handleCancel = () => {
        this.props.onClose();
    };

    async handleConfirmation(assignableTasks, reset=false) {
        this.setState({ loading: true, loaded: 0 });
        let remainingList = [...assignableTasks];
        let totalElements = 0;
        for (let i = 0; i < assignableTasks.length; i++) {
            if (remainingList.length === 0) break
            // Batch 100 tasks together
            const batchList = remainingList.slice(0, 100);
            remainingList.splice(0, 100)
            totalElements += batchList.length;
            this.setState({ loaded: totalElements * 100 / assignableTasks.length })
            console.log(totalElements)
            await Promise.all(batchList.map(async (assignableTask) => {
                let newDelta = 0;
                if (reset === false)  newDelta = Number(this.state.deltaDays) * 1440 + Number(this.state.deltaHours) * 60 + Number(this.state.deltaMinutes) + Number(assignableTask.delta);
                if (assignableTask.delta === newDelta) {
                    console.log("No need to update")
                } else {
                    const deltaDiff = newDelta - assignableTask.delta;
                    const newApplicableDate = moment(new Date(assignableTask.applicableDate)).add(deltaDiff, 'minutes')
                    try {
                        await assignableTasksApi.updateAssignableTaskDelta(assignableTask.id, newDelta, newApplicableDate);
                    } catch (e) {
                        console.log(e.errors)
                        alert("Fail to perform the action on task: " + assignableTask.name);
                    }
                }
            }));
        }
        if (assignableTasks.length > 200) window.location.reload();
        setTimeout(() => {
            this.setState({ loading: false })
            this.props.onClose();
        }, 2000);
    }

    async handleReset(assignableTasks) {
        this.setState({ loading: true })
        for (let i = 0; i < assignableTasks.length; i++) {
            const assignableTask = assignableTasks[i];
            const newDelta = 0;
            if (assignableTask.delta === newDelta) {
                console.log("No need to update")
                continue
            }
            const deltaDiff = newDelta - assignableTask.delta;
            const newApplicableDate = moment(new Date(assignableTask.applicableDate)).add(deltaDiff, 'minutes')
            try {
                assignableTasksApi.updateAssignableTaskDelta(assignableTask.id, newDelta, newApplicableDate);
            } catch (e) {
                console.log(e.errors)
                alert("Fail to perform the action on task: " + assignableTask.name);
            }
        }
        // To compensate the time to update wait here at least 5s
        setTimeout(() => {
            if (assignableTasks.length > 200) window.location.reload();
            this.setState({ loading: false })
            this.props.onClose();
        }, Math.max(assignableTasks.length * 10, 3000));

    }

    handleChange = stateName => event => {
        this.setState({
            [stateName]: event.target.value,
        });
    };

    render() {
        let { assignableTasks, ...other } = this.props;
        let { loading, loaded } = this.state;
        if (!assignableTasks) {
            assignableTasks = [];
        }
        const LoadingComponent = React.memo(function LoadingComponent() {
            return (
                <LinearProgress variant="determinate" value={loaded} />
            )
        });
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xl"
                onEntering={this.handleEntering}
                aria-labelledby="confirmation-dialog-title"
                {...other}
            >
                <DialogTitle id="confirmation-dialog-title">You are about to translate those tasks
                {
                        loading ?
                            <LoadingComponent /> :
                            null
                    }
                </DialogTitle>
                <DialogContent>
                    The tasks on the list bellow will be translated ONLY for the current participant
                    <br />
                    <List>
                        {assignableTasks.map(assignableTask =>
                            <ListItem key={assignableTask.id}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <ClearIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={assignableTask.protocolName}
                                    secondary={moment(assignableTask.applicableDate).format('HH:mm DD/MM/YYYY')}
                                />
                            </ListItem>
                        )}
                    </List>

                    <form noValidate autoComplete="off">
                        <h4>Delta to add to the task date</h4>
                        <FormControl>
                            <InputLabel shrink>
                                Days
              </InputLabel>
                            <TextField
                                id="standard-number-days"
                                value={this.state.deltaDays}
                                onChange={this.handleChange('deltaDays')}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel shrink htmlFor="deltaHours">
                                Hours
              </InputLabel>
                            <TextField
                                id="standard-number-hours"
                                value={this.state.deltaHours}
                                onChange={this.handleChange('deltaHours')}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel shrink htmlFor="deltaMinutes">
                                Minutes
              </InputLabel>
                            <TextField
                                id="standard-number-minutes"
                                value={this.state.deltaMinutes}
                                onChange={this.handleChange('deltaMinutes')}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                            />
                        </FormControl>
                    </form>
                </DialogContent>


                <DialogActions>

                    <Button disabled={loading} onClick={() => this.handleConfirmation(assignableTasks, true)} color="secondary" variant="contained">
                        Reset to the protocol time.
                    </Button>
                    <Box flexGrow={1} />
                    <Button disabled={loading} onClick={this.handleCancel} color="primary">
                        Cancel
          </Button>
                    <Button disabled={loading} onClick={() => this.handleConfirmation(assignableTasks)} color="primary" variant="contained">
                        Go!
          </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

TranslateAssignableTasksDialog.propTypes = {
    onClose: PropTypes.func
};

export default TranslateAssignableTasksDialog;