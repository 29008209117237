import React, { Component } from 'react';
import './ListParts.css';

import { Link } from "react-router-dom";

// UI
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import FolderIcon from '@material-ui/icons/Folder';

// Own components
import { PartRoute } from '../../Routing.js'

import { isStudyManager } from '../../utils/api/user';
import EditPartButton from '../editPartButton/EditPartButton';

class ListParts extends Component {
  componentDidMount() {
    this.checkGroups()
  }

  state = {
    StudyManager: false,
  };

  async checkGroups() {
    const StudyManager = await isStudyManager()
    this.setState({
      StudyManager: StudyManager,
    })
  }

  render() {
    let { parts } = this.props;
    let { StudyManager } = this.state;
    const ListView = ({ parts }) => (
      <div>
        <List>
          {parts.map(part =>
            <ListItem button divider component={Link} to={PartRoute + part.id} key={part.id}>
              <ListItemAvatar>
                <Avatar>
                  <FolderIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={part.name}
                secondary={part.description}
              />
              {
                StudyManager ?
                  <ListItemSecondaryAction>
                    <EditPartButton part={part}/>
                  </ListItemSecondaryAction>
                  :
                  null
              }
            </ListItem>
          )}
        </List>
      </div>
    );
    return (
      <div>
        <ListView parts={parts}></ListView>
      </div>
    );
  }
}

export default ListParts;