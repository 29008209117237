/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPrintTemplate = /* GraphQL */ `
  mutation CreatePrintTemplate(
    $input: CreatePrintTemplateInput!
    $condition: ModelPrintTemplateConditionInput
  ) {
    createPrintTemplate(input: $input, condition: $condition) {
      id
      templatePath
      name
      printerId
      printer {
        id
        printerNetworkId
        friendlyName
        active
        createdAt
        updatedAt
      }
      active
      createdAt
      updatedAt
    }
  }
`;
export const updatePrintTemplate = /* GraphQL */ `
  mutation UpdatePrintTemplate(
    $input: UpdatePrintTemplateInput!
    $condition: ModelPrintTemplateConditionInput
  ) {
    updatePrintTemplate(input: $input, condition: $condition) {
      id
      templatePath
      name
      printerId
      printer {
        id
        printerNetworkId
        friendlyName
        active
        createdAt
        updatedAt
      }
      active
      createdAt
      updatedAt
    }
  }
`;
export const deletePrintTemplate = /* GraphQL */ `
  mutation DeletePrintTemplate(
    $input: DeletePrintTemplateInput!
    $condition: ModelPrintTemplateConditionInput
  ) {
    deletePrintTemplate(input: $input, condition: $condition) {
      id
      templatePath
      name
      printerId
      printer {
        id
        printerNetworkId
        friendlyName
        active
        createdAt
        updatedAt
      }
      active
      createdAt
      updatedAt
    }
  }
`;
export const createPrinter = /* GraphQL */ `
  mutation CreatePrinter(
    $input: CreatePrinterInput!
    $condition: ModelPrinterConditionInput
  ) {
    createPrinter(input: $input, condition: $condition) {
      id
      printerNetworkId
      friendlyName
      active
      createdAt
      updatedAt
    }
  }
`;
export const updatePrinter = /* GraphQL */ `
  mutation UpdatePrinter(
    $input: UpdatePrinterInput!
    $condition: ModelPrinterConditionInput
  ) {
    updatePrinter(input: $input, condition: $condition) {
      id
      printerNetworkId
      friendlyName
      active
      createdAt
      updatedAt
    }
  }
`;
export const deletePrinter = /* GraphQL */ `
  mutation DeletePrinter(
    $input: DeletePrinterInput!
    $condition: ModelPrinterConditionInput
  ) {
    deletePrinter(input: $input, condition: $condition) {
      id
      printerNetworkId
      friendlyName
      active
      createdAt
      updatedAt
    }
  }
`;
export const createLocationCat = /* GraphQL */ `
  mutation CreateLocationCat(
    $input: CreateLocationCatInput!
    $condition: ModelLocationCatConditionInput
  ) {
    createLocationCat(input: $input, condition: $condition) {
      id
      name
      prefix
      active
      createdAt
      updatedAt
    }
  }
`;
export const updateLocationCat = /* GraphQL */ `
  mutation UpdateLocationCat(
    $input: UpdateLocationCatInput!
    $condition: ModelLocationCatConditionInput
  ) {
    updateLocationCat(input: $input, condition: $condition) {
      id
      name
      prefix
      active
      createdAt
      updatedAt
    }
  }
`;
export const deleteLocationCat = /* GraphQL */ `
  mutation DeleteLocationCat(
    $input: DeleteLocationCatInput!
    $condition: ModelLocationCatConditionInput
  ) {
    deleteLocationCat(input: $input, condition: $condition) {
      id
      name
      prefix
      active
      createdAt
      updatedAt
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      id
      locationCatId
      name
      friendlyName
      active
      createdAt
      updatedAt
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      id
      locationCatId
      name
      friendlyName
      active
      createdAt
      updatedAt
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      id
      locationCatId
      name
      friendlyName
      active
      createdAt
      updatedAt
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      selector
      active
      comment
      createdAt
      updatedAt
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      selector
      active
      comment
      createdAt
      updatedAt
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      selector
      active
      comment
      createdAt
      updatedAt
    }
  }
`;
export const createSampleProtocolTemplate = /* GraphQL */ `
  mutation CreateSampleProtocolTemplate(
    $input: CreateSampleProtocolTemplateInput!
    $condition: ModelSampleProtocolTemplateConditionInput
  ) {
    createSampleProtocolTemplate(input: $input, condition: $condition) {
      id
      partId
      active
      name
      sponsorName
      studyName
      validationDate
      validateUserId
      validationDateSecond
      validateUserIdSecond
      createdAt
      updatedAt
    }
  }
`;
export const updateSampleProtocolTemplate = /* GraphQL */ `
  mutation UpdateSampleProtocolTemplate(
    $input: UpdateSampleProtocolTemplateInput!
    $condition: ModelSampleProtocolTemplateConditionInput
  ) {
    updateSampleProtocolTemplate(input: $input, condition: $condition) {
      id
      partId
      active
      name
      sponsorName
      studyName
      validationDate
      validateUserId
      validationDateSecond
      validateUserIdSecond
      createdAt
      updatedAt
    }
  }
`;
export const deleteSampleProtocolTemplate = /* GraphQL */ `
  mutation DeleteSampleProtocolTemplate(
    $input: DeleteSampleProtocolTemplateInput!
    $condition: ModelSampleProtocolTemplateConditionInput
  ) {
    deleteSampleProtocolTemplate(input: $input, condition: $condition) {
      id
      partId
      active
      name
      sponsorName
      studyName
      validationDate
      validateUserId
      validationDateSecond
      validateUserIdSecond
      createdAt
      updatedAt
    }
  }
`;
export const createListing = /* GraphQL */ `
  mutation CreateListing(
    $input: CreateListingInput!
    $condition: ModelListingConditionInput
  ) {
    createListing(input: $input, condition: $condition) {
      id
      sampleProtocolTemplateId
      name
      printTemplateId
      printTemplate {
        id
        templatePath
        name
        printerId
        printer {
          id
          printerNetworkId
          friendlyName
          active
          createdAt
          updatedAt
        }
        active
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateListing = /* GraphQL */ `
  mutation UpdateListing(
    $input: UpdateListingInput!
    $condition: ModelListingConditionInput
  ) {
    updateListing(input: $input, condition: $condition) {
      id
      sampleProtocolTemplateId
      name
      printTemplateId
      printTemplate {
        id
        templatePath
        name
        printerId
        printer {
          id
          printerNetworkId
          friendlyName
          active
          createdAt
          updatedAt
        }
        active
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteListing = /* GraphQL */ `
  mutation DeleteListing(
    $input: DeleteListingInput!
    $condition: ModelListingConditionInput
  ) {
    deleteListing(input: $input, condition: $condition) {
      id
      sampleProtocolTemplateId
      name
      printTemplateId
      printTemplate {
        id
        templatePath
        name
        printerId
        printer {
          id
          printerNetworkId
          friendlyName
          active
          createdAt
          updatedAt
        }
        active
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSampleTemplate = /* GraphQL */ `
  mutation CreateSampleTemplate(
    $input: CreateSampleTemplateInput!
    $condition: ModelSampleTemplateConditionInput
  ) {
    createSampleTemplate(input: $input, condition: $condition) {
      id
      listingId
      theoreticalTime
      theoreticalTimeEnd
      theoreticalTimeLabel
      period
      matrixName
      index
      createdAt
      updatedAt
    }
  }
`;
export const updateSampleTemplate = /* GraphQL */ `
  mutation UpdateSampleTemplate(
    $input: UpdateSampleTemplateInput!
    $condition: ModelSampleTemplateConditionInput
  ) {
    updateSampleTemplate(input: $input, condition: $condition) {
      id
      listingId
      theoreticalTime
      theoreticalTimeEnd
      theoreticalTimeLabel
      period
      matrixName
      index
      createdAt
      updatedAt
    }
  }
`;
export const deleteSampleTemplate = /* GraphQL */ `
  mutation DeleteSampleTemplate(
    $input: DeleteSampleTemplateInput!
    $condition: ModelSampleTemplateConditionInput
  ) {
    deleteSampleTemplate(input: $input, condition: $condition) {
      id
      listingId
      theoreticalTime
      theoreticalTimeEnd
      theoreticalTimeLabel
      period
      matrixName
      index
      createdAt
      updatedAt
    }
  }
`;
export const createAlicotTemplate = /* GraphQL */ `
  mutation CreateAlicotTemplate(
    $input: CreateAlicotTemplateInput!
    $condition: ModelAlicotTemplateConditionInput
  ) {
    createAlicotTemplate(input: $input, condition: $condition) {
      id
      listingId
      indexPrefix
      indexFirst
      number
      matrixName
      printTemplateId
      printTemplate {
        id
        templatePath
        name
        printerId
        printer {
          id
          printerNetworkId
          friendlyName
          active
          createdAt
          updatedAt
        }
        active
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAlicotTemplate = /* GraphQL */ `
  mutation UpdateAlicotTemplate(
    $input: UpdateAlicotTemplateInput!
    $condition: ModelAlicotTemplateConditionInput
  ) {
    updateAlicotTemplate(input: $input, condition: $condition) {
      id
      listingId
      indexPrefix
      indexFirst
      number
      matrixName
      printTemplateId
      printTemplate {
        id
        templatePath
        name
        printerId
        printer {
          id
          printerNetworkId
          friendlyName
          active
          createdAt
          updatedAt
        }
        active
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAlicotTemplate = /* GraphQL */ `
  mutation DeleteAlicotTemplate(
    $input: DeleteAlicotTemplateInput!
    $condition: ModelAlicotTemplateConditionInput
  ) {
    deleteAlicotTemplate(input: $input, condition: $condition) {
      id
      listingId
      indexPrefix
      indexFirst
      number
      matrixName
      printTemplateId
      printTemplate {
        id
        templatePath
        name
        printerId
        printer {
          id
          printerNetworkId
          friendlyName
          active
          createdAt
          updatedAt
        }
        active
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCohort = /* GraphQL */ `
  mutation CreateCohort(
    $input: CreateCohortInput!
    $condition: ModelCohortConditionInput
  ) {
    createCohort(input: $input, condition: $condition) {
      id
      partId
      name
      positionList
      active
      createdAt
      updatedAt
    }
  }
`;
export const updateCohort = /* GraphQL */ `
  mutation UpdateCohort(
    $input: UpdateCohortInput!
    $condition: ModelCohortConditionInput
  ) {
    updateCohort(input: $input, condition: $condition) {
      id
      partId
      name
      positionList
      active
      createdAt
      updatedAt
    }
  }
`;
export const deleteCohort = /* GraphQL */ `
  mutation DeleteCohort(
    $input: DeleteCohortInput!
    $condition: ModelCohortConditionInput
  ) {
    deleteCohort(input: $input, condition: $condition) {
      id
      partId
      name
      positionList
      active
      createdAt
      updatedAt
    }
  }
`;
export const createLabel = /* GraphQL */ `
  mutation CreateLabel(
    $input: CreateLabelInput!
    $condition: ModelLabelConditionInput
  ) {
    createLabel(input: $input, condition: $condition) {
      id
      cohortId
      serial
      theoreticalTime
      theoreticalTimeLabel
      theoreticalTimeEnd
      period
      matrixName
      index
      printTemplateId
      printTemplate {
        id
        templatePath
        name
        printerId
        printer {
          id
          printerNetworkId
          friendlyName
          active
          createdAt
          updatedAt
        }
        active
        createdAt
        updatedAt
      }
      printed
      active
      studyName
      sponsorName
      inclusion
      parentId
      firstFillEventTime
      firstFillEventUserAndPseudo
      firstCentriEventTime
      firstCentriEventUserAndPseudo
      firstFreezeEventTime
      firstFreezeEventUserAndPseudo
      firstOutEventTime
      firstOutEventUserAndPseudo
      lastEventLocationID
      lastEventType
      lastEventTime
      lastEventAuthor
      comments
      createdAt
      updatedAt
    }
  }
`;
export const updateLabel = /* GraphQL */ `
  mutation UpdateLabel(
    $input: UpdateLabelInput!
    $condition: ModelLabelConditionInput
  ) {
    updateLabel(input: $input, condition: $condition) {
      id
      cohortId
      serial
      theoreticalTime
      theoreticalTimeLabel
      theoreticalTimeEnd
      period
      matrixName
      index
      printTemplateId
      printTemplate {
        id
        templatePath
        name
        printerId
        printer {
          id
          printerNetworkId
          friendlyName
          active
          createdAt
          updatedAt
        }
        active
        createdAt
        updatedAt
      }
      printed
      active
      studyName
      sponsorName
      inclusion
      parentId
      firstFillEventTime
      firstFillEventUserAndPseudo
      firstCentriEventTime
      firstCentriEventUserAndPseudo
      firstFreezeEventTime
      firstFreezeEventUserAndPseudo
      firstOutEventTime
      firstOutEventUserAndPseudo
      lastEventLocationID
      lastEventType
      lastEventTime
      lastEventAuthor
      comments
      createdAt
      updatedAt
    }
  }
`;
export const deleteLabel = /* GraphQL */ `
  mutation DeleteLabel(
    $input: DeleteLabelInput!
    $condition: ModelLabelConditionInput
  ) {
    deleteLabel(input: $input, condition: $condition) {
      id
      cohortId
      serial
      theoreticalTime
      theoreticalTimeLabel
      theoreticalTimeEnd
      period
      matrixName
      index
      printTemplateId
      printTemplate {
        id
        templatePath
        name
        printerId
        printer {
          id
          printerNetworkId
          friendlyName
          active
          createdAt
          updatedAt
        }
        active
        createdAt
        updatedAt
      }
      printed
      active
      studyName
      sponsorName
      inclusion
      parentId
      firstFillEventTime
      firstFillEventUserAndPseudo
      firstCentriEventTime
      firstCentriEventUserAndPseudo
      firstFreezeEventTime
      firstFreezeEventUserAndPseudo
      firstOutEventTime
      firstOutEventUserAndPseudo
      lastEventLocationID
      lastEventType
      lastEventTime
      lastEventAuthor
      comments
      createdAt
      updatedAt
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      labelId
      owner
      pseudo
      usedDate
      locationId
      type
      description
      labelData
      createdAt
      updatedAt
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      labelId
      owner
      pseudo
      usedDate
      locationId
      type
      description
      labelData
      createdAt
      updatedAt
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      labelId
      owner
      pseudo
      usedDate
      locationId
      type
      description
      labelData
      createdAt
      updatedAt
    }
  }
`;
export const createPrintServer = /* GraphQL */ `
  mutation CreatePrintServer(
    $input: CreatePrintServerInput!
    $condition: ModelPrintServerConditionInput
  ) {
    createPrintServer(input: $input, condition: $condition) {
      id
      endpoint
      createdAt
      updatedAt
    }
  }
`;
export const updatePrintServer = /* GraphQL */ `
  mutation UpdatePrintServer(
    $input: UpdatePrintServerInput!
    $condition: ModelPrintServerConditionInput
  ) {
    updatePrintServer(input: $input, condition: $condition) {
      id
      endpoint
      createdAt
      updatedAt
    }
  }
`;
export const deletePrintServer = /* GraphQL */ `
  mutation DeletePrintServer(
    $input: DeletePrintServerInput!
    $condition: ModelPrintServerConditionInput
  ) {
    deletePrintServer(input: $input, condition: $condition) {
      id
      endpoint
      createdAt
      updatedAt
    }
  }
`;
