import React, { PureComponent } from 'react';
import './Group.css';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { GroupParticipantsRoute, GroupProtocolRoute, PartRoute, GroupPositionsRoute } from '../../Routing';
import { Link } from "react-router-dom";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import FolderIcon from '@material-ui/icons/Folder';
import ArrowBack from '@material-ui/icons/ArrowBack';

import { Button } from '@material-ui/core';
import * as groupApi from '../../utils/api/group';

class Group extends PureComponent {
    state = {
        groupInfo: null,
    };

    componentDidMount() {
        this.queryGroupInfo(this.props.match.params.id);
    }

    async queryGroupInfo(groupID) {
        const queryGroup = await groupApi.getGroupById(groupID)
        this.setState({
            groupInfo: queryGroup,
        });
    }

    render() {
        const { match } = this.props
        const { groupInfo } = this.state;
        const name = groupInfo ? groupInfo.name : null;
        const description = groupInfo ? groupInfo.description : null;

        const groupId = match.params.id
        return (
            <div>
                <Typography variant="h4" color="inherit" >
                    Group: {name}
                </Typography>
                <Divider variant="middle" />

                {
                    groupInfo ?
                        <Button color="primary" component={Link} to={PartRoute + groupInfo.partId} >
                            <ArrowBack />
                            Back to part
                        </Button>
                        :
                        <Button color="primary" >
                            <ArrowBack />
                            Loading...
                        </Button>
                }
                {
                    description && description !== " " ?
                        <div className="description">
                            <Typography color="inherit">
                                Description: {description}
                            </Typography>
                        </div>
                        :
                        null
                }
                <div className="padding-top">
                    <List>
                        <ListItem button divider onClick={() => window.location.replace(GroupParticipantsRoute + groupId)}>
                            <ListItemAvatar>
                                <Avatar>
                                    <FolderIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary="Participants"
                            />
                        </ListItem>
                        <ListItem button divider onClick={() => window.location.replace(GroupPositionsRoute + groupId)}>
                            <ListItemAvatar>
                                <Avatar>
                                    <FolderIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary="Positions"
                            />
                        </ListItem>
                        <ListItem button divider onClick={() => window.location.replace(GroupProtocolRoute + groupId)}>
                            <ListItemAvatar>
                                <Avatar>
                                    <FolderIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary="Tasks"
                            />
                        </ListItem>
                    </List>
                </div>
            </div>
        );
    }
}

export default Group;