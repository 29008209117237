import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './AddPosition.css';

import * as positionApi from '../../utils/api/position'
import * as participantApi from '../../utils/api/participant'

import * as constants from '../../utils/constants'

// UI
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker
} from '@material-ui/pickers';

import { Divider, Box, InputLabel, Select, MenuItem } from '@material-ui/core';

class AddPositionDialog extends React.PureComponent {
    state = {
        errors: [],
        loading: false,
        name: "",
        inclusion: "",
        treatment: "",
        t0: new Date().toISOString(),
        dose: "",
        participantId: "",
        participantList: [],
    };

    componentDidUpdate(prevProps) {
        if (this.props.groupID && this.props.groupID !== prevProps.groupID) {
            this.getParticipantsList(this.props.groupID);
        }
    }

    async getParticipantsList(groupId) {
        if (groupId) {
            let participantList = await participantApi.listParticipantByGroup(groupId)
            participantList = participantList.items ? participantList.items : [];
            participantList.push({ id: constants.POSITION_UNASSIGNED, code: 'Unassigned' })
            this.setState({
                participantList: participantList,
            })
        }
    }

    handleCancel = () => {
        this.props.onClose();
    };

    async handleConfirmation() {
        let errors = [];

        if (!this.state.name || !this.state.t0) {
            errors.push('Please fill all mandatory fields (*)');
            this.setState({
                errors: errors,
            });
            return
        }
        this.setState({ loading: true });

        if (!this.props.groupID) {
            return
        }
        try {
            let createPositionResult = await positionApi.createPosition(this.props.groupID,
                this.state.name,
                this.state.t0,
                this.state.inclusion,
                this.state.treatment,
                this.state.dose,
                this.state.participantId);
            if (createPositionResult.code !== "201") {
                createPositionResult.messages.forEach(message => {
                    errors.push(message)
                });
            }
        } catch (e) {
            console.log(e)
            e.errors.forEach(error => {
                errors.push(error.message)
            });
        }

        this.setState({ loading: false });
        if (errors.length > 0) {
            this.setState({
                errors: errors,
            });
        } else {
            this.props.onClose();
        }
    }

    handleOk = () => {
        this.props.onClose();
    };

    handleChange = stateName => event => {
        this.setState({
            [stateName]: event.target.value,
        });
    };
    handleDateChange = stateName => date => {
        this.setState({
            [stateName]: date,
        });
    };

    render() {
        const { groupID, ...other } = this.props;
        const { errors,
            loading,
            name,
            inclusion,
            treatment,
            t0,
            dose,
            participantId,
            participantList
        } = this.state;

        let loadingDisplay;
        if (loading) {
            loadingDisplay = <DialogContent><CircularProgress /></DialogContent>;
        }

        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="md"
                aria-labelledby="confirmation-dialog-title"
                {...other}
            >
                <DialogTitle id="confirmation-dialog-title">You are about to add a new position to this group</DialogTitle>
                <DialogContent>
                    Please fill the information below:
                    <form noValidate autoComplete="off">
                        <Box marginBottom={2}>
                            <TextField
                                required
                                id="name"
                                label="Position name"
                                value={name}
                                onChange={this.handleChange('name')}
                                margin="normal"
                            />
                        </Box>
                        <Divider />
                        <Box marginBottom={2}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDateTimePicker
                                    required
                                    margin="dense"
                                    ampm={false}
                                    label="T0"
                                    format="dd/MM/yyyy HH:mm"
                                    value={t0}
                                    onChange={this.handleDateChange('t0')}

                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Box>
                        <Divider />
                        <Box marginBottom={2}>
                            <TextField
                                id="inclusion"
                                label="Inclusion number"
                                value={inclusion}
                                onChange={this.handleChange('inclusion')}
                                margin="normal"
                            />
                        </Box>
                        <Divider />
                        <Box marginBottom={2}>
                            <TextField
                                id="treatment"
                                label="Treatment number"
                                value={treatment}
                                onChange={this.handleChange('treatment')}
                                margin="normal"
                            />
                        </Box>
                        <Divider />
                        <Box marginBottom={2}>
                            <TextField
                                id="dose"
                                label="Dose"
                                value={dose}
                                onChange={this.handleChange('dose')}
                                margin="normal"
                            />
                        </Box>
                        <Divider />
                        <Box marginBottom={2} marginTop={2}>
                            <InputLabel id="select-participant-label">Participant</InputLabel>
                            <Select
                                labelId="select-participant-label"
                                //disabled={!isParticipantManager}
                                id="select-participant"
                                value={participantId}
                                onChange={this.handleChange('participantId')}
                            >
                                {participantList.map((value, index) => {
                                    return <MenuItem value={value.id} key={index}>{value.code}</MenuItem>
                                })}
                            </Select>
                        </Box>
                    </form>
                </DialogContent>
                {loadingDisplay}
                <DialogContent>
                    <ul>
                        {errors.map((value, index) => {
                            return <li style={{ color: "red" }} key={index}>{value}</li>
                        })}
                    </ul>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={this.handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button variant="contained" disabled={loading} onClick={() => this.handleConfirmation()} color="primary">
                        Add!
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

AddPositionDialog.propTypes = {
    onClose: PropTypes.func
};

class AddPosition extends PureComponent {
    state = {
        dialogOpen: false,
    };

    handleClose = () => {
        this.setState({ dialogOpen: false });
    };

    handleOpen() {
        this.setState({ dialogOpen: true });
    }

    render() {
        return (
            <div>
                <Button variant="contained" color="primary" onClick={() => this.handleOpen()} disabled={this.props.loading}>
                    <AddIcon />
                    Add a position to this group
                </Button>

                <AddPositionDialog
                    open={this.state.dialogOpen}
                    onClose={this.handleClose}
                    groupID={this.props.groupID}
                />
            </div>
        );
    }
}

export default AddPosition;