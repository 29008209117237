import React, { useEffect, useState } from 'react';
import { getValidationStatus } from '../../utils/api/protocolValidation';

import * as user from '../../utils/api/user';
import * as protocolValidationApi from '../../utils/api/protocolValidation'
import * as protocolCommentApi from '../../utils/api/protocolComment'
import * as subscriptions from '../../graphql/subscriptions';

import { API, graphqlOperation } from 'aws-amplify';

import Utils from '../../utils/utils';
export const ValidateProtocolButtonContext = React.createContext();

const ValidateProtocolButtonContextProvider = (props) => {
    const [validationStatus, setValidationStatus] = useState(null);
    const [group, setGroup] = useState(null);
    const [isStudyValidator, setIsStudyValidator] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [trackerItems, setTrackerItems] = useState([]);
    const [messagesItems, setMessagesItems] = useState([]);
    const [paginationTracker, setPaginationTracker] = useState({ 'tokens': [null], 'index': 0 });
    const [paginationMessages, setPaginationMessages] = useState({ 'tokens': [null], 'index': 0 });

    useEffect(() => {
        let subscriptionNewComment, subscriptionNewValidation, subscriptionUpdateComment;
        if (props.group.id) {
            setGroup(props.group)
            initData();
            loadData();
            // Subscribe to new event (comment or protocol validation)
            subscriptionNewComment = subscribeNewComment();
            subscriptionNewValidation = subscribeNewValidation();
            // Comments can be crossed subscrible to this event too
            subscriptionUpdateComment = subscribeUpdateComment();
        }
        return () => {
            if (subscriptionNewComment) subscriptionNewComment.unsubscribe();
            if (subscriptionUpdateComment) subscriptionUpdateComment.unsubscribe();
            if (subscriptionNewValidation) subscriptionNewValidation.unsubscribe();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.group.id])

    const subscribeNewComment = () => {
        return API.graphql(
            graphqlOperation(subscriptions.onCreateProtocolComment)
        ).subscribe({
            next: (protocolCommentData) => {
                const newItem = protocolCommentData.value.data.onCreateProtocolComment;
                if (newItem.groupId === props.group.id) {
                    setMessagesItems((prev) => {
                        let newProtocolCommentItems = [...prev];
                        newProtocolCommentItems.unshift(newItem);
                        return newProtocolCommentItems
                    });
                }
            }
        });
    }

    const subscribeUpdateComment = () => {
        return API.graphql(
            graphqlOperation(subscriptions.onUpdateProtocolComment)
        ).subscribe({
            next: (protocolCommentData) => {
                const newItem = protocolCommentData.value.data.onUpdateProtocolComment;
                if (newItem.groupId === props.group.id) {
                    setMessagesItems((prev) => {
                        let newProtocolCommentItems = [...prev];
                        for (let i = 0; i < newProtocolCommentItems.length; i++) {
                            if (newItem.id === newProtocolCommentItems[i].id) {
                                newProtocolCommentItems[i] = newItem
                                break
                            }
                        }
                        return newProtocolCommentItems
                    })
                }
            }
        });
    }

    const subscribeNewValidation = () => {
        return API.graphql(
            graphqlOperation(subscriptions.onCreateProtocolValidation)
        ).subscribe({
            next: (protocolValidationData) => {
                const newItem = protocolValidationData.value.data.onCreateProtocolValidation;
                if (newItem.groupId === props.group.id) {
                    setTrackerItems((prev) => {
                        let newProtocolTrackerItems = [...prev];
                        newProtocolTrackerItems.unshift(newItem);
                        return newProtocolTrackerItems
                    })
                    // Get protocol Status
                    updateValidationStatus();
                }
            }
        });
    }

    const nextPageTracker = async () => {
        if (paginationTracker.index >= paginationTracker.tokens.length - 1) return
        const nextToken = paginationTracker.tokens[paginationTracker.index + 1];
        setPaginationTracker((prev) => {
            let newPagination = { ...prev };
            newPagination.index++;
            return newPagination
        })
        loadTracker(nextToken)
    }

    const prevPageTracker = async () => {
        if (paginationTracker.index < 1) return
        const prevToken = paginationTracker.tokens[paginationTracker.index - 1];
        setPaginationTracker((prev) => {
            let newPagination = { ...prev };
            newPagination.index--;
            return newPagination
        })
        loadTracker(prevToken)
    }

    const loadTracker = async (token = null) => {
        setIsLoading(true);
        const protocolValidation = await protocolValidationApi.listProtocolValidationByGroup(props.group.id, token);
        setIsLoading(false);

        const protocolValidationItems = Utils.checkNested(protocolValidation, "items") ? protocolValidation.items : [];
        if (protocolValidation && protocolValidation.nextToken) {
            setPaginationTracker((prev) => {
                let newPagination = { ...prev };
                if (prev.tokens.length - 1 === prev.index) newPagination.tokens.push(protocolValidation.nextToken)
                else if (prev.index < prev.tokens.length - 1) newPagination.tokens.splice(prev.index + 1, 1, protocolValidation.nextToken)
                return newPagination
            })
        }
        setTrackerItems(protocolValidationItems)
    }

    const nextPageMessages = async () => {
        if (paginationMessages.index >= paginationMessages.tokens.length - 1) return
        const nextToken = paginationMessages.tokens[paginationMessages.index + 1];
        setPaginationMessages((prev) => {
            let newPagination = { ...prev };
            newPagination.index++;
            return newPagination
        })
        loadMessages(nextToken)
    }

    const prevPageMessages = async () => {
        if (paginationMessages.index < 1) return
        const prevToken = paginationMessages.tokens[paginationMessages.index - 1];
        setPaginationMessages((prev) => {
            let newPagination = { ...prev };
            newPagination.index--;
            return newPagination
        })
        loadMessages(prevToken)
    }

    const loadMessages = async (token = null) => {
        setIsLoading(true);
        const protocolComment = await protocolCommentApi.listProtocolCommentByGroup(props.group.id, token);
        setIsLoading(false);

        const protocolCommentItems = Utils.checkNested(protocolComment, "items") ? protocolComment.items : [];
        if (protocolComment && protocolComment.nextToken) {
            setPaginationMessages((prev) => {
                let newPagination = { ...prev };
                if (prev.tokens.length - 1 === prev.index) newPagination.tokens.push(protocolComment.nextToken)
                else if (prev.index < prev.tokens.length - 1) newPagination.tokens.splice(prev.index + 1, 1, protocolComment.nextToken)

                return newPagination
            })
        }
        setMessagesItems(protocolCommentItems)
    }

    const loadData = async () => {
        // Load protocol validation (up to 100 items)
        await loadTracker(paginationTracker.tokens[paginationTracker.index]);

        // load comments (up to 20 items)
        await loadMessages(paginationMessages.tokens[paginationMessages.index])
    }

    const initData = async () => {
        // Get user role
        const resIsStudyValidator = await user.isStudyValidator();
        setIsStudyValidator(resIsStudyValidator);

        // Get protocol Status
        updateValidationStatus()
    }

    const updateValidationStatus = async () => {
        const newStatus = await getValidationStatus(props.group.id);
        setValidationStatus(newStatus);
    }

    return <ValidateProtocolButtonContext.Provider value={{
        validationStatus,
        group,
        isStudyValidator,
        isLoading,
        messagesItems,
        paginationMessages,
        nextPageMessages,
        prevPageMessages,
        trackerItems,
        paginationTracker,
        nextPageTracker,
        prevPageTracker
    }}>
        {props.children}
    </ValidateProtocolButtonContext.Provider>
}

export default ValidateProtocolButtonContextProvider