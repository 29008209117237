import React from 'react';

// Import pages
import ScannedTubes from './pages/scannedTubes/ScannedTubes.tsx';
import Studies from './pages/studies/Studies.js'
import Planning from './pages/planning/Planning.js'
import Assignation from './pages/assignation/Assignation.js'
import UsersManagment from './pages/usersManagment/UsersManagment.js'
import Study from './pages/study/Study.js'
import StudyCheck from './pages/studyCheck/StudyCheck.js'
import Part from './pages/part/Part.js'
import Group from './pages/group/Group.js'
import GroupParticipants from './pages/groupParticipants/GroupParticipants.js'
import GroupProtocol from './pages/groupProtocol/GroupProtocol.js'
import TasksPosition from './pages/tasksPosition/TasksPosition.js'
import GroupPositions from './pages/groupPositions/GroupPositions.js';
import Reports from './pages/reports/Reports';
import ReportStaff from './pages/reportStaff/ReportStaff';
import ReportParticipant from './pages/reportParticipant/ReportParticipant';
import ReportTaskType from './pages/reportTaskType/ReportTaskType';
import ReportTaskByStudy from './pages/reportTaskByStudy/ReportTaskByStudy';
import ReportPresence from './pages/reportPresence/ReportPresence';

// Import react router
import { Route } from "react-router-dom";
import Supervisor from './pages/supervisor/Supervisor.js';

//export const IndexRoute = "/";
export const StudiesRoute = "/studies/";
export const PlanningRoute = "/";
export const SupervisorRoute = "/supervisor/";
export const AssignationRoute = "/assignation/";
export const UsersManagmentRoute = "/users-managment/";
export const StudyRoute = "/study/";
export const PartRoute = "/part/";
export const GroupRoute = "/group/";
export const GroupParticipantsRoute = "/group-participants/";
export const GroupPositionsRoute = "/group-positions/";
export const GroupProtocolRoute = "/group-protocol/";
export const TasksPositionRoute = "/tasks-position/";
export const ReportsRoute = "/reports/";
export const ReportStaffRoute = "/report-staff/";
export const ReportParticipantRoute = "/report-participant/";
export const ReportTaskTypeRoute = "/report-tasktype/";
export const ReportPresenceRoute = "/report-presence/";
export const ReportTaskByStudyRoute = "/report-tasks-study/";
export const ScannedTubesRoute = "/scan-history";

export const StudyCheckRoute = "/study-check/";

class Routing extends React.PureComponent {
      render() {
        return (
            <div>
                {/*<Route path={IndexRoute} exact component={Home} /> */}
                <Route path={ScannedTubesRoute} component={ScannedTubes} />
                <Route path={StudiesRoute} component={Studies} />
                <Route path={PlanningRoute} exact component={Planning} />
                <Route path={SupervisorRoute} exact component={Supervisor} />
                <Route path={AssignationRoute} component={Assignation} />
                <Route path={UsersManagmentRoute} component={UsersManagment} />
                <Route path={StudyRoute + ":id"} component={Study} />
                <Route path={PartRoute + ":id"} component={Part} />
                <Route path={GroupRoute + ":id"} component={Group} />
                <Route path={GroupParticipantsRoute + ":id"} component={GroupParticipants} />
                <Route path={GroupPositionsRoute + ":id"} component={GroupPositions} />
                <Route path={GroupProtocolRoute + ":id"} component={GroupProtocol} />
                <Route path={TasksPositionRoute + ":id"} component={TasksPosition} />
                <Route path={ReportsRoute} component={Reports} />
                <Route path={ReportStaffRoute} component={ReportStaff} />
                <Route path={ReportParticipantRoute} component={ReportParticipant} />
                <Route path={ReportTaskTypeRoute} component={ReportTaskType} />
                <Route path={ReportPresenceRoute} component={ReportPresence} />
                <Route path={ReportTaskByStudyRoute} component={ReportTaskByStudy} />
                <Route path={StudyCheckRoute + ":id"} component={StudyCheck} />
            </div>

        );
    }
}

export default Routing;
