import React, { useEffect, useState, useContext } from 'react';

import MessageIcon from '@material-ui/icons/Message';
import DeleteIcon from '@material-ui/icons/Delete';

import {
    Box,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Divider,
    ListItemSecondaryAction,
    IconButton,
    Button,
    CircularProgress
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import * as protocolCommentApi from '../../utils/api/protocolComment'
import * as userApi from '../../utils/api/user'

import moment from 'moment';
import * as constants from '../../utils/constants'

import { ValidateProtocolButtonContext } from './ValidateProtocolButtonContextProvider';

const MessagesList = (props) => {
    const [username, setUsername] = useState("");
    const currentCtx = useContext(ValidateProtocolButtonContext);

    useEffect(() => {
        getUsername()
    }, [])

    const getUsername = async () => {
        let resUsername = await userApi.getCurrentUsername()
        setUsername(resUsername)
    }

    const returnCommentFormat = (comment) => {
        let ret = comment.replace(constants.CROSS_KEYWORD, '');
        return ret
    }

    const isCrossed = (comment) => {
        return comment.includes(constants.CROSS_KEYWORD);
    }

    const crossComment = (item) => {
        protocolCommentApi.crossProtocolComment(item)
    }

    const isAuthor = (item) => {
        if (username === item.user) return true
        return false
    }

    const Pagination = () => (
        <Box display="flex" flexWrap="wrap" alignItems="center" marginBottom={2} marginTop={2}>
            <Box>
                <Button
                    startIcon={<ArrowBackIosIcon />}
                    disabled={!currentCtx.paginationMessages
                        || currentCtx.paginationMessages?.index === 0
                        || currentCtx.isLoading}
                    onClick={() => currentCtx.prevPageMessages()}>
                    prev
                    </Button>
            </Box>
            <Box flexGrow={1} />
            {currentCtx.isLoading && <CircularProgress />}
            <Box flexGrow={1} />
            <Box>
                <Button
                    endIcon={<ArrowForwardIosIcon />}
                    disabled={!currentCtx.paginationMessages
                        || currentCtx.paginationMessages?.index >= currentCtx.paginationMessages?.tokens.length - 1
                        || currentCtx.isLoading}
                    onClick={() => currentCtx.nextPageMessages()}>
                    next
                    </Button>
            </Box>
        </Box>
    )

    return (
        <React.Fragment>
            <Pagination />

            <List dense={true}>
                {currentCtx.messagesItems.map((item, id) =>
                    <Box key={id} style={{ whiteSpace: "pre" }}>
                        <ListItem>
                            <ListItemIcon>
                                <MessageIcon />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ style: { whiteSpace: "normal", textDecoration: isCrossed(item.comment) ? "line-through" : "none" } }}
                                primary={returnCommentFormat(item.comment)}
                                secondary={item.user + " " + moment(item.date).format("HH:mm DD/MM/YYYY")}
                            />
                            {
                                isAuthor(item) ?
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end"
                                            aria-label="delete"
                                            disabled={isCrossed(item.comment)}
                                            onClick={() => crossComment(item)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                    :
                                    null
                            }
                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </Box>
                )}
            </List>
            <Pagination />
        </React.Fragment>
    )
}

export default MessagesList