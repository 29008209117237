import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import moment from "moment";

import { getCurrentUsername } from "../../utils/api/user";
import ListScannedTubes from "./components/ListScannedTubes";

const ScannedTubes: React.FC = () => {
  const [username, setUsername] = useState(undefined);

  const updateUsername = async () => {
    const fetchUsername = await getCurrentUsername();
    setUsername(fetchUsername);
  };

  useEffect(() => {
    updateUsername();
  }, []);

  return (
    <>
      <Box display="flex" flexWrap="wrap" alignItems="flex-end">
        <Typography variant="h4" color="inherit">
          Historique des tubes
        </Typography>
        <Box flexGrow={1} />
        <Typography>
          Last complete update: {moment().format("HH:mm")}
        </Typography>
      </Box>
        {username && <ListScannedTubes username={username} />}

    </>
  );
};

export default ScannedTubes;
