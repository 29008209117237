import React, { Component } from 'react';
import './Part.css';

import * as subscriptions from '../../graphql/subscriptions';

import { API, graphqlOperation } from 'aws-amplify';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import AddGroup from '../../components/addGroup/AddGroup'
import ListGroups from '../../components/listGroups/ListGroups';
import Utils from '../../utils/utils';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Link } from "react-router-dom";

import { isStudyManager } from '../../utils/api/user';
import * as groupApi from '../../utils/api/group';
import * as partApi from '../../utils/api/part';
import * as protocolValidationApi from '../../utils/api/protocolValidation';
import { Button, LinearProgress } from '@material-ui/core';
import { StudyRoute } from '../../Routing';

import moment from 'moment';
class Part extends Component {

    componentDidMount() {
        this.queryCompleteList(this.props.match.params.id);
        // Check groups
        this.checkGroups()
        // Subscribe to new group
        this.subscriptionNew = this.subscribeNewGroup();
        // Subscribe to delete group
        this.subscriptionDelete = this.subscribeDeleteGroup();
        // Subscribe to update group
        this.subscriptionUpdate = this.subscribeUpdateGroup();
    }

    state = {
        partID: "",
        partInfo: null,
        listGroups: [],
        StudyManager: false,
    };

    async checkGroups() {
        const StudyManager = await isStudyManager()
        this.setState({
            StudyManager: StudyManager,
        })
    }

    async queryCompleteList(partID) {
        const queryPart = await partApi.getPart(partID)
        const queryGroups = await groupApi.listGroupsByPart(partID)
        
        let formatGroupList = [];
        for (let i = 0; i < queryGroups.items.length; i++) {
            let newGroup = queryGroups.items[i];
            const validationStatus = await protocolValidationApi.getValidationStatus(newGroup.id);
            let versionDisplay = "Not validated";
            if (validationStatus.isValidate === true) {
                let validationDate = moment(validationStatus.validatationDate).format('DD/MM/YYYY')
                versionDisplay = `Version: ${validationStatus.version} - Validated the ${validationDate}`;
            }
            newGroup.version = versionDisplay;
            formatGroupList.push(newGroup);
        }
        let partInfo = Utils.checkNested(queryPart, 'data', 'getPart');
        
        this.setState({
            partInfo: partInfo,
            partID: partID,
            listGroups: formatGroupList
        });
    }

    subscribeNewGroup() {
        return API.graphql(
            graphqlOperation(subscriptions.onCreateGroup)
        ).subscribe({
            next: (groupData) => {
                let newListGroup = this.state.listGroups;
                if (groupData.value.data.onCreateGroup.partId === this.state.partID) {
                    newListGroup.unshift(groupData.value.data.onCreateGroup);
                    this.setState({ listGroups: newListGroup });
                }
            }
        });
    }

    subscribeDeleteGroup() {
        return API.graphql(
            graphqlOperation(subscriptions.onDeleteGroup)
        ).subscribe({
            next: (groupData) => {
                let newListGroup = this.state.listGroups;
                for (let i = 0; i < newListGroup.length; i++) {
                    if (newListGroup[i].id === groupData.value.data.onDeleteGroup.id) {
                        newListGroup.splice(i, 1);
                    }
                }
                this.setState({ listGroups: newListGroup });
            }
        });
    }

    subscribeUpdateGroup() {
        return API.graphql(
            graphqlOperation(subscriptions.onUpdateGroup)
        ).subscribe({
            next: (groupData) => {
                let newListGroup = this.state.listGroups;
                for (let i = 0; i < newListGroup.length; i++) {
                    if (newListGroup[i].id === groupData.value.data.onUpdateGroup.id) {
                        newListGroup[i] = groupData.value.data.onUpdateGroup;
                    }
                }
                this.setState({ listGroups: newListGroup });
            }
        });
    }

    componentWillUnmount() {
        this.subscriptionNew.unsubscribe();
        this.subscriptionDelete.unsubscribe();
        this.subscriptionUpdate.unsubscribe();
    }

    render() {
        const { partInfo, partID, listGroups, StudyManager } = this.state;
        const name = partInfo ? partInfo.name : null;
        const description = partInfo ? partInfo.description : null;
        return (
            <div>
                <Typography variant="h4" color="inherit">
                    Part: {name}
                </Typography>
                <Divider variant="middle" />
                {
                    partInfo ?
                        <Button color="primary" component={Link} to={StudyRoute + partInfo.studyId} >
                            <ArrowBack />
                            Back to study
                        </Button>
                        :
                        <Button color="primary" >
                            <ArrowBack />
                            Loading...
                        </Button>
                }

                {
                    description && description !== " " ?
                        <div className="description">
                            <Typography color="inherit">
                                Description: {description}
                            </Typography>
                        </div>
                        :
                        null
                }

                {
                    StudyManager ?
                        <div className="add-button"><AddGroup partID={partID}></AddGroup></div>
                        :
                        null
                }
                {
                    partID ?
                    <ListGroups groups={listGroups}></ListGroups>
                    :
                    <LinearProgress />
                }
                
            </div>
        );
    }
}

export default Part;