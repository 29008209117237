import React, { PureComponent } from 'react';
import { Box } from '@material-ui/core';

import moment from 'moment';

class ClockComponent extends PureComponent {
    state = {
        currentDate: new Date(),
    }

    componentDidMount() {
        let refreshId = setInterval(this.updateTime.bind(this), 1000);
        this.setState({ refreshId: refreshId });
    }
    
    componentWillUnmount() {
        clearInterval(this.state.refreshId);
    }

    async updateTime() {
        this.setState({
            currentDate: new Date(),
        })
    }

    render() {
        const { currentDate } = this.state;
        return (
            <Box>
                {moment(currentDate).format('DD/MM/YYYY HH:mm:ss')}
            </Box>
        );
    }
}

export default ClockComponent;