import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import * as user from '../../../utils/api/user'

// UI
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Utils from '../../../utils/utils';

class DeleteUserDialog extends PureComponent {
    state = {
        userName: "",
        password: "",
    };

    handleEntering() {
        console.log(this.props.user)
        if (Utils.checkNested(this.props, 'user', 'name')) {
            this.setState({
                userName: this.props.user.name,
            });
        }
    }

    handleCancel = () => {
        this.props.onClose();
    };

    async handleConfirmation() {
        this.setState({ loading: true });
        try {
            let deleteUserResult = await user.deleteUser(this.state.userName, this.state.password);
            console.log(deleteUserResult)
            if (!deleteUserResult) {
                alert("Fail to delete the user, check that you have correct access right and password.")
            }else if(deleteUserResult.code === '400') {
                alert("Fail to delete the user, check that you have correct access right and password.")
            } 
        } catch {
            alert("Fail to delete the user, check that you have correct access right and password.")
        }
        this.setState({ loading: false });
        this.props.onClose();
    }

    handleChange = stateName => event => {
        this.setState({
            [stateName]: event.target.value,
        });
    };

    render() {
        const { ...other } = this.props;
        const { loading, userName } = this.state;

        let loadingDisplay;
        if (loading) {
            loadingDisplay = <DialogContent><CircularProgress /></DialogContent>;
        }
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xs"
                onEntering={() => this.handleEntering()}
                aria-labelledby="confirmation-dialog-title"
                {...other}
            >
                <DialogTitle id="confirmation-dialog-title">You are about to definily delete the user {userName}</DialogTitle>
                <DialogContent>
                    Please confirm that it is indeed what you want to do, enter the admin password and click "confirm":
        <form noValidate autoComplete="off">
                        <TextField
                            required
                            id="password"
                            label="password"
                            value={this.state.password}
                            onChange={this.handleChange('password')}
                            margin="normal"
                        />
                    </form>
                </DialogContent>
                {loadingDisplay}
                <DialogActions>
                    <Button disabled={loading} onClick={this.handleCancel} color="primary">
                        Cancel
      </Button>
                    <Button disabled={loading} onClick={() => this.handleConfirmation()} color="primary">
                        Confirm
      </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

DeleteUserDialog.propTypes = {
    onClose: PropTypes.func
};

export default DeleteUserDialog;