import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import * as assignableTasksApi from '../../utils/api/assignableTask'

// UI
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

class AddAssignableTaskDialog extends React.PureComponent {
    state = {
        description: '',
        errors: "",
        loading: false,
        deltaDays: 0,
        deltaHours: 0,
        deltaMinutes: 0,
    };

    handleCancel = () => {
        this.props.onClose();
    };

    async handleConfirmation() {
        if (!this.props.positionid) {
            alert("Fail to create this task, please retry later.")
            return
        }

        this.setState({ loading: true });

        const delta = Number(this.state.deltaDays) * 1440 + Number(this.state.deltaHours) * 60 + Number(this.state.deltaMinutes);
        try {
           await assignableTasksApi.createNotLinkedAssignableTask(this.props.positionid, this.state.description, delta)
        } catch (e) {
            console.log(e)
            alert("Fail to create this task, please retry later.")
        }

        this.setState({ loading: false });

        this.props.onClose();
    }

    handleOk = () => {
        this.props.onClose();
    };

    handleChange = stateName => event => {
        this.setState({
            [stateName]: event.target.value,
        });
    };

    render() {
        const { participantId, ...other } = this.props;
        const { loading } = this.state;

        let loadingDisplay;
        if (loading) {
            loadingDisplay = <DialogContent><CircularProgress /></DialogContent>;
        }

        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xl"
                onEntering={this.handleEntering}
                aria-labelledby="confirmation-dialog-title"
                {...other}
            >
                <DialogTitle id="confirmation-dialog-title">You are about to add a new task ONLY for this position</DialogTitle>
                <DialogContent>
                    Please fill the information below:
                    <form noValidate autoComplete="off">
                        <TextField
                            required
                            id="standard-name"
                            label="Specific description"
                            value={this.state.description}
                            onChange={this.handleChange('description')}
                            margin="normal"
                            multiline
                            fullWidth
                        />
                        <br />
                        <h4>Time to T0</h4>
                        <FormControl>
                            <InputLabel shrink>
                                Days
                                </InputLabel>
                            <TextField
                                id="standard-number-days"
                                value={this.state.deltaDays}
                                onChange={this.handleChange('deltaDays')}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel shrink htmlFor="hoursToT0">
                                Hours
                                </InputLabel>
                            <TextField
                                id="standard-number-hours"
                                value={this.state.deltaHours}
                                onChange={this.handleChange('deltaHours')}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel shrink htmlFor="minutesToT0">
                                Minutes
                                </InputLabel>
                            <TextField
                                id="standard-number-minutes"
                                value={this.state.deltaMinutes}
                                onChange={this.handleChange('deltaMinutes')}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                            />
                        </FormControl>
                    </form>
                </DialogContent>
                {loadingDisplay}
                <DialogActions>
                    <Button disabled={loading} onClick={this.handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={loading} onClick={() => this.handleConfirmation()} color="primary">
                        Add!
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

AddAssignableTaskDialog.propTypes = {
    onClose: PropTypes.func
};

class AddAssignableTask extends PureComponent {
    state = {
        dialogOpen: false,
    };

    handleClose = () => {
        this.setState({ dialogOpen: false });
    };

    handleOpen() {
        this.setState({ dialogOpen: true });
    }

    render() {
        return (
            <div>
                <Button variant="contained" color="primary" onClick={() => this.handleOpen()} disabled={this.props.loading}>
                    <AddIcon />
                    Add a task to this position
                </Button>

                <AddAssignableTaskDialog
                    open={this.state.dialogOpen}
                    onClose={this.handleClose}
                    positionid={this.props.positionid}
                />
            </div>
        );
    }
}

export default AddAssignableTask;