import { EventType } from "./event";
import { blue, grey, red } from "@material-ui/core/colors";
import {
  AccessAlarm,
  AcUnit,
  ChatOutlined,
  FiberNew,
  FlipCameraAndroid,
  Help,
  LocalPrintshop,
  Mail,
  Opacity,
  PinDrop,
} from "@material-ui/icons";

const ReturnIcon: React.FC<{
  event: string | null | undefined;
  style?: React.CSSProperties | undefined;
}> = ({ event, style }) => {
  switch (event) {
    case EventType.CREATION:
      return <FiberNew style={{ color: grey[800], ...style }} />;
    case EventType.PRINT:
      return <LocalPrintshop color="primary" style={style} />;
    case EventType.FILL:
      return <Opacity color="error" style={style} />;
    case EventType.CENTRI:
      return <FlipCameraAndroid style={{ color: "orange", ...style }} />;
    case EventType.FREEZE:
      return <AcUnit style={{ color: blue[600], ...style }} />;
    case EventType.TRANSFER:
      return <AccessAlarm style={{ color: red[900], ...style }} />;
    case EventType.LOCATION:
      return <PinDrop style={{ color: grey[800], ...style }} />;
    case EventType.OUT:
      return <Mail style={{ color: "green", ...style }} />;
    case EventType.COMMENT:
      return <ChatOutlined style={{ color: "black", ...style }} />;
    default:
      return <Help style={{ color: grey[700], ...style }} />;
  }
};

export default ReturnIcon;
