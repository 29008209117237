/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateStudy = /* GraphQL */ `
  subscription OnCreateStudy {
    onCreateStudy {
      id
      name
      date
      description
      active
    }
  }
`;
export const onUpdateStudy = /* GraphQL */ `
  subscription OnUpdateStudy {
    onUpdateStudy {
      id
      name
      date
      description
      active
    }
  }
`;
export const onDeleteStudy = /* GraphQL */ `
  subscription OnDeleteStudy {
    onDeleteStudy {
      id
      name
      date
      description
      active
    }
  }
`;
export const onCreatePart = /* GraphQL */ `
  subscription OnCreatePart {
    onCreatePart {
      id
      studyId
      name
      date
      description
    }
  }
`;
export const onUpdatePart = /* GraphQL */ `
  subscription OnUpdatePart {
    onUpdatePart {
      id
      studyId
      name
      date
      description
    }
  }
`;
export const onDeletePart = /* GraphQL */ `
  subscription OnDeletePart {
    onDeletePart {
      id
      studyId
      name
      date
      description
    }
  }
`;
export const onCreateGroup = /* GraphQL */ `
  subscription OnCreateGroup {
    onCreateGroup {
      id
      partId
      name
      date
      description
      indicativeT0
      active
    }
  }
`;
export const onUpdateGroup = /* GraphQL */ `
  subscription OnUpdateGroup {
    onUpdateGroup {
      id
      partId
      name
      date
      description
      indicativeT0
      active
    }
  }
`;
export const onDeleteGroup = /* GraphQL */ `
  subscription OnDeleteGroup {
    onDeleteGroup {
      id
      partId
      name
      date
      description
      indicativeT0
      active
    }
  }
`;
export const onCreateProtocolValidation = /* GraphQL */ `
  subscription OnCreateProtocolValidation {
    onCreateProtocolValidation {
      id
      groupId
      date
      user
      validate
      action
      comment
      version
    }
  }
`;
export const onUpdateProtocolValidation = /* GraphQL */ `
  subscription OnUpdateProtocolValidation {
    onUpdateProtocolValidation {
      id
      groupId
      date
      user
      validate
      action
      comment
      version
    }
  }
`;
export const onDeleteProtocolValidation = /* GraphQL */ `
  subscription OnDeleteProtocolValidation {
    onDeleteProtocolValidation {
      id
      groupId
      date
      user
      validate
      action
      comment
      version
    }
  }
`;
export const onCreateProtocolComment = /* GraphQL */ `
  subscription OnCreateProtocolComment {
    onCreateProtocolComment {
      id
      groupId
      date
      user
      comment
    }
  }
`;
export const onUpdateProtocolComment = /* GraphQL */ `
  subscription OnUpdateProtocolComment {
    onUpdateProtocolComment {
      id
      groupId
      date
      user
      comment
    }
  }
`;
export const onDeleteProtocolComment = /* GraphQL */ `
  subscription OnDeleteProtocolComment {
    onDeleteProtocolComment {
      id
      groupId
      date
      user
      comment
    }
  }
`;
export const onCreateProtocolTask = /* GraphQL */ `
  subscription OnCreateProtocolTask {
    onCreateProtocolTask {
      id
      groupId
      name
      timeToT0
      description
      taskTypeId
    }
  }
`;
export const onUpdateProtocolTask = /* GraphQL */ `
  subscription OnUpdateProtocolTask {
    onUpdateProtocolTask {
      id
      groupId
      name
      timeToT0
      description
      taskTypeId
    }
  }
`;
export const onDeleteProtocolTask = /* GraphQL */ `
  subscription OnDeleteProtocolTask {
    onDeleteProtocolTask {
      id
      groupId
      name
      timeToT0
      description
      taskTypeId
    }
  }
`;
export const onCreateTaskType = /* GraphQL */ `
  subscription OnCreateTaskType {
    onCreateTaskType {
      id
      name
    }
  }
`;
export const onUpdateTaskType = /* GraphQL */ `
  subscription OnUpdateTaskType {
    onUpdateTaskType {
      id
      name
    }
  }
`;
export const onDeleteTaskType = /* GraphQL */ `
  subscription OnDeleteTaskType {
    onDeleteTaskType {
      id
      name
    }
  }
`;
export const onCreateAssignableTask = /* GraphQL */ `
  subscription OnCreateAssignableTask {
    onCreateAssignableTask {
      id
      description
      applicableDate
      delta
      notLinkToProtocol
      activationStatus
      protocolTaskId
      positionId
      userId
    }
  }
`;
export const onUpdateAssignableTask = /* GraphQL */ `
  subscription OnUpdateAssignableTask {
    onUpdateAssignableTask {
      id
      description
      applicableDate
      delta
      notLinkToProtocol
      activationStatus
      protocolTaskId
      positionId
      userId
    }
  }
`;
export const onDeleteAssignableTask = /* GraphQL */ `
  subscription OnDeleteAssignableTask {
    onDeleteAssignableTask {
      id
      description
      applicableDate
      delta
      notLinkToProtocol
      activationStatus
      protocolTaskId
      positionId
      userId
    }
  }
`;
export const onCreatePosition = /* GraphQL */ `
  subscription OnCreatePosition {
    onCreatePosition {
      id
      groupId
      participantId
      name
      t0
      inclusion
      treatment
      dose
      validate
    }
  }
`;
export const onUpdatePosition = /* GraphQL */ `
  subscription OnUpdatePosition {
    onUpdatePosition {
      id
      groupId
      participantId
      name
      t0
      inclusion
      treatment
      dose
      validate
    }
  }
`;
export const onDeletePosition = /* GraphQL */ `
  subscription OnDeletePosition {
    onDeletePosition {
      id
      groupId
      participantId
      name
      t0
      inclusion
      treatment
      dose
      validate
    }
  }
`;
export const onCreateParticipant = /* GraphQL */ `
  subscription OnCreateParticipant {
    onCreateParticipant {
      id
      groupId
      selection
      code
      sex
      birthday
      room
      inDate
      outDate
      active
    }
  }
`;
export const onUpdateParticipant = /* GraphQL */ `
  subscription OnUpdateParticipant {
    onUpdateParticipant {
      id
      groupId
      selection
      code
      sex
      birthday
      room
      inDate
      outDate
      active
    }
  }
`;
export const onDeleteParticipant = /* GraphQL */ `
  subscription OnDeleteParticipant {
    onDeleteParticipant {
      id
      groupId
      selection
      code
      sex
      birthday
      room
      inDate
      outDate
      active
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      userName
      email
      create
      lastUpdate
      color
      active
      code
      pseudo
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      userName
      email
      create
      lastUpdate
      color
      active
      code
      pseudo
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      userName
      email
      create
      lastUpdate
      color
      active
      code
      pseudo
    }
  }
`;
export const onCreateTaskValidation = /* GraphQL */ `
  subscription OnCreateTaskValidation($user: String!) {
    onCreateTaskValidation(user: $user) {
      id
      date
      assignableTaskId
      positionId
      active
      userId
      comment
    }
  }
`;
export const onUpdateTaskValidation = /* GraphQL */ `
  subscription OnUpdateTaskValidation {
    onUpdateTaskValidation {
      id
      date
      assignableTaskId
      positionId
      active
      userId
      comment
    }
  }
`;
export const onDeleteTaskValidation = /* GraphQL */ `
  subscription OnDeleteTaskValidation {
    onDeleteTaskValidation {
      id
      date
      assignableTaskId
      positionId
      active
      userId
      comment
    }
  }
`;
