import React, { PureComponent } from 'react';

import './ListTaskTypeReport.scss'

import MaterialTable from 'material-table'
import * as constants from '../../utils/constants';
import * as userApi from '../../utils/api/user';

import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import uuid from 'uuid/v4';

class ListTaskTypeReport extends PureComponent {
  state = {
    currentUser: "",
    listReports: [],
    listAssignableTask: [],
  };

  componentDidMount() {
    this.loadCurrentUser();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.lastUpdate !== prevProps.lastUpdate) {
      const listReports = await this.sortReportsByParticipant(this.props.listTasks);
      this.setState({
        listReports: listReports,
        listAssignableTask: this.props.listTasks,
        loading: false,
      });
    }
  }

  async loadCurrentUser() {
    const currentUser = await userApi.getCurrentUsername()
    this.setState({ currentUser: currentUser });
  }

  async sortReportsByParticipant(listTasks) {
    let listReports = [];
    for (let i = 0; i < listTasks.length; i++) {
      let found = false;
      for (let j = 0; j < listReports.length; j++) {
        if (listReports[j].taskTypeName === listTasks[i].taskTypeName) {
          found = true;
          listReports[j].tasks.push(listTasks[i]);
        }
      }
      if (found === false) {
        const newEntry = {
          tasks: [listTasks[i]],
          taskTypeName: listTasks[i].taskTypeName,
        }
        listReports.push(newEntry);
      }
    }
    return listReports
  }

  render() {
    const { listReports } = this.state;
    return (
      <div id="ListParticipantReport">
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"></link>
        {listReports.map((report) =>
          <Box key={uuid()} marginBottom={2} className="page-break">
            <Typography><b>{report.taskTypeName || "Undefined"}</b> planning between <b>{moment(this.props.dateBeginning).format("HH:mm DD/MM/Y")}</b> and <b>{moment(this.props.dateEnd).format("HH:mm DD/MM/Y")}</b></Typography>
            <Typography>Generated by <b>{this.state.currentUser}</b> at <b>{moment(this.props.lastUpdate).format("HH:mm DD/MM/Y")}</b></Typography>
            <MaterialTable
              columns={[
                {
                  title: 'Study',
                  field: 'studyName',
                  sorting: false,
                  width: null,
                  render: rowData => {
                    return (
                      <div>
                        {rowData.studyName}{rowData.groupDescription ? '/' + rowData.groupDescription : null}
                      </div>
                    )
                  },
                },
                {
                  title: 'Date Time',
                  field: 'applicableDate',
                  defaultSort: 'asc',
                  render: rowData => {
                    let color;
                    if (rowData.activationStatus === constants.ASSIGNABLE_TASK_INACTIVE) {
                      color = "grey";
                    }
                    return (
                      <div style={{ color: color }}>
                        <b>{moment(rowData.applicableDate).format('HH:mm')} </b>
                        {moment(rowData.applicableDate).format('DD/MM/YYYY')}
                      </div>
                    )
                  },
                },
                { title: 'Theoretical time', field: 'protocolName', sorting: false, },
                {
                  title: 'Participant',
                  field: 'participantCode',
                  sorting: false,
                  render: rowData => {
                    return (
                      <div>
                        {
                          rowData.positionInclusion ?
                            rowData.participantCode + "/ " + rowData.participantSelection + "/ " + rowData.positionInclusion
                            :
                            rowData.participantCode + "/ " + rowData.participantSelection + "/ " + rowData.positionName
                        }
                      </div>
                    )
                  },
                },
                { title: 'Sex', field: 'participantSex', sorting: false, },
                { title: 'Room', field: 'participantRoom', sorting: false, },
                {
                  title: 'Act',
                  field: 'protocolDescription',
                  sorting: false,
                  render: rowData => {
                    return (
                      <div>
                        <div hidden={!rowData.protocolDescription}>{rowData.protocolDescription}<br /></div>
                        <div hidden={!rowData.specificDescription}><span style={{ fontWeight: "bold" }}>Specific description:</span> {rowData.specificDescription}</div>
                      </div>
                    )
                  },
                },
              ]}
              options={{
                actionsColumnIndex: -1,
                paging: false,
                sorting: true,
                search: false,
                draggable: false,
              }}
              data={report.tasks}
              title=""
            />
            <Box className="no-print" marginBottom={5} />
          </Box>
        )}

      </div>
    );
  }
}

export default ListTaskTypeReport;