import React, { PureComponent } from 'react';

import './ListAssignation.scss';

import * as constants from '../../utils/constants';
import * as assignableTaskApi from '../../utils/api/assignableTask';
import * as userApi from '../../utils/api/user';

import MaterialTable, { MTableBody } from 'material-table'

import moment from 'moment';
import { LinearProgress, Tooltip } from '@material-ui/core';

class ListAssignation extends PureComponent {
  constructor() {
    super()
    this.filterRef = React.createRef({
      "studyName": "",
      "applicableDate": "",
      "protocolName": "",
      "positionName": "",
      "participantSex": "",
      "positionInclusion": "",
      "protocolDescription": "",
      "taskTypeName": "",
      "staffColumnName": ""
    });
  }
  
  state = {
    selectedAssignableTask: [],
    listAssignableTask: [],
    isTaskAssign: false,
    nbrPerPage: this.getInitNbrPerPage(),
  };

  componentDidMount() {
    this.checkGroups();
  }

  getInitNbrPerPage() {
    return localStorage.getItem('nbrPerPageAssign')
      ? Number(localStorage.getItem('nbrPerPageAssign'))
      : 100;
  }

  async checkGroups() {
    const isTaskAssign = await userApi.isTaskAssign()
    this.setState({
      isTaskAssign: isTaskAssign,
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.lastUpdate !== prevProps.lastUpdate) {
      this.setState({ listAssignableTask: this.props.listTasks })
    }
  }

  async assignToUser(rowsSelected) {
    this.setState({ loading: true, loaded: 0 })

    if (!this.props.userSelected) {
      alert("Please select a user before assigning.")
      return
    }

    let remainingList = [...rowsSelected];
    let totalElements = 0;
    for (let i = 0; i < rowsSelected.length; i++) {
      if (remainingList.length === 0) break
      // Batch 100 tasks together
      const batchList = remainingList.slice(0, 100);
      remainingList.splice(0, 100)
      totalElements += batchList.length;
      this.setState({ loaded: totalElements * 100 / rowsSelected.length })
      console.log(totalElements)
      await Promise.all(batchList.map(async (rowSelected) => {
        if (!rowSelected.id) {
          alert("Please select a task to assign.")
          return
        }
        // If we have a user and a task id, we can assign
        try {
          await assignableTaskApi.assignAssignableTask(rowSelected.id, this.props.userSelected);
        } catch (e) {
          console.log(e.errors)
          alert("Fail to assign the task...");
        }
      }));
    }

    this.setState({ loading: false, loaded: 0 })
  }

  async unassignTask(rowsSelected) {
    this.setState({ loading: true, loaded: 0 })

    let remainingList = [...rowsSelected];
    let totalElements = 0;
    for (let i = 0; i < rowsSelected.length; i++) {
      if (remainingList.length === 0) break
      // Batch 100 tasks together
      const batchList = remainingList.slice(0, 100);
      remainingList.splice(0, 100)
      totalElements += batchList.length;
      this.setState({ loaded: totalElements * 100 / rowsSelected.length })
      console.log(totalElements)
      await Promise.all(batchList.map(async (rowSelected) => {
        if (!rowSelected.id) {
          alert("Please select a task to unassign.")
          return
        }
        // If we have a task id, we can unassign
        try {
          await assignableTaskApi.unassignAssignableTask(rowSelected.id);
        } catch (e) {
          console.log(e.errors)
          alert("Fail to unassign the task...");
        }
      }));
    }

    this.setState({ loading: false, loaded: 0 })
  }

  render() {
    const { listAssignableTask, loading, loaded, isTaskAssign } = this.state;
    return (
      <div id="ListAssignation">
        {
          loading ?
            <LinearProgress variant="determinate" value={loaded} />
            : null
        }

        <MaterialTable
          components={{
            Body: props => <MTableBody {...props} onFilterChanged={(columnId, value) => {
              props.onFilterChanged(columnId, value);
              let columnName = "staffColumnName"
              if (columnId === 0) columnName = "studyName"
              else if (columnId === 1) columnName = "applicableDate"
              else if (columnId === 2) columnName = "protocolName"
              else if (columnId === 3) columnName = "positionName"
              else if (columnId === 4) columnName = "participantSex"
              else if (columnId === 5) columnName = "positionInclusion"
              else if (columnId === 6) columnName = "protocolDescription"
              else if (columnId === 7) columnName = "taskTypeName"
              let newFilters = {...this.filterRef.current}
              newFilters[columnName] = value
              this.filterRef.current = newFilters
            }} />
          }}
          onChangeRowsPerPage={(newNbrPerPage) => {
            this.setState({nbrPerPage: newNbrPerPage});
            localStorage.setItem('nbrPerPageAssign', newNbrPerPage);
          } }
          columns={[
            {
              title: 'Study',
              field: 'studyName',
              defaultFilter: this.filterRef.current ? this.filterRef.current['studyName'] : "",
              width: null,
              render: rowData => {
                let color = "#ff6666";
                if (rowData.groupValidate === true) {
                  color = "";
                }
                return (
                  <Tooltip title={color ? "Warning: Group not validated." : "Group validate."}>
                    <div style={{ width: '100%', backgroundColor: color, height: '100%' }} >
                      {rowData.studyName}{rowData.groupDescription ? '/' + rowData.groupDescription : null}
                    </div>
                  </Tooltip>
                )
              },
            },
            {
              title: 'Time',
              field: 'applicableDate',
              defaultFilter: this.filterRef.current ? this.filterRef.current['applicableDate'] : "",
              defaultSort: 'asc',
              render: rowData => {
                let color;
                if (rowData.activationStatus === constants.ASSIGNABLE_TASK_INACTIVE) {
                  color = "grey";
                }
                return (
                  <div style={{ color: color }}>
                    {moment(rowData.applicableDate).format('HH:mm DD/MM/YYYY')}
                  </div>
                )
              },
            },
            { title: 'TheoTime', field: 'protocolName', defaultFilter: this.filterRef.current ? this.filterRef.current['protocolName'] : "" },
            {
              title: 'Position',
              field: 'positionName',
              defaultFilter: this.filterRef.current ? this.filterRef.current['positionName'] : "",
              render: rowData => {
                let color = "#ff6666";
                if (rowData.positionValidate === true) {
                  color = "";
                }
                return (
                  <Tooltip title={color ? "Warning: Position not validated." : "Position validate."}>
                    <div style={{ width: '100%', backgroundColor: color, height: '100%' }} >
                      {rowData.positionName}
                    </div>
                  </Tooltip>
                )
              },
            },
            { title: 'Sex', field: 'participantSex', defaultFilter: this.filterRef.current ? this.filterRef.current['participantSex'] : "" },
            { title: 'n°Inclusion', field: 'positionInclusion', defaultFilter: this.filterRef.current ? this.filterRef.current['positionInclusion'] : "" },
            {
              title: 'Act',
              field: 'protocolDescription',
              defaultFilter: this.filterRef.current ? this.filterRef.current['protocolDescription'] : "",
              render: rowData => {
                return (
                  <div>
                    <div hidden={!rowData.protocolDescription}>{rowData.protocolDescription}<br /></div>
                    <div hidden={!rowData.specificDescription}><span style={{ fontWeight: "bold" }}>Specific description:</span> {rowData.specificDescription}</div>
                  </div>
                )
              },
            },
            { title: 'Task Type', field: 'taskTypeName', defaultFilter: this.filterRef.current ? this.filterRef.current['taskTypeName'] : "" },
            {
              title: 'Staff',
              field: 'staffColumnName',
              defaultFilter: this.filterRef.current ? this.filterRef.current['staffColumnName'] : "",
              render: rowData => {
                const color = rowData.staffColor
                return (
                  <div style={{ width: '100%', backgroundColor: color, height: '100%', fontSize: "1.2em" }}>
                    {rowData.staffPseudo || rowData.staffUserName}
                  </div>
                )
              },
            }
          ]}
          actions={[
            {
              icon: 'add',
              tooltip: 'Assign',
              disabled: loading,
              hidden: !isTaskAssign,
              onClick: (event, rowSelected) => {
                this.assignToUser(rowSelected);
              },
            },
            {
              icon: 'remove',
              tooltip: 'Unassign',
              disabled: loading,
              hidden: !isTaskAssign,
              onClick: (event, rowSelected) => {
                this.unassignTask(rowSelected);
              },
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            paging: true,
            pageSizeOptions: [100, 200, 500, 1000],
            pageSize: this.state.nbrPerPage,
            sorting: true,
            selection: true,
            draggable: false,
            filtering: true,
          }}
          data={listAssignableTask}
          title=""
        />
      </div>
    );
  }
}

export default ListAssignation;