import React, { PureComponent } from 'react';
import './Studies.css';
import ListStudies from './../../components/listStudies/ListStudies.js'
import AddStudy from './../../components/addStudy/AddStudy'
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { isStudyManager } from '../../utils/api/user';


class Studies extends PureComponent {
  state = {
    StudyManager: false,
  }

  componentDidMount() {
    this.checkGroups();
  }

  async checkGroups() {
    const StudyManager = await isStudyManager()
    this.setState({
      StudyManager: StudyManager,
    })
  }

  render() {
    const { StudyManager } = this.state;
    return (
      <div>
        <Typography variant="h4" color="inherit">
          Studies list
        </Typography>
        <Divider variant="middle" />
        {
          StudyManager ?
            <div className="add-button"><AddStudy ></AddStudy></div>
            :
            null
        }
        <ListStudies></ListStudies>
      </div>
    );
  }
}

export default Studies;