import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './EditStudyButton.css';

import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import * as studyApi from '../../utils/api/study'
import { TextField } from '@material-ui/core';
import DeleteStudyButton from '../deleteStudyButton/DeleteStudyButton';
import * as constants from '../../utils/constants';

class ConfirmationDialogRaw extends React.PureComponent {
    state = {
        loading: false,
        name: "",
        description: "",
        active: constants.STATUS_ONLINE,
    };

    componentDidMount() {
        this.setState({
            name: this.props.study.name,
            active: this.props.study.active,
        })
        if (this.props.study.description) {
            this.setState({
                description: this.props.study.description,
            })
        }
    }

    handleCancel = () => {
        this.props.onClose();
    };

    handleChange = stateName => event => {
        this.setState({
            [stateName]: event.target.value,
        });
    };

    async handleConfirmation(study) {
        this.setState({ loading: true });
        if (this.state.name) {
            let resultUpdateStudy = await studyApi.updateStudy(study.id,
                this.state.name,
                this.state.active,
                this.state.description);
            if (!resultUpdateStudy) {
                alert("Fail to edit the study.")
            } else {
                window.location.reload();
            }
        }
        this.setState({ loading: false });
        this.props.onClose();
    }

    render() {
        const { study, ...other } = this.props;
        const { loading } = this.state;

        let loadingDisplay;
        if (loading) {
            loadingDisplay = <DialogContent><CircularProgress /></DialogContent>;
        }
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xs"
                onEntering={this.handleEntering}
                aria-labelledby="confirmation-dialog-title"
                {...other}
            >
                <DeleteStudyButton study={this.props.study} />

                <DialogTitle id="confirmation-dialog-title">You are about to edit {study.name}</DialogTitle>
                <DialogContent>
                    Please update the information below:
                    <form noValidate autoComplete="off">
                        <TextField
                            required
                            id="standard-name"
                            label="Name"
                            value={this.state.name}
                            onChange={this.handleChange('name')}
                            margin="normal"
                        />
                        <br />
                        <TextField
                            id="description"
                            label="Description"
                            value={this.state.description}
                            onChange={this.handleChange('description')}
                            margin="normal"
                            multiline
                        />
                        <br />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.active === constants.STATUS_ONLINE}
                                    onChange={() => this.setState((prev) => {
                                        const newStatus = prev.active === constants.STATUS_ONLINE
                                            ? constants.STATUS_OFFLINE
                                            : constants.STATUS_ONLINE;
                                        return { active: newStatus }
                                    })
                                    }
                                    name="active"
                                />}
                            label="Active"
                        />
                    </form>
                </DialogContent>
                {loadingDisplay}
                <DialogActions>
                    <Button disabled={loading} onClick={this.handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={loading} onClick={() => this.handleConfirmation(study)} color="primary">
                        Done!
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

ConfirmationDialogRaw.propTypes = {
    onClose: PropTypes.func
};


class EditStudyButton extends PureComponent {
    state = {
        open: false,
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    editStudy() {
        this.setState({ open: true });
    }
    render() {
        const { study } = this.props;
        return (
            <div>
                <IconButton aria-label="Delete" onClick={() => this.editStudy()}>
                    <EditIcon />
                </IconButton>

                <ConfirmationDialogRaw
                    open={this.state.open}
                    onClose={this.handleClose}
                    study={study}
                />
            </div>
        );
    }
}

export default EditStudyButton;