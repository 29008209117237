import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import * as positionApi from '../../../utils/api/position'
import * as constants from '../../../utils/constants';

// UI
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import { Divider, Box, Typography, InputLabel, Select, MenuItem, LinearProgress } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import * as users from '../../../utils/api/user';
import moment from 'moment';

class EditPositionDialog extends PureComponent {
  state = {
    errors: [],
    loading: false,
    name: "",
    t0: null,
    inclusion: "",
    treatment: "",
    dose: "",
    validate: false,
    id: null,
    groupId: null,
    isPositionUpdater: false,
    isParticipantValidator: false,
    participantCode: "",
    participantSelection: "",
    participantSex: constants.SEX_OTHER,
    participantValidate: false,
    participantId: "",
    participantIdSelected: "",
    participantList: [],
  };

  componentDidMount() {
    this.checkGroups();
  }

  async checkGroups() {
    const isPositionUpdater = await users.isPositionUpdater();
    const isParticipantValidator = await users.isParticipantValidator();
    this.setState({
      isPositionUpdater: isPositionUpdater,
      isParticipantValidator: isParticipantValidator,
    })
  }

  handleEntering = () => {
    this.updateParticipantList();
    this.setState({
      id: this.props.position.id,
      groupId: this.props.position.groupId,
      name: this.props.position.name ? this.props.position.name : "",
      t0: this.props.position.t0,
      inclusion: this.props.position.inclusion ? this.props.position.inclusion : "",
      treatment: this.props.position.treatment ? this.props.position.treatment : "",
      dose: this.props.position.dose ? this.props.position.dose : "",
      validate: this.props.position.validate ? this.props.position.validate : false,

      participantId: this.props.position.participantId ? this.props.position.participantId : null,
      participantIdSelected: this.props.position.participantId ? this.props.position.participantId : constants.POSITION_UNASSIGNED,
      participantSelection: this.props.position.participantSelection ? this.props.position.participantSelection : "",
      participantCode: this.props.position.participantCode ? this.props.position.participantCode : "",
      participantSex: this.props.position.participantSex ? this.props.position.participantSex : constants.SEX_OTHER,
      participantRoom: this.props.position.participantRoom ? this.props.position.participantRoom : "",
      participantBirthday: this.props.position.participantBirthday ? this.props.position.participantBirthday : null,

      errors: [],
    })
  }

  updateParticipantList() {
    // The list from the props contained participant available for assignation
    // Add an "unassigned" option and as well as the current selected participant if available
    let participantList = [...this.props.listparticipants] || [];
    const currentParticipant = {
      id: this.props.position.participantId || "",
      selection: this.props.position.participantSelection || "",
      code: this.props.position.participantCode || "",
      sex: this.props.position.participantSex || constants.SEX_OTHER,
      room: this.props.position.participantRoom || "",
      birthday: this.props.position.participantBirthday || null,
    }
    participantList.push(currentParticipant);
    const unassignPosition = {
      id: constants.POSITION_UNASSIGNED,
      selection: constants.POSITION_UNASSIGNED,
      code: constants.POSITION_UNASSIGNED,
      sex: constants.POSITION_UNASSIGNED,
      room: constants.POSITION_UNASSIGNED,
      birthday: constants.POSITION_UNASSIGNED,
    }
    participantList.push(unassignPosition);
    this.setState({
      participantList: participantList,
    })
  }

  handleCancel = () => {
    this.props.onClose();
  };

  async handleToogleValidation() {
    let errors = [];
    if (!this.state.id) {
      errors.push("Unable to determine the validation status, please retry.");
      this.setState({
        errors: errors,
      });
      return
    }

    let newValidate = !this.state.validate;

    this.setState({ loading: true });

    try {
      await positionApi.validatePosition(this.state.id, newValidate);
    } catch (e) {
      console.log(e)
      e.errors.forEach(error => {
        errors.push(error.message)
      });
    }

    if (errors.length > 0) {
      errors.push('Fail to update the validaton status. Make sure that all necessary fields are fill and try again.')
      this.setState({
        loading: false,
        errors: errors,
      });
    } else {
      this.setState({
        loading: false,
        validate: newValidate
      });
      this.props.onClose();
    }
  }

  async handleConfirmation() {
    let errors = [];

    if (!this.state.name || !this.state.t0 || !this.state.id || !this.state.groupId) {
      errors.push('Please fill all mandatory fields (*)');
      this.setState({
        errors: errors,
      });
      return
    }
    this.setState({ loading: true });

    let t0ToUpdate = null;
    if (this.props.position.t0 !== this.state.t0) t0ToUpdate = this.state.t0;
    try {
      await positionApi.updatePosition(this.state.id,
        this.state.name,
        t0ToUpdate,
        this.state.inclusion,
        this.state.treatment,
        this.state.dose,
        this.state.participantIdSelected,
      );
    } catch (e) {
      console.log(e)
      e.errors.forEach(error => {
        errors.push(error.message)
      });
    }

    // The validation status is removed for a t0 change or a reassignation
    if (this.state.validate === true &&
      (
        this.props.position.t0 !== this.state.t0 ||
        this.props.position.participantId !== this.state.participantIdSelected
      )) {
      try {
        await positionApi.validatePosition(this.state.id, false);
      } catch (e) {
        console.log(e)
        e.errors.forEach(error => {
          errors.push(error.message)
        });
      }
    }

    this.setState({ loading: false });
    if (errors.length > 0) {
      this.setState({
        errors: errors,
      });
    } else {
      this.props.onClose();
    }
  }

  handleOk = () => {
    this.props.onClose();
  };

  handleChange = stateName => event => {
    this.setState({
      [stateName]: event.target.value,
    });
  };
  handleDateChange = stateName => date => {
    this.setState({
      [stateName]: date,
    });
  };

  render() {
    const { position, ...other } = this.props;
    const { loading,
      name,
      t0,
      inclusion,
      treatment,
      dose,
      errors,
      isPositionUpdater,
      isParticipantValidator,
      validate,
      participantIdSelected,
      participantId,
      participantList,
    } = this.state;


    const selectedParticipant = participantList.find(x => x.id === participantIdSelected);

    let loadingDisplay;
    if (loading) {
      loadingDisplay = <LinearProgress />;
    }
    let errorDisplay;
    if (errors && errors.length > 0) {
      errorDisplay = <DialogContent>
        <ul>
          {errors.map((value, index) => {
            return <li style={{ color: "red" }} key={index}>{value}</li>
          })}
        </ul>
      </DialogContent>
    }
    return (
      <Dialog
        maxWidth="md"
        disableEscapeKeyDown
        disableBackdropClick
        onEntering={() => this.handleEntering()}
        aria-labelledby="confirmation-dialog-title"
        {...other}
      >
        <DialogTitle id="confirmation-dialog-title">Here are all information about this position
        {loadingDisplay}
        </DialogTitle>
        <DialogContent>
          If you have the correct access rights, you can update information below:
          <Box marginTop={2}>
            <Typography style={{ fontWeight: "500" }}>
              Position information
            </Typography>
          </Box>
          <form noValidate autoComplete="off">
            <Box marginBottom={2}>
              <TextField
                required
                id="name"
                label="Position name"
                value={name}
                onChange={this.handleChange('name')}
                margin="normal"
                disabled={!isPositionUpdater}
              />
            </Box>
            <Divider />
            <Box marginBottom={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  required
                  margin="dense"
                  ampm={false}
                  label="T0"
                  format="dd/MM/yyyy HH:mm"
                  value={t0}
                  onChange={this.handleDateChange('t0')}
                  disabled={!isPositionUpdater}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Box>
            <Divider />
            <Box marginBottom={2}>
              <TextField
                id="inclusion"
                label="Inclusion number"
                value={inclusion}
                onChange={this.handleChange('inclusion')}
                margin="normal"
                disabled={!isPositionUpdater}
              />
            </Box>
            <Divider />
            <Box marginBottom={2}>
              <TextField
                id="treatment"
                label="Treatment number"
                value={treatment}
                onChange={this.handleChange('treatment')}
                margin="normal"
                disabled={!isPositionUpdater}
              />
            </Box>
            <Divider />
            <Box marginBottom={2}>
              <TextField
                id="dose"
                label="Dose"
                value={dose}
                onChange={this.handleChange('dose')}
                margin="normal"
                disabled={!isPositionUpdater}
              />
            </Box>
          </form>
          <Divider />
          <Box marginTop={2} marginBottom={1}>
            <Typography style={{ fontWeight: "500" }}>
              Participant information
            </Typography>
          </Box>
          {
            // ROLE
            true ?
              <Box>
                <Box marginBottom={2} marginTop={2}>
                  <InputLabel id="select-participant-label">Participant</InputLabel>
                  <Select
                    labelId="select-participant-label"
                    //disabled={!isParticipantManager}
                    id="select-participant"
                    value={participantIdSelected}
                    onChange={this.handleChange('participantIdSelected')}
                    disabled={!isPositionUpdater}
                  >
                    {participantList.map((value, index) => {
                      return <MenuItem value={value.id} key={index}>{value.selection}</MenuItem>
                    })}
                  </Select>
                </Box>
                <Typography>
                  Code: {selectedParticipant ? selectedParticipant.code : null}
                </Typography>
                <Typography>
                  Sex: {selectedParticipant ? selectedParticipant.sex : null}
                </Typography>
                <Typography>
                  Room: {selectedParticipant && selectedParticipant.room ? selectedParticipant.room : "Unknown"}
                </Typography>
                <Typography>
                  Date of birth: {selectedParticipant && selectedParticipant.birthday ? moment(new Date(selectedParticipant.birthday)).format("DD/MM/YYYY") : "Unknown"}
                </Typography>
              </Box>
              :
              <Box><Typography>Not assigned. Position cannot be validated without a participant assigned.</Typography></Box>
          }
        </DialogContent>
        {errorDisplay}
        <DialogActions>
          <Box>
            {
              isParticipantValidator && participantId ?
                <Button variant="contained"
                  onClick={() => this.handleToogleValidation()}
                  disabled={loading}
                  style={{ backgroundColor: validate ? "#8b0000" : "#008b00", color: "white" }} >
                  {validate ? "Remove validation" : "Validate"}
                </Button>
                :
                null
            }
          </Box>
          <Box flexGrow={1} />
          <Box>
            <Button disabled={loading} onClick={this.handleCancel} color="primary">
              {isPositionUpdater ? "Cancel" : "Close"}
            </Button>
          </Box>
          <Box>
            {
              isPositionUpdater ?
                <Button variant="contained" disabled={loading} onClick={() => this.handleConfirmation()} color="primary">
                  Update!
                  </Button>
                :
                null
            }
          </Box>
        </DialogActions>
      </Dialog>
    );
  }
}

EditPositionDialog.propTypes = {
  onClose: PropTypes.func
};

export default EditPositionDialog;