import React, { Component } from 'react';

import './ListUsers.css';
import ColorPickerDialog from './colorPickerDialog/ColorPickerDialog'
import DeleteUserDialog from './deleteUserDialog/DeleteUserDialog'
import * as userApi from '../../utils/api/user';

import MaterialTable from 'material-table'
import EditUserDialog from './editUserDialog/EditUserDialog';

class ListUsers extends Component {
  state = {
    dialogPickerOpen: false,
    dialogRemoveUserOpen: false,
    dialogEditUserOpen: false,
    selectedUser: [],
    isStaffManager: false,
  };

  componentDidMount() {
    this.checkGroups();
  }

  async checkGroups() {
    const isStaffManager = await userApi.isStaffManager()
    this.setState({
      isStaffManager: isStaffManager,
    })
  }

  handlePickerClose = () => {
    this.setState({ dialogPickerOpen: false });
  };

  handleRemoveUserClose = () => {
    this.setState({ dialogRemoveUserOpen: false });
  };

  handleEditUserClose = () => {
    this.setState({ dialogEditUserOpen: false });
  };

  handlePickerOpen(rowData) {
    this.setState({
      dialogPickerOpen: true,
      selectedUser: rowData
    });
  }

  handleRemoveUserOpen(rowData) {
    this.setState({
      selectedUser: rowData,
      dialogRemoveUserOpen: true
    });
  }

  handleEditUserOpen(rowData) {
    this.setState({
      selectedUser: rowData,
      dialogEditUserOpen: true
    });
  }

  render() {
    const { isStaffManager } = this.state;
    const { listUsers } = this.props;
    return (
      <div>
        <script src="https://sdk.amazonaws.com/js/aws-sdk-2.385.0.min.js"></script>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"></link>
        <MaterialTable
          columns={[
            { title: 'Name', field: 'name', width: null, defaultSort: 'asc' },
            {
              title: 'Pseudo',
              field: 'pseudo',
              render: rowData => {
                return (
                  <div>
                    {rowData.pseudo || "Undefined"}
                  </div>
                )
              },
            },
            {
              title: 'Color',
              field: 'color',
              render: rowData => {
                const color = rowData.color
                return (
                  <div style={{ width: '100%', backgroundColor: color, height: '100%', fontSize: "1.2em" }}>
                    {rowData.pseudo || rowData.name}
                  </div>
                )
              },
            }
          ]}
          actions={[
            {
              icon: 'editl',
              tooltip: 'Edit pseudo',
              hidden: !isStaffManager,
              onClick: (event, rowData) => this.handleEditUserOpen(rowData),
            },
            {
              icon: 'format_color_fill',
              tooltip: 'Pick a color',
              hidden: !isStaffManager,
              onClick: (event, rowData) => this.handlePickerOpen(rowData),
            },
            {
              icon: 'remove',
              tooltip: 'Remove user',
              hidden: !isStaffManager,
              onClick: (event, rowData) => this.handleRemoveUserOpen(rowData),
            }
          ]}
          options={{
            actionsColumnIndex: -1,
            paging: false,
            draggable: false,
          }}
          data={listUsers}
          title="List users"
        />
        <EditUserDialog
          open={this.state.dialogEditUserOpen}
          onClose={this.handleEditUserClose}
          user={this.state.selectedUser}
        />
        <ColorPickerDialog
          open={this.state.dialogPickerOpen}
          onClose={this.handlePickerClose}
          user={this.state.selectedUser}
        />
        <DeleteUserDialog
          open={this.state.dialogRemoveUserOpen}
          onClose={this.handleRemoveUserClose}
          user={this.state.selectedUser}
        />
      </div>
    );
  }
}

export default ListUsers;