import React from 'react';
import PropTypes from 'prop-types';

import * as positionApi from '../../../utils/api/position'

import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { LinearProgress } from '@material-ui/core';

class DeletePositionDialog extends React.PureComponent {
    state = {
        loading: false,
    }
    handleCancel = () => {
        this.props.onClose();
    };

    async handleConfirmation(position) {
        this.setState({ loading: true });
        let resultDeletePosition = await positionApi.deletePosition(position.id);
        console.log(resultDeletePosition)
        this.setState({ loading: false });
        this.props.onClose();
    }

    render() {
        const { position, ...other } = this.props;
        const { loading } = this.state;

        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xs"
                onEntering={this.handleEntering}
                aria-labelledby="confirmation-dialog-title"
                {...other}
            >
                <DialogTitle id="confirmation-dialog-title">You are about to delete permanantly the position {position.name}
                {
                    loading ?
                    <LinearProgress />
                    :
                    null
                }
                </DialogTitle>
                <DialogContent>
                    This action will delete all information about this position (position information and assignable tasks linked) and cannot be undone. Please confirm this is what you want to do.
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={this.handleCancel} color="primary">
                        Cancel
                </Button>
                    <Button variant="contained" disabled={loading} onClick={() => this.handleConfirmation(position)} color="primary">
                        Yes it is!
                </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

DeletePositionDialog.propTypes = {
    onClose: PropTypes.func
};

export default DeletePositionDialog;