import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import * as mutations from '../../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';

import ColorPicker from 'material-ui-color-picker'

// UI
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';

class ColorPickerDialog extends PureComponent {
  state = {
    userName: "",
    color: "",
    errors: "",
    loading: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.setState({
        userName: this.props.user.name,
        color: this.props.user.color,
      });
    }
  }

  handleCancel = () => {
    this.props.onClose();
  };

  async handleConfirmation() {
    let errors;

    this.setState({ loading: true });

    let input = {
      userName: this.state.userName,
      color: this.state.color,
    }
    try {
      await API.graphql(graphqlOperation(mutations.updateUser, { input: input }));
    } catch (e) {
      errors = e.errors;
      console.log(errors)
      alert("Fail to update the user color, please retry later or contact your administrator.")
    }

    this.setState({ loading: false });
    this.props.onClose();
  }

  handleOk = () => {
    this.props.onClose();
  };

  handleChange = stateName => event => {
    this.setState({
      [stateName]: event.target.value,
    });
  };

  handleColorChange = (color) => {
    if (color) {
      this.setState({
        color: color,
      });
    }
  }

  render() {
    const { user, ...other } = this.props;
    const { loading } = this.state;

    let loadingDisplay;
    if (loading) {
      loadingDisplay = <DialogContent><CircularProgress /></DialogContent>;
    }

    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        onEntering={this.handleEntering}
        aria-labelledby="confirmation-dialog-title"
        {...other}
      >
        <DialogTitle id="confirmation-dialog-title">Edit color for a user</DialogTitle>
        <DialogContent>
          Please select the color you want to assign to {user.name}:
        </DialogContent>
        <DialogContent>
          <ColorPicker
            name='color'
            defaultValue={this.props.user.color}
            //value={this.state.color}
            onChange={color => this.handleColorChange(color)}
          />
          <div style={{ width: '50px', height: '250px' }}></div>
        </DialogContent>
        <DialogContent>
          {loadingDisplay}
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={this.handleCancel} color="primary">
            Cancel
      </Button>
          <Button disabled={loading} onClick={() => this.handleConfirmation()} color="primary">
            Update!
      </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ColorPickerDialog.propTypes = {
  onClose: PropTypes.func
};

export default ColorPickerDialog;