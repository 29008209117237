/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getStudy = /* GraphQL */ `
  query GetStudy($id: ID!) {
    getStudy(id: $id) {
      id
      name
      date
      description
      active
    }
  }
`;
export const listStudys = /* GraphQL */ `
  query ListStudys(
    $id: ID
    $filter: ModelStudyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStudys(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        date
        description
        active
      }
      nextToken
    }
  }
`;
export const getPart = /* GraphQL */ `
  query GetPart($id: ID!) {
    getPart(id: $id) {
      id
      studyId
      name
      date
      description
    }
  }
`;
export const listParts = /* GraphQL */ `
  query ListParts(
    $id: ID
    $filter: ModelPartFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listParts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        studyId
        name
        date
        description
      }
      nextToken
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      partId
      name
      date
      description
      indicativeT0
      active
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $id: ID
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGroups(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        partId
        name
        date
        description
        indicativeT0
        active
      }
      nextToken
    }
  }
`;
export const getProtocolValidation = /* GraphQL */ `
  query GetProtocolValidation($id: ID!) {
    getProtocolValidation(id: $id) {
      id
      groupId
      date
      user
      validate
      action
      comment
      version
    }
  }
`;
export const listProtocolValidations = /* GraphQL */ `
  query ListProtocolValidations(
    $id: ID
    $filter: ModelProtocolValidationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProtocolValidations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        groupId
        date
        user
        validate
        action
        comment
        version
      }
      nextToken
    }
  }
`;
export const getProtocolComment = /* GraphQL */ `
  query GetProtocolComment($id: ID!) {
    getProtocolComment(id: $id) {
      id
      groupId
      date
      user
      comment
    }
  }
`;
export const listProtocolComments = /* GraphQL */ `
  query ListProtocolComments(
    $id: ID
    $filter: ModelProtocolCommentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProtocolComments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        groupId
        date
        user
        comment
      }
      nextToken
    }
  }
`;
export const getProtocolTask = /* GraphQL */ `
  query GetProtocolTask($id: ID!) {
    getProtocolTask(id: $id) {
      id
      groupId
      name
      timeToT0
      description
      taskTypeId
    }
  }
`;
export const listProtocolTasks = /* GraphQL */ `
  query ListProtocolTasks(
    $id: ID
    $filter: ModelProtocolTaskFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProtocolTasks(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        groupId
        name
        timeToT0
        description
        taskTypeId
      }
      nextToken
    }
  }
`;
export const getTaskType = /* GraphQL */ `
  query GetTaskType($id: ID!) {
    getTaskType(id: $id) {
      id
      name
    }
  }
`;
export const listTaskTypes = /* GraphQL */ `
  query ListTaskTypes(
    $id: ID
    $filter: ModelTaskTypeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTaskTypes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
      }
      nextToken
    }
  }
`;
export const getAssignableTask = /* GraphQL */ `
  query GetAssignableTask($id: ID!) {
    getAssignableTask(id: $id) {
      id
      description
      applicableDate
      delta
      notLinkToProtocol
      activationStatus
      protocolTaskId
      positionId
      userId
    }
  }
`;
export const listAssignableTasks = /* GraphQL */ `
  query ListAssignableTasks(
    $id: ID
    $filter: ModelAssignableTaskFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAssignableTasks(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        description
        applicableDate
        delta
        notLinkToProtocol
        activationStatus
        protocolTaskId
        positionId
        userId
      }
      nextToken
    }
  }
`;
export const getPosition = /* GraphQL */ `
  query GetPosition($id: ID!) {
    getPosition(id: $id) {
      id
      groupId
      participantId
      name
      t0
      inclusion
      treatment
      dose
      validate
    }
  }
`;
export const listPositions = /* GraphQL */ `
  query ListPositions(
    $id: ID
    $filter: ModelPositionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPositions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        groupId
        participantId
        name
        t0
        inclusion
        treatment
        dose
        validate
      }
      nextToken
    }
  }
`;
export const getParticipant = /* GraphQL */ `
  query GetParticipant($id: ID!) {
    getParticipant(id: $id) {
      id
      groupId
      selection
      code
      sex
      birthday
      room
      inDate
      outDate
      active
    }
  }
`;
export const listParticipants = /* GraphQL */ `
  query ListParticipants(
    $id: ID
    $filter: ModelParticipantFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listParticipants(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        groupId
        selection
        code
        sex
        birthday
        room
        inDate
        outDate
        active
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($userName: ID!) {
    getUser(userName: $userName) {
      userName
      email
      create
      lastUpdate
      color
      active
      code
      pseudo
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $userName: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      userName: $userName
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userName
        email
        create
        lastUpdate
        color
        active
        code
        pseudo
      }
      nextToken
    }
  }
`;
export const getTaskValidation = /* GraphQL */ `
  query GetTaskValidation($id: ID!) {
    getTaskValidation(id: $id) {
      id
      date
      assignableTaskId
      positionId
      active
      userId
      comment
    }
  }
`;
export const listTaskValidations = /* GraphQL */ `
  query ListTaskValidations(
    $id: ID
    $filter: ModelTaskValidationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTaskValidations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        date
        assignableTaskId
        positionId
        active
        userId
        comment
      }
      nextToken
    }
  }
`;
export const getAdminCredential = /* GraphQL */ `
  query GetAdminCredential($id: ID!) {
    getAdminCredential(id: $id) {
      id
      accessKeyId
      secretAccessKey
      region
    }
  }
`;
export const listAdminCredentials = /* GraphQL */ `
  query ListAdminCredentials(
    $filter: ModelAdminCredentialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminCredentials(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accessKeyId
        secretAccessKey
        region
      }
      nextToken
    }
  }
`;
export const studyByName = /* GraphQL */ `
  query StudyByName(
    $active: ActiveStatus
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studyByName(
      active: $active
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        date
        description
        active
      }
      nextToken
    }
  }
`;
export const studyByDate = /* GraphQL */ `
  query StudyByDate(
    $active: ActiveStatus
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studyByDate(
      active: $active
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        date
        description
        active
      }
      nextToken
    }
  }
`;
export const partByStudy = /* GraphQL */ `
  query PartByStudy(
    $studyId: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    partByStudy(
      studyId: $studyId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studyId
        name
        date
        description
      }
      nextToken
    }
  }
`;
export const groupByPart = /* GraphQL */ `
  query GroupByPart(
    $partId: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupByPart(
      partId: $partId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partId
        name
        date
        description
        indicativeT0
        active
      }
      nextToken
    }
  }
`;
export const validationByGroup = /* GraphQL */ `
  query ValidationByGroup(
    $groupId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProtocolValidationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    validationByGroup(
      groupId: $groupId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        date
        user
        validate
        action
        comment
        version
      }
      nextToken
    }
  }
`;
export const commentsByGroup = /* GraphQL */ `
  query CommentsByGroup(
    $groupId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProtocolCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByGroup(
      groupId: $groupId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        date
        user
        comment
      }
      nextToken
    }
  }
`;
export const protocolTaskByGroup = /* GraphQL */ `
  query ProtocolTaskByGroup(
    $groupId: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProtocolTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    protocolTaskByGroup(
      groupId: $groupId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        name
        timeToT0
        description
        taskTypeId
      }
      nextToken
    }
  }
`;
export const assignableTaskByPosition = /* GraphQL */ `
  query AssignableTaskByPosition(
    $positionId: ID
    $applicableDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAssignableTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    assignableTaskByPosition(
      positionId: $positionId
      applicableDate: $applicableDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        applicableDate
        delta
        notLinkToProtocol
        activationStatus
        protocolTaskId
        positionId
        userId
      }
      nextToken
    }
  }
`;
export const assignableTaskByProtocolTask = /* GraphQL */ `
  query AssignableTaskByProtocolTask(
    $protocolTaskId: ID
    $applicableDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAssignableTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    assignableTaskByProtocolTask(
      protocolTaskId: $protocolTaskId
      applicableDate: $applicableDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        applicableDate
        delta
        notLinkToProtocol
        activationStatus
        protocolTaskId
        positionId
        userId
      }
      nextToken
    }
  }
`;
export const assignableTaskByUserAssigned = /* GraphQL */ `
  query AssignableTaskByUserAssigned(
    $userId: ID
    $applicableDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAssignableTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    assignableTaskByUserAssigned(
      userId: $userId
      applicableDate: $applicableDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        applicableDate
        delta
        notLinkToProtocol
        activationStatus
        protocolTaskId
        positionId
        userId
      }
      nextToken
    }
  }
`;
export const assignableTaskByActivation = /* GraphQL */ `
  query AssignableTaskByActivation(
    $activationStatus: AssignableTaskActivation
    $applicableDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAssignableTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    assignableTaskByActivation(
      activationStatus: $activationStatus
      applicableDate: $applicableDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        applicableDate
        delta
        notLinkToProtocol
        activationStatus
        protocolTaskId
        positionId
        userId
      }
      nextToken
    }
  }
`;
export const positionByGroup = /* GraphQL */ `
  query PositionByGroup(
    $groupId: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPositionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    positionByGroup(
      groupId: $groupId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        participantId
        name
        t0
        inclusion
        treatment
        dose
        validate
      }
      nextToken
    }
  }
`;
export const positionByParticipant = /* GraphQL */ `
  query PositionByParticipant(
    $participantId: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPositionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    positionByParticipant(
      participantId: $participantId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        participantId
        name
        t0
        inclusion
        treatment
        dose
        validate
      }
      nextToken
    }
  }
`;
export const participantBySexCode = /* GraphQL */ `
  query ParticipantBySexCode(
    $sex: Sex
    $code: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelParticipantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    participantBySexCode(
      sex: $sex
      code: $code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        selection
        code
        sex
        birthday
        room
        inDate
        outDate
        active
      }
      nextToken
    }
  }
`;
export const participantByGroup = /* GraphQL */ `
  query ParticipantByGroup(
    $groupId: ID
    $code: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelParticipantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    participantByGroup(
      groupId: $groupId
      code: $code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        selection
        code
        sex
        birthday
        room
        inDate
        outDate
        active
      }
      nextToken
    }
  }
`;
export const participantByOutDate = /* GraphQL */ `
  query ParticipantByOutDate(
    $active: ActiveStatus
    $outDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelParticipantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    participantByOutDate(
      active: $active
      outDate: $outDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        selection
        code
        sex
        birthday
        room
        inDate
        outDate
        active
      }
      nextToken
    }
  }
`;
export const userByCode = /* GraphQL */ `
  query UserByCode(
    $active: ActiveStatus
    $code: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByCode(
      active: $active
      code: $code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userName
        email
        create
        lastUpdate
        color
        active
        code
        pseudo
      }
      nextToken
    }
  }
`;
export const userByPseudo = /* GraphQL */ `
  query UserByPseudo(
    $active: ActiveStatus
    $pseudo: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByPseudo(
      active: $active
      pseudo: $pseudo
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userName
        email
        create
        lastUpdate
        color
        active
        code
        pseudo
      }
      nextToken
    }
  }
`;
export const validationByUser = /* GraphQL */ `
  query ValidationByUser(
    $userId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskValidationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    validationByUser(
      userId: $userId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        assignableTaskId
        positionId
        active
        userId
        comment
      }
      nextToken
    }
  }
`;
export const validationByTask = /* GraphQL */ `
  query ValidationByTask(
    $assignableTaskId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskValidationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    validationByTask(
      assignableTaskId: $assignableTaskId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        assignableTaskId
        positionId
        active
        userId
        comment
      }
      nextToken
    }
  }
`;
export const validationByTime = /* GraphQL */ `
  query ValidationByTime(
    $active: TaskValidationActivation
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskValidationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    validationByTime(
      active: $active
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        assignableTaskId
        positionId
        active
        userId
        comment
      }
      nextToken
    }
  }
`;
export const validationByPosition = /* GraphQL */ `
  query ValidationByPosition(
    $positionId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskValidationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    validationByPosition(
      positionId: $positionId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        assignableTaskId
        positionId
        active
        userId
        comment
      }
      nextToken
    }
  }
`;
