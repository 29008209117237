import React from 'react';
import PropTypes from 'prop-types';

import * as protocolTaskApi from '../../../utils/api/protocolTask'

import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ClearIcon from '@material-ui/icons/Clear';

import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { LinearProgress } from '@material-ui/core';

class TranslateProtocolTasksDialog extends React.PureComponent {
    state = {
        loaded: 0,
        loading: false,
        daysToT0: 0,
        hoursToT0: 0,
        minutesToT0: 0,
    };

    handleCancel = () => {
        this.props.onClose();
    };

    async handleConfirmation(protocolTasks) {
        this.setState({ loading: true, loaded: 0 });
        let remainingList = [...protocolTasks];
        let totalElements = 0;
        for (let i = 0; i < protocolTasks.length; i++) {
            if (remainingList.length === 0) break
            // Batch 100 tasks together
            const batchList = remainingList.slice(0, 100);
            remainingList.splice(0, 100)
            totalElements += batchList.length;
            this.setState({ loaded: totalElements * 100 / protocolTasks.length })
            console.log(totalElements)
            await Promise.all(batchList.map(async (protocolTask) => {
                let timeToT0 = (this.state.daysToT0 * 24 * 60 + this.state.hoursToT0 * 60 + this.state.minutesToT0 * 1) + protocolTask.timeToT0;
                let resultUpdateProtocolTask = await protocolTaskApi.updateProtocolTask(protocolTask.id,
                    protocolTask.groupId,
                    protocolTask.name,
                    protocolTask.description,
                    timeToT0);
                if (resultUpdateProtocolTask.code !== "201") {
                    alert("Fail to update the task: " + protocolTask.name);
                }
            }));
        }

        setTimeout(() => {
            if (protocolTasks.length > 200) window.location.reload();
            this.setState({ loading: false })
            this.props.onClose();
        }, 2000);
    }

    handleChange = stateName => event => {
        this.setState({
            [stateName]: event.target.value,
        });
    };

    render() {
        let { protocolTasks, ...other } = this.props;
        let { loading, loaded } = this.state;
        if (!protocolTasks) {
            protocolTasks = [];
        }
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xl"
                onEntering={this.handleEntering}
                aria-labelledby="confirmation-dialog-title"
                {...other}
            >
                <DialogTitle id="confirmation-dialog-title">You are about to translate those tasks and modify their delta to T0
                {
                        loading ?
                            <LinearProgress variant="determinate" value={loaded} /> :
                            null
                    }
                </DialogTitle>
                <DialogContent>
                    The T0 of tasks in the list below will be translated by the value set.
                    <br />
                    <List>
                        {protocolTasks.map(protocolTask =>
                            <ListItem key={protocolTask.id}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <ClearIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={protocolTask.name}
                                    secondary={"Time to T0: " + protocolTask.timeToT0 + "min"}
                                />
                            </ListItem>
                        )}
                    </List>
                    <form noValidate autoComplete="off">
                        <h4>Delta to add to tasks</h4>
                        <FormControl>
                            <InputLabel shrink>
                                Days
              </InputLabel>
                            <TextField
                                value={this.state.daysToT0}
                                onChange={this.handleChange('daysToT0')}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel shrink htmlFor="hoursToT0">
                                Hours
              </InputLabel>
                            <TextField
                                value={this.state.hoursToT0}
                                onChange={this.handleChange('hoursToT0')}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel shrink htmlFor="minutesToT0">
                                Minutes
              </InputLabel>
                            <TextField
                                value={this.state.minutesToT0}
                                onChange={this.handleChange('minutesToT0')}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                            />
                        </FormControl>
                    </form>

                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={this.handleCancel} color="primary">
                        Cancel
          </Button>
                    <Button variant="contained" disabled={loading} onClick={() => this.handleConfirmation(protocolTasks)} color="primary">
                        Translate!
          </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

TranslateProtocolTasksDialog.propTypes = {
    onClose: PropTypes.func
};

export default TranslateProtocolTasksDialog;