import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import Utils from '../../utils/utils';
import * as constants from '../constants';
import uuid from 'uuid/v4';

export async function deleteParticipant(participantId) {
    let returnMessage = {
        code: "201",
        messages: [],
    }

    try {
        await API.graphql(graphqlOperation(mutations.deleteParticipant, { input: { id: participantId } }));
    } catch (e) {
        returnMessage.code = "400";
        returnMessage.messages = e.errors;
        return returnMessage
    }
    let newMessages = returnMessage.messages;
    newMessages.push("Deleted the participant")
    returnMessage.messages = newMessages;
    return returnMessage
}

export async function listParticipantByGroup(groupId) {
    try {
        const rawParticipantByGroup = await API.graphql(graphqlOperation(queries.participantByGroup,
            {
                groupId: groupId,
                sortDirection: constants.ORDER_DESC,
                limit: constants.LIMIT
            }));
        let participantByGroup = Utils.checkNested(rawParticipantByGroup, 'data', 'participantByGroup')
        return participantByGroup
    } catch (e) {
        console.log(e)
        return null
    }
}

export async function listParticipantByOutDate(outDate) {
    try {
        const rawParticipantByOutDate = await API.graphql(graphqlOperation(queries.participantByOutDate,
            {
                active: constants.ACTIVE_STATUS_ONLINE,
                outDate: { ge: outDate },
                sortDirection: constants.ORDER_DESC,
                limit: constants.LIMIT
            }));
        let participantByOutDate = Utils.checkNested(rawParticipantByOutDate, 'data', 'participantByOutDate')
        return participantByOutDate
    } catch (e) {
        console.log(e)
        return null
    }
}

export async function createParticipant(groupId,
    selection,
    code,
    sex,
    birthday = null,
    room = null,
    inDate = null,
    outDate = null) {
    const nullDate = new Date(null).toISOString();
    // First create input with mandatory fields
    let input = {
        id: uuid(),
        groupId: groupId,
        selection: selection,
        code: code,
        sex: sex,
        birthday: nullDate,
        inDate: nullDate,
        outDate: nullDate,
        active: constants.ACTIVE_STATUS_ONLINE,
    }
    if (room && room !== " ") {
        input.room = room;
    }
    if (birthday && birthday !== nullDate) {
        input.birthday = birthday;
    }
    if (inDate && inDate !== nullDate) {
        input.inDate = inDate;
    }
    if (outDate && outDate !== nullDate) {
        input.outDate = outDate;
    }
    if (outDate && outDate !== nullDate) {
        input.outDate = outDate;
    }
    let result = await API.graphql(graphqlOperation(mutations.createParticipant, { input: input }));
    return result
}

export async function updateParticipant(id,
    groupId,
    selection,
    code,
    sex,
    birthday = null,
    room = null,
    inDate = null,
    outDate = null) {
    const nullDate = new Date(null).toISOString();
    // First create input with mandatory fields
    let input = {
        id: id,
        groupId: groupId,
        selection: selection,
        code: code,
        sex: sex,
        birthday: nullDate,
        inDate: nullDate,
        outDate: nullDate,
        active: constants.ACTIVE_STATUS_ONLINE,
    }
    if (room && room !== " ") {
        input.room = room;
    }
    if (birthday && birthday !== nullDate) {
        input.birthday = birthday;
    }
    if (inDate && inDate !== nullDate) {
        input.inDate = inDate;
    }
    if (outDate && outDate !== nullDate) {
        input.outDate = outDate;
    }
    if (outDate && outDate !== nullDate) {
        input.outDate = outDate;
    }

    let result = await API.graphql(graphqlOperation(mutations.updateParticipant, { input: input }));
    return result
}

export async function getParticipant(id) {
    if (id === null) return null
    try {
        const result = await API.graphql(graphqlOperation(queries.getParticipant, { id: id }));
        return result
    } catch (e) {
        console.log(e)
        return null
    }
}