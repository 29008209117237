import React, { useState, useEffect, useContext } from 'react';

import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';

import { TextField, Box, Typography, Divider, ButtonGroup } from '@material-ui/core';

import * as protocolValidationApi from '../../utils/api/protocolValidation';
import * as protocolCommentApi from '../../utils/api/protocolComment';
import * as user from '../../utils/api/user';

import { ValidateProtocolButtonContext } from './ValidateProtocolButtonContextProvider';
import TrackerList from './TrackerList';
import MessagesList from './MessagesList';


const ValidateProtocolDialog = (props) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [comment, setComment] = useState("");
    const [listSelected, setListSelected] = useState("tracker");
    const [canValidate, setCanValidate] = useState(false);
    const currentCtx = useContext(ValidateProtocolButtonContext);

    const handleComment = async (group) => {
        if (!group.id || !comment || comment === "") {
            setErrors([...errors, 'Fail to comment, please retry later.'])
            return
        }
        setLoading(true);
        console.log("comment" + comment)
        await protocolCommentApi.createProtocolComment(group.id, comment)
        setLoading(false);
        setErrors([]);
        setComment("")
    }

    const handleValidate = async (group) => {
        if (!group.id) {
            setErrors([...errors, 'Fail to comment, please retry later.'])
            return
        }
        setLoading(true);
        await protocolValidationApi.validateProtocol(group.id, currentCtx.validationStatus)
        setLoading(false);
    }

    useEffect(() => {
        checkCanValidate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCtx.isStudyValidator, currentCtx.validationStatus])

    const checkCanValidate = async () => {
        if (currentCtx.isStudyValidator && currentCtx.validationStatus && currentCtx.validationStatus.items.length > 0) {
            const currentUsername = await user.getCurrentUsername();
            if (currentCtx.validationStatus.items[0].user !== currentUsername || currentCtx.validationStatus.items[0].validate === false) {
                // That means is the last one validating, not allowed to do it again
                setCanValidate(true)
                return;
            }
        }
        setCanValidate(false)
    }

    return (
        <Dialog
            open={props.open}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            fullWidth={true}
            aria-labelledby="confirmation-dialog-title"
        >
            <DialogTitle id="confirmation-dialog-title">
                <Box display="flex" flexWrap='wrap' alignItems='center'>
                    <Box marginRight={2}>
                        <Typography>Protocol: <b>{currentCtx.group ? currentCtx.group.name : "Loading"}</b></Typography>
                        {
                            currentCtx.validationStatus === null ?
                                <Typography>Loading...</Typography>
                                :
                                <Typography>{currentCtx.validationStatus.isValidate ? "Version: " + currentCtx.validationStatus.version : "Not validated"}</Typography>
                        }
                    </Box>
                    <Box>
                        <Button disabled={loading || !canValidate} onClick={() => handleValidate(currentCtx.group)} color="primary">
                            Validate
                            </Button>
                    </Box>
                    <Box flexGrow={1} />
                    <Box>
                        <Button disabled={loading} onClick={() => props.onClose()} color="secondary">Close</Button>
                    </Box>
                </Box>
                <Divider />
            </DialogTitle>
            <DialogContent>
                <Box>
                    <ButtonGroup color="primary">
                        <Button
                            variant={listSelected === "tracker" && "contained"}
                            onClick={() => setListSelected('tracker')}>
                            Tracker
                        </Button>
                        <Button
                            variant={listSelected === "messages" && "contained"}
                            onClick={() => setListSelected('messages')}>
                            Messages
                        </Button>
                    </ButtonGroup>
                </Box>
                {
                    listSelected === "messages" &&
                    <Box display="flex" flexWrap="wrap" alignItems='flex-end'>
                        <Box flexGrow={1} >
                            <form noValidate autoComplete="off">
                                <TextField
                                    required
                                    id="standard-comment"
                                    label="Comment"
                                    value={comment}
                                    onChange={(event) => setComment(event.target.value)}
                                    margin="normal"
                                    fullWidth
                                    multiline
                                />
                            </form>
                        </Box>
                        <Box>
                            {
                                loading ?
                                    <CircularProgress />
                                    :
                                    null
                            }
                        </Box>
                        <Box >
                            <Button disabled={loading} onClick={() => handleComment(currentCtx.group)} color="primary">
                                Post comment
                            </Button>
                        </Box>
                    </Box>
                }

                <Box>
                    <ul>
                        {errors.map((value, index) => {
                            return <li style={{ color: "red" }} key={index}>{value}</li>
                        })}
                    </ul>
                </Box>

                <Divider />
                <Box>
                    {listSelected === "tracker" ?
                        <TrackerList />
                        :
                        <MessagesList />
                    }
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default ValidateProtocolDialog