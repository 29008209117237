import { useSnackbar } from "notistack";
import {
  CreateEventInput,
  Label,
  LabelByParentQueryVariables,
} from "../../../utils/BarcodeClient/API";
import getQueryList from "../../../utils/BarcodeClient/genericApi/getQueryList";
import { labelByParent } from "../../../utils/BarcodeClient/graphql/queries";
import { BarcodeClientConfig } from "../../../utils/BarcodeClient/initClient";
import { EventType } from "./event";

export interface SideEffectEvent {
  label: Label;
  labelIdEventTrigger: string;
  event?: CreateEventInput;
}

const useEventSideEffect = () => {
  const { enqueueSnackbar } = useSnackbar();

  /*
    If centri, we need to a fill event for all alicots
  */
  const onCentri = async (
    newLabel: Label,
    event: CreateEventInput
  ): Promise<SideEffectEvent[]> => {
    if (newLabel.parentId) return [];
    try {
      const labels = await getQueryList<Label, LabelByParentQueryVariables>({
        query: labelByParent,
        key: "labelByParent",
        variables: {
          parentId: newLabel.id,
          limit: 100,
        },
        client: BarcodeClientConfig,
      });
      if (labels instanceof Error) {
        enqueueSnackbar("Fail to fetch centri side effect", {
          variant: "error",
        });
        return [];
      }
      return labels.items.map((label) => ({
        label,
        labelIdEventTrigger: newLabel.id,
        event: {
          ...event,
          labelId: label.id,
          type: EventType.FILL,
        },
      }));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      enqueueSnackbar("Fail to fetch centri side effect", { variant: "error" });
      return [];
    }
  };

  /*
    If transfer, we need to create the same event for all alicot at once
  */
  const onTransfer = async (
    newLabel: Label,
    event: CreateEventInput
  ): Promise<SideEffectEvent[]> => {
    if (!newLabel.parentId) return [];
    try {
      const labels = await getQueryList<Label, LabelByParentQueryVariables>({
        query: labelByParent,
        key: "labelByParent",
        variables: {
          parentId: newLabel.parentId,
          limit: 100,
        },
        client: BarcodeClientConfig,
      });
      if (labels instanceof Error) {
        enqueueSnackbar("Fail to fetch transfer side effect", {
          variant: "error",
        });
        return [];
      }
      return labels.items.map((label) => ({
        label,
        labelIdEventTrigger: newLabel.id,
        event: {
          ...event,
          labelId: label.id,
          type: EventType.TRANSFER,
        },
      }));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      enqueueSnackbar("Fail to fetch centri side effect", { variant: "error" });
      return [];
    }
  };

  const eventSideEffect = async (
    newLabel: Label,
    event: CreateEventInput
  ): Promise<SideEffectEvent[]> => {
    if (event.type === EventType.CENTRI) {
      const listSideEddects = await onCentri(newLabel, event);
      return listSideEddects;
    }
    if (event.type === EventType.TRANSFER) {
      const listSideEddects = await onTransfer(newLabel, event);
      return listSideEddects;
    }
    return [];
  };

  return {
    eventSideEffect,
  };
};

export default useEventSideEffect;
