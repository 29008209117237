import { IconQrCodeScanner } from "@aws-amplify/ui-react";
import { IconButton } from "@material-ui/core";
import { useState } from "react";
import AddEventDialog from "../labelEvent/Events/AddEventDialog";

const EventButton: React.FC = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <IconButton
        style={{ marginRight: "2em", border: "1px solid white" }}
        onClick={() => setOpen(true)}
      >
        <IconQrCodeScanner style={{ color: "white" }} />
      </IconButton>
      <AddEventDialog isOpen={open} cancel={() => setOpen(false)} />
    </>
  );
};

export default EventButton;
