/* eslint-disable @typescript-eslint/ban-types */
import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import { QueryListConfigType } from "../hooks/useQueryList";
import { initClient } from "../initClient";

const getQueryList = async <ItemType extends {}, VariablesType extends {} = {}>(
  config: QueryListConfigType<VariablesType>
) => {
  try {
    let rowResult;
    const clientOverride = initClient(config.client);
    if (clientOverride) {
      rowResult = await clientOverride.query({
        query: gql(config.query),
        variables: config.variables,
      });
    } else {
      rowResult = await API.graphql(
        graphqlOperation(config.query, config.variables)
      );
    }

    const {
      data: {
        [config.key]: { items: listItems, nextToken: fetchedNextToken },
      },
    } = rowResult as {
      data: {
        [key: string]: { items: ItemType[]; nextToken: string | null };
      };
    };
    return {
      items: listItems,
      nextToken: fetchedNextToken,
    };
  } catch (errorFetch) {
    // eslint-disable-next-line no-console
    console.log(errorFetch);
    return new Error(`Fail to fetch ${config.key}`);
  }
};

export default getQueryList;
