import React, { PureComponent } from 'react';

import './ListAssignableTasks.css';

import MaterialTable from 'material-table'
import * as constants from '../../utils/constants';

import * as assignableTaskApi from '../../utils/api/assignableTask';
import * as users from '../../utils/api/user';

import TranslateAssignableTasksDialog from './translateAssignableTasksDialog/TranslateAssignablelTasksDialog';
import EditAssignableTaskDialog from './editAssignableTaskDialog/EditAssignableTaskDialog';
import moment from 'moment';
import { LinearProgress, Tooltip } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

class ListAssignableTasks extends PureComponent {
  state = {
    selectedAssignableTasks: [],
    selectedAssignableTask: "",
    listAssignableTask: [],
    dialogEditDeltaOpen: false,
    dialogEditTasksOpen: false,
    loading: false,
    laoded: 0,
    isStudyManager: false,
  };
  
  componentDidMount() {
    this.checkGroups();
  }

  async checkGroups() {
    const isStudyManager = await users.isStudyManager();
    this.setState({
      isStudyManager: isStudyManager,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lastUpdate !== this.props.lastUpdate) {
      this.setState({ listAssignableTask: this.props.listTasks })
    }
  }

  handleEditDeltaClose = () => {
    this.setState({ dialogEditDeltaOpen: false });
  };

  handleEditDeltaOpen(rowData) {
    this.setState({
      selectedAssignableTasks: rowData,
      dialogEditDeltaOpen: true
    });
  }

  handleEditTasksClose = () => {
    this.setState({ dialogEditTasksOpen: false });
  };

  handleEditTasksOpen(rowData) {
    if (rowData.length > 1) {
      alert("Editing option is available only for editing task one by one, you selected " + rowData.length + " tasks.");
      return
    }
    this.setState({
      selectedAssignableTask: rowData[0],
      dialogEditTasksOpen: true
    });
  }

  async activateTasks(rowsSelected, activate) {
    this.setState({ loading: true, loaded: 0 });
    let remainingList = [...rowsSelected];
    let totalElements = 0;
    for (let i = 0; i < rowsSelected.length; i++) {
      if (remainingList.length === 0) break
      // Batch 100 tasks together
      const batchList = remainingList.slice(0, 100);
      remainingList.splice(0, 100)
      totalElements += batchList.length;
      this.setState({ loaded: totalElements * 100 / rowsSelected.length })
      console.log(totalElements)
      await Promise.all(batchList.map(async (assignableTask) => {
        try {
          const updateAssignableTaskResult = await assignableTaskApi.activateAssignableTask(assignableTask.id, activate);
          if (updateAssignableTaskResult.code !== "201") {
            alert("Fail to perform the action on task: " + assignableTask.name);
          }
        } catch (e) {
          console.log(e.errors)
          alert("Fail to perform the action on task: " + assignableTask.name);
        }
      }));
    }
    if (rowsSelected.length > 200) window.location.reload();
    this.setState({ loading: false, loaded: 0 })
  }

  render() {
    const { isStudyManager, listAssignableTask, selectedAssignableTasks, selectedAssignableTask, loading, loaded } = this.state;

    const LoadingComponent = React.memo(function LoadingComponent() {
      return (
        loaded !== 0 ?
          <LinearProgress variant='determinate' value={loaded} />
          :
          <LinearProgress />
      )
    });
    return (
      <div>
        {
          loading ?
            <LoadingComponent />
            : null
        }
        <MaterialTable
          columns={[
            {
              title: 'Date',
              field: 'applicableDate',
              width: null,
              defaultSort: 'asc',
              render: rowData => {
                let textDecoration = "none";
                let color;
                if (rowData.activationStatus === constants.ASSIGNABLE_TASK_INACTIVE) {
                  textDecoration = "line-through";
                  color = "grey";
                }
                const applicableDate = moment(new Date(rowData.applicableDate || "")).format('HH:mm DD/MM/YYYY')
                return (
                  <div style={{ textDecoration: textDecoration, color: color }}>
                    {applicableDate}
                  </div>
                )
              },
            },
            {
              title: 'Theoretical time',
              field: 'protocolName',
              render: rowData => {
                let textDecoration = "none";
                let color;
                if (rowData.activationStatus === constants.ASSIGNABLE_TASK_INACTIVE) {
                  textDecoration = "line-through";
                  color = "grey";
                }
                return (
                  <div style={{ textDecoration: textDecoration, color: color }}>
                    <div>{rowData.protocolName}<br /></div>
                  </div>
                )
              },
            },
            {
              title: 'Act',
              field: 'protocolDescription',
              render: rowData => {
                let textDecoration = "none";
                let color;
                if (rowData.activationStatus === constants.ASSIGNABLE_TASK_INACTIVE) {
                  textDecoration = "line-through";
                  color = "grey";
                }
                return (
                  <div style={{ textDecoration: textDecoration, color: color }}>
                    <div hidden={!rowData.protocolDescription}>{rowData.protocolDescription}<br /></div>
                    <div hidden={!rowData.description}><span style={{ fontWeight: "bold" }}>Specific:</span> {rowData.description}</div>
                  </div>
                )
              },
            },
            {
              title: 'From protocol',
              field: 'notLinkToProtocol',
              render: rowData => {
                let textDecoration = "none";
                let color;
                if (rowData.activationStatus === constants.ASSIGNABLE_TASK_INACTIVE) {
                  textDecoration = "line-through";
                  color = "grey";
                }
                return (
                  <div style={{ textDecoration: textDecoration, color: color }}>
                    {!rowData.notLinkToProtocol && (<div>Yes</div>)}
                    {rowData.notLinkToProtocol && (<div style={{ backgroundColor: "rgba(203, 44, 44, 0.53)" }}>No</div>)}
                  </div>
                )
              },
            },
            {
              title: 'Staff',
              field: 'staffName',
              render: rowData => {
                const color = rowData.staffColor
                return (
                  <div style={{ width: '100%', backgroundColor: color, height: '100%', fontSize: "1.2em" }}>
                    {rowData.staffPseudo || rowData.staffName}
                  </div>
                )
              },
            },
            {
              title: 'Done?',
              field: 'isDone',
              render: rowData => {
                return (
                  <div>
                    {rowData.isDone ?
                      <Tooltip title={"Done by " + rowData.doneUser}>
                        <CheckCircleIcon color='secondary' />
                      </Tooltip>
                      :
                      null}
                  </div>
                )
              },
            }
          ]}
          actions={[
            {
              icon: 'check_box',
              tooltip: 'Activate tasks',
              disabled: loading,
              hidden: !isStudyManager,
              onClick: (event, rowSelected) => {
                this.activateTasks(rowSelected, true);
              },
            },
            {
              icon: 'indeterminate_check_box',
              tooltip: 'Unactivate tasks',
              disabled: loading,
              hidden: !isStudyManager,
              onClick: (event, rowSelected) => {
                this.activateTasks(rowSelected, false);
              },
            },
            {
              icon: 'swap_vert',
              tooltip: 'Add a time delta to selected tasks',
              disabled: loading,
              hidden: !isStudyManager,
              onClick: (event, rowData) => this.handleEditDeltaOpen(rowData),
            },
            {
              icon: 'edit',
              tooltip: 'Edit tasks',
              disabled: loading,
              hidden: !isStudyManager,
              onClick: (event, rowData) => this.handleEditTasksOpen(rowData),
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            paging: true,
            pageSizeOptions: [1, 100, 200, 500, 1000],
            pageSize: 200,
            sorting: true,
            selection: true,
            draggable: false,
          }}
          data={listAssignableTask}
          title=""
        />
        <TranslateAssignableTasksDialog
          open={this.state.dialogEditDeltaOpen}
          onClose={this.handleEditDeltaClose}
          assignableTasks={selectedAssignableTasks}
        />
        <EditAssignableTaskDialog
          open={this.state.dialogEditTasksOpen}
          onClose={this.handleEditTasksClose}
          assignableTask={selectedAssignableTask}
        />
      </div>
    );
  }
}

export default ListAssignableTasks;