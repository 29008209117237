import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  TextField,
} from "@material-ui/core";
import { useState } from "react";

const AskPseudoDialog: React.FC<{
  isOpen: boolean;
  loading: boolean;
  onValidate: (pseudo: string) => void;
}> = ({ isOpen, loading, onValidate }) => {
  const [pseudo, setPseudo] = useState<string>("");
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onValidate(pseudo);
  };
  return (
    <Dialog open={isOpen}>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <form
            style={{ paddingBottom: "1em", paddingTop: "1em" }}
            onSubmit={handleSubmit}
          >
            <TextField
              style={{ marginBottom: "1em" }}
              id="pseudo"
              label="Paraph"
              variant="outlined"
              fullWidth
              value={pseudo}
              onChange={(e) => {
                setPseudo(e.target.value);
              }}
              required
              error={!pseudo}
            />
            <div style={{ display: "flex" }}>
              <div style={{ flexGrow: "1" }} />
              <Button disabled={!pseudo} variant="contained" type="submit">
                Validate
              </Button>
            </div>
          </form>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AskPseudoDialog;
