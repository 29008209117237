import React, { PureComponent } from 'react';
import './Planning.css';
import ListPlanning from '../../components/listPlanning/ListPlanning'

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import * as assignableTaskApi from '../../utils/api/assignableTask'
import * as constants from '../../utils/constants';
import * as userApi from '../../utils/api/user';
import * as taskValidationApi from '../../utils/api/taskValidation';
import * as protocolTaskApi from '../../utils/api/protocolTask';
import * as taskTypeApi from '../../utils/api/taskType';
import * as positionApi from '../../utils/api/position';
import * as participantApi from '../../utils/api/participant';
import * as groupApi from '../../utils/api/group';
import * as partApi from '../../utils/api/part';
import * as studyApi from '../../utils/api/study';
import * as protocolValidationApi from '../../utils/api/protocolValidation';

import moment from 'moment';
import Utils from '../../utils/utils';
import { Box, LinearProgress } from '@material-ui/core';

class Planning extends PureComponent {
  state = {
    listAssignableTasks: [],
    lastUpdate: new Date().toISOString(),
    dateFilterBeginning: moment(new Date()).add(-4, 'hours').toISOString(),
    dateFilterEnd: moment(new Date()).add(12, 'hours').toISOString(),
    updateFull: false,
    lastFullUpdate: null,
    currentUser: null,
    isNextToken: false,
    loading: false,
    loaded: 0,
    noInternet: false,

    loadedStaff: [],
    loadedProtocolTask: [],
    loadedTaskType: [],
    loadedPosition: [],
    loadedParticipant: [],
    loadedGroup: [],
    loadedPart: [],
    loadedStudy: [],
    loadedValidationStatus: [],
  };

  componentDidMount() {
    this.queryCompleteList();
    let refreshId = setInterval(this.queryCompleteList.bind(this), 60 * 1000);
    this.setState({ refreshId: refreshId });
  }

  async queryCompleteList() {
    if (!window.navigator.onLine) {
      alert("You are currently offline, planning will not be updated");
      this.setState({ noInternet: true });
      return
    }
    this.setState({ noInternet: false });
    let currentUser = this.state.currentUser;
    if (!currentUser) currentUser = await userApi.getCurrentUsername();
    const lastUpdate = new Date().toISOString();
    const filterBeginning = moment(new Date()).add(-4, 'hours').toISOString();
    const filterEnd = moment(new Date()).add(12, 'hours').toISOString();

    // Determine if we should ask for a full update (15min)
    // 15min in millisecond
    const timeLimit = 900000;
    let lastFullUpdate = this.state.lastFullUpdate;
    const timeDiff = (new Date() - new Date(lastFullUpdate));
    if (timeDiff > timeLimit) {
      this.setState({ loading: true, loaded: 0 })
      console.log("Last full update 15min ago, empty cache");
      this.emptyCache();
      lastFullUpdate = new Date().toISOString();
    }

    // Reload done list
    await this.getDoneList(filterBeginning, filterEnd);
    // Get the assignable list
    const assignableTaskQuery = await assignableTaskApi.listAssignableTaskByUserAssigned(currentUser, filterBeginning, filterEnd)
    const isNextToken = Utils.checkNested(assignableTaskQuery, 'nextToken') ? true : false;
    let listAssignableTasks = Utils.checkNested(assignableTaskQuery, 'items') ? assignableTaskQuery.items : [];

    // Improve the list
    let upgradedList = [];
    let remainingList = [...listAssignableTasks];
    let totalElements = 0;
    for (let i = 0; i < listAssignableTasks.length; i++) {
      if (remainingList.length === 0) break
      // Batch 100 tasks together
      const batchList = remainingList.slice(0, 100);
      remainingList.splice(0, 100)
      totalElements += batchList.length;
      this.setState({ loaded: totalElements * 100 / listAssignableTasks.length })
      console.log(totalElements)
      await Promise.all(batchList.map(async (task) => {
        if (task.activationStatus === constants.ASSIGNABLE_TASK_ACTIVE) {
          const newElement = await this.formatATask(task);
          // If the task is done and more than 30min ago, then do not add it
          // 30min in millisecond
          const timeLimit = -1800000;
          const timeDiff = (new Date(newElement.applicableDate) - new Date());
          if (newElement.isDone && timeDiff < timeLimit) {
            console.log("done more than 30min ago");
          } else {
            upgradedList.push(newElement);
          }
        }
      }));
    }

    this.setState({
      lastUpdate: lastUpdate,
      lastFullUpdate: lastFullUpdate,
      currentUser: currentUser,
      dateFilterBeginning: filterBeginning,
      dateFilterEnd: filterEnd,
      listAssignableTasks: listAssignableTasks,
      upgradedList: upgradedList,
      isNextToken: isNextToken,
      loading: false,
      loaded: 0,
    });
  }

  emptyCache() {
    console.log("Cache empty")
    this.state.loadedStaff = [];
    this.state.loadedProtocolTask = [];
    this.state.loadedProtocolTask = [];
    this.state.loadedTaskType = [];
    this.state.loadedPosition = [];
    this.state.loadedParticipant = [];
    this.state.loadedGroup = [];
    this.state.loadedPart = [];
    this.state.loadedStudy = [];
    this.state.loadedValidationStatus = [];
  }

  async getDoneList(filterBeginning, filterEnd) {
    const doneListQuery = await taskValidationApi.listTaskValidationByTimeFrame(filterBeginning, filterEnd)
    const doneList = Utils.checkNested(doneListQuery, 'items') ? doneListQuery.items : [];
    this.state.doneList = doneList;
  }

  async formatATask(task) {
    let formatTask = {};

    // Directly with applicable task
    formatTask.id = Utils.checkNested(task, 'id');
    formatTask.applicableDate = Utils.checkNested(task, 'applicableDate') || "";
    formatTask.specificDescription = Utils.checkNested(task, 'description') || false;

    // Done list
    formatTask.isDone = false;
    for (let index = 0; index < this.state.doneList.length; index++) {
      if (this.state.doneList[index].assignableTaskId === task.id) {
        formatTask.isDone = true;
        formatTask.doneUser = this.state.doneList[index].userId;
        formatTask.doneId = this.state.doneList[index].id;
      }
    }

    // Need staff
    const staffId = task.userId;
    formatTask.staffUserName = "";
    formatTask.staffColor = "";
    if (staffId) {
      let found = false;
      for (let index = 0; index < this.state.loadedStaff.length; index++) {
        if (this.state.loadedStaff[index].userName === staffId) {
          found = true;
          formatTask.staffUserName = this.state.loadedStaff[index].userName;
          formatTask.staffColor = this.state.loadedStaff[index].color;
          break
        }
      }
      if (!found) {
        const newUser = await this.loadUser(staffId);
        formatTask.staffUserName = newUser.userName;
        formatTask.staffColor = newUser.color;
      }
    }

    // Need protocol task
    // get theorical time (name), description
    const protocolTaskId = task.protocolTaskId;
    formatTask.protocolName = "";
    formatTask.protocolDescription = "";
    if (protocolTaskId) {
      let found = false;
      let protocolTask = null;
      for (let index = 0; index < this.state.loadedProtocolTask.length; index++) {
        if (this.state.loadedProtocolTask[index].id === protocolTaskId) {
          found = true;
          formatTask.protocolName = this.state.loadedProtocolTask[index].name;
          formatTask.protocolDescription = this.state.loadedProtocolTask[index].description;
          protocolTask = this.state.loadedProtocolTask[index];
          break
        }
      }
      if (!found) {
        const newProtocolTask = await this.loadProtocolTask(protocolTaskId);
        formatTask.protocolName = newProtocolTask.name;
        formatTask.protocolDescription = newProtocolTask.description;
        protocolTask = newProtocolTask;
      }

      // Need protocol task / task type
      // get task type
      const taskTypeId = Utils.checkNested(protocolTask, "taskTypeId");
      if (taskTypeId) {
        let found = false;
        for (let index = 0; index < this.state.loadedTaskType.length; index++) {
          if (this.state.loadedTaskType[index].id === taskTypeId) {
            found = true;
            formatTask.taskTypeName = this.state.loadedTaskType[index].name;
            break
          }
        }
        if (!found) {
          const newTaskType = await this.loadTaskType(taskTypeId);
          if (newTaskType) {
            formatTask.taskTypeName = newTaskType.name;
          } else {
            formatTask.taskTypeName = "Unknown";
          }

        }
      }
    }

    // Need position
    // get position name (name), inclusion
    const positionId = task.positionId;
    formatTask.positionName = "";
    formatTask.positionInclusion = "";
    let position = null;
    if (positionId) {
      let found = false;
      for (let index = 0; index < this.state.loadedPosition.length; index++) {
        if (this.state.loadedPosition[index].id === positionId) {
          found = true;
          formatTask.positionName = this.state.loadedPosition[index].name;
          formatTask.positionInclusion = this.state.loadedPosition[index].inclusion;
          formatTask.positionId = this.state.loadedPosition[index].id;
          formatTask.positionValidate = this.state.loadedPosition[index].validate;
          position = this.state.loadedPosition[index];
          break
        }
      }
      if (!found) {
        const newPosition = await this.loadPosition(positionId);
        formatTask.positionName = newPosition.name;
        formatTask.positionInclusion = newPosition.inclusion;
        formatTask.positionValidate = newPosition.validate;
        formatTask.positionId = newPosition.id;
        position = newPosition;
      }

      // Need position / participant
      // get sex          
      if (position.participantId) {
        found = false;
        this.state.loadedParticipant.forEach(participant => {
          if (participant.id === position.participantId) {
            found = true;
            formatTask.participantSex = participant.sex;
            formatTask.participantSelection = participant.selection;
            formatTask.participantCode = participant.code;
            formatTask.participantRoom = participant.room;
          }
        });
        if (!found) {
          const newParticipant = await this.loadParticipant(position.participantId);
          formatTask.participantSex = newParticipant.sex;
          formatTask.participantSelection = newParticipant.selection;
          formatTask.participantRoom = newParticipant.room;
          formatTask.participantCode = newParticipant.code;
        }
      }

      // Need position / group
      const groupId = Utils.checkNested(position, "groupId");
      formatTask.groupName = "";
      if (groupId) {
        let found = false;
        let group = null;
        for (let index = 0; index < this.state.loadedGroup.length; index++) {
          if (this.state.loadedGroup[index].id === groupId) {
            found = true;
            formatTask.groupName = this.state.loadedGroup[index].name;
            formatTask.groupDescription = this.state.loadedGroup[index].description;
            group = this.state.loadedGroup[index];
            break
          }
        }
        if (!found) {
          const newGroup = await this.loadGroup(groupId);
          formatTask.groupName = newGroup.name;
          formatTask.groupDescription = newGroup.description;
          group = newGroup;
        }

        // Need validatation status
        let foundValidation = false;
        this.state.loadedValidationStatus.forEach(validationStatusLoaded => {
          if (validationStatusLoaded.groupId === groupId) {
            foundValidation = true;
            formatTask.groupValidate = validationStatusLoaded.isValidate;
          }
        });
        if (!foundValidation) {
          let newValidationStatus = await protocolValidationApi.getValidationStatus(groupId);
          newValidationStatus.groupId = groupId;
          this.state.loadedValidationStatus.push(newValidationStatus);
          formatTask.groupValidate = newValidationStatus.isValidate;
        }

        // Need part
        const partId = Utils.checkNested(group, "partId")
        if (partId) {
          let found = false;
          let part = null;
          for (let index = 0; index < this.state.loadedPart.length; index++) {
            if (this.state.loadedPart[index].id === partId) {
              found = true;
              formatTask.partName = this.state.loadedPart[index].name;
              part = this.state.loadedPart[index];
              break
            }
          }
          if (!found) {
            const newPart = await this.loadPart(partId);
            formatTask.partName = newPart.name;
            part = newPart;
          }

          // Need study
          const studyId = Utils.checkNested(part, "studyId")
          if (studyId) {
            let found = false;
            for (let index = 0; index < this.state.loadedStudy.length; index++) {
              if (this.state.loadedStudy[index].id === studyId) {
                found = true;
                formatTask.studyName = this.state.loadedStudy[index].name;
                break
              }
            }
            if (!found) {
              const newStudy = await this.loadStudy(studyId);
              formatTask.studyName = newStudy.name;
            }
          }
        }
      }
    }
    return formatTask
  }

  async loadUser(userId) {
    const userQuery = await userApi.getUser(userId);
    const user = Utils.checkNested(userQuery, 'data', 'getUser');
    if (user) this.state.loadedStaff.push(user);
    return user
  }

  async loadProtocolTask(id) {
    const protocolTaskQuery = await protocolTaskApi.getProtocolTask(id);
    const protocolTask = Utils.checkNested(protocolTaskQuery, 'data', 'getProtocolTask');
    if (protocolTask) this.state.loadedProtocolTask.push(protocolTask);
    return protocolTask
  }

  async loadTaskType(id) {
    const taskTypeQuery = await taskTypeApi.getTaskType(id);
    const taskType = Utils.checkNested(taskTypeQuery, 'data', 'getTaskType');
    if (taskType) this.state.loadedTaskType.push(taskType);
    return taskType
  }

  async loadPosition(id) {
    const positionQuery = await positionApi.getPosition(id);
    const position = Utils.checkNested(positionQuery, 'data', 'getPosition');
    if (position) this.state.loadedPosition.push(position);
    return position
  }

  async loadParticipant(id) {
    let rawParticipant = await participantApi.getParticipant(id);
    let participant = Utils.checkNested(rawParticipant, 'data', 'getParticipant');
    if (!participant) {
      participant = { id: id }
    }
    this.state.loadedParticipant.push(participant);
    return participant
  }

  async loadGroup(id) {
    const group = await groupApi.getGroupById(id);
    if (group) this.state.loadedGroup.push(group);
    return group
  }

  async loadPart(id) {
    const partQuery = await partApi.getPart(id);
    const part = Utils.checkNested(partQuery, 'data', 'getPart');
    if (part) this.state.loadedPart.push(part);
    return part
  }

  async loadStudy(id) {
    const studyQuery = await studyApi.getStudy(id);
    const study = Utils.checkNested(studyQuery, 'data', 'getStudy');
    if (study) this.state.loadedStudy.push(study);
    return study
  }

  componentWillUnmount() {
    clearInterval(this.state.refreshId);
  }

  render() {
    const { upgradedList, lastUpdate, isNextToken, lastFullUpdate, loading, loaded } = this.state;
    return (
      <div>
        <Box display="flex" flexWrap="wrap" alignItems="flex-end">
          <Typography variant="h4" color="inherit">
            Planning
          </Typography>
          <Box flexGrow={1} />
          <Typography>Last complete update: {moment(lastFullUpdate).format("HH:mm")}</Typography>
        </Box>

        <Divider variant="middle" />
        {
          isNextToken ?
            <Typography color="secondary">Warning! More than 2000 elements to display, last elements can be missing.</Typography>
            :
            null
        }
        {
          loading ?
            <LinearProgress variant="determinate" value={loaded} />
            : null
        }
        <ListPlanning listTasks={upgradedList} lastUpdate={lastUpdate} noInternet={this.state.noInternet}></ListPlanning>
      </div>
    );
  }
}

export default Planning;