import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as assignableTasksApi from '../../../utils/api/assignableTask'

// UI
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

class EditAssignableTaskDialog extends PureComponent {
  state = {
    id: "",
    specificDescription: "",
    loading: false,
  };

  handleEntering() {
    this.setState({
      id: this.props.assignableTask.id,
      specificDescription: this.props.assignableTask.description || "",
    });
  }

  handleCancel = () => {
    this.props.onClose();
  };

  async handleConfirmation() {
    this.setState({ loading: true });
    if (!this.state.id) {
      return
    }

    try {
      await assignableTasksApi.updateAssignableDescription(this.state.id, this.state.specificDescription);
    } catch (e) {
      console.log(e)
      alert("Fail to update this task, please retry later.")
    }
    this.setState({ loading: false });
    this.props.onClose();
  }

  async handleDelete() {
    this.setState({ loading: true });

    if (!this.state.id) {
      return
    }

    if (!this.props.assignableTask.notLinkToProtocol) {
      alert("You cannot delete a task link to the protocol, unactivate it if you dont want this task to appear on the list of assignable tasks")
      this.setState({ loading: false });
      this.props.onClose();
      return
    }

    let deleteAssignableResult;

    try {
      deleteAssignableResult = await assignableTasksApi.deleteAssignableTask(this.state.id);
      console.log(deleteAssignableResult)
    } catch (e) {
      console.log(e)
      alert("Fail to delete this task, please retry later.")
    }

    this.setState({ loading: false });
    this.props.onClose();
  }

  handleChange = stateName => event => {
    this.setState({
      [stateName]: event.target.value,
    });
  };

  render() {
    const { assignableTask, ...other } = this.props;
    const { loading } = this.state;

    let loadingDisplay;
    if (loading) {
      loadingDisplay = <DialogContent><CircularProgress /></DialogContent>;
    }

    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xl"
        onEntering={() => this.handleEntering()}
        aria-labelledby="confirmation-dialog-title"
        {...other}
      >
        <DialogTitle id="confirmation-dialog-title">You are about to add/update a description ONLY for this participant</DialogTitle>
        {
          assignableTask.notLinkToProtocol ?
            <DialogContent>
              Click here if you want to delete this task (only for none protocol task)<br /><br />
              <Button disabled={loading} onClick={() => this.handleDelete()} color="secondary" variant="contained">
                Delete!
              </Button>
            </DialogContent>
            :
            null
        }

        <DialogContent>
          Please fill the information below to update:
        <form noValidate autoComplete="off">
            {
              !assignableTask.notLinkToProtocol ?
                <div>
                  <TextField
                    id="protocol-name"
                    value={assignableTask.protocolName || ""}
                    margin="normal"
                    fullWidth
                    multiline
                    disabled
                  />
                  <TextField
                    id="protocol-description"
                    value={assignableTask.protocolDescription || ""}
                    margin="normal"
                    fullWidth
                    multiline
                    disabled
                  />
                </div>
                : null
            }

            <TextField
              required
              id="assignable-name"
              label="Specific description"
              value={this.state.specificDescription }
              onChange={this.handleChange('specificDescription')}
              margin="normal"
              fullWidth
              multiline
            />
          </form>
        </DialogContent>
        {loadingDisplay}
        <DialogActions>
          <Button disabled={loading} onClick={this.handleCancel} color="primary">
            Cancel
          </Button>
          <Button disabled={loading} onClick={() => this.handleConfirmation()} color="primary" variant="contained">
            Update!
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

EditAssignableTaskDialog.propTypes = {
  onClose: PropTypes.func
};

export default EditAssignableTaskDialog;