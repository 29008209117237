import React, { PureComponent } from 'react';

import * as constants from '../../utils/constants';
import * as userApi from '../../utils/api/user';
import * as protocolTaskApi from '../../utils/api/protocolTask';
import * as assignableTaskApi from '../../utils/api/assignableTask';
import * as taskTypeApi from '../../utils/api/taskType';
import * as positionApi from '../../utils/api/position';
import * as participantApi from '../../utils/api/participant';
import * as groupApi from '../../utils/api/group';
import * as partApi from '../../utils/api/part';
import * as studyApi from '../../utils/api/study';
import * as taskValidationApi from '../../utils/api/taskValidation';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import Utils from '../../utils/utils';
import moment from 'moment';
import { Box, Fab, LinearProgress } from '@material-ui/core';

import PrintIcon from '@material-ui/icons/Print'
import ListTasksStudyReport from '../../components/listTasksStudyReport/ListTasksStudyReport';

class ReportTaskByStudy extends PureComponent {
  state = {
    listUser: [],
    selectedUserId: "All",
    listAssignableTasks: [{ userName: "All" }],
    dateFilterBeginning: new Date().toISOString(),
    dateFilterEnd: moment(new Date()).add(24, 'hours').toISOString(),
    isNextToken: false,
    loading: false,
    loaded: 0,
    doneList: [],

    loadedStaff: [],
    loadedProtocolTask: [],
    loadedTaskType: [],
    loadedPosition: [],
    loadedParticipant: [],
    loadedGroup: [],
    loadedPart: [],
    loadedStudy: [],
  };

  componentDidMount() {
    this.queryCompleteList(this.state.selectedUserId, this.state.dateFilterBeginning, this.state.dateFilterEnd);
    this.queryCompleteUserList();
  }

  async queryCompleteList(userId, dateBeginning, dateEnd) {
    this.setState({ loading: true, loaded: 0 });
    const filterBeginning = new Date(dateBeginning).toISOString();
    const filterEnd = new Date(dateEnd).toISOString();

    let assignableTaskQuery = [];
    if (userId === "All") {
      assignableTaskQuery = await assignableTaskApi.listAssignableTaskByTimeFrame(filterBeginning, filterEnd)
    } else {
      assignableTaskQuery = await assignableTaskApi.listAssignableTaskByUserAssigned(userId, filterBeginning, filterEnd)
    }
    const isNextToken = Utils.checkNested(assignableTaskQuery, 'nextToken') ? true : false;
    let listAssignableTasks = Utils.checkNested(assignableTaskQuery, 'items') ? assignableTaskQuery.items : [];

    // Get done list
    await this.getDoneList(filterBeginning, filterEnd);
    console.log(this.state.doneList)
    // Improve the list
    let upgradedList = [];
    let remainingList = [...listAssignableTasks];
    let totalElements = 0;
    for (let i = 0; i < listAssignableTasks.length; i++) {
      if (remainingList.length === 0) break
      // Batch 100 tasks together
      const batchList = remainingList.slice(0, 100);
      remainingList.splice(0, 100)
      totalElements += batchList.length;
      this.setState({ loaded: totalElements * 100 / listAssignableTasks.length })
      console.log(totalElements)
      await Promise.all(batchList.map(async (task) => {
        if (task.activationStatus === constants.ASSIGNABLE_TASK_ACTIVE) {
          const newElement = await this.formatATask(task);
          upgradedList.push(newElement);
        }
      }));
    }

    this.setState({
      listAssignableTasks: listAssignableTasks,
      upgradedList: upgradedList,
      isNextToken: isNextToken,
      lastUpdate: new Date().toISOString(),
      loading: false,
      loaded: 0,
    });
  }

  async getDoneList(filterBeginning, filterEnd) {
    const doneListQuery = await taskValidationApi.listTaskValidationByTimeFrame(filterBeginning, filterEnd)
    const doneList = Utils.checkNested(doneListQuery, 'items') ? doneListQuery.items : [];
    this.state.doneList = doneList;
  }

  async formatATask(task) {
    let formatTask = {};

    // Directly with applicable task
    formatTask.id = Utils.checkNested(task, 'id');
    formatTask.applicableDate = Utils.checkNested(task, 'applicableDate') || "";
    formatTask.specificDescription = Utils.checkNested(task, 'description') || false;

    // Done list
    formatTask.isDone = false;
    for (let index = 0; index < this.state.doneList.length; index++) {
      if (this.state.doneList[index].assignableTaskId === task.id) {
        formatTask.isDone = true;
        formatTask.doneUser = this.state.doneList[index].userId;
      }
    }

    // Need staff
    const staffId = task.userId;
    formatTask.staffUserName = "";
    formatTask.staffColor = "";
    if (staffId) {
      let found = false;
      for (let index = 0; index < this.state.loadedStaff.length; index++) {
        if (this.state.loadedStaff[index].userName === staffId) {
          found = true;
          formatTask.staffUserName = this.state.loadedStaff[index].userName;
          formatTask.staffPseudo = this.state.loadedStaff[index].pseudo;
          formatTask.staffColor = this.state.loadedStaff[index].color;
          break
        }
      }
      if (!found) {
        const newUser = await this.loadUser(staffId);
        formatTask.staffUserName = newUser.userName;
        formatTask.staffPseudo = newUser.pseudo;
        formatTask.staffColor = newUser.color;
      }
    }

    // Need protocol task
    // get theorical time (name), description
    const protocolTaskId = task.protocolTaskId;
    formatTask.protocolName = "";
    formatTask.protocolDescription = "";
    if (protocolTaskId) {
      let found = false;
      let protocolTask = null;
      for (let index = 0; index < this.state.loadedProtocolTask.length; index++) {
        if (this.state.loadedProtocolTask[index].id === protocolTaskId) {
          found = true;
          formatTask.protocolName = this.state.loadedProtocolTask[index].name;
          formatTask.protocolDescription = this.state.loadedProtocolTask[index].description;
          protocolTask = this.state.loadedProtocolTask[index];
          break
        }
      }
      if (!found) {
        const newProtocolTask = await this.loadProtocolTask(protocolTaskId);
        formatTask.protocolName = newProtocolTask.name;
        formatTask.protocolDescription = newProtocolTask.description;
        protocolTask = newProtocolTask;
      }

      // Need protocol task / task type
      // get task type
      const taskTypeId = Utils.checkNested(protocolTask, "taskTypeId");
      if (taskTypeId) {
        let found = false;
        for (let index = 0; index < this.state.loadedTaskType.length; index++) {
          if (this.state.loadedTaskType[index].id === taskTypeId) {
            found = true;
            formatTask.taskTypeName = this.state.loadedTaskType[index].name;
            break
          }
        }
        if (!found) {
          const newTaskType = await this.loadTaskType(taskTypeId);
          if (newTaskType) {
            formatTask.taskTypeName = newTaskType.name;
          } else {
            formatTask.taskTypeName = "Unknown";
          }
        }
      }
    }

    // Need position
    // get position name (name), inclusion
    const positionId = task.positionId;
    formatTask.positionName = "";
    formatTask.positionInclusion = "";
    let position = null;
    if (positionId) {
      let found = false;
      for (let index = 0; index < this.state.loadedPosition.length; index++) {
        if (this.state.loadedPosition[index].id === positionId) {
          found = true;
          formatTask.positionName = this.state.loadedPosition[index].name;
          formatTask.positionInclusion = this.state.loadedPosition[index].inclusion;
          formatTask.positionId = this.state.loadedPosition[index].id;
          formatTask.positionValidate = this.state.loadedPosition[index].validate;
          position = this.state.loadedPosition[index];
          break
        }
      }
      if (!found) {
        const newPosition = await this.loadPosition(positionId);
        formatTask.positionName = newPosition.name;
        formatTask.positionInclusion = newPosition.inclusion;
        formatTask.positionValidate = newPosition.validate;
        formatTask.positionId = newPosition.id;
        position = newPosition;
      }

      // Need position / participant
      // get sex          
      if (position.participantId) {
        found = false;
        this.state.loadedParticipant.forEach(participant => {
          if (participant.id === position.participantId) {
            found = true;
            formatTask.participantSex = participant.sex;
            formatTask.participantSelection = participant.selection;
            formatTask.participantCode = participant.code;
            formatTask.participantRoom = participant.room;
          }
        });
        if (!found) {
          const newParticipant = await this.loadParticipant(position.participantId);
          formatTask.participantSex = newParticipant.sex;
          formatTask.participantSelection = newParticipant.selection;
          formatTask.participantRoom = newParticipant.room;
          formatTask.participantCode = newParticipant.code;
        }
      }

      // Need position / group
      const groupId = Utils.checkNested(position, "groupId");
      formatTask.groupName = "";
      if (groupId) {
        let found = false;
        let group = null;
        for (let index = 0; index < this.state.loadedGroup.length; index++) {
          if (this.state.loadedGroup[index].id === groupId) {
            found = true;
            formatTask.groupName = this.state.loadedGroup[index].name;
            formatTask.groupDescription = this.state.loadedGroup[index].description;
            group = this.state.loadedGroup[index];
            break
          }
        }
        if (!found) {
          const newGroup = await this.loadGroup(groupId);
          formatTask.groupName = newGroup.name;
          formatTask.groupDescription = newGroup.description;
          group = newGroup;
        }

        // Need part
        const partId = Utils.checkNested(group, "partId")
        if (partId) {
          let found = false;
          let part = null;
          for (let index = 0; index < this.state.loadedPart.length; index++) {
            if (this.state.loadedPart[index].id === partId) {
              found = true;
              formatTask.partName = this.state.loadedPart[index].name;
              part = this.state.loadedPart[index];
              break
            }
          }
          if (!found) {
            const newPart = await this.loadPart(partId);
            formatTask.partName = newPart.name;
            part = newPart;
          }

          // Need study
          const studyId = Utils.checkNested(part, "studyId")
          if (studyId) {
            let found = false;
            for (let index = 0; index < this.state.loadedStudy.length; index++) {
              if (this.state.loadedStudy[index].id === studyId) {
                found = true;
                formatTask.studyName = this.state.loadedStudy[index].name;
                break
              }
            }
            if (!found) {
              const newStudy = await this.loadStudy(studyId);
              formatTask.studyName = newStudy.name;
            }
          }
        }
      }
    }
    return formatTask
  }

  async loadUser(userId) {
    const userQuery = await userApi.getUser(userId);
    const user = Utils.checkNested(userQuery, 'data', 'getUser');
    if (user) this.state.loadedStaff.push(user);
    return user
  }

  async loadProtocolTask(id) {
    const protocolTaskQuery = await protocolTaskApi.getProtocolTask(id);
    const protocolTask = Utils.checkNested(protocolTaskQuery, 'data', 'getProtocolTask');
    if (protocolTask) this.state.loadedProtocolTask.push(protocolTask);
    return protocolTask
  }

  async loadTaskType(id) {
    const taskTypeQuery = await taskTypeApi.getTaskType(id);
    const taskType = Utils.checkNested(taskTypeQuery, 'data', 'getTaskType');
    if (taskType) this.state.loadedTaskType.push(taskType);
    return taskType
  }

  async loadPosition(id) {
    const positionQuery = await positionApi.getPosition(id);
    const position = Utils.checkNested(positionQuery, 'data', 'getPosition');
    if (position) this.state.loadedPosition.push(position);
    return position
  }

  async loadParticipant(id) {
    let rawParticipant = await participantApi.getParticipant(id);
    let participant = Utils.checkNested(rawParticipant, 'data', 'getParticipant');
    if (!participant) {
      participant = { id: id }
    }
    this.state.loadedParticipant.push(participant);
    return participant
  }

  async loadGroup(id) {
    const group = await groupApi.getGroupById(id);
    if (group) this.state.loadedGroup.push(group);
    return group
  }

  async loadPart(id) {
    const partQuery = await partApi.getPart(id);
    const part = Utils.checkNested(partQuery, 'data', 'getPart');
    if (part) this.state.loadedPart.push(part);
    return part
  }

  async loadStudy(id) {
    const studyQuery = await studyApi.getStudy(id);
    const study = Utils.checkNested(studyQuery, 'data', 'getStudy');
    if (study) this.state.loadedStudy.push(study);
    return study
  }

  async queryCompleteUserList() {
    let userList = await userApi.listAllUsers();
    userList.push({ userName: "All" });
    userList.sort((a, b) => {
      if (a.userName.toUpperCase() >= b.userName.toUpperCase()) return 1
      if (a.userName.toUpperCase() < b.userName.toUpperCase()) return -1
      return 0
    });
    this.setState({
      listUser: userList,
    });
    return userList
  }

  updateDateBeginning(date) {
    // The end date is 24h after the selected date
    let timeSpan = 24;
    let dateBeginning = moment(new Date(date)).toISOString();
    let dateEnd = moment(new Date(date)).add(timeSpan, 'hours').toISOString();
    this.setState({
      dateFilterBeginning: dateBeginning,
      dateFilterEnd: dateEnd,
    });
    this.queryCompleteList(this.state.selectedUserId, dateBeginning, dateEnd);
  };

  handleUserChange = stateName => event => {
    this.setState({
      selectedUserId: event.target.value,
    });
    this.queryCompleteList(event.target.value, this.state.dateFilterBeginning, this.state.dateFilterEnd);
  };

  render() {
    const { upgradedList, isNextToken, lastUpdate, dateFilterBeginning, dateFilterEnd, loaded, loading } = this.state;
    return (
      <div>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"></link>
        <Box marginBottom={5} className="no-print">
          <Typography variant="h4" color="inherit" >
            Tasks by Study report
          </Typography>
          <Divider variant="middle" />
          <br /><br />
          {
            isNextToken ?
              <Box style={{ color: "red" }}><Typography variant="h6">WARNING: Selected time range too wide. Too many elements to display.
           Please reduce the time range.</Typography></Box>
              :
              null
          }
          <form noValidate>
            <Box display='flex' flexWrap="wrap" alignItems="center" marginBottom={1}>
              <Box marginRight={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDateTimePicker
                    required
                    margin="dense"
                    ampm={false}
                    label="Report start"
                    format="dd/MM/yyyy HH:mm"
                    value={dateFilterBeginning}
                    onChange={(date) => this.updateDateBeginning(date)}

                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
                <Box>
                  Report end: {moment(dateFilterEnd).format("DD/MM/YY HH:mm")}
                </Box>
              </Box>

              <Box flexGrow={1} />
              <Box>
                <Fab color="secondary" aria-label="print" onClick={() => { window.print(); return false; }}>
                  <PrintIcon />
                </Fab>
              </Box>
            </Box>
          </form>
        </Box>
        {
          loading ?
            <LinearProgress variant="determinate" value={loaded} />
            : null
        }
        <Box>
          <ListTasksStudyReport listTasks={upgradedList} lastUpdate={lastUpdate} dateBeginning={dateFilterBeginning} dateEnd={dateFilterEnd} />
        </Box>
      </div >
    );
  }
}

export default ReportTaskByStudy;