import React, { PureComponent } from 'react';

import './ListTasksStudyReport.scss'

import MaterialTable from 'material-table'
import * as constants from '../../utils/constants';
import * as userApi from '../../utils/api/user';

import { Box, FormControl, MenuItem, Select, Tooltip, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import moment from 'moment';
import uuid from 'uuid/v4';

class ListTasksStudyReport extends PureComponent {
  state = {
    currentUser: "",
    listReports: [],
    listAssignableTask: [],
    studyFilter: "All"
  };

  componentDidMount() {
    this.loadCurrentUser();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.lastUpdate !== prevProps.lastUpdate) {
      const listReports = await this.sortReportsByStudy(this.props.listTasks);
      this.setState({
        listReports: listReports,
        listAssignableTask: this.props.listTasks,
        loading: false,
      });
    }
  }

  async loadCurrentUser() {
    const currentUser = await userApi.getCurrentUsername()
    this.setState({ currentUser: currentUser });
  }

  async sortReportsByStudy(listTasks) {
    let listReports = [];
    for (let i = 0; i < listTasks.length; i++) {
      let found = false;
      for (let j = 0; j < listReports.length; j++) {
        if (listReports[j].studyName === listTasks[i].studyName) {
          found = true;
          listReports[j].tasks.push(listTasks[i]);
        }
      }
      if (found === false) {
        const newEntry = {
          tasks: [listTasks[i]],
          studyName: listTasks[i].studyName,
        }
        listReports.push(newEntry);
      }
    }
    return listReports
  }

  handleChange = stateName => event => {
    this.setState({
      [stateName]: event.target.value,
    });
  };

  render() {
    const { listReports, studyFilter } = this.state;
    return (
      <div id="ListTasksStudyReport">
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"></link>
        <Box marginBottom={5}>
          <FormControl>
            <Select
              displayEmpty
              value={this.state.studyFilter}
              onChange={this.handleChange('studyFilter')}
              autoWidth
            >
              <MenuItem value="All">
                All Studies
              </MenuItem>
              {listReports.sort(function (a, b) {
                var nameA = a.studyName.toUpperCase();
                var nameB = b.studyName.toUpperCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1
                return 0;
              }).map(report =>
                <MenuItem value={report.studyName} key={report.studyName}>{report.studyName}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>

        {listReports.map((report) =>
          <Box>
            {
              studyFilter === "All" || studyFilter === report.studyName ?

                <Box key={uuid()} marginBottom={2} className="page-break">
                  <Box marginBottom={2}>
                    <Typography>Study: <b>{report.studyName || "Undefined"}</b>{report.groupDescription ? '(/' + report.groupDescription + ')' : null} planning between <b>{moment(this.props.dateBeginning).format("HH:mm DD/MM/Y")}</b> and <b>{moment(this.props.dateEnd).format("HH:mm DD/MM/Y")}</b></Typography>
                    <Typography>Generated by <b>{this.state.currentUser}</b> at <b>{moment(this.props.lastUpdate).format("HH:mm DD/MM/Y")}</b></Typography>
                  </Box>
                  <MaterialTable
                    columns={[
                      {
                        title: 'Date Time',
                        field: 'applicableDate',
                        width: null,
                        defaultSort: 'asc',
                        render: rowData => {
                          let color;
                          if (rowData.activationStatus === constants.ASSIGNABLE_TASK_INACTIVE) {
                            color = "grey";
                          }
                          return (
                            <div style={{ color: color }}>
                              <b>{moment(rowData.applicableDate).format('HH:mm')} </b>
                              {moment(rowData.applicableDate).format('DD/MM/YYYY')}
                            </div>
                          )
                        },
                      },
                      { title: 'Theoretical time', field: 'protocolName', sorting: false, },
                      {
                        title: 'Participant',
                        field: 'participantCode',
                        sorting: false,
                        render: rowData => {
                          return (
                            <div>
                              {
                                rowData.positionInclusion ?
                                  rowData.participantCode + "/ " + rowData.participantSelection + "/ " + rowData.positionInclusion
                                  :
                                  rowData.participantCode + "/ " + rowData.participantSelection + "/ " + rowData.positionName
                              }
                            </div>
                          )
                        },
                      },
                      { title: 'Sex', field: 'participantSex', sorting: false, },
                      { title: 'Room', field: 'participantRoom', sorting: false, },
                      {
                        title: 'Act',
                        field: 'protocolDescription',
                        sorting: false,
                        render: rowData => {
                          return (
                            <div>
                              <div hidden={!rowData.protocolDescription}>{rowData.protocolDescription}<br /></div>
                              <div hidden={!rowData.specificDescription}><span style={{ fontWeight: "bold" }}>Specific description:</span> {rowData.specificDescription}</div>
                            </div>
                          )
                        },
                      },
                      {
                        title: 'Staff',
                        field: 'staffName',
                        render: rowData => {
                          const color = rowData.staffColor
                          return (
                            <div style={{ width: '100%', backgroundColor: color, height: '100%', fontSize: "1.2em" }}>
                              {rowData.staffPseudo || rowData.staffName}
                            </div>
                          )
                        },
                      },
                      {
                        title: 'Done?',
                        field: 'isDone',
                        render: rowData => {
                          return (
                            <div>
                              {rowData.isDone ?
                                <Tooltip title={"Done by " + rowData.doneUser}>
                                  <CheckCircleIcon color='secondary' />
                                </Tooltip>
                                :
                                null}
                            </div>
                          )
                        },
                      }
                    ]}
                    options={{
                      actionsColumnIndex: -1,
                      paging: false,
                      sorting: true,
                      search: false,
                      draggable: false,
                    }}
                    data={report.tasks}
                    title=""
                  />
                  <Box className="no-print" marginBottom={5} />
                </Box>
                :
                null
            }
          </Box>
        )}

      </div>
    );
  }
}

export default ListTasksStudyReport;