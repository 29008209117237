import {
  DialogTitle,
  TextField,
  Button,
  Dialog,
  DialogActions
} from "@material-ui/core";
import { useEffect, useState } from "react";
import CommentDropDown from "./CommentDropDown";

const AddSpecificComment: React.FC<{
  open: boolean;
  cancel: (comment: string | null) => void;
  comment: string;
}> = ({ open, cancel, comment }) => {
  const [specificComment, setSpecificComment] = useState(comment);

  useEffect(() => {
    if (open) setSpecificComment(comment);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleAddComment = () => {
    cancel(specificComment);
  };

  const handleChangeComment = (newComment: string) => {
    setSpecificComment(newComment);
  };

  return (
    <Dialog
      onClose={(_, raison) => {
        if (raison === "backdropClick") return;
        cancel(null);
      }}
      fullWidth
      maxWidth="sm"
      open={open}
    >
      <DialogTitle>Set Specific Comment</DialogTitle>
      <div
        style={{
          display: "flex",
          marginTop: "1em",
          alignItems: "center",
          marginBottom: "1em"
        }}
      >
        <TextField
          style={{ marginRight: "1em", flexGrow: "1", marginLeft: "1em" }}
          id="comment"
          label="comment"
          variant="outlined"
          value={specificComment || ""}
          multiline
          onChange={(e) => {
            setSpecificComment(e.target.value);
          }}
        />
        <CommentDropDown callBack={handleChangeComment} />
      </div>
      <DialogActions style={{display: "flex"}}>
        <Button onClick={() => cancel(null)}>Cancel</Button>
        <div style={{ flexGrow: "1" }} />
        <Button
          onClick={handleAddComment}
          variant="contained"
          color="primary"
        >
          Add comment
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSpecificComment;
