/* eslint-disable @typescript-eslint/ban-types */
import { useEffect, useState } from "react";
import useQueryList, { QueryListConfigType } from "./useQueryList";
import useSubscription, { SubscriptionConfigType } from "./useSubscription";

type UseAmplifyListType<ItemType> = {
  list: ItemType[];
  nextToken: string | null;
  loading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  refetch: () => void;
};

export type ConfigType<
  ItemType extends {},
  QueryVariableType extends {},
  CreateSubVariableType extends {},
  UpdateSubVariableType extends {},
  DeleteSubVariableType extends {}
> = {
  list: QueryListConfigType<QueryVariableType>;
  create: SubscriptionConfigType<CreateSubVariableType, ItemType>;
  update: SubscriptionConfigType<UpdateSubVariableType, ItemType>;
  delete: SubscriptionConfigType<DeleteSubVariableType, ItemType>;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useAmplifyList = <
  ItemType extends {},
  QueryVariableType extends {} = {},
  CreateSubVariableType extends {} = {},
  UpdateSubVariableType extends {} = {},
  DeleteSubVariableType extends {} = {}
>(
  config: ConfigType<
    ItemType,
    QueryVariableType,
    CreateSubVariableType,
    UpdateSubVariableType,
    DeleteSubVariableType
  >
): UseAmplifyListType<ItemType> => {
  const [list, setList] = useState<(ItemType & { id: string })[]>([]);

  const { items, nextToken, loading, error, refetch } = useQueryList<
    ItemType & { id: string },
    QueryVariableType
  >(config.list);

  const { item: createItem } = useSubscription<
    ItemType & { id: string },
    CreateSubVariableType
  >(config.create);

  const { item: updateItem } = useSubscription<
    ItemType & { id: string },
    UpdateSubVariableType
  >(config.update);

  const { item: deleteItem } = useSubscription<
    ItemType & { id: string },
    DeleteSubVariableType
  >(config.delete);

  useEffect(() => {
    setList(items);
  }, [items]);

  useEffect(() => {
    if (!createItem) return;
    setList((prev) => {
      const newList = [...prev];
      newList.unshift(createItem);
      return newList;
    });
  }, [createItem]);

  useEffect(() => {
    if (!updateItem) return;
    setList((prev) => {
      const newList = [...prev];
      const updatedId = newList.findIndex((e) => e.id === updateItem.id);
      if (updatedId !== -1) {
        newList.splice(updatedId, 1, updateItem);
      } else {
        newList.unshift(updateItem);
      }
      return newList;
    });
  }, [updateItem]);

  useEffect(() => {
    if (!deleteItem) return;
    setList((prev) => {
      const newList = [...prev];
      const deletedId = newList.findIndex((e) => e.id === deleteItem.id);
      newList.splice(deletedId, 1);
      return newList;
    });
  }, [deleteItem]);

  return {
    list,
    nextToken,
    loading,
    error,
    refetch
  };
};

export default useAmplifyList;
