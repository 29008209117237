import * as queries from '../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import Utils from '../utils';
import * as constants from '../constants';

export async function listTaskTypes() {
    try {
        const listTaskTypesResult = await API.graphql(graphqlOperation(queries.listTaskTypes,
            {
                limit: constants.LIMIT
            }));
        let listTaskTypes = Utils.checkNested(listTaskTypesResult, 'data', 'listTaskTypes', "items");
        listTaskTypes = listTaskTypes ? listTaskTypes : [];
        return listTaskTypes
    } catch (e) {
        console.log(e)
        return []
    }
}

export async function getTaskType(id) {
    if (id === null) return false
    try {
        const result = await API.graphql(graphqlOperation(queries.getTaskType, { id: id }));
        return result
    } catch (e) {
        console.log(e)
        return false
    }
}