import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './AddUser.css';

import * as user from '../../utils/api/user'

// UI
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

class AddUserDialog extends React.PureComponent {
    state = {
        name: '',
        errors: "",
        loading: false,
        email: "",
        tempPassword: "",
        adminPassword: "",
    };

    handleCancel = () => {
        this.props.onClose();
    };

    async handleConfirmation() {
        this.setState({ loading: true });

        const createUserResult = await user.createNewUser(this.state.name, this.state.email, this.state.tempPassword, this.state.adminPassword);
        console.log(createUserResult.messages);

        this.setState({ loading: false });
        this.props.onClose();
    }

    handleOk = () => {
        this.props.onClose();
    };

    handleChange = stateName => event => {
        this.setState({
            [stateName]: event.target.value,
        });
    };

    render() {
        const { ...other } = this.props;
        const { loading } = this.state;

        let loadingDisplay;
        if (loading) {
            loadingDisplay = <DialogContent><CircularProgress /></DialogContent>;
        }

        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xs"
                onEntering={this.handleEntering}
                aria-labelledby="confirmation-dialog-title"
                {...other}
            >
                <DialogTitle id="confirmation-dialog-title">You are about to create a new user</DialogTitle>
                <DialogContent>
                    Please fill the information below:
          <form noValidate autoComplete="off">
                        <TextField
                            required
                            id="admlin-password"
                            label="Admin password"
                            value={this.state.adminPassword}
                            onChange={this.handleChange('adminPassword')}
                            margin="normal"
                        />
                        <br /><br />
                        <TextField
                            required
                            id="standard-name"
                            label="Name"
                            value={this.state.name}
                            onChange={this.handleChange('name')}
                            margin="normal"
                        />
                        <TextField
                            required
                            id="email"
                            label="email"
                            value={this.state.email}
                            onChange={this.handleChange('email')}
                            margin="normal"
                        />
                        <TextField
                            required
                            id="tempPassword"
                            label="Temporary password"
                            value={this.state.tempPassword}
                            onChange={this.handleChange('tempPassword')}
                            margin="normal"
                        />
                    </form>
                </DialogContent>
                {loadingDisplay}
                <DialogActions>
                    <Button disabled={loading} onClick={this.handleCancel} color="primary">
                        Cancel
        </Button>
                    <Button disabled={loading} onClick={() => this.handleConfirmation()} color="primary">
                        Add!
        </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

AddUserDialog.propTypes = {
    onClose: PropTypes.func
};

class AddUser extends PureComponent {
    state = {
        dialogOpen: false,
    };

    handleClose = () => {
        this.setState({ dialogOpen: false });
    };

    handleOpen() {
        this.setState({ dialogOpen: true });
    }

    render() {
        return (
            <div>
                <Button variant="contained" color="primary" onClick={() => this.handleOpen()}>
                    <AddIcon />
                    Add a user
        </Button>

                <AddUserDialog
                    open={this.state.dialogOpen}
                    onClose={this.handleClose}
                />
            </div>
        );
    }
}

export default AddUser;