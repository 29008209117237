import React, { Component } from 'react';
import './StudyCheck.css';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import * as assignableTaskApi from '../../utils/api/assignableTask';
import * as protocolTaskApi from '../../utils/api/protocolTask';
import * as positionApi from '../../utils/api/position';
import * as participantApi from '../../utils/api/participant';
import * as groupApi from '../../utils/api/group';
import * as partApi from '../../utils/api/part';
import * as studyApi from '../../utils/api/study';
import * as protocolValidationApi from '../../utils/api/protocolValidation';

import Utils from '../../utils/utils';
import * as constants from '../../utils/constants';
import { Button, Box, LinearProgress } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Link } from "react-router-dom";
import { StudyRoute } from '../../Routing';
import { isStudyManager } from '../../utils/api/user';
import SyncIcon from '@material-ui/icons/Sync';
import moment from 'moment';

class StudyCheck extends Component {
    state = {
        studyId: "",
        StudyManager: false,
        studyArchi: {},
        loading: false,
    };

    componentDidMount() {
        // Init
        this.init(this.props.match.params.id);
        // Check groups
        this.checkGroups()
    }

    async init(studyId) {
        const queryStudy = await studyApi.getStudy(studyId);
        let studyInfo = Utils.checkNested(queryStudy, 'data', 'getStudy');
        this.setState({
            studyId: studyId,
            studyInfo: studyInfo,
            studyArchi: studyInfo,
        });
    }

    async checkGroups() {
        const StudyManager = await isStudyManager()
        this.setState({
            StudyManager: StudyManager,
        })
    }

    async launchTest() {
        this.setState({ loading: true })
        // First get parts list
        await this.getPartsList(this.state.studyArchi);
        // Get groups list
        for (let i = 0; i < this.state.studyArchi.partList.length; i++) {
            await this.getGroups(this.state.studyArchi.partList[i])
            for (let j = 0; j < this.state.studyArchi.partList[i].groupList.length; j++) {
                // Get validation status
                await this.getProtocolValidation(this.state.studyArchi.partList[i].groupList[j])
                // Get protocol
                await this.getProtocol(this.state.studyArchi.partList[i].groupList[j])
                // Get participants
                await this.getParticipant(this.state.studyArchi.partList[i].groupList[j])
                // Get position
                await this.getPosition(this.state.studyArchi.partList[i].groupList[j])
                for (let k = 0; k < this.state.studyArchi.partList[i].groupList[j].positionList.length; k++) {
                    // Get assignable tasks
                    await this.getAssignableTask(this.state.studyArchi.partList[i].groupList[j].positionList[k])
                    // Check assignable task consistency
                    await this.checkAssignableTaskConsistency(this.state.studyArchi.partList[i].groupList[j].protocolList,
                        this.state.studyArchi.partList[i].groupList[j].positionList[k]);
                }
            }
        }
        this.setState({ loading: false })
        console.log(this.state.studyArchi)
    }

    async getPartsList(studyArchi) {
        const queryParts = await partApi.listPartsByStudy(this.state.studyId);
        const partList = Utils.checkNested(queryParts, "items") ? queryParts.items : [];
        studyArchi.partList = partList;
        studyArchi.nextToken = Utils.checkNested(queryParts, "nextToken") ? true : false;
        this.setState({
            studyArchi: this.state.studyArchi,
        })
    }

    async getGroups(partArchi) {
        const queryGroups = await groupApi.listGroupsByPart(partArchi.id);
        const groupList = Utils.checkNested(queryGroups, "items") ? queryGroups.items : [];
        partArchi.groupList = groupList;
        partArchi.nextToken = Utils.checkNested(queryGroups, "nextToken") ? true : false;
        this.setState({
            studyArchi: this.state.studyArchi,
        })
    }

    async getProtocolValidation(groupArchi) {
        const queryValidation = await protocolValidationApi.getValidationStatus(groupArchi.id);
        groupArchi.validationStatus = queryValidation;
        this.setState({
            studyArchi: this.state.studyArchi,
        })
    }

    async getProtocol(groupArchi) {
        const queryProtocol = await protocolTaskApi.listProtocolTaskByGroup(groupArchi.id);
        const protocolList = Utils.checkNested(queryProtocol, "items") ? queryProtocol.items : [];
        groupArchi.protocolList = protocolList;
        groupArchi.nextTokenProtocol = Utils.checkNested(queryProtocol, "nextToken") ? true : false;
        this.setState({
            studyArchi: this.state.studyArchi,
        })
    }

    async getParticipant(groupArchi) {
        const queryParticipant = await participantApi.listParticipantByGroup(groupArchi.id);
        const participantList = Utils.checkNested(queryParticipant, "items") ? queryParticipant.items : [];
        groupArchi.participantList = participantList;
        groupArchi.nextTokenParticipant = Utils.checkNested(queryParticipant, "nextToken") ? true : false;
        this.setState({
            studyArchi: this.state.studyArchi,
        })
    }

    async getPosition(groupArchi) {
        const queryPosition = await positionApi.listPositionByGroup(groupArchi.id);
        const positionList = Utils.checkNested(queryPosition, "items") ? queryPosition.items : [];
        groupArchi.positionList = positionList;
        groupArchi.nextTokenPosition = Utils.checkNested(queryPosition, "nextToken") ? true : false;
        this.setState({
            studyArchi: this.state.studyArchi,
        })
    }

    async getAssignableTask(positionArchi) {
        const queryTask = await assignableTaskApi.listAssignableTaskByPosition(positionArchi.id);
        const taskList = Utils.checkNested(queryTask, "items") ? queryTask.items : [];
        positionArchi.taskList = taskList;
        positionArchi.nextToken = Utils.checkNested(queryTask, "nextToken") ? true : false;
        this.setState({
            studyArchi: this.state.studyArchi,
        })
    }

    async checkAssignableTaskConsistency(protocolArchi, positionArchi, t0) {
        let countActive = 0;
        let countNotLinkToProtocol = 0;
        let extraList = [];
        let wrongTimeList = [];
        for (let i = 0; i < positionArchi.taskList.length; i++) {
            if (positionArchi.taskList[i].activationStatus === constants.ASSIGNABLE_TASK_ACTIVE) countActive++;

            // Check if all assignable task has a protocol task link except for specific tasks
            let timeToT0 = 0;
            if (positionArchi.taskList[i].notLinkToProtocol && !positionArchi.taskList[i].protocolTaskId) {
                countNotLinkToProtocol++;
            } else {
                let found = false;
                protocolArchi.forEach(protocolTask => {
                    if (protocolTask.id === positionArchi.taskList[i].protocolTaskId) {
                        found = true
                        timeToT0 = protocolTask.timeToT0;
                    }
                });
                if (!found) {
                    extraList.push(positionArchi.taskList[i])
                    continue
                }
            }
            // Check time
            const supposedTime = this.calculateTime(positionArchi.t0, timeToT0, positionArchi.taskList[i].delta)
            if (supposedTime !== positionArchi.taskList[i].applicableDate) {
                let newElement = positionArchi.taskList[i]
                newElement.supposedTime = supposedTime;
                wrongTimeList.push(newElement);
            }
        }
        // Look for missing task
        let missingList = [];
        for (let i = 0; i < protocolArchi.length; i++) {
            let found = false;
            positionArchi.taskList.forEach(assignableTask => {
                if (protocolArchi[i].id === assignableTask.protocolTaskId) {
                    found = true
                }
            });
            if (!found) {
                missingList.push(protocolArchi[i])
            }
        }

        positionArchi.activeTask = countActive;
        positionArchi.notLinkToProtocolTask = countNotLinkToProtocol;
        positionArchi.extraList = extraList;
        positionArchi.wrongTimeList = wrongTimeList;
        positionArchi.missingList = missingList;
        this.setState({
            studyArchi: this.state.studyArchi,
        })
    }

    calculateTime(t0, timeToT0, delta) {
        return moment(t0).add(timeToT0, "minutes").add(delta, "minutes").toISOString();
    }

    render() {
        const { studyId, studyInfo, StudyManager, studyArchi, loading } = this.state;
        const name = studyInfo ? studyInfo.name : null;
        return (
            <div>
                <Typography variant="h4" color="inherit">
                    {name} consistency check
                </Typography>
                <Divider variant="middle" />
                <Button color="primary" component={Link} to={StudyRoute + studyId} >
                    <ArrowBack />
                    Back to study
                </Button>
                <Box display="flex" flexWrap="wrap" alignItems="center" marginTop={2} marginBottom={4}>
                    {
                        StudyManager ?
                            <Box>
                                <Button variant="contained" color="primary" onClick={() => this.launchTest()} >
                                    <SyncIcon />
                                    Launch test
                                </Button>
                            </Box>
                            :
                            null
                    }
                </Box>
                {
                    loading ?
                        <LinearProgress />
                        :
                        null
                }

                {
                    studyArchi.partList ?
                        <Box>
                            <Typography component="span"><b>Study</b> {name} contained {studyArchi.partList.length} part(s)</Typography>
                            {studyArchi.partList.map(part =>
                                <Box marginLeft={2} key={part.id}>
                                    <Typography component="span"><b>{part.name}</b></Typography>
                                    {
                                        part.groupList ?
                                            <Box>
                                                {part.groupList.map(group =>
                                                    <Box marginLeft={2} marginTop={1} key={group.id}>
                                                        <Typography component="span"><b>{group.name}</b>, {group.protocolList ? group.protocolList.length : "loading"} protocol task(s),
                                                        {group.positionList ? group.positionList.length : "loading"} position(s),
                                                        {group.participantList ? group.participantList.length : "loading"} participant(s), </Typography>
                                                        {
                                                            group.validationStatus ?
                                                                <Typography component="span" style={{ color: group.validationStatus.isValidate ? "green" : "red" }}>
                                                                    {group.validationStatus.isValidate ? "Version: " + group.validationStatus.version : "Not validate"}</Typography>
                                                                :
                                                                "loading"
                                                        }
                                                        {
                                                            group.positionList ?
                                                                <Box>
                                                                    {group.positionList.map(position =>
                                                                        <Box marginLeft={2} key={position.id}>
                                                                            <Typography component="span"><b>{position.name}</b></Typography>
                                                                            <Box marginLeft={2}>
                                                                                <Typography style={{ color: position.validate ? "green" : "red" }}>{position.validate ? "Validate" : "Not validate"}</Typography>
                                                                                <Typography>T0 {moment(position.t0).format("HH:mm DD/MM/YYYY")}</Typography>
                                                                                <Typography>{position.taskList ? position.taskList.length : "loading"} assignable tasks</Typography>
                                                                                <Typography>{position.activeTask} active task(s)</Typography>
                                                                                <Typography>{position.notLinkToProtocolTask} task(s) not link to protocol</Typography>
                                                                                <Typography style={{ color: position.extraList && position.extraList.length === 0 ? "green" : "red" }}>
                                                                                    {position.extraList ? position.extraList.length : "loading"} task(s) in the position cannot be found in the protocol (extra task)
                                                                                </Typography>
                                                                                {
                                                                                    position.extraList && position.extraList.length > 0 ?
                                                                                        <Box marginLeft={2}>
                                                                                            {position.extraList.map(assignableTask =>
                                                                                                <Typography>Id: {assignableTask.id}, Applicable time: {moment(assignableTask.assignableDate).format("HH:mm DD/MM/YYYY")}</Typography>
                                                                                            )}
                                                                                        </Box>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                <Typography style={{ color: position.wrongTimeList && position.wrongTimeList.length === 0 ? "green" : "red" }}>
                                                                                    {position.wrongTimeList ? position.wrongTimeList.length : "loading"} task(s) have a different applicable time than expected
                                                                                </Typography>
                                                                                {
                                                                                    position.wrongTimeList && position.wrongTimeList.length > 0 ?
                                                                                        <Box marginLeft={2}>
                                                                                            {position.wrongTimeList.map(assignableTask =>
                                                                                                <Typography>Id: {assignableTask.id}, Applicable time {moment(assignableTask.assignableDate).format("HH:mm DD/MM/YYYY")}, Should be {moment(assignableTask.supposedTime).format("HH:mm DD/MM/YYYY")}</Typography>
                                                                                            )}
                                                                                        </Box>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                <Typography style={{ color: position.missingList && position.missingList.length === 0 ? "green" : "red" }}>
                                                                                    {position.missingList ? position.missingList.length : "loading"} task(s) from protocol cannot be found in the position (missing task)
                                                                                </Typography>
                                                                                {
                                                                                    position.missingList && position.missingList.length > 0 ?
                                                                                        <Box marginLeft={2}>
                                                                                            {position.missingList.map(protocolTask =>
                                                                                                <Typography>Name: {protocolTask.name}, T0: {moment(protocolTask.t0).format("HH:mm DD/MM/YYYY")}</Typography>
                                                                                            )}
                                                                                        </Box>
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </Box>
                                                                        </Box>
                                                                    )}
                                                                </Box>
                                                                :
                                                                "loading"
                                                        }
                                                    </Box>
                                                )}
                                            </Box>
                                            :
                                            "loading"
                                    }
                                </Box>

                            )}
                        </Box> :
                        null
                }

            </div>
        );
    }
}

export default StudyCheck;