/* eslint-disable @typescript-eslint/ban-types */
import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import { useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { initClient, InitClientInterface } from "../initClient";

type UseQueryType<ItemType> = {
  loading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  items: ItemType[];
  nextToken: string | null;
  refetch: () => void;
};

export type QueryListConfigType<VariableType extends {}> = {
  query: string;
  key: string;
  variables?: VariableType;
  client?: InitClientInterface;
};

const useQueryList = <ItemType extends {}, VariablesType extends {} = {}>(
  config: QueryListConfigType<VariablesType>
): UseQueryType<ItemType> => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>();
  const [items, setItems] = useState<ItemType[]>([]);
  const [nextToken, setNextToken] = useState<string | null>(null);

  const fetchQuery = async (
    queryFetch: string,
    variablesFetch?: VariablesType
  ) => {
    setLoading(true);
    try {
      let rowResult;
      const clientOverride = initClient(config.client);
      if (clientOverride) {
        rowResult = await clientOverride.query({
          query: gql(queryFetch),
          variables: variablesFetch
        });
      } else {
        rowResult = await API.graphql(
          graphqlOperation(queryFetch, variablesFetch)
        );
      }

      const {
        data: {
          [config.key]: { items: listItems, nextToken: fetchedNextToken }
        }
      } = rowResult as {
        data: {
          [key: string]: { items: ItemType[]; nextToken: string | null };
        };
      };
      setItems(listItems);
      setNextToken(fetchedNextToken);
    } catch (errorFetch) {
      // eslint-disable-next-line no-console
      console.log(errorFetch);
      setError(errorFetch);
    } finally {
      setLoading(false);
    }
  };

  const refetch = () => {
    fetchQuery(config.query, config.variables);
  };

  useDeepCompareEffect(() => {
    fetchQuery(config.query, config.variables);
  }, [config.query, config.variables]);

  return {
    nextToken,
    loading,
    items,
    error,
    refetch
  };
};

export default useQueryList;
